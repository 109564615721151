<script setup>
import iconShield from "../assets/images/icon-shield.svg";
import useVuelidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import { reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AuthService from "../services/auth.service";

const route = useRoute();
const router = useRouter();
const error = ref();

const state = reactive({
  email: "",
  password: "",
  confirm_password: "",
  notice_validated: false,
});
const rules = computed(() => {
  return {
    password: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    confirm_password: {
      //required: helpers.withMessage('Le champ est requis !', required),
      sameAsPassword: helpers.withMessage(
        "Le mot de passe est différent !",
        sameAs(state.password)
      ),
    },
    notice_validated: {
      mustBeTrue: helpers.withMessage(
        "Veuillez valider la notice !",
        (value) => value === true
      ),
    },
  };
});
const v$ = useVuelidate(rules, state);

async function submitForm() {
  try {
    error.value = null;
    await AuthService.validateAccount(route.params.token, state);
    router.push({ path: `/login` });
  } catch (e) {
    if (e.response.data && e.response.data.info) {
      error.value = e.response.data.info;
    } else {
      error.value =
        "Une erreur inattendue est survenue, veuillez contacter un administrateur !";
    }
  }
}
</script>

<template>
  <AuthTemplate :icon="iconShield">
    <h4>Première connexion</h4>
    <h5>Redéfinition de votre mot de passe</h5>
    <span
      >Merci de modifier votre mot de passe avec un minimum de 8 chiffres</span
    >

    <div class="field">
      <input
        v-model="state.email"
        type="text"
        placeholder="Confirmez votre adresse email"
        @blur="v$.email.$touch"
      />
    </div>

    <div :class="{ error: v$.password.$errors.length }" class="field">
      <input
        v-model="state.password"
        type="password"
        placeholder="Nouveau mot de passe"
        @blur="v$.password.$touch"
      />
      <div
        v-for="error of v$.password.$errors"
        :key="error.$uid"
        class="input-errors"
      >
        <div class="error-msg">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div :class="{ error: v$.confirm_password.$errors.length }" class="field">
      <input
        v-model="state.confirm_password"
        type="password"
        placeholder="Confirmation de mot de passe"
        @blur="v$.confirm_password.$touch"
      />
      <div
        v-for="error of v$.confirm_password.$errors"
        :key="error.$uid"
        class="input-errors"
      >
        <div class="error-msg">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <RadioYesNo
      label="Pour finaliser votre compte vous devez valider la notice d'utilisation"
      :is-italic="true"
      :default-value="state.masterIsOwnerDog"
      :disabled="false"
      @on-change="(newValue) => (state.notice_validated = newValue)"
      id="masterIsOwnerDog"
      @on-click="router.push({ path: '/politique-de-confidentialite' })"
    />
    <span v-if="error" class="error">{{ error }}</span>
    <Button
      type="primary"
      :disabled="v$.$invalid"
      :margin-top="error ? '10px' : '50px'"
      @on-click="submitForm()"
    >
      Validation
    </Button>
    <div style="margin-bottom: 100px" />
  </AuthTemplate>
</template>
<style lang="scss" scoped>
h4 {
  font-size: 26px;
  font-family: "NunitoSansLight";
  margin-bottom: 5px;
}
h5 {
  margin-top: 0px;
  font-size: 26px;
  text-align: center;
}
span {
  font-size: 18px;
  text-align: center;
}
.error {
  margin-top: 50px;
}
.field {
  width: 100%;
  margin-bottom: 30px;

  input {
    margin-top: 10px;
    height: 40px;
    width: 100%;
  }
}
</style>
