<script setup>
import { useStore } from "../../stores";
import { computed } from "vue";

const store = useStore();
const isAdmin = computed(() => store.userIsAdmin);
</script>

<template>
  <template v-if="!isAdmin">
    <slot />
  </template>
</template>
