<script>
export default {
  props: {
    submit : Boolean,
    type: String,
    marginTop: String,
    size : String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClick'],
};
</script>
<template>
  <button

    :type="submit ? 'submit' : 'button'"
    :style="{ marginTop: marginTop ? marginTop : '0px' }"
    :class="{
      primary: type == 'primary',
      transparent: type == 'transparent',
      secondary: type == 'secondary',
      danger : type == 'danger',
      green: type === ('green'),
      'secondary-green': type === ('secondary-green'),
      small : size?.includes('small')
      
    }"
    :disabled="disabled"
    @click="$emit('onClick')"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
@import '../../assets/css/_variables.scss';
button {
  min-height: 45px;
  border: 0px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 15px;
  transition: 0.3s;
  font-weight: 500;
  cursor: pointer;  
  font-family: 'NunitoSans';

  &.small {
      height: 35px;
  }
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.primary {
  background-color: $primary;
  color: white;
}

.transparent {
  background-color: transparent;
  color: #a9af9a;
}
.secondary {
  background-color: $blue-light;
  color: $primary;
}

.green {
  background-color: $green;
  color: #fff;
}

.danger{
  background: $danger;
  color: #fff;
}

.secondary-green {
  color: $green;
  background-color: $sand;
  border: 1px solid $sand;
}

@media screen and (min-width: 1281px) {
  button {
    max-width: 300px;
  }
}
</style>
