<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 9H18V6C18 2.69166 15.3083 0 12 0C8.69166 0 6 2.69166 6 6V9H4.5C2.84316 9 1.5 10.3432 1.5 12V21C1.5 22.6568 2.84316 24 4.5 24H19.5C21.1568 24 22.5 22.6568 22.5 21V12C22.5 10.3432 21.1568 9 19.5 9ZM8.25 6C8.25 3.93239 9.93239 2.25 12 2.25C14.0676 2.25 15.75 3.93239 15.75 6V9H8.25V6ZM20.25 21C20.25 21.4135 19.9135 21.75 19.5 21.75H4.5C4.08647 21.75 3.75 21.4135 3.75 21V12C3.75 11.5865 4.08647 11.25 4.5 11.25H19.5C19.9135 11.25 20.25 11.5865 20.25 12V21ZM12 14.25C11.3789 14.25 10.875 14.7539 10.875 15.375V17.625C10.875 18.2461 11.3789 18.75 12 18.75C12.6211 18.75 13.125 18.2461 13.125 17.625V15.375C13.125 14.7539 12.6211 14.25 12 14.25Z"
      fill="#659F12"
    />
  </svg>
</template>