<script setup>
import {ref, watch} from 'vue'

import TextInputController from "../../../../components/form/TextInputController/TextInputController.vue";
import {
  trueOrFalseConfig,
  hasTechnicalFormations,
  multipleYesConfig,
  favorableOrNotConfig,
  yesNoMaybeConfig,
} from "../../../../config/MasterConfig";
import useForm from "../../../../compositions/useForm";

const props = defineProps({
  master: Object,
  owner: Object,
  dog: Object,
  census: Object,
  masterValidator: Object,
  ownerValidator: Object,
  readOnly: Boolean,
  disabled: Boolean,
  dogMasterEntries: Array,
  dogMasterList: Array,
  masterIsOwnerDog: Boolean,
  ownerIsOwnerExploitation: Boolean,
  masterIsOwnerExploitation: Boolean,
  masterId: Number,
  ownerId: Number,
  ownerSocialReason: String,
  masterSocialReason: String,
})

const emit = defineEmits([
  'update:master',
  'update:owner',
  'update:dog',
  'update:masterIsOwnerDog',
  'update:ownerIsOwnerExploitation',
  'update:masterIsOwnerExploitation',
  'update:masterId',
  'update:ownerId',
  'update:ownerSocialReason',
  'update:masterSocialReason',
]);

const  { isRequired } = useForm()

const error = ref(null);

const handleMasterChange = (value, name) => {
  emit('update:master', {
    ...props.master,
    [name]: value
  })
}
const handleOwnerChange = (value, name) => {
  emit('update:owner', {
    ...props.owner,
    [name]: value
  })

  if (props.masterIsOwnerDog) {
    handleMasterChange(value, name)
  }
}
const handleOwnerExploit = (value) => {
  const exploit = props.census.exploitation;

  if (value) {
    if (!exploit.owner) {
      emit('update:ownerId', -1);
    } else {
      emit('update:ownerId', exploit.owner.id)
      if (props.masterIsOwnerDog) {
        emit('update:masterId', exploit.owner.id)
      }
    }
  } else {
    if (props.owner?.id === exploit?.owner?.id) {
      emit('update:ownerId', -1);
      if (props.masterIsOwnerDog) {
        emit('update:masterId', -1);
      }
    }
  }

  emit('update:ownerIsOwnerExploitation', value);
}
const handleMasterExploitationChange = (value) => {
  const exploit = props.census.exploitation;
  if (value) {
    if (!exploit.owner) {
      emit('update:masterId', -1);
    } else {
      emit('update:masterId', exploit.owner.id)
      if (props.masterIsOwnerDog) {
        emit('update:ownerId', exploit.owner.id)
      }
    }
  } else {
    if (props.masterId === exploit?.owner.id) {
      emit('update:masterId', -1);
      if (props.masterIsOwnerDog) {
        emit('update:ownerId', -1);
      }
    }
  }
}

watch(() => props.ownerSocialReason, (value) => {
  if (value === "social") {
    handleOwnerChange(null, 'firstname');
    handleOwnerChange(null, 'lastname');
  } else {
    handleOwnerChange("", 'companyname');
  }
});
watch(() => props.masterSocialReason, (value) => {
  if (value === "social") {
    handleMasterChange(null, 'firstname');
    handleMasterChange(null, 'lastname');
  } else {
    handleMasterChange("", 'companyname');
  }
});
</script>

<template>
  <form>
    <div class="holding">
      <div class="holding-head">
        <p>INFORMATIONS SUR LE PROPRIÉTAIRE</p>
      </div>

      <div class="holding-body">
        <div class="holding-body-cat">
          <TitleLine
              title="Propriétaire du chien"
              type="master"
          />

          <RadioYesNo
              :key="JSON.stringify(ownerIsOwnerExploitation)"
              label="Le propriétaire du chien est celui de l'exploitation"
              :is-italic="true"
              :default-value="ownerIsOwnerExploitation"
              :disabled="disabled"
              @on-change="(value) => handleOwnerExploit(value)"
              id="ownerIsOwnerExploitation"
              :read-only="readOnly"
          />

          <RadioYesNo
              :key="JSON.stringify(masterIsOwnerDog)"
              label="Le propriétaire est le maître du chien"
              :is-italic="true"
              :default-value="masterIsOwnerDog"
              :disabled="disabled"
              @on-change="(value) => $emit('update:masterIsOwnerDog', value)"
              id="masterIsOwnerDog"
              :read-only="readOnly"
          />

          <SelectInputController
              :disabled="disabled"
              :key="JSON.stringify(dogMasterEntries)"
              custom-class="master-input"
              :name="'owner'"
              :model="ownerId ?? -1"
              :on-change="(value) => $emit('update:ownerId', value)"
              :options="dogMasterEntries"
              :label="'Propriétaire du chien'"
              :read-only="readOnly"
          />

          <SelectInputController
              :disabled="disabled"
              :key="JSON.stringify(ownerSocialReason)"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="(value) => emit('update:ownerSocialReason', value)"
              :name="'reasonOwner'"
              :label="'Raison sociale'"
              :model="ownerSocialReason"
              :placeholder="'Particulier'"
              :options="[
                { value: 'particular', label: 'Particulier' },
                { value: 'social', label: 'Raison Sociale' },
              ]"
          />

          <TextInputController
              v-if="ownerSocialReason === 'social'"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleOwnerChange"
              :name="'companyname'"
              :model="owner.companyname"
              label="Nom de la société"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="ownerSocialReason === 'particular'"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleOwnerChange"
              :name="'lastname'"
              :model="owner.lastname"
              label="Nom du propriétaire"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="ownerSocialReason === 'particular'"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleOwnerChange"
              :name="'firstname'"
              :model="owner.firstname"
              label="Prénom du propriétaire"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="ownerSocialReason === 'particular'"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleOwnerChange"
              :name="'address'"
              :model="owner.address"
              label="Adresse"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <div class="tw-flex tw-space-x-5 tw-items-start tw-justify-between">
            <TextInputController
                custom-class="master-input"
                :read-only="readOnly"
                :on-change="handleOwnerChange"
                :name="'zipCode'"
                :type="'zipCode'"
                :model="owner.zipCode"
                label="Code postal"
                placeholder=""
                :disabled="disabled"
                :maxlength="5"
                :class="{'is-invalid': ownerValidator.zipCode.$invalid}"
                :errors="ownerValidator.zipCode.$errors"
                :on-blur="ownerValidator.zipCode.$touch"
                :required="isRequired(ownerValidator.zipCode)"
            />

            <TextInputController
                custom-class="master-input"
                :read-only="readOnly"
                :on-change="handleOwnerChange"
                :name="'city'"
                :model="owner.city"
                label="Ville"
                placeholder=""
                :disabled="disabled"
                :required="true"
            />
          </div>

          <TextInputController
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleOwnerChange"
              :name="'email'"
              :model="owner.email"
              :class="{'is-invalid': ownerValidator.email.$invalid}"
              :errors="ownerValidator.email.$errors"
              :on-blur="ownerValidator.email.$touch"
              :required="isRequired(ownerValidator.email)"
              label="Mail du propriétaire"
              placeholder=""
              :disabled="disabled"
          />

          <TextInputController
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleOwnerChange"
              :name="'phoneNumber'"
              :type="'phone'"
              :model="owner.phoneNumber"
              label="Téléphone du propriétaire"
              placeholder=""
              :maxlength="10"
              :disabled="disabled"
          />
        </div>
      </div>
    </div>

    <div class="holding" v-if="!masterIsOwnerDog">
      <div class="holding-head">
        <p>INFORMATIONS SUR LE MAÎTRE</p>
      </div>
      <div class="holding-body">
        <div class="holding-body-cat">
          <RadioYesNo
              :key="JSON.stringify(masterIsOwnerExploitation)"
              label="Le maître est le propriétaire de l'exploitation"
              :is-italic="true"
              :model="masterIsOwnerExploitation"
              :default-value="masterIsOwnerExploitation"
              :name="'masterIsOwnerExploitation'"
              :disabled="disabled"
              @on-change="(value) => handleMasterExploitationChange(value)"
              id="masterIsOwnerExploitation"
              :read-only="readOnly"
          />

          <TitleLine title="Identité du maître" type="master"/>

          <SelectInputController
              :key="JSON.stringify(dogMasterEntries)"
              custom-class="master-input"
              :name="'master'"
              :model="masterId ?? -1"
              :on-change="(value) => emit('update:masterId', value)"
              :options="dogMasterEntries"
              :label="'Maître du chien'"
              :read-only="readOnly"
          />

          <SelectInputController
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="(value) =>   emit('update:masterSocialReason', value)"
              :name="'reasonMaster'"
              :label="'Raison sociale'"
              :model="masterSocialReason"
              :placeholder="'Particulier'"
              :options="[
                { value: 'particular', label: 'Particulier' },
                { value: 'social', label: 'Raison Sociale' },
              ]"
          />
          <TextInputController
              v-if="masterSocialReason === 'particular'"
              style="margin-top: 20px"
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'lastname'"
              :model="master.lastname"
              label="Nom"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="masterSocialReason === 'particular'"
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'firstname'"
              :model="master.firstname"
              label="Prénom"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="masterSocialReason === 'social'"
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'companyname'"
              :model="master.companyname"
              label="Nom de la société"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <TextInputController
              v-if="masterSocialReason === 'particular'"
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'address'"
              :model="master.address"
              label="Adresse"
              placeholder=""
              :disabled="disabled"
              :required="true"
          />

          <div class="tw-flex tw-space-x-5 tw-items-start tw-justify-between">
            <TextInputController
                custom-class="master-input"
                :read-only="readOnly"
                :on-change="handleMasterChange"
                :name="'zipCode'"
                :type="'zipCode'"
                :model="master.zipCode"
                label="Code postal"
                placeholder=""
                :disabled="disabled"
                :maxlength="5"
                :class="{'is-invalid': masterValidator.zipCode.$invalid}"
                :errors="masterValidator.zipCode.$errors"
                :on-blur="masterValidator.zipCode.$touch"
                :required="isRequired(masterValidator.zipCode)"
            />

            <TextInputController
                custom-class="master-input"
                :read-only="readOnly"
                :on-change="handleMasterChange"
                :name="'city'"
                :model="master.city"
                label="Ville"
                placeholder=""
                :disabled="disabled"
                :required="true"
            />
          </div>

          <TextInputController
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'email'"
              :model="master.email"
              label="Mail"
              :class="{'is-invalid': masterValidator.email.$invalid}"
              :errors="masterValidator.email.$errors"
              :on-blur="masterValidator.email.$touch"
              :required="isRequired(masterValidator.email)"
              placeholder=""
              :disabled="disabled"
          />

          <TextInputController
              custom-class="master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              :name="'phoneNumber'"
              :type="'phone'"
              :model="master.phoneNumber"
              label="Téléphone"
              placeholder=""
              :maxlength="10"
              :disabled="disabled"
          />
        </div>
      </div>
    </div>

    <div
        class="holding">
      <div class="holding-head">
        <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
      </div>
      <div class="holding-body">
        <div class="holding-body-cat">
          <TextInputController
              style="margin-top: 10px"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleMasterChange"
              :name="'usedCp'"
              :model="master.usedCp"
              label="Nombre de chiens de protection utilisés"
              placeholder=""
              :disabled="disabled"
              :type="'num'"
              :class="{'is-invalid': masterValidator.usedCp.$invalid}"
              :errors="masterValidator.usedCp.$errors"
              :on-blur="masterValidator.usedCp.$touch"
              :required="isRequired(masterValidator.usedCp)"
          />

          <TextInputController
              style="margin-top: 10px"
              :read-only="readOnly"
              custom-class="master-input"
              :on-change="handleMasterChange"
              :name="'yearRegister'"
              :model="master.yearRegister"
              label="Utilisateur depuis... (Notez l'année)"
              placeholder=""
              :disabled="disabled"
              :type="'num'"
              :maxlength="4"
              :class="{'is-invalid': masterValidator.yearRegister.$invalid}"
              :errors="masterValidator.yearRegister.$errors"
              :on-blur="masterValidator.yearRegister.$touch"
              :required="isRequired(masterValidator.yearRegister)"
          />

          <SelectInputController
              custom-class="Select master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              name="hasGrownPuppy"
              label="A-t-il déjà produit des chiots ?"
              :model="master.hasGrownPuppy"
              :options="trueOrFalseConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.hasGrownPuppy.$invalid}"
              :errors="masterValidator.hasGrownPuppy.$errors"
              :on-blur="masterValidator.hasGrownPuppy.$touch"
              :required="isRequired(masterValidator.hasGrownPuppy)"
          />

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="hasCpFormation"
              label="A-t-il suivi au moins une formation sur les CP ? (plusieurs choix possibles)"
              :model="master.hasCpFormation"
              :options="hasTechnicalFormations"
              :disabled="disabled"
              :is-multiple="true"
              :config="hasTechnicalFormations"
              :class="{'is-invalid': masterValidator.hasCpFormation.$invalid}"
              :errors="masterValidator.hasCpFormation.$errors"
              :on-blur="masterValidator.hasCpFormation.$touch"
              :required="isRequired(masterValidator.hasCpFormation)"
          />

          <SelectInputController
              custom-class="Select master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              name="hasTechnicalFormation"
              label="A-t-il bénéficié d'un accompagnement technique ? (plusieurs choix possibles)"
              :model="master.hasTechnicalFormation"
              :options="hasTechnicalFormations"
              :disabled="disabled"
              :is-multiple="true"
              :config="hasTechnicalFormations"
              :class="{'is-invalid': masterValidator.hasTechnicalFormation.$invalid}"
              :errors="masterValidator.hasTechnicalFormation.$errors"
              :on-blur="masterValidator.hasTechnicalFormation.$touch"
              :required="isRequired(masterValidator.hasTechnicalFormation)"
          />
        </div>
      </div>
    </div>

    <div
        class="holding">
      <div class="holding-head">
        <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
      </div>
      <div class="holding-body">
        <div class="holding-body-cat">
          <div class="tooltip-row">
            <TitleLine
                title="Appréciation de l’envie du propriétaire/maître à intégrer un réseau de « producteurs » de chiens"
                type="master"
            />
            <Tooltip
                custom-class="master-input"
                text="Questionner l’éleveur que s’il correspond au profil et peut offrir aux chiots des conditions d’élevage satisfaisantes ; sinon, ne pas aborder le sujet. "
            />
          </div>

          <SelectInputController
              custom-class="Select master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              name="profileMatch"
              label="Le maître correspond-il au profil pour intégrer le réseau de producteur selon vous ?"
              :model="master.profileMatch"
              :options="trueOrFalseConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.profileMatch.$invalid}"
              :errors="masterValidator.profileMatch.$errors"
              :on-blur="masterValidator.profileMatch.$touch"
              :required="isRequired(masterValidator.profileMatch)"
          />
          <div v-if="master.profileMatch === true" class="sub-title">
            Est-il disposé à
          </div>
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="hasRegularVisit"
              label="Recevoir la visite régulière d'un conseiller pour avoir un suivi"
              :model="master.hasRegularVisit"
              :options="yesNoMaybeConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.hasRegularVisit.$invalid}"
              :errors="masterValidator.hasRegularVisit.$errors"
              :on-blur="masterValidator.hasRegularVisit.$touch"
              :required="isRequired(masterValidator.hasRegularVisit)"
          />
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="breedChart"
              label="Suivre une Charte de Bonnes Pratiques d’Elevage ?"
              :model="master.breedChart"
              :options="trueOrFalseConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.breedChart.$invalid}"
              :errors="masterValidator.breedChart.$errors"
              :on-blur="masterValidator.breedChart.$touch"
              :required="isRequired(masterValidator.breedChart)"
          />
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="couplings"
              label="Mettre en place des accouplements raisonnés (se déplacer pour faire saillir sa femelle ou son mâle,
              faire des tests génétiques aux parents et aux chiots…)?"
              :model="master.couplings"
              :options="trueOrFalseConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.couplings.$invalid}"
              :errors="masterValidator.couplings.$errors"
              :on-blur="masterValidator.couplings.$touch"
              :required="isRequired(masterValidator.couplings)"
          />
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="hasSelfFormation"
              label="Se former / s’informer régulièrement ?"
              :model="master.hasSelfFormation"
              :options="trueOrFalseConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.hasSelfFormation.$invalid}"
              :errors="masterValidator.hasSelfFormation.$errors"
              :on-blur="masterValidator.hasSelfFormation.$touch"
              :required="isRequired(masterValidator.hasSelfFormation)"
          />
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="batchCaring"
              label="A-t-il le temps, la place et l’envie de s’occuper d’une portée ?"
              :model="master.batchCaring"
              :options="multipleYesConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.batchCaring.$invalid}"
              :errors="masterValidator.batchCaring.$errors"
              :on-blur="masterValidator.batchCaring.$touch"
              :required="isRequired(masterValidator.batchCaring)"
          />
          <SelectInputController
              v-if="master.profileMatch === true"
              :read-only="readOnly"
              custom-class="Select master-input"
              :on-change="handleMasterChange"
              name="brokenDogs"
              label="A-t-il le temps, la place et l’envie de produire un ou des chiens débourrés (7-8 mois) ?"
              :model="master.brokenDogs"
              :options="multipleYesConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.brokenDogs.$invalid}"
              :errors="masterValidator.brokenDogs.$errors"
              :on-blur="masterValidator.brokenDogs.$touch"
              :required="isRequired(masterValidator.brokenDogs)"
          />
        </div>
      </div>
    </div>

    <div
        class="holding">
      <div class="holding-head">
        <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
      </div>
      <div class="holding-body">
        <div class="holding-body-cat">
          <div class="tooltip-row">
            <TitleLine
                title="Conclusion technique par le recenseur"
                type="master"
            />
            <Tooltip
                custom-class="master-input"
                text="A remplir hors présence de l’éleveur. Objectif : repérer les conditions vraiment défavorables."
            />
          </div>

          <SelectInputController
              custom-class="Select master-input"
              :read-only="readOnly"
              :on-change="handleMasterChange"
              name="puppyGrowth"
              label="Environnement d'élevage (pour des chiots) ?"
              :model="master.puppyGrowth"
              :options="favorableOrNotConfig"
              :disabled="disabled"
              :class="{'is-invalid': masterValidator.puppyGrowth.$invalid}"
              :errors="masterValidator.puppyGrowth.$errors"
              :on-blur="masterValidator.puppyGrowth.$touch"
              :required="isRequired(masterValidator.puppyGrowth)"
          />

          <div class="notes">
            <div class="sub-title">Commentaire</div>
            <textarea
                :value="master.notes"
                :disabled="disabled || readOnly"
                @input="handleMasterChange($event.target.value, 'notes')"
                :style="[disabled || readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
            />
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<style lang="scss" scoped>
@import "../../../../assets/css/_variables.scss";

.buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

h1 {
  font-size: 16px;
}

.container-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 1em;

  .title {
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .border {
    margin-left: 10px;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid $censusDog;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .lof {
    display: flex;
    align-items: center;
  }

  .title-line {
    margin-top: 1em;
    margin-bottom: 1em;
    color: $censusDog;
  }
}

.lof {
  display: flex;
  align-items: center;
}

.lof-switch {
  margin-left: 1em !important;
  margin-top: 0 !important;
  margin-bottom: 7% !important;
  width: 50% !important;
}

.light-blue {
  color: $censusDog;
}

.medium-blue {
  color: $censusMaster;
}

.orange {
  color: $censusHolding;
}

.holding {
  &-head {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1em 2em;
    color: white;
    background-color: $censusMaster;

    &.has-tooltip {
      position: relative;
    }

    p {
      margin: 0 !important;
      font-size: 15px;
    }
  }

  &-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 1em 1em;

    &-cat {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-top: 1em;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-icon-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }

      &-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }
    }
  }
}

.sub-title {
  font-size: 13px;
}

.sub-title-tooltip {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
  margin-bottom: 1em;
}

.carac {
  margin-top: 2em;

  &-selects {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    border-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 15px 2em;
  }
}

.date-picker {
  position: relative;
}

textarea {
  background: white;
  border: 1px solid $censusMaster;
  border-radius: 30px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  margin-top: 1em;
  height: 250px;
  width: 92%;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

.blured {
  filter: blur(5px);
  pointer-events: none;
}

form {
  transition: filter 200ms;
}

.has-switch {
  &-switch {
    width: 50% !important;
    margin-left: 1em;
  }

  display: flex;
  align-items: center;
}

.other-specie {
  margin-top: 1em !important;
}

.period-title {
  margin-top: 0.5em;
}

.error {
  color: $danger;
}

p {
  font-size: 14px;
}

.identity-body {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 2em 1em;
  margin-bottom: 1em;
}

.form-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #3f3f3f;
}

.house-icon {
  padding-right: 1em;
}

.little-title {
  margin: 0;
}

.notes {
  textarea {
    text-transform: uppercase;
  }
}

.notes-title {
  position: relative;
  display: flex;
  align-items: center;
}

.no-dogs {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 2em;
}

.label-is-lof label {
  width: 12%;
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
  position: relative;
}
</style>
