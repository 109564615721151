export const dangerosityCategory = [
  { label: "Catégorie 1", value: "category_1" },
  { label: "Catégorie 2", value: "category_2" },
  { label: "Jeune non catégorisable", value: "not_categorizable_too_young" },
  { label: "Non catégorisé", value: "uncategorized}" },
];

export const sexeConfig = [
  { value: "full_male", label: "Mâle entier" },
  { value: "castrated_male", label: "Mâle castré" },
  { value: "full_female", label: "Femelle entière" },
  { value: "sterilized_female", label: "Femelle stérilisée" },
];

export const appearanceConfig = [
  { value: "pure_lof", label: "Race pure LOF" },
  {
    value: "standard",
    label:
      "Apparence raciale (deux parents de même race et le chien ressemble au « standard »)",
  },
  { value: "cross", label: "Croisé (races de protection)" },
  { value: "undetermined", label: "Indéterminé" },
];

export const identificationType = [
  { value: "chip", label: "Puce (vérification avec un lecteur)" },
  { value: "tatoo", label: "Tatouage" },
  { value: "unidentified", label: "En attente du numéro d’identification" },
  {
    value: "impossible",
    label: "Impossible de vérifier la puce avec le lecteur",
  },
];

export const typeOfSystem = [
  { value: "totally_outdoor", label: "Plein-air intégral" },
  { value: "outdoor_and_indoor", label: "Plein-air et bâtiment" },
  { value: "indoor", label: "Uniquement en bâtiment" },
];

export const bornHerdConfig = [
  { value: "totally_outdoor", label: "Plein-air intégral" },
  { value: "outdoor_and_indoor", label: "Plein-air et bâtiment" },
  { value: "indoor", label: "Uniquement en bâtiment" },
];

export const atWorkConfig = [
  { value: "true", label: "Oui" },
  { value: "false", label: "Non" },
  { value: "null", label: "Ne sait pas" },
];

export const isAlwaysUsableConfig = [
  { value: "true", label: "Oui" },
  { value: "false", label: "Non" },
  { value: "null", label: "Ne sait pas" },
];

export const trueOrFalseConfig = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];

export const nonConformConfig = [
  { value: "true", label: "OK" },
  { value: "false", label: "NC" },
  { value: "null", label: "Ne sait pas" },
];

export const testicleConfig = [
  { value: "true", label: "OK" },
  { value: "false", label: "NC" },
  { value: "nc", label: "Non concerné" },
  { value: "null", label: "Ne sait pas" },
];

export const pigmentationConfig = [
  { value: true, label: "OK" },
  { value: false, label: "Dépigmenté" },
];

export const hipDysplasiaConfig = [
  { value: "no_test", label: "Pas de test" },
  { value: "grade_a", label: "Grade A" },
  { value: "grade_b", label: "Grade B" },
  { value: "grade_c", label: "Grade C" },
  { value: "grade_d", label: "Grade D" },
  { value: "grade_e", label: "Grade E" },
];

export const elbowDysplasiaConfig = [
  { value: "no_test", label: "Pas de test" },
  { value: "grade_o", label: "Grade 0" },
  { value: "grade_sl", label: "Grade SL" },
  { value: "grade_dc1", label: "Grade DC1" },
  { value: "grade_dc2", label: "Grade DC2" },
  { value: "grade_dc3", label: "Grade DC3" },
];

export const introductionConditionConfig = [
  { value: "alone", label: "Seul" },
  { value: "other_puppies", label: `Avec un ou d'autres chiots` },
  { value: "with_adults", label: `Avec un ou des adultes` },
];

export const introductionConditionPuppiesConfig = [
  { value: "same_brotherhood", label: "De la même fratrie" },
  { value: "different_brotherhood", label: `D'une fratrie différente` },
];

export const introductionConditionAdultsConfig = [
  { value: "already_present", label: "Déjà présent(s)" },
  { value: "same_time", label: `Introduit(s) en même temps (<< marraine >>)` },
];

export const parcelConfig = [
  { value: "dedicated", label: "Sur un lot dédié" },
  { value: "heifer", label: "Sur le troupeau" },
  { value: "sheepfold", label: "En bergerie" },
  { value: "outdoor", label: "En plein-air" },
];

export const parcelHeifersConfig = [
  { value: "agnelles", label: "Sur des agnelles" },
  { value: "butcher_lambs", label: "Sur des agneaux de boucherie" },
  { value: "brebis", label: "Sur des brebis" },
  { value: "situtated_brebis", label: "Sur des brebis suitées" },
  { value: "rams", label: "Sur des béliers" },
  { value: "little_goats", label: "Sur des chevrettes" },
  { value: "goats", label: "Sur des chèvres" },
  { value: "situtated_goats", label: "Sur des chèvres suitées" },
  { value: "goats_male", label: "Sur des boucs" },
  { value: "heifers", label: "Sur des génisses" },
  { value: "cows", label: "Sur des vaches" },
  { value: "situated_cows", label: "Sur des vaches suitées" },
  { value: "camelids", label: "Sur des camélidés" },
  { value: "chickens", label: "Sur des volailles" },
  { value: "horses", label: "Sur des chevaux" },
  { value: "asins", label: "Sur des asins" },
  { value: "others", label: "Autres" },
];

export const behaviorWithHumanConfig = [
  { value: "distrustful", label: "Plutôt méfiant" },
  { value: "confident", label: "Confiant" },
  { value: "clingy", label: "Plutôt collant" },
];

export const temperConfig = [
  { value: "calm", label: "Calme" },
  { value: "active", label: "Actif" },
];

export const isRespectingOthersConfig = [
  { value: "yes", label: "Oui" },
  { value: "little", label: "Non (jeux, blessures...) : un peu" },
  { value: "a_lot", label: "Non (jeux, blessures...) : beaucoup" },
];

export const isEasyDogConfig = [
  { value: "true", label: "Facile" },
  { value: "false", label: "Difficile" },
  { value: "null", label: "Ne sait pas" },
];

export const trustWithOthersConfig = [
  { value: "full_yes", label: "Oui, tout à fait" },
  { value: "yes", label: "Oui, plutôt" },
  { value: "no", label: "Non" },
];

export const trustOthersWithoutYouConfig = [
  { value: "full_yes", label: "Oui, tout à fait" },
  { value: "yes", label: "Oui, plutôt" },
  { value: "no", label: "Non" },
];

export const positionInGroupConfig = [
  { value: "dominant", label: "Dominant" },
  { value: "intermediate", label: "Intermédiaire" },
  { value: "submissive", label: "Soumis" },
  { value: `dont_know`, label: "Ne sait pas" },
];

export const canWorkAloneConfig = [
  { value: "true", label: "Oui" },
  {
    value: "false",
    label: "Non, a absolument besoin d'un humain pour rester au troupeau",
  },
  {
    value: "need_dog",
    label: "Non, a absolument besoin d'un autre chien pour rester au troupeau",
  },
  { value: "null", label: "Ne sait pas" },
];

export const presentHerdConfig = [
  { value: "yes", label: "Oui" },
  { value: "no_rambling", label: "Non, divagant" },
  { value: "no", label: "Non (parc de repos, chenil, attache)" },
];

export const watchfulAwakeningConfig = [
  { value: "full", label: "Oui, tout à fait " },
  { value: "yes", label: "Oui, plutôt" },
  { value: "no", label: "Non " },
];

export const hasExcessiveBarkingConfig = [
  { value: true, label: "A bon escient " },
  { value: false, label: "Excessifs" },
];

export const distanceHerdConfig = [
  { value: "close", label: "Toujours au troupeau" },
  {
    value: "depend",
    label:
      "Régulièrement en périphérie du troupeau, pdt qqes dizaines de minutes",
  },
  {
    value: "far",
    label: "Régulièrement à grandes distances, pendant plusieurs heures",
  },
];

export const fenceRespectConfig = [
  { value: "always", label: "Oui, toujours" },
  {
    value: "depend",
    label: "Pas toujours selon le type de clôture ou le stimulus",
  },
  { value: "no", label: "Non" },
  { value: "no_precision", label: "Sans objet" },
];

export const runAfterVehiclesConfig = [
  {
    value: "always",
    label: "Oui, systématiquement, quelle que soit la vitesse du véhicule ",
  },
  {
    value: "depend",
    label: "Oui, de temps en temps (notamment si la vitesse est stimulante) ",
  },
  { value: "never", label: "Non, jamais" },
  { value: "without_object", label: "Sans objet" },
];

export const canNotJudgeConfig = [
  { value: "true", label: "Bonne" },
  { value: "false", label: "Mauvaise" },
  { value: "null", label: "Je ne peux pas juger" },
];

export const asPuppyConfig = [
  { value: "true", label: "Facile" },
  { value: "false", label: "Difficile" },
  { value: "null", label: "Je ne peux pas juger" },
];

export const dogsRecovery = [
  { value: "adapted", label: "Adaptée à la situation" },
  { value: "slow", label: "Trop lente" },
  { value: "dont_know", label: "Ne sait pas" },
];

export const raceConfig = [
  { value: "berger_central_asia", label: "Berger d'Asie centrale" },
  {
    value: "berger_tornjak",
    label: "Berger de Bosnie Herzégovine et de Croatie (Tornjak)",
  },
  { value: "berger_maremme", label: "Berger de la Maremme et des Abruzzes" },
  { value: "berger_russia", label: "Berger de Russie Méridionale" },
  { value: "berger_caucase", label: "Berger du Caucase" },
  { value: "berger_karst", label: "Berger du Karst" },
  { value: "bouvier_swiss", label: "Bouvier Suisse" },
  { value: "berger_tartras", label: "Chien de berger des Tatras" },
  { value: "berger_kangal", label: "Chien de berger Kangal" },
  { value: "berger_bucovine", label: "Chien de berger roumain de Bucovine" },
  { value: "berger_mioritza", label: "Chien de berger Roumain de Mioritza" },
  { value: "berger_carpathes", label: "Chien de berger Roumain des Carpathes" },
  {
    value: "berger_charplanina",
    label: "Chien de berger Yougoslave de Charplanina",
  },
  { value: "chien_laboreiro", label: "Chien de Castro Laboreiro" },
  { value: "chien_serra", label: "Chien de la Serra Estrela" },
  { value: "chien_aidi", label: "Chien de Montagne de l’atlas (Aïdi)" },
  { value: "chien_pyrenees", label: "Chien de Montagne des Pyrénées" },
  { value: "chien_bernard", label: "Chien du mont st Bernard" },
  { value: "dogue_tibet", label: "Dogue du Tibet" },
  { value: "hovawart", label: "Hovawart" },
  { value: "komondor", label: "Komondor" },
  { value: "kuvasz", label: "Kuvasz" },
  { value: "matin_pyrenees", label: "Mâtin des Pyrénées" },
  { value: "matin_espagnol", label: "Mâtin Espagnol" },
  { value: "matin_transmontano", label: "Mâtin Transmontano" },
  { value: "tchouvatch_slovaque", label: "Tchouvatch Slovaque" },
  { value: "akbash", label: "Akbash" },
  { value: "berger_karakachan", label: "Berger Bulgare Karakachan" },
  { value: "bouvier_mongol", label: "Bouvier Mongol (Bankhar)" },
  { value: "cane_mannara", label: "Cane di Mannara" },
  { value: "berger_sila", label: "Chien de berger du Sila" },
  { value: "berger_sicilien", label: "Chien de Berger sicilien" },
  { value: "gampr_armenien", label: "Gampr Arménien" },
  { value: "hellenikos", label: "Hellenikos Poimenikos" },
  { value: "qahderijani", label: "Qahderijani dog" },
  { value: "sarabi", label: "Sarabi Dog" },
];

export const zeroTwoMonthConfig = [
  { value: "brotherhood", label: "Avec une fratrie" },
  { value: "alone", label: "Seul (sans fratrie)" },
  { value: "dont_know", label: "Ne sait pas" },
];

export const yesOrNoConfig = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];

export const yesOrNoStringConfig = [
  { value: "true", label: "Oui" },
  { value: "false", label: "Non" },
];

export const considerDog = [
  {
    value: "smooth",
    label:
      "Souple (capable de changer de main...), bonne capacité d'adaptation",
  },
  { value: "attentive", label: "A l’écoute, attentif à l’Homme" },
  { value: "sensible", label: "Sensible à l’Homme" },
  { value: "independant", label: "Têtu, indépendant" },
  { value: "proud", label: "Sûr de lui" },
  { value: "calm", label: "Calme" },
  { value: "active", label: "Actif" },
  { value: "agressive", label: "Agressif" },
];

export const goodProtectorConfig = [
  { value: "absolutely", label: "Oui, tout à fait" },
  { value: "somehow", label: "Oui, plutôt" },
  { value: "little", label: "Oui, un peu" },
  { value: "no", label: "Non" },
];
