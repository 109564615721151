<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import DeleteElementModalComponent from "../../../../views/components/modal/delete/ElementDeleteModal.vue";
import {createToaster} from "@meforma/vue-toaster";
import useDogStore from "../../../../stores/useDogStore";
import {useStore} from "../../../../stores";

const store = useStore()
const dogStore = useDogStore()

const dog = ref(null)
const show = ref(false)
const loading = ref(true)
const modal = ref(null)

const props = defineProps({ dogId: Number })
const emit = defineEmits(['deleted', 'error'])
const toaster = createToaster({ position: "bottom" });

const fetchDog = async () => {
  loading.value = true
  dog.value = (await axios.get(`${process.env.VUE_APP_API_URL}dogs/${props.dogId}`)).data
  loading.value = false
}
const handleDelete = async () => {
  loading.value = true
  try{
    const response = await axios.delete(`${process.env.VUE_APP_API_URL}dogs/${props.dogId}`)
    if (response.status === 204) {
      toaster.success("Le chien a bien été supprimé")
      if (dog.value) {
          dogStore.deleteItem(dog.value.id)
        store.deleteDog(dog.value.id)
        const storedCensuses = localStorage.getItem("recensements") ? JSON.parse(localStorage.getItem("recensements")) : [];
        const filteredCensuses = storedCensuses.map(el => ({
          ...el,
          dogs: el.dogs ? el.dogs.filter(d => d.id !== dog.value.id): []
        }))
        localStorage.setItem("recensements",  JSON.stringify(filteredCensuses));
      }
      emit('deleted')
      modal.value.show = false;
    } else {
      emit('error', response)
      toaster.error("Une erreur est survenue lors de la suppression du chien")
    }
  } catch (e) {
    emit('error', e)
    toaster.error("Une erreur est survenue lors de la suppression du chien")
  }

  loading.value = false
}

onMounted(() => {
  fetchDog();
})
</script>

<template>
  <DeleteElementModalComponent
      :loading="loading"
      @delete="handleDelete"
      :title="`Suppression du chien #${dog?.id} - ${dog?.name}`"
      description="Le chien sera supprimé du recensement"
      ref="modal"
  />
</template>