export const hasCPFormations = [
    { value: 'no', label: 'Non' },
    { value: 'yes_one_time', label: 'Oui, une fois' },
    { value: 'yes_mutliple_times', label: 'Oui, plusieurs fois' },
];

export const hasTechnicalFormations = [
    { value: 'yes_with_idelete', label: 'Oui avec Idele'},
    { value: 'yes_with_pyrenean_pastoral', label: 'Oui avec la Pastorale pyréenéenne'},
    { value: 'yes_with_anthr_organism', label: 'Oui avec un autre organisme'},
    { value: 'no', label: 'Non' },

];

export const yesNoMaybeConfig = [
    { value: 'true', label: 'Oui' },
    { value: 'false', label: 'Non' },
    { value: 'null', label: 'Peut-être' },
]

export const trueOrFalseConfig = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' },
];

export const multipleYesConfig = [
    {value: 'as_possible', label: 'Oui, dès que possible'},
    {value: 'at_term', label: 'Oui, à terme'},
    {value: 'no', label: 'Non'},
];

export const favorableOrNotConfig = [
    {value: 'favorable', label: 'Favorable'},
    {value: 'unfavorable', label: 'Défavorable'}
]