export const departments = [
  {
    dep_name: 'Ain',
    num_dep: '01',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Aisne',
    num_dep: '02',
    region_name: 'Hauts-de-France',
  },
  {
    dep_name: 'Allier',
    num_dep: '03',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Alpes-de-Haute-Provence',
    num_dep: '04',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Hautes-Alpes',
    num_dep: '05',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Alpes-Maritimes',
    num_dep: '06',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Ardèche',
    num_dep: '07',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Ardennes',
    num_dep: '08',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Ariège',
    num_dep: '09',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Aube',
    num_dep: '10',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Aude',
    num_dep: '11',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Aveyron',
    num_dep: '12',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Bouches-du-Rhône',
    num_dep: '13',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Calvados',
    num_dep: '14',
    region_name: 'Normandie',
  },
  {
    dep_name: 'Cantal',
    num_dep: '15',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Charente',
    num_dep: '16',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Charente-Maritime',
    num_dep: '17',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Cher',
    num_dep: '18',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Corrèze',
    num_dep: '19',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: "Côte-d'Or",
    num_dep: '21',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: "Côtes-d'Armor",
    num_dep: '22',
    region_name: 'Bretagne',
  },
  {
    dep_name: 'Creuse',
    num_dep: '23',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Dordogne',
    num_dep: '24',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Doubs',
    num_dep: '25',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Drôme',
    num_dep: '26',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Eure',
    num_dep: '27',
    region_name: 'Normandie',
  },
  {
    dep_name: 'Eure-et-Loir',
    num_dep: '28',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Finistère',
    num_dep: '29',
    region_name: 'Bretagne',
  },
  {
    dep_name: 'Corse-du-Sud',
    num_dep: '2A',
    region_name: 'Corse',
  },
  {
    dep_name: 'Haute-Corse',
    num_dep: '2B',
    region_name: 'Corse',
  },
  {
    dep_name: 'Gard',
    num_dep: '30',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Haute-Garonne',
    num_dep: '31',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Gers',
    num_dep: '32',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Gironde',
    num_dep: '33',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Hérault',
    num_dep: '34',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Ille-et-Vilaine',
    num_dep: '35',
    region_name: 'Bretagne',
  },
  {
    dep_name: 'Indre',
    num_dep: '36',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Indre-et-Loire',
    num_dep: '37',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Isère',
    num_dep: '38',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Jura',
    num_dep: '39',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Landes',
    num_dep: '40',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Loir-et-Cher',
    num_dep: '41',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Loire',
    num_dep: '42',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Haute-Loire',
    num_dep: '43',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Loire-Atlantique',
    num_dep: '44',
    region_name: 'Pays de la Loire',
  },
  {
    dep_name: 'Loiret',
    num_dep: '45',
    region_name: 'Centre-Val de Loire',
  },
  {
    dep_name: 'Lot',
    num_dep: '46',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Lot-et-Garonne',
    num_dep: '47',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Lozère',
    num_dep: '48',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Maine-et-Loire',
    num_dep: '49',
    region_name: 'Pays de la Loire',
  },
  {
    dep_name: 'Manche',
    num_dep: '50',
    region_name: 'Normandie',
  },
  {
    dep_name: 'Marne',
    num_dep: '51',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Haute-Marne',
    num_dep: '52',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Mayenne',
    num_dep: '53',
    region_name: 'Pays de la Loire',
  },
  {
    dep_name: 'Meurthe-et-Moselle',
    num_dep: '54',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Meuse',
    num_dep: '55',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Morbihan',
    num_dep: '56',
    region_name: 'Bretagne',
  },
  {
    dep_name: 'Moselle',
    num_dep: '57',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Nièvre',
    num_dep: '58',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Nord',
    num_dep: '59',
    region_name: 'Hauts-de-France',
  },
  {
    dep_name: 'Oise',
    num_dep: '60',
    region_name: 'Hauts-de-France',
  },
  {
    dep_name: 'Orne',
    num_dep: '61',
    region_name: 'Normandie',
  },
  {
    dep_name: 'Pas-de-Calais',
    num_dep: '62',
    region_name: 'Hauts-de-France',
  },
  {
    dep_name: 'Puy-de-Dôme',
    num_dep: '63',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Pyrénées-Atlantiques',
    num_dep: '64',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Hautes-Pyrénées',
    num_dep: '65',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Pyrénées-Orientales',
    num_dep: '66',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Bas-Rhin',
    num_dep: '67',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Haut-Rhin',
    num_dep: '68',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Rhône',
    num_dep: '69',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Haute-Saône',
    num_dep: '70',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Saône-et-Loire',
    num_dep: '71',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Sarthe',
    num_dep: '72',
    region_name: 'Pays de la Loire',
  },
  {
    dep_name: 'Savoie',
    num_dep: '73',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Haute-Savoie',
    num_dep: '74',
    region_name: 'Auvergne-Rhône-Alpes',
  },
  {
    dep_name: 'Paris',
    num_dep: '75',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Seine-Maritime',
    num_dep: '76',
    region_name: 'Normandie',
  },
  {
    dep_name: 'Seine-et-Marne',
    num_dep: '77',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Yvelines',
    num_dep: '78',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Deux-Sèvres',
    num_dep: '79',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Somme',
    num_dep: '80',
    region_name: 'Hauts-de-France',
  },
  {
    dep_name: 'Tarn',
    num_dep: '81',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Tarn-et-Garonne',
    num_dep: '82',
    region_name: 'Occitanie',
  },
  {
    dep_name: 'Var',
    num_dep: '83',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Vaucluse',
    num_dep: '84',
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dep_name: 'Vendée',
    num_dep: '85',
    region_name: 'Pays de la Loire',
  },
  {
    dep_name: 'Vienne',
    num_dep: '86',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Haute-Vienne',
    num_dep: '87',
    region_name: 'Nouvelle-Aquitaine',
  },
  {
    dep_name: 'Vosges',
    num_dep: '88',
    region_name: 'Grand Est',
  },
  {
    dep_name: 'Yonne',
    num_dep: '89',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Territoire de Belfort',
    num_dep: '90',
    region_name: 'Bourgogne-Franche-Comté',
  },
  {
    dep_name: 'Essonne',
    num_dep: '91',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Hauts-de-Seine',
    num_dep: '92',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Seine-Saint-Denis',
    num_dep: '93',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Val-de-Marne',
    num_dep: '94',
    region_name: 'Île-de-France',
  },
  {
    dep_name: "Val-d'Oise",
    num_dep: '95',
    region_name: 'Île-de-France',
  },
  {
    dep_name: 'Guadeloupe',
    num_dep: '971',
    region_name: 'Guadeloupe',
  },
  {
    dep_name: 'Martinique',
    num_dep: '972',
    region_name: 'Martinique',
  },
  {
    dep_name: 'Guyane',
    num_dep: '973',
    region_name: 'Guyane',
  },
  {
    dep_name: 'La Réunion',
    num_dep: '974',
    region_name: 'La Réunion',
  },
  {
    dep_name: 'Mayotte',
    num_dep: '976',
    region_name: 'Mayotte',
  },
];
