<script setup>

defineProps({
  isVisible: Boolean,
  items: Array,
  type: String,

})

const emit = defineEmits(['onClick', 'onClose'])

</script>
<template>
  <transition name="bounce">
    <div
      v-if="isVisible"
      class="dropdown"
    >
      <h1>
        Êtes-vous sûr de vouloir valider ce recensement ?
      </h1>
      <div class="buttons">
        <Button
          class="search-btn"
          @on-click="emit('onClick')"
        >
          Confirmer
        </Button>
        <Button
          @on-click="emit('onClose')"
        >
          Annuler
        </Button>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 38%;
  left: 25%;
  width: 50%;
  right: 0;
  height: 20%;
  background-color: white;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);


  h1 {
    font-size: 20px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1em;

  }


  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: $green;
    color: white;
    border-radius: 14px;
    font-size: 15px;


  }
}
.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .dropdown {
    min-width: 90%;
    left: 2.5%;
    padding: 0.5em;
  }
}

</style>