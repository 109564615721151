function getFatherBody(fatherForm) {
  return {
    name: fatherForm?.name,
    identificationNum: fatherForm?.identificationNum,
    breed: fatherForm?.breed? fatherForm.breed.toString() : null,
    appearance: fatherForm?.appearance,
    atWork: fatherForm?.atWork,
    isCertifiedSonship: fatherForm?.isCertifiedSonship,
    numLof: fatherForm?.numLof,
    ownerName: fatherForm?.ownerName,
    address: fatherForm?.address,
    phone: fatherForm?.phone,
    email: fatherForm?.email,
  };
}

function getMotherBody(motherForm) {
  return {
    name: motherForm?.name,
    identificationNum: motherForm.identificationNum,
    breed: motherForm?.breed? motherForm.breed.toString() : null,
    appearance: motherForm.appearance,
    atWork: motherForm.atWork,
    isCertifiedSonship: motherForm.isCertifiedSonship,
    numLof: motherForm.numLof,
    ownerName: motherForm.ownerName,
    address: motherForm.address,
    phone: motherForm.phone,
    email: motherForm.email,
  };
}

function getOwnerBody(ownerForm, exploitationId) {
  return {
    firstname: ownerForm.firstname,
    lastname: ownerForm.lastname,
    companyname: ownerForm.companyname,
    address: ownerForm.address,
    zipCode: ownerForm.zipCode,
    city: ownerForm.city,
    email: ownerForm.email,
    phoneNumber: ownerForm.phoneNumber,
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}

function getMasterBody(masterForm, exploitationId) {
  return {
    firstname: masterForm.firstname,
    lastname: masterForm.lastname,
    companyname: masterForm.companyname,
    address: masterForm.address,
    zipCode: masterForm.zipCode,
    city: masterForm.city,
    email: masterForm.email,
    phoneNumber: masterForm.phoneNumber,
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}


function getFullMasterBody(form, exploitationId) {
  return {
    firstname: form.firstname,
    lastname: form.lastname,
    companyname: form.companyname,
    address: form.address,
    zipCode: form.zipCode,
    city: form.city,
    email: form.email,
    phoneNumber: form.phoneNumber,
    yearRegister: parseInt(form.yearRegister),
    hasCpFormation: form.hasCpFormation?.toString(),
    hasTechnicalFormation: form.hasTechnicalFormation?.toString(),
    isJoiningProductionNetwork: form.isJoiningProductionNetwork,
    profileMatch: form.profileMatch,
    hasRegularVisit: form.hasRegularVisit,
    breedChart: form.breedChart,
    hasSelfFormation: form.hasSelfFormation,
    batchCaring: form.batchCaring,
    brokenDogs: form.brokenDogs,
    puppyGrowth: form.puppyGrowth,
    usedCp: parseInt(form.usedCp),
    notes: form.notes,
    couplings: form.couplings,
    masterIsOwnerExploitation: form.masterIsOwnerExploitation,
    // masterOwner: masterOwner.value != null ? masterOwner.value.id : null,
    hasGrownPuppy: form.hasGrownPuppy,
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}

function getBody(
  form,
  fatherForm,
  fatherBody,
  motherForm,
  motherBody,
  state = null
) {
  if (form?.specie?.findIndex(spec => spec === 'other') !== -1 && state.specieOther) {
    form.specie[form.specie.findIndex(spec => spec === 'other')] = `other##` + state.specieOther
  }
  return {
    name: form.name,
    birthName: form.birthName,
    gender: form.gender,
    birthdate: form.birthdate,
    identificationType: form.identificationType,
    identificationNum: form.identificationNum,
    verificationPuce: form.verificationPuce,
    numLof: form.numLof,
    confirmLof: form.confirmLof,
    isLof: form.isLof,
    breed: form.breed ? form.breed.toString() : null,
    appearance: form.appearance,
    characteristics: form.characteristics,
    isIdentified: form.isIdentified,
    isBornHerd: form.isBornHerd,
    isFirstForBreeder: form.isFirstForBreeder,
    isHealthy: form.isHealthy,
    isAdaptedMorpho: form.isAdaptedMorpho,
    isAdaptedSize: form.isAdaptedSize,
    isAdaptedDemarche: form.isAdaptedDemarche,
    isAdaptedAplombs: form.isAdaptedAplombs,
    isAdaptedBackLine: form.isAdaptedBackLine,
    hasTesticleConcerned: form.hasTesticleConcerned,
    isAdaptedJaw: form.isAdaptedJaw,
    isAdaptedPigmentation: form.isAdaptedPigmentation,
    isAgressifToOtherDogs: form.isAgressifToOtherDogs,
    hasHealthyTrouble: form.hasHealthyTrouble,
    aboutDogTemper: form?.aboutDogTemper?.toString(),
    hipDysplasia: form.hipDysplasia,
    elbowDysplasia: form.elbowDysplasia,
    beforeTwoMonth: form.beforeTwoMonth,
    parcel: form.parcel?.toString(),
    parcelHeifers: form.parcelHeifers?.toString(),
    parcelOthers: form.parcelOthers,
    exploitationAge: parseInt(form.exploitationAge),
    introductionCondition: form.introductionCondition,
    introductionConditionPuppies: form.introductionConditionPuppies,
    introductionConditionAdults: form.introductionConditionAdults,
    behaviorWithHuman: form.behaviorWithHuman,
    temper: form.temper,
    respectingOthers: form.respectingOthers,
    ageRespectingOthers: form.ageRespectingOthers,
    tookTime: form.tookTime,
    isGoodProtector: form.isGoodProtector,
    startRespectingOthers: form.startRespectingOthers,
    endRespectingOthers: form.endRespectingOthers,
    isEasyDog: form.isEasyDog,
    isManipulable: form.isManipulable,
    isAgressif: form.isAgressif,
    isMunipulableCensusTaker: form.isMunipulableCensusTaker,
    trustWithOthers: form.trustWithOthers,
    trustOthersWithoutYou: form.trustOthersWithoutYou,
    positionInGroup: form.positionInGroup,
    canWorkInGroup: form.canWorkInGroup,
    canWorkAlone: form.canWorkAlone,
    presentHerd: form.presentHerd,
    watchfulAwakening: form.watchfulAwakening,
    hasExcessiveBarking: form.hasExcessiveBarking,
    distanceHerd: form.distanceHerd,
    fenceRespect: form.fenceRespect,
    runAfterVehicles: form.runAfterVehicles,
    isAlwaysUsable: form.isAlwaysUsable,
    isPotentialBreeder: form.isPotentialBreeder,
    hasGoodImpregnateHerdBfrTwoMounth: form.hasGoodImpregnateHerdBfrTwoMounth,
    hasGoodFamiliarizationHumansBfrTwoMounths:
      form.hasGoodFamiliarizationHumansBfrTwoMounths,
    hasGoodFamiliarizationHumansAfterTwoMounths:
      form.hasGoodFamiliarizationHumansAfterTwoMounths,
    wasEasySetupPuppy: form.wasEasySetupPuppy,
    isClingyWithHerd: form.isClingyWithHerd,
    hasGoodGlobalAppreciation: form.hasGoodGlobalAppreciation,
    adaptedMorphoCommentary: form.adaptedMorphoCommentary,
    wantPuppy: form.wantPuppy,
    recovery: form.recovery,
    unknownDogTemper: form.unknownDogTemper?.toString(),
    unknownIsManipulable: form.unknownIsManipulable,
    unknownIsAgressif: form.unknownIsAgressif,
    unknownIsMunipulableCensusTaker: form.unknownIsMunipulableCensusTaker,
    unknownTrustWithOthers: form.unknownTrustWithOthers,
    unknownTrustOthersWithoutYou: form.unknownTrustOthersWithoutYou,
    otherProblem: form.otherProblem,
    typeOfDriving: form.typeOfDriving?.toString(),
    typeOfSystem: form.typeOfSystem,
    typeOfPredation: form.typeOfPredation?.toString(),
    otherTypeOfDriving: form.otherTypeOfDriving?.toString(),
    // otherStartAtPeriodHousing: form.otherStartAtPeriodHousing,
    // otherEndAtPeriodHousing: form.otherEndAtPeriodHousing,
    notes: form.notes,
    masterIsOwnerDog: form.masterIsOwnerDog,
    ownerIsOwnerExploitation: form.ownerIsOwnerExploitation,
    father: state?.noFatherInfo || !fatherForm.name ? null : fatherBody,
    mother: state?.noMotherInfo || !motherForm.name ? null : motherBody,
    specie: form?.specie?.toString(),
    nbPersons: JSON.stringify(state.specNbPersons),
    specieOther:
      form.specie && form.specie?.includes("other") ? form.specieOther : null,
    humainPresence: form.humainPresence?.toString(),
    otherDogsPresence: form.otherDogsPresence?.toString(),
    oustiderHumanPresence: form.oustiderHumanPresence?.toString(),
    otherTypeOfPredation: form.otherTypeOfPredation,
    isOtherSpecie: form.isOtherSpecie,
    otherSpecie: form.otherSpecie?.toString(),
    otherTypeOfSystem: form.otherTypeOfSystem,
  };
}

function formatBody(datas, specNbPersons) {
  return {
    name: datas.name,
    birthName: datas.birthName,
    gender: datas.gender,
    birthdate: datas.birthdate,
    identificationType: datas.identificationType,
    identificationNum: datas.identificationNum,
    verificationPuce: datas.verificationPuce,
    numLof: datas.numLof,
    confirmLof: datas.confirmLof,
    isLof: datas.isLof,
    breed: datas.breed? datas.breed.toString() : null,
    appearance: datas.appearance,
    characteristics: datas.characteristics,
    isIdentified: datas.isIdentified,
    isBornHerd: datas.isBornHerd,
    isFirstForBreeder: datas.isFirstForBreeder,
    isHealthy: datas.isHealthy,
    isAdaptedMorpho: datas.isAdaptedMorpho,
    isAdaptedSize: datas.isAdaptedSize,
    isAdaptedDemarche: datas.isAdaptedDemarche,
    isAdaptedAplombs: datas.isAdaptedAplombs,
    isAdaptedBackLine: datas.isAdaptedBackLine,
    isAdaptedJaw: datas.isAdaptedJaw,
    isAdaptedPigmentation: datas.isAdaptedPigmentation,
    isAgressifToOtherDogs: datas.isAgressifToOtherDogs,
    hasHealthyTrouble: datas.hasHealthyTrouble,
    aboutDogTemper: datas?.aboutDogTemper?.toString(),
    hipDysplasia: datas.hipDysplasia,
    elbowDysplasia: datas.elbowDysplasia,
    beforeTwoMonth: datas.beforeTwoMonth,
    parcel: datas.parcel?.toString(),
    parcelHeifers: datas.parcelHeifers?.toString(),
    parcelOthers: datas.parcelOthers,
    exploitationAge: parseInt(datas.exploitationAge),
    introductionCondition: datas.introductionCondition,
    introductionConditionPuppies: datas.introductionConditionPuppies,
    introductionConditionAdults: datas.introductionConditionAdults,
    behaviorWithHuman: datas.behaviorWithHuman,
    temper: datas.temper,
    respectingOthers: datas.respectingOthers,
    // ageRespectingOthers: datas.ageRespectingOthers,
    tookTime: datas.tookTime,
    isGoodProtector: datas.isGoodProtector,
    isEasyDog: datas.isEasyDog,
    isManipulable: datas.isManipulable,
    isAgressif: datas.isAgressif,
    isMunipulableCensusTaker: datas.isMunipulableCensusTaker,
    trustWithOthers: datas.trustWithOthers,
    trustOthersWithoutYou: datas.trustOthersWithoutYou,
    positionInGroup: datas.positionInGroup,
    canWorkInGroup: datas.canWorkInGroup,
    canWorkAlone: datas.canWorkAlone,
    presentHerd: datas.presentHerd,
    watchfulAwakening: datas.watchfulAwakening,
    hasExcessiveBarking: datas.hasExcessiveBarking,
    distanceHerd: datas.distanceHerd,
    fenceRespect: datas.fenceRespect,
    runAfterVehicles: datas.runAfterVehicles,
    isAlwaysUsable: datas.isAlwaysUsable,
    isPotentialBreeder: datas.isPotentialBreeder,
    hasGoodImpregnateHerdBfrTwoMounth: datas.hasGoodImpregnateHerdBfrTwoMounth,
    hasGoodFamiliarizationHumansBfrTwoMounths:
      datas.hasGoodFamiliarizationHumansBfrTwoMounths,
    hasGoodFamiliarizationHumansAfterTwoMounths:
      datas.hasGoodFamiliarizationHumansAfterTwoMounths,
    wasEasySetupPuppy: datas.wasEasySetupPuppy,
    isClingyWithHerd: datas.isClingyWithHerd,
    hasGoodGlobalAppreciation: datas.hasGoodGlobalAppreciation,
    adaptedMorphoCommentary: datas.adaptedMorphoCommentary,
    wantPuppy: datas.wantPuppy,
    recovery: datas.recovery,
    unknownDogTemper: datas.unknownDogTemper?.toString(),
    unknownIsManipulable: datas.unknownIsManipulable,
    unknownIsAgressif: datas.unknownIsAgressif,
    unknownIsMunipulableCensusTaker: datas.unknownIsMunipulableCensusTaker,
    unknownTrustWithOthers: datas.unknownTrustWithOthers,
    unknownTrustOthersWithoutYou: datas.unknownTrustOthersWithoutYou,
    otherProblem: datas.otherProblem,
    typeOfDriving: datas.typeOfDriving?.toString(),
    typeOfSystem: datas.typeOfSystem,
    typeOfPredation: datas.typeOfPredation?.toString(),
    otherTypeOfDriving: datas.otherTypeOfDriving?.toString(),
    otherStartAtPeriodHousing: datas.otherStartAtPeriodHousing,
    otherEndAtPeriodHousing: datas.otherEndAtPeriodHousing,
    notes: datas.notes,
    masterIsOwnerDog: datas.masterIsOwnerDog,
    ownerIsOwnerExploitation: datas.ownerIsOwnerExploitation,
    father: datas.fater,
    mother: datas.mother,
    specie: datas?.specie?.toString(),
    specieOther:
      datas.specie && datas.specie?.includes("other")
        ? datas.specieOther
        : null,
    nbPersons: specNbPersons ? JSON.stringify(specNbPersons) : datas.nbPersons,
    humainPresence: datas.humainPresence?.toString(),
    otherDogsPresence: datas.otherDogsPresence?.toString(),
    oustiderHumanPresence: datas.oustiderHumanPresence?.toString(),
    otherTypeOfPredation: datas.otherTypeOfPredation,
    isOtherSpecie: datas.isOtherSpecie,
    otherSpecie: datas.otherSpecie?.toString(),
    otherTypeOfSystem: datas.otherTypeOfSystem,
  };
}

export {
  getFatherBody,
  getMotherBody,
  getOwnerBody,
  getMasterBody,
  getFullMasterBody,
  getBody,
  formatBody,
};
