<script setup>
import {useStore} from '../../stores';
import {useRouter} from "vue-router";
import {ref} from "vue";
import {useClickOutside} from "@/helpers/dropdownHelper";


let props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  items: Array,
  type: String //primary | secondary
})
const store = useStore();
const router = useRouter();
const emit = defineEmits(['onClick', 'clickOutside', 'closeModal', 'onClose'])
let dropdownRef = ref(null);

useClickOutside(dropdownRef, () => {
  emit('clickOutside')
})

function canShowItem(item) {
  if (!item.isAdmin) {
    return true;
  }
  return store.userIsAdmin;


}
</script>
<template>
  <transition
      appear
      name="bounce"
  >
    <div
        v-show="props.isVisible"
        ref="dropdownRef"
        class="dropdown"
        :class="{dropdownPrimaryBg: props.type === 'primary', dropdownSecondaryBg: props.type === 'secondary'} "
    >
      <template
          v-for="item in props.items"
          :key="item.name"
      >
        <router-link
            v-if="item.link && canShowItem(item)"
            :to="item.link"
            @click="item.link != null ? (router.push({ path: item.link }), $emit('onClose')) : $emit('onClick')"
        >
          <div
              class="item"
              :class="{primary: props.type === 'primary', secondary: props.type === 'secondary'}"
          >
            {{ item.name }}
          </div>
        </router-link>

        <div
            v-if="!item.link && canShowItem(item)"
            class="item"
            :class="{primary: props.type === 'primary', secondary: props.type === 'secondary'}"
            @click="emit('onClick')"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  box-shadow: 0px 20px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .item {
    padding: 20px 0px;
    margin: 0px 40px;
    font-family: 'NunitoSansBold';
    transition: 0.3s;
    cursor: pointer;

    &.primary {
      color: $primary !important;
      border-bottom: 1px solid rgb(111, 144, 107, 0.4) !important;
    }

    &.secondary {
      color: #EC9A29 !important;
      border-bottom: 1px solid rgb(217, 169, 23, 0.5) !important;
    }
  }

  .item:hover {
    color: #0a0d06;
  }

  a:last-child {
    .item {
      border-bottom: 0;
    }
  }
}

.dropdownPrimaryBg {
  background-color: #D1E2B8!important;
}
.dropdownSecondaryBg{
  background-color: #F7EED1 !important;
}

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

</style>