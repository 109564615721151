<script setup lang="js">
import {onMounted, onUnmounted, ref} from "vue";
import HoldingService from "../../../services/holding.service";
import HoldingEditFormTemplate from "../../../components/form/HoldingEditFormTemplate.vue";
import IconArrowLeft from "../../../components/icons/IconArrowLeft.vue";
import DogListComponent from "@/views/components/DogListComponent.vue";

const props = defineProps({
  holdingId: String,
})

const loading = ref(true);
const holding = ref(null);
const controller = ref(new AbortController())

onMounted(async () => {
  holding.value = await HoldingService.getHoldingById(props.holdingId, controller.value)
  loading.value = false;
})

onUnmounted(() => {
  controller.value?.abort();
});
</script>

<template>
  <div class="content">
    <RouterLink
        class="nav-button tw-no-underline"
        :to="{path: '/holdings', query: { history: 'true' } }"
    >
      <IconArrowLeft /> <span>Retour</span>
    </RouterLink>

    <h1>Exploitations</h1>

    <LoaderList v-if="loading" />

    <div class="tw-flex tw-items-center tw-justify-between">
      <h2 v-if="!loading">{{ holding.name }}</h2>

      <RouterLink
          class="nav-button secondary tw-no-underline tw-bg-secondary"
          :to="{path: '/holdings/' + holdingId + '/edit', query: { history: 'true' } }"
      >
        <span>Modifier</span>
      </RouterLink>
    </div>

    <HoldingEditFormTemplate
        v-if="!loading"
        :holding="holding"
        read-only
    />

    <div
        v-if="!loading"
        class="tw-py-5">
      <h2 v-if="!loading">Liste des chiens</h2>

      <DogListComponent
          v-if="holding.dogs.length > 0"
          :dogs="holding.dogs"
          @select="(id) => $router.push({path: `/dogs/${id}`})"
      />

      <p
          v-else
      >Aucun chien dans l'exploitation</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

.secondary{
  background-color: $secondary;
}
</style>
