<script setup>
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { reactive, computed, ref } from 'vue';
import AuthService from '../services/auth.service';
import { useRouter } from 'vue-router';
import iconAuth from '../assets/images/icon-auth.svg';

const router = useRouter();
const error = ref();
const loading = ref(false);
const state = reactive({
  email: '',
  password: '',
});

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage('Le champ est requis !', required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    password: {
      required: helpers.withMessage('Le champ est requis !', required),
    },
  };
});
const v$ = useVuelidate(rules, state);

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
  console.log('state', state);
};

async function submitForm() {
  try {
    error.value = null;
    loading.value = true;
    const res = await AuthService.login(state);
    if (res.token) {
       //Double authentification
       //await router.push({path: `/login/${res.token}/confirmation`});
       await router.push({path: `/`});
    }
  } catch (e) {
    loading.value = false;
    error.value = "L'email ou le mot de passe est incorrect !";
    console.log(e);
  }
}
</script>

<template>
  <AuthTemplate
    :icon="iconAuth"
    title="Connexion"
  >
    <form @submit.prevent="submitForm()">
      <TextInputController
        :on-change="handleChange"
        :name="'email'"
        :model="state.email"
        :errors="v$.email.$errors"
        :on-blur="v$.email.$touch"
        :label="'Email'"
      >
        <template #icon>
          <IconMail />
        </template>
      </TextInputController>

      <TextInputController
        :on-change="handleChange"
        :name="'password'"
        :model="state.password"
        :errors="v$.password.$errors"
        :on-blur="v$.password.$touch"
        :label="'Mot de passe'"
        type="password"
      >
        <template #icon>
          <IconPassword />
        </template>
      </TextInputController>

      <span
        v-if="error"
        class="error"
      >{{ error }}</span>

      <Button
        type="primary"
        :disabled="v$.$invalid || loading"
        :submit="true"
      >
        Connexion
      </Button>
      <Button
        type="transparent"
        margin-top="40px"
        @on-click="router.push({path: '/forgot'})"
      >
        Mot de passe oublié ?
      </Button>
      <Button
        type="transparent"
        @on-click="router.push({path: '/politique-de-confidentialite'})"

      >
        CGV / RGPD
      </Button>
    </form>
  </AuthTemplate>
</template>
<style lang="scss" scoped>
@import '../assets/css/_all.scss';

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.field {
  width: 100%;

  input {
    margin-top: 10px;
    height: 40px;
    width: 100%;
  }
}

form {
  flex-direction: column;
}

.field:last-of-type {
  margin-bottom: 30px;
}

@media screen and (min-width: 1280px) {
  form {
    min-width: 20%;
    max-width: 20%;
  }
}
</style>
