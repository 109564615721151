<template>
  <abbr
      class="
      tw-text-white
      tw-bg-primary
      tw-rounded-full
      tw-justify-center
      tw-p-1
      tw-group
      tw-items-center
      tw-whitespace-nowrap
      tw-h-6
      tw-w-6
      tw-flex-items-center
      tw-flex
  "
      title="Chien mineur"
  >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          height="16"
          width="18"
          viewBox="0 0 576 512"
      >
        <path
            d="M309.6 158.5L332.7 19.8C334.6 8.4 344.5 0 356.1 0c7.5 0 14.5 3.5 19 9.5L392 32h52.1c12.7 0 24.9 5.1 33.9 14.1L496 64h56c13.3 0 24 10.7 24 24v24c0 44.2-35.8 80-80 80H464 448 426.7l-5.1 30.5-112-64zM416 256.1L416 480c0 17.7-14.3 32-32 32H352c-17.7 0-32-14.3-32-32V364.8c-24 12.3-51.2 19.2-80 19.2s-56-6.9-80-19.2V480c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V249.8c-28.8-10.9-51.4-35.3-59.2-66.5L1 167.8c-4.3-17.1 6.1-34.5 23.3-38.8s34.5 6.1 38.8 23.3l3.9 15.5C70.5 182 83.3 192 98 192h30 16H303.8L416 256.1zM464 80a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"/>
      </svg>
    </abbr>
</template>
<script setup>
</script>