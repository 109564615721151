<template>
  <div
      class="holdings-head"
      :class="{blured : showDropdownHoldings}"
  >
    <h1>Liste des exploitations</h1>

    <IconButton
        :icon="iconSearch"
        class="primary"
        @on-click="showDropdownHoldings = !showDropdownHoldings"
    />
  </div>
  <HoldingsModal
      :is-visible="showDropdownHoldings"
      @onClose="showDropdownHoldings = false"
      @queryParams="(value) => state.params = value "
  />
  <RequiredOnlineMode v-if="isOffline && state.users.length === 0"/>
  <LoaderList v-if="loading && state.exploits.length === 0"/>

  <div
      class="holdings"
      :class="{blured : showDropdownHoldings}"
  >
    <div
        v-if="state.isFiltered && !loading"
        class="holdings-filtered-title"
    >
      Résultats de recherche
    </div>

    <div
        v-if="state.exploits.length === 0 && !loading"
        class="empty-holding"
    >
      Aucune explotation trouvée
    </div>
    <div
        v-for="exploit in state.exploits"
        :key="exploit.id"
        class="exploit-row"
        @click="handleSelect(exploit.id)"
        :id="'holding-' + exploit.id"
    >
      <div class="exploit-row-title">
        <div class="exploit-row-title-name">
          {{ exploit.name }}
        </div>
        <div class="exploit-row-title-city">
          {{ exploit.city }}
        </div>
      </div>
      <div
          class="exploit-row-img"
      >
        <img
            :src="rightArrow"
            alt="right-arrow"
        >
      </div>
    </div>
    <Button
        v-if="maxSize !== state.exploits.length"
        class="btn-more"
        @click="showMore()"
    >
      Voir plus
    </Button>
    <Button
        v-if="state.isFiltered && !loading"
        class="search-btn"
        @click="getHoldings(1, ''); state.exploits = []; state.isFiltered = false;"
    >
      RETOUR AU LISTING
    </Button>
    <LoaderItem v-if="loading && state.exploits.length !== 0"/>
  </div>
</template>

<script setup>

import HoldingService from "../../../services/holding.service";
import {useRouter, useRoute} from "vue-router";
import {onMounted, computed, reactive, ref, watch, nextTick} from 'vue';
import {useStore} from "../../../stores";
import iconSearch from '../../../assets/images/icon-search.svg';
import rightArrow from '../../../assets/images/right-circled-arrow.svg';
import HoldingsModal from "../../../components/holdings-modal/HoldingsModal";
import useHoldingStore from "../../../stores/useHoldingStore";

const holdingStore = useHoldingStore()

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const state = reactive({
      exploits: [],
      params: '',
      isFiltered: false,
    }
);
const page = ref(0);
const maxSize = ref(0);
const store = useStore();
const controller = ref(new AbortController());
const isOffline = computed(() => !store.getIsOnline);
let showDropdownHoldings = ref(false);

onMounted(() => {
  if (holdingStore.items.length) {
    state.exploits = holdingStore.items;
    page.value = holdingStore.page;

    if (holdingStore.lastItemId) {
      nextTick(() => {
        const element = document.querySelector('#holding-' + holdingStore.lastItemId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
            element.focus();
          }, 150);
        }
      });
    }

    return
  }


  if (!isOffline.value) {
    const searchParams = localStorage.getItem('holdingsSearchQuery')
    if (route.query.history && searchParams !== '' && searchParams !== 'none') {
      state.exploits = [];
      state.isFiltered = true;
      getHoldings(1, searchParams);
    } else {
      getHoldings(1);
    }
  }
})

watch(() => store.loadNextData, async function () {
  if (store.loadNextData === true && maxSize.value !== state.exploits.length) {
    await getHoldings(page.value + 1, state.params);
    store.setScroll(false);
  }
});

watch(() => store.getIsOnline, function (params) {
  if (!isOffline.value && !loading.value && !state.exploits.length) {
    getHoldings(1, params);
  }
});

watch(() => state.params, (params) => {
      if (!isOffline.value && !loading.value) {
        state.exploits = [];
        state.isFiltered = true;
        getHoldings(1, params);
        showDropdownHoldings.value = false;
      }
    }
)

async function showMore() {
  await getHoldings(page.value + 1, state.params);
}

async function getHoldings(newPage, params) {
  localStorage.setItem('holdingsSearchQuery', params ?? 'none');
  loading.value = true;
  page.value = newPage;
  const res = await HoldingService.getFilteredHoldings(newPage, params, controller.value);
  state.exploits = [...state.exploits, ...res['hydra:member']];
  if (newPage === 1) {
    maxSize.value = res['hydra:totalItems'];
  }

  holdingStore.items = state.exploits;
  holdingStore.page = page.value;

  loading.value = false;
}

const handleSelect = (id) => {
  holdingStore.lastItemId = id;
  router.push({path: `/holdings/${id}`})
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

h1 {
  font-size: 25px;
  text-align: center;
}

.holdings-filtered-title {
  font-weight: 900;
  font-size: 23px;
  color: $green;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.holdings-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: filter 100ms;

  img {
    height: 50%;
    padding: 0.5em;
    border-radius: 12px;
    background-color: #659f1254;
  }
}


.exploit-row {
  border-bottom: 1px solid $altGreen;
  padding: 0.5em;
  height: 35px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  font-size: 14px;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
  transition: background-color 200ms;

  &-title {
    font-weight: bold;
    display: flex;
    color: #667085;
    line-height: 12px;

    &-name {
      width: 135px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-city {
      font-weight: 400;
      padding-left: 0.5em;
      margin-left: 0.3em;
      border-left: 1px solid
    }
  }

}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659F12;
  color: white;
  border-radius: 14px;
  margin-top: 1.5em !important;
}

.blured {
  filter: blur(4px)
}

.btn-more {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659F12;
  color: white;
  border-radius: 20px;
  margin-top: 0.5em !important;
}

.empty-holding {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>