<script setup>
import {onMounted, onUnmounted, computed, reactive, ref, watch, nextTick} from 'vue';
import UserService from '../../../services/user.service';
import iconAdd from "../../../assets/images/icon-add.svg";
import arrowRight from "../../../assets/images/arrow-right.svg";
import {useRouter} from 'vue-router';
import {useStore} from '../../../stores';
import useUserStore from "../../../stores/useUserStore";

const userStore = useUserStore()

const router = useRouter();
const loading = ref(false);
const state = reactive({users: []});
const page = ref(0);
const maxSize = ref(0);
const sort = ref('firstname');
const order = ref('asc')
const store = useStore()
const controller = ref(new AbortController());
const isOffline = computed(() => !store.getIsOnline);

const sortBy = (field) => {
  {
    if (!isOffline.value)
      if (sort.value === field) {
        order.value = order.value === 'asc' ? 'desc' : 'asc'
      } else {
        order.value = 'asc'
      }
    sort.value = field;
    state.users = [];
    getUsers(1);
  }
}

onMounted(() => {
  if (userStore.items.length) {
    state.users = userStore.items;
    page.value = userStore.page;

    if (userStore.lastItemId) {
      nextTick(() => {
        const element = document.querySelector('#user-' + userStore.lastItemId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
            element.focus();
          }, 150);
        }
      });
    }

    return
  }

  if (!isOffline.value)
    getUsers(1);
})


watch(() => store.loadNextData, async function () {
  if (store.loadNextData === true && maxSize.value !== state.users.length) {
    await getUsers(page.value + 1);
    store.setScroll(false);
  }
});

watch(() => store.getIsOnline, function () {
  if (!isOffline.value && !loading.value && !state.users.length) {
    getUsers(1);
  }
});

onUnmounted(() => {
  controller.value.abort()
});

async function getUsers(newPage) {
  loading.value = true;
  page.value = newPage;
  const res = await UserService.getUsers(newPage, controller.value, sort.value, order.value);
  state.users = [...state.users, ...res['hydra:member']];
  if (newPage == 1) {
    maxSize.value = res['hydra:totalItems'];
  }

  userStore.items = state.users;
  userStore.page = page.value;

  loading.value = false;
}

const handleSelect = (id) => {
  userStore.lastItemId = id;
  router.push({path: `/users/${id}`})
}

</script>
<template>
  <AdminPermission :show-message="true">
    <div class="content">
      <div class="header">
        <h1>Comptes utilisateurs</h1>
        <IconButton
            :icon="iconAdd"
            class="blue"
            :can-click="true"
            @on-click="router.push({path: '/users/add'})"
        />
      </div>

      <RequiredOnlineMode v-if="isOffline && state.users.length === 0"/>
      <LoaderList v-if="loading && state.users.length === 0"/>

      <div class="list-users">
        <div
            class="item-user list tw-bg-primary tw-text-white"
            style="cursor: default"
        >
          <label @click="sortBy('firstname')"
                 class="firstname tw-flex tw-text-white tw-items-center tw-gap-1 tw-cursor-pointer">
            <span v-if="sort === 'firstname'" class="tw-flex tw-items-center">
              <svg :class="{ 'tw-rotate-180': order === 'desc'}" class="tw-h-4 tw-w-4" width="24" height="24"
                   viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                   stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline
                  points="6 9 12 15 18 9"/></svg>
            </span>

            <span>Prénom</span>
          </label>
          <div class="separator" style="border-color: rgba(255,255,255,0.53)"></div>

          <label @click="sortBy('lastname')"
                 class="lastname tw-flex tw-text-white tw-items-center tw-gap-1 tw-cursor-pointer">
            <span v-if="sort === 'lastname'" class="tw-flex tw-items-center">
              <svg :class="{ 'tw-rotate-180': order === 'desc'}" class="tw-h-4 tw-w-4" width="24" height="24"
                   viewBox="0 0 24 24" stroke-width="2"
                   stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path
                  stroke="none" d="M0 0h24v24H0z"/>  <polyline points="6 9 12 15 18 9"/></svg>
            </span>

            <span>Nom</span>
          </label>

          <div class="separator" style="border-color: rgba(255,255,255,0.53)"></div>

          <label style="width: 14px" @click="sortBy('active')"
                 class="lastname tw-flex tw-text-white tw-items-center tw-gap-1 tw-cursor-pointer">
            <span v-if="sort === 'active'" class="tw-flex tw-items-center">
              <svg :class="{ 'tw-rotate-180': order === 'desc'}" class="tw-h-4 tw-w-4" width="24" height="24"
                   viewBox="0 0 24 24" stroke-width="2"
                   stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path
                  stroke="none" d="M0 0h24v24H0z"/>  <polyline points="6 9 12 15 18 9"/></svg>
            </span>

            <span>Actif</span>
          </label>
          <div style="width: 42px">
          </div>
        </div>
      </div>

      <div class="list-users">
        <div
            v-for="user of state.users"
            :key="user.id"
            class="item-user list"
            @click="handleSelect(user.id)"
            :id="'user-' + user.id"
        >
          <label class="firstname">{{ user.firstname }}</label>
          <div class="separator"/>
          <label class="lastname">{{ user.lastname }}</label>
          <div class="separator"/>
          <div
              class="status"
              :class="{enabled: user.active}"
          />
          <div class="show-detail">
            <img :src="arrowRight">
          </div>
        </div>
        <LoaderItem v-if="loading && state.users.length !== 0"/>
      </div>
      <div
          v-if="!loading && state.users.length !== maxSize"
          class="container-button"
      >
        <Button
            type="primary"
            margin-top="10"
            @on-click="getUsers(page + 1)"
        >
          Voir plus
        </Button>
      </div>
    </div>
  </AdminPermission>
</template>
<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list-users {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 20px;

  .item-user {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border: 1px solid $blue-light;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 200ms;

    &:hover {
      border: 1px solid rgba(101, 159, 18, 0.3);
      box-shadow: 0 2px 15px 0 rgba(101, 159, 18, 0.3);
    }

    .firstname, .lastname {
      width: 15%;
    }

    .email {
      width: 35%;
    }

    .separator {
      margin: 0px 5px;
      height: 16px;
      border-right: 3px solid rgba(14, 16, 18, 0.4);
    }

    .status {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $error;
      margin-left: 10px;

      &.enabled {
        background-color: $primary;
      }
    }

    .show-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background-color: #F3F0E3;
      border-radius: 15px;
    }
  }
}

.container-button {
  margin-top: 1em;
  display: flex;
  margin-bottom: 1em;
}
</style>