<template>
  <Transition>
    <p>Pour afficher le contenu de cette page, vous devez être connecté à Internet !</p>   
  </Transition>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

p{
    font-family: 'NunitoSemiBold';
}
</style>