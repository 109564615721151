<template>
  <form :class="props.showModal ? 'blured' : ''">
    <div
        class="tw-flex tw-items-center"
    >
      <div
          style="background-color: #0F8B8DFF"
          class="tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-mr-2 tw-cursor-pointer"
          @click="submitForm(() => router.push({ path: `/census/${props.censusId}/dogs` }))"
      >
        <img alt="" :src="arrowBack"/>
      </div>

      <SelectorList
          class="tw-flex-1"
          id="dog-selector"
          v-if="dogList"
          type="dog"
          :items="dogList"
          :default="dogList[0]"
          @on-click="(item) => props.currentPage && props.currentPage === 'dog' && submitForm(() => changeDog(item))"
          @on-action="() => router.push({ path: `/census/${props.censusId}/dogs` })"
      />
    </div>
    <div v-if="state.isDogAboveEighteen && !loading">
      <div class="holding">
        <div class="holding-head">
          <p>IDENTITE</p>
        </div>

        <div class="identity-body">
          <TextInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="dog-input"
              :on-change="handleChange"
              :name="'name'"
              :model="form?.name"
              :errors="vDog$.name.$errors"
              :on-blur="vDog$.name.$touch"
              label="Nom *"
              placeholder=""
              :disabled="props.disabled"
          />

          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'gender'"
              :model="form?.gender"
              :errors="vDog$.gender.$errors"
              :on-blur="vDog$.gender.$touch"
              label="Sexe *"
              placeholder=""
              :options="dogConst.sexeConfig"
              :disabled="props.disabled"
          />

          <DatePickerController
              custom-class="dog-input"
              :on-change="handleChange"
              :name="'birthdate'"
              :errors="vDog$.birthdate.$errors"
              :on-blur="vDog$.birthdate.$touch"
              :model="new Date(form?.birthdate)"
              label="Date de naissance *"
              :timepicker="false"
              :disabled="props.disabled"
              :read-only="!isSameUser && !isUserAdmin"
          />

          <div class="border"/>

          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'identificationType'"
              :errors="vDog$.identificationType.$errors"
              :on-blur="vDog$.identificationType.$touch"
              :model="form?.identificationType"
              label="Type d'identification *"
              placeholder=""
              :options="dogConst.identificationType"
              :disabled="props.disabled"
              first-tooltip="Si le chien n’est pas identifié, il faut informer l’éleveur de son obligation d’identification.
            Si plusieurs chiens ne sont pas identifiés alors ne pas procéder au recensement."
          />
          <TextInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="dog-input"
              :on-change="handleChange"
              :name="'identificationNum'"
              :errors="vDog$.identificationNum.$errors"
              :on-blur="vDog$.identificationNum.$touch"
              :model="form?.identificationNum"
              label="N° d'identification *"
              placeholder=""
              :disabled="props.disabled"
          />
          <div v-if="state.error" class="error">
            {{ state.error }}
          </div>

          <div class="form-line"/>
          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'appearance'"
              :model="form?.appearance"
              :errors="vDog$.appearance.$errors"
              :on-blur="vDog$.appearance.$touch"
              label="Type racial *"
              :options="dogConst.appearanceConfig"
              :disabled="props.disabled"
          />

          <div
              v-if="form.appearance === 'pure_lof'"
              class="has-switch label-is-lof"
          >
            <TextInputController
                :read-only="!isSameUser && !isUserAdmin"
                class="has-switch-input"
                custom-class="dog-input"
                :on-change="handleChange"
                :name="'numLof'"
                :errors="vDog$.numLof.$errors"
                :on-blur="vDog$.numLof.$touch"
                :model="form?.numLof"
                label="N° lof"
                placeholder=""
                :disabled="props.disabled"
                tooltip="S’il est né de parents LOF mais qu’il n’a
              pas été confirmé, le préciser"
            />
            <RadioYesNo
                label="Confirmé"
                :is-italic="true"
                :default-value="form?.isLof"
                :disabled="props.disabled"
                :read-only="!isSameUser && !isUserAdmin"
                @on-change="(newValue) => (state.isLof = newValue)"
                id="isLof"
            />
          </div>

          <TextInputController
              v-if="form.appearance === 'pure_lof'"
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="dog-input"
              :on-change="handleChange"
              :name="'birthName'"
              :model="form?.birthName"
              label="Nom de naissance"
              placeholder=""
              :disabled="props.disabled"
          />

          <SelectInputController
              v-if="
              form.appearance === 'cross'
            "
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'breed'"
              :errors="vDog$.breed.$errors"
              :on-blur="vDog$.breed.$touch"
              :model="form?.breed"
              :label="
              'Apparence(s) raciale(s) (4 max)*'
            "
              :is-multiple="true"
              :options="dogConst.raceConfig"
              :disabled="props.disabled"
              :config="dogConst.raceConfig"
              :maxMultiple="4"
          />

          <SelectInputController
              v-if="
              form.appearance === 'standard' ||
              form.appearance === 'pure_lof'
            "
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'breed'"
              :errors="vDog$.breed.$errors"
              :on-blur="vDog$.breed.$touch"
              :model="form?.breed"
              :label="
              form?.appearance === 'pure_lof' ? 'Race *' : 'Apparence raciale *'
            "
              :is-multiple="false"
              :options="dogConst.raceConfig"
              :disabled="props.disabled"
              :config="dogConst.raceConfig"
          />

          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              :name="'isIdentified'"
              :errors="vDog$.isIdentified.$errors"
              :on-blur="vDog$.isIdentified.$touch"
              label="Identification / Empreinte génétique déjà réalisée"
              :model="form?.isIdentified"
              :options="dogConst.atWorkConfig"
              :disabled="props.disabled"
          />
        </div>
      </div>
      <div class="holding">
        <div class="holding-head">
          <p>INFORMATIONS SUR LE PERE</p>
        </div>
        <div class="holding-body">
          <RadioYesNo
              label="Informations sur le père"
              :is-italic="true"
              :default-value="!state.noFatherInfo"
              :disabled="props.disabled"
              :read-only="!isSameUser && !isUserAdmin"
              @on-change="(newValue) => (state.noFatherInfo = !newValue)"
              id="noFatherInfo"
          />

          <div v-if="!state.noFatherInfo" class="holding-body-cat">
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'name'"
                    :model="fatherForm?.name"
                    label="Nom"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'identificationNum'"
                    :model="fatherForm?.identificationNum"
                    label="N° d'identification"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>

            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="fatherHandleChange"
                    name="appearance"
                    label="Type racial"
                    :model="fatherForm?.appearance"
                    :options="dogConst.appearanceConfig"
                    :disabled="props.disabled"
                />
              </div>
            </div>

            <div
                v-if="fatherForm?.appearance === 'pure_lof'"
                class="holding-body-row"
            >
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'numLof'"
                    :model="fatherForm?.numLof"
                    label="N° lof (facultatif)"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>

            <div
                class="holding-body-row"
                v-if="fatherForm.appearance === 'cross'"
            >
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="fatherHandleChange"
                    :name="'breed'"
                    :model="fatherForm?.breed"
                    :label="
                    'Apparence(s) raciale(s) (4 max)'
                  "
                    :is-multiple="true"
                    :options="dogConst.raceConfig"
                    :disabled="props.disabled"
                    :config="dogConst.raceConfig"
                    :maxMultiple="4"
                />
              </div>
            </div>
            <div
                class="holding-body-row"
                v-if="fatherForm.appearance === 'standard' ||
              fatherForm?.appearance === 'pure_lof'"
            >
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="fatherHandleChange"
                    :name="'breed'"
                    :model="fatherForm?.breed"
                    :label="fatherForm?.appearance === 'pure_lof' ? 'Race' : 'Apparence raciale'"
                    :is-multiple="false"
                    :options="dogConst.raceConfig"
                    :disabled="props.disabled"
                    :config="dogConst.raceConfig"
                />
              </div>
            </div>

            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="fatherHandleChange"
                    name="isCertifiedSonship"
                    label="Filiation certifiée (génétiquement)"
                    :model="fatherForm?.isCertifiedSonship"
                    :options="dogConst.trueOrFalseConfig"
                    :disabled="props.disabled"
                    first-tooltip="Filiation certifiée génétiquement (par
                  un test : certification de parenté)"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="fatherHandleChange"
                    name="atWork"
                    label="Au travail"
                    :model="fatherForm?.atWork"
                    :options="dogConst.atWorkConfig"
                    :disabled="props.disabled"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'ownerName'"
                    :model="fatherForm?.ownerName"
                    label="Nom du propriétaire"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'phone'"
                    :type="'phone'"
                    :model="fatherForm?.phone"
                    :errors="vFather$.phone.$errors"
                    :on-blur="vFather$.phone.$touch"
                    label="Num. de téléphone"
                    placeholder=""
                    :maxlength="10"
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'email'"
                    :model="fatherForm?.email"
                    label="Email"
                    :errors="vFather$.email.$errors"
                    :on-blur="vFather$.email.$touch"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>

            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="fatherHandleChange"
                    :name="'address'"
                    :model="fatherForm?.address"
                    label="Adresse"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">
          <p>INFORMATIONS SUR LA MERE</p>
        </div>
        <div class="holding-body">
          <RadioYesNo
              label="Informations sur la mère"
              :is-italic="true"
              :default-value="!state.noMotherInfo"
              :disabled="props.disabled"
              :read-only="!isSameUser && !isUserAdmin"
              @on-change="(newValue) => (state.noMotherInfo = !newValue)"
              id="noMotherInfo"
          />
          <div v-if="!state.noMotherInfo" class="holding-body-cat">
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'name'"
                    :model="motherForm?.name"
                    label="Nom"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'identificationNum'"
                    :model="motherForm?.identificationNum"
                    label="N° d'identification"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="motherHandleChange"
                    name="appearance"
                    label="Type racial"
                    :model="motherForm?.appearance"
                    :options="dogConst.appearanceConfig"
                    :disabled="props.disabled"
                />
              </div>
            </div>

            <div
                v-if="motherForm?.appearance === 'pure_lof'"
                class="holding-body-row"
            >
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'numLof'"
                    :model="motherForm?.numLof"
                    label="N° lof (facultatif)"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>

            <div
                class="holding-body-row"
                v-if="motherForm.appearance === 'cross'"
            >
              <div class="holding-body-row-input">
                <SelectInputController

                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="motherHandleChange"
                    :name="'breed'"
                    :model="motherForm?.breed"
                    :label="
                    'Apparence(s) raciale(s) (4 max)'
                  "
                    :is-multiple="true"
                    :options="dogConst.raceConfig"
                    :disabled="props.disabled"
                    :config="dogConst.raceConfig"
                    :maxMultiple="4"
                />
              </div>
            </div>
            <div
                class="holding-body-row"
                v-if="motherForm.appearance === 'standard' ||
              motherForm?.appearance === 'pure_lof'"
            >
              <div class="holding-body-row-input">
                <SelectInputController
                    v-if="motherForm.appearance !== 'cross'"
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="motherHandleChange"
                    :name="'breed'"
                    :model="motherForm?.breed"
                    :label="
                  motherForm?.appearance === 'pure_lof' ? 'Race' : 
                    'Apparence raciale'
                  "
                    :is-multiple="false"
                    :options="dogConst.raceConfig"
                    :disabled="props.disabled"
                    :config="dogConst.raceConfig"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="motherHandleChange"
                    name="atWork"
                    label="Au travail"
                    :model="motherForm?.atWork"
                    :options="dogConst.atWorkConfig"
                    :disabled="props.disabled"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="motherHandleChange"
                    name="isCertifiedSonship"
                    label="Filiation certifiée (génétiquement)"
                    first-tooltip="filiation certifiée génétiquement (par
                  un test : certification de parenté)"
                    :model="motherForm?.isCertifiedSonship"
                    :options="dogConst.trueOrFalseConfig"
                    :disabled="props.disabled"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'ownerName'"
                    :model="motherForm?.ownerName"
                    label="Nom du propriétaire"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'phone'"
                    :type="'phone'"
                    :model="motherForm?.phone"
                    :errors="vMother$.phone.$errors"
                    :on-blur="vMother$.phone.$touch"
                    label="Num. de téléphone"
                    placeholder=""
                    :maxlength="10"
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'email'"
                    :model="motherForm?.email"
                    label="Email"
                    :errors="vMother$.email.$errors"
                    :on-blur="vMother$.email.$touch"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="motherHandleChange"
                    :name="'address'"
                    :model="motherForm?.address"
                    label="Adresse"
                    placeholder=""
                    :disabled="props.disabled"
                    :read-only="!isUserAdmin && !isSameUser"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">
          <IconHolding class="house-icon" :fill="'white'"/>
          <p>ENVIRONNEMENT DU CHIEN DANS l'EXPLOITATION</p>
        </div>
        <div class="holding-body">
          <p>ANIMAUX</p>
          <div class="holding-body-cat">
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="specie"
                    :errors="vDog$.specie.$errors"
                    :on-blur="vDog$.specie.$touch"
                    label="Espèces (plusieurs réponses possibles)"
                    :model="form.specie"
                    :options="exploitConst.speciesConfig"
                    first-tooltip="On parle ici de l’espèce ou des espèces
                  sur lesquelles travaille le chien."
                    second-tooltip="S’il est à la fois sur des brebis et des chèvres, on cochera les deux."
                    third-tooltip="S’il est sur des brebis et qu’il croise de temps en temps des vaches, on ne cochera pas ici la case bovin mais on le précisera à la question « Y a-t-il d’autres espèces dans l’exploitation ?»"
                    :disabled="props.disabled"
                    :is-multiple="true"
                    :config="exploitConst.speciesConfig"
                />
              </div>
            </div>
            <div
                v-if="
                  form.specie && form.specie.includes('other')
                "
                class="holding-body-row"
            >
              <div class="holding-body-row-icon-input">
                <TextInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="dog-input"
                    :on-change="handleChangeSpecieOther"
                    name="specieOther"
                    label="Autre(s) espèce(s)"
                    :model="state.specieOther"
                />
              </div>
            </div>
            <div v-if="form.specie?.length > 0 && form.specie !== ''">
              <p>EFFECTIF POUR CHAQUE ESPÈCE</p>
              <div
                  v-for="spec of form.specie"
                  :key="spec"
                  class="holding-body-row"
              >
                <div class="holding-body-row-icon-input">
                  <TextInputController
                      custom-class="dog-input"
                      :on-change="handleChangeSpecie"
                      :name="'nbPersons-' + spec"
                      :label="'Effectif ' + formatSpecie(spec)"
                      placeholder=""
                      :model="state.specNbPersons[spec]"
                      :disabled="props.disabled"
                      :read-only="!isUserAdmin && !isSameUser"
                      :type="'num'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="holding-body-cat period-title">
            <p class="little-title">SYSTEME D'ELEVAGE</p>
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    :name="'typeOfSystem'"
                    :errors="vDog$.typeOfSystem.$errors"
                    :on-blur="vDog$.typeOfSystem.$touch"
                    :model="form.typeOfSystem"
                    label="Type de système"
                    placeholder=""
                    :options="exploitConst.typeOfSystem"
                    :disabled="props.disabled"
                />
              </div>
            </div>
            <div
                v-if="form.typeOfSystem !== 'indoor'"
                class="holding-body-row"
            >
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    :name="'typeOfDriving'"
                    :errors="vDog$.typeOfDriving.$errors"
                    :on-blur="vDog$.typeOfDriving.$touch"
                    :model="form.typeOfDriving"
                    label="Conduite du troupeau à l'extérieur (si période en plein-air)"
                    placeholder=""
                    :options="exploitConst.typeOfConduct"
                    :disabled="props.disabled"
                    :is-multiple="true"
                    :config="exploitConst.typeOfConduct"
                />
              </div>
            </div>
          </div>
          <p>FREQUENTATION DE L'ENVIRONNEMENT DU CHIEN</p>
          <div class="holding-body-cat">
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="humainPresence"
                    label="Présence humaine sur l'exploitation (plusieurs réponses possibles)"
                    :model="form.humainPresence"
                    :errors="vDog$.humainPresence.$errors"
                    :on-blur="vDog$.humainPresence.$touch"
                    :options="exploitConst.humanPresences"
                    :disabled="props.disabled"
                    :is-multiple="true"
                    :config="exploitConst.humanPresences"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="otherDogsPresence"
                    :errors="vDog$.otherDogsPresence.$errors"
                    :on-blur="vDog$.otherDogsPresence.$touch"
                    label="Présence d'autres chiens de ferme (plusieurs réponses possibles)"
                    :model="form.otherDogsPresence"
                    :options="exploitConst.otherDogPresences"
                    :disabled="props.disabled"
                    :is-multiple="true"
                    :config="exploitConst.otherDogPresences"
                />
              </div>
            </div>
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="oustiderHumanPresence"
                    :errors="vDog$.oustiderHumanPresence.$errors"
                    :on-blur="vDog$.oustiderHumanPresence.$touch"
                    label="Fréquentation humaine de personnes extérieures (indiquez la plus forte fréquentation)"
                    :model="form.oustiderHumanPresence"
                    :options="exploitConst.outsiderHumainPresences"
                    :disabled="props.disabled"
                    first-tooltip="Elle est permanente si : "
                    second-tooltip="- En alpage/estive > 3 mois "
                    third-tooltip="- Sur la ferme (vente directe par exemple) > 2j par semaine pendant 6 mois consécutifs"
                    :is-multiple="true"
                    :config="exploitConst.outsiderHumainPresences"
                />
              </div>
            </div>
          </div>
          <p>TYPE(S) DE PREDATION POUR LEQUEL LE CHIEN EST UTILISE</p>
          <div class="holding-body-cat">
            <div class="holding-body-row">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="typeOfPredation"
                    :errors="vDog$.typeOfPredation.$errors"
                    :on-blur="vDog$.typeOfPredation.$touch"
                    label="Prédation (plusieurs réponses possibles)"
                    :is-multiple="true"
                    :model="form.typeOfPredation"
                    :options="exploitConst.typeOfPredations"
                    :disabled="props.disabled"
                    :config="exploitConst.typeOfPredations"
                />
              </div>
            </div>
            <div
                v-if="
                  form.typeOfPredation && form.typeOfPredation.includes('other')
                "
                class="holding-body-row"
            >
              <div class="holding-body-row-icon-input">
                <TextInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="dog-input"
                    :on-change="handleChange"
                    name="otherTypeOfPredation"
                    :errors="vDog$.otherTypeOfPredation.$errors"
                    :on-blur="vDog$.otherTypeOfPredation.$touch"
                    label="Autre type de prédation"
                    :model="form.otherTypeOfPredation"
                />
              </div>
            </div>
            <div class="holding-body-row other-specie">
              <div class="holding-body-row-icon-input">
                <SelectInputController
                    :read-only="!isSameUser && !isUserAdmin"
                    custom-class="Select dog-input"
                    :on-change="handleChange"
                    name="isOtherSpecie"
                    :errors="vDog$.isOtherSpecie.$errors"
                    :on-blur="vDog$.isOtherSpecie.$touch"
                    label="Y a-t-il d’autres espèces sans lien avec le chien de protection dans cette exploitation ?"
                    :model="form.isOtherSpecie"
                    :options="exploitConst.yesOrNo"
                    :disabled="props.disabled"
                />
              </div>
            </div>
          </div>

          <div v-if="form.isOtherSpecie" class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="!isSameUser && !isUserAdmin"
                  custom-class="Select dog-input"
                  :on-change="handleChange"
                  name="otherSpecie"
                  :errors="vDog$.otherSpecie.$errors"
                  :on-blur="vDog$.otherSpecie.$touch"
                  label="Autre espèce (plusieurs réponses possibles)"
                  :model="form.otherSpecie"
                  :options="exploitConst.otherSpeciesConfig"
                  :disabled="props.disabled"
                  :is-multiple="true"
                  :config="exploitConst.otherSpeciesConfig"
              />
            </div>
          </div>
          <div v-if="form.isOtherSpecie" class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="!isSameUser && !isUserAdmin"
                  custom-class="Select dog-input"
                  :on-change="handleChange"
                  name="otherTypeOfSystem"
                  :errors="vDog$.otherTypeOfSystem.$errors"
                  :on-blur="vDog$.otherTypeOfSystem.$touch"
                  label="Autre système d'élevage"
                  :model="form.otherTypeOfSystem"
                  :options="exploitConst.typeOfSystem"
                  :disabled="props.disabled"
              />
            </div>
          </div>
          <div class="holding-body-row-icon-input">
            <SelectInputController
                v-if="form.otherTypeOfSystem !== 'indoor' && form.isOtherSpecie"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                :name="'otherTypeOfDriving'"
                :errors="vDog$.otherTypeOfDriving.$errors"
                :on-blur="vDog$.otherTypeOfDriving.$touch"
                :model="form.otherTypeOfDriving"
                label="Conduite du troupeau à l'extérieur (si période en plein-air)"
                placeholder=""
                :options="exploitConst.typeOfConduct"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="exploitConst.typeOfConduct"
            />
          </div>
        </div>
        <!-- <Button
          v-if="!props.disabled && props.census?.dogs?.length > 1"
          class="search-btn"
          :disabled="loading || props.isOffline"
          @on-click="handleApplyOthersDogs()"
        >
          APPLIQUER AUX AUTRES CHIENS
        </Button> -->
      </div>

      <div class="holding">
        <div class="holding-head">
          <p>CARACTERISTIQUES GENERALES</p>
        </div>

        <div class="carac-selects">
          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              name="isBornHerd"
              :errors="vDog$.isBornHerd.$errors"
              :on-blur="vDog$.isBornHerd.$touch"
              label="Chien né au troupeau"
              :model="form?.isBornHerd"
              :options="dogConst.atWorkConfig"
              :disabled="props.disabled"
          />
          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              name="beforeTwoMonth"
              :errors="vDog$.beforeTwoMonth.$errors"
              :on-blur="vDog$.beforeTwoMonth.$touch"
              label="Environnement du chien de 0 à 2 mois"
              :model="form?.beforeTwoMonth"
              :options="dogConst.zeroTwoMonthConfig"
              :disabled="props.disabled"
          />
          <SelectInputController
              :read-only="!isSameUser && !isUserAdmin"
              custom-class="Select dog-input"
              :on-change="handleChange"
              name="isFirstForBreeder"
              :errors="vDog$.isFirstForBreeder.$errors"
              :on-blur="vDog$.isFirstForBreeder.$touch"
              label="1er chien de l'éleveur"
              :model="form?.isFirstForBreeder"
              :options="dogConst.trueOrFalseConfig"
              :disabled="props.disabled"
          />
        </div>
        <div class="holding">
          <div class="holding-head">
            <p>CARACTERISTIQUES PHYSIQUES</p>
          </div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isHealthy"
                :errors="vDog$.isHealthy.$errors"
                :on-blur="vDog$.isHealthy.$touch"
                label="Bon état général du chien"
                first-tooltip="Du point de vue sanitaire (état corporel....)"
                :model="form?.isHealthy"
                :options="dogConst.trueOrFalseConfig"
                :disabled="props.disabled"
            />

            <div class="sub-title-tooltip">
              ADAPTATION DE LA MORPHOLOGIE PAR RAPPORT AU TRAVAIL (EN VUE D'UNE
              REPRODUCTION)
              <Tooltip
                  text="L’objectif est de sortir de la reproduction les extrêmes, pas de se conformer aux standards SCC."
                  custom-class="dog-input"
              />
            </div>

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedMorpho"
                :errors="vDog$.isAdaptedMorpho.$errors"
                :on-blur="vDog$.isAdaptedMorpho.$touch"
                label="Globalement"
                :model="form?.isAdaptedMorpho"
                :options="dogConst.atWorkConfig"
                :disabled="props.disabled"
            />
            <div v-if="form.isAdaptedMorpho === 'false'" class="sub-title">
              PRECISEZ QUEL(S) CRITERE(S) SONT NON CONFORMES (NC)
            </div>

            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedSize"
                :errors="vDog$.isAdaptedSize.$errors"
                :on-blur="vDog$.isAdaptedSize.$touch"
                label="Taille"
                :model="form?.isAdaptedSize"
                :options="dogConst.nonConformConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedDemarche"
                :errors="vDog$.isAdaptedDemarche.$errors"
                :on-blur="vDog$.isAdaptedDemarche.$touch"
                label="Démarche"
                :model="form?.isAdaptedDemarche"
                :options="dogConst.nonConformConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedAplombs"
                :errors="vDog$.isAdaptedAplombs.$errors"
                :on-blur="vDog$.isAdaptedAplombs.$touch"
                label="Aplombs"
                :model="form?.isAdaptedAplombs"
                :options="dogConst.nonConformConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedBackLine"
                :errors="vDog$.isAdaptedBackLine.$errors"
                :on-blur="vDog$.isAdaptedBackLine.$touch"
                label="Ligne de dos"
                :model="form?.isAdaptedBackLine"
                :options="dogConst.nonConformConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedJaw"
                :errors="vDog$.isAdaptedJaw.$errors"
                :on-blur="vDog$.isAdaptedJaw.$touch"
                label="Mâchoire (absence de prognathie inférieure ou supérieure)"
                :model="form?.isAdaptedJaw"
                :options="dogConst.nonConformConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAdaptedPigmentation"
                :errors="vDog$.isAdaptedPigmentation.$errors"
                :on-blur="vDog$.isAdaptedPigmentation.$touch"
                label="Pigmentation (absence de muqueuses blanches)"
                :model="form?.isAdaptedPigmentation"
                :options="dogConst.pigmentationConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.isAdaptedMorpho === 'false'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasTesticleConcerned"
                :errors="vDog$.hasTesticleConcerned.$errors"
                :on-blur="vDog$.hasTesticleConcerned.$touch"
                label="2 testicules si concerné"
                :model="form?.hasTesticleConcerned"
                :options="dogConst.testicleConfig"
                :disabled="props.disabled"
            />
            <TextInputController
                v-if="form.isAdaptedMorpho === 'false'"
                custom-class="dog-input"
                :on-change="handleChange"
                name="adaptedMorphoCommentary"
                :errors="vDog$.adaptedMorphoCommentary.$errors"
                :on-blur="vDog$.adaptedMorphoCommentary.$touch"
                :model="form?.adaptedMorphoCommentary"
                label="Commentaire"
                placeholder=""
                :disabled="props.disabled"
                :read-only="!isUserAdmin && !isSameUser"
            />
          </div>
        </div>

        <div class="holding">
          <h1 class="holding-head">TESTS DE SANTE</h1>

          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasHealthyTrouble"
                :errors="vDog$.hasHealthyTrouble.$errors"
                :on-blur="vDog$.hasHealthyTrouble.$touch"
                label="Le chien a-t-il un problème de santé connu ?"
                first-tooltip="On parle d’un problème qui dure dans
              le temps : cardiaque, sourd, aveugle, problèmes neuro... Si
              oui, précisez lequel."
                :model="form?.hasHealthyTrouble"
                :options="dogConst.yesOrNoConfig"
                :disabled="props.disabled"
            />
            <TextInputController
                v-if="form.hasHealthyTrouble"
                custom-class="dog-input"
                :on-change="handleChange"
                :name="'otherProblem'"
                :errors="vDog$.otherProblem.$errors"
                :on-blur="vDog$.otherProblem.$touch"
                :model="form?.otherProblem"
                label="Précisez lequel"
                placeholder=""
                :disabled="props.disabled"
                :read-only="!isUserAdmin && !isSameUser"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hipDysplasia"
                :errors="vDog$.hipDysplasia.$errors"
                :on-blur="vDog$.hipDysplasia.$touch"
                label="Dysplasie des hanches"
                :model="form?.hipDysplasia"
                :options="dogConst.hipDysplasiaConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="elbowDysplasia"
                :errors="vDog$.elbowDysplasia.$errors"
                :on-blur="vDog$.elbowDysplasia.$touch"
                label="Dysplasie des coudes"
                :model="form?.elbowDysplasia"
                :options="dogConst.elbowDysplasiaConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head">PAR RAPPORT A SA MISE EN PLACE</div>
          <div class="carac-selects">
            <TextInputController
                custom-class="dog-input"
                :on-change="handleChange"
                :name="'exploitationAge'"
                :errors="vDog$.exploitationAge.$errors"
                :on-blur="vDog$.exploitationAge.$touch"
                :model="form?.exploitationAge"
                label="Age d'arrivée sur l'exploitation (en mois)"
                placeholder=""
                :disabled="props.disabled"
                :read-only="!isUserAdmin && !isSameUser"
                @keypress="isNumber($event)"
            />
          </div>
        </div>

        <div class="holding">
          <div class="carac-selects">
            <p class="sub-title">CONDITIONS D'INTRODUCTION AU TROUPEAU</p>
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="introductionCondition"
                :errors="vDog$.introductionCondition.$errors"
                :on-blur="vDog$.introductionCondition.$touch"
                label="Avec qui ?"
                :model="form.introductionCondition"
                :options="dogConst.introductionConditionConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.introductionCondition === 'other_puppies'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="introductionConditionPuppies"
                :errors="vDog$.introductionConditionPuppies.$errors"
                :on-blur="vDog$.introductionConditionPuppies.$touch"
                label="Précisez"
                :model="form.introductionConditionPuppies"
                :options="dogConst.introductionConditionPuppiesConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.introductionCondition === 'with_adults'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="introductionConditionAdults"
                :errors="vDog$.introductionConditionPuppies.$errors"
                :on-blur="vDog$.introductionConditionPuppies.$touch"
                label="Précisez"
                :model="form.introductionConditionAdults"
                :options="dogConst.introductionConditionAdultsConfig"
                :disabled="props.disabled"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="parcel"
                :errors="vDog$.parcel.$errors"
                :on-blur="vDog$.parcel.$touch"
                label="Comment ? (plusieurs réponses possibles)"
                :model="form?.parcel"
                :options="dogConst.parcelConfig"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="dogConst.parcelConfig"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="parcelHeifers"
                :errors="vDog$.parcelHeifers.$errors"
                :on-blur="vDog$.parcelHeifers.$touch"
                label="Sur quelle(s) espèce(s)"
                :model="form?.parcelHeifers"
                :options="dogConst.parcelHeifersConfig"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="dogConst.parcelHeifersConfig"
            />
            <TextInputController
                v-if="form?.parcelHeifers?.includes('others')"
                custom-class="dog-input"
                :on-change="handleChange"
                name="parcelOthers"
                :errors="vDog$.parcelOthers.$errors"
                :on-blur="vDog$.parcelOthers.$touch"
                :model="form?.parcelOthers"
                label="Autres"
                placeholder=""
                :disabled="props.disabled"
            />
            <p class="sub-title">COMPORTEMENTS LORS DE SA MISE EN PLACE</p>

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="behaviorWithHuman"
                :errors="vDog$.behaviorWithHuman.$errors"
                :on-blur="vDog$.behaviorWithHuman.$touch"
                label="Comportement vis-à-vis des humains à l'arrivée à la ferme"
                first-tooltip="Plutôt méfiant : chiot qui évite
              l’éleveur, se cache quand il le voit"
                second-tooltip="Confiant : intéressé par l’Homme ou neutre"
                third-tooltip="Plutôt collant : dépendance affective à l’Homme, en
              demande, suit l’Homme quand il part et/ou pleure"
                :model="form?.behaviorWithHuman"
                :options="dogConst.behaviorWithHumanConfig"
                :disabled="props.disabled"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="temper"
                :errors="vDog$.temper.$errors"
                :on-blur="vDog$.temper.$touch"
                label="Caractère"
                :model="correctDog?.temper"
                :options="dogConst.temperConfig"
                :disabled="props.disabled"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="respectingOthers"
                :errors="vDog$.respectingOthers.$errors"
                :on-blur="vDog$.respectingOthers.$touch"
                label="Respect des animaux"
                :model="form?.respectingOthers"
                :options="dogConst.isRespectingOthersConfig"
                :disabled="props.disabled"
            />

            <span
                v-if="
                form.respectingOthers === 'little' ||
                form.respectingOthers === 'a_lot'
              "
            >
              De quel âge à quel âge (en mois) ?
            </span>
            <FormLine
                v-if="
                form.respectingOthers === 'little' ||
                form.respectingOthers === 'a_lot'
              "
            >
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleChange"
                  :name="'startRespectingOthers'"
                  :errors="vDog$.startRespectingOthers.$errors"
                  :on-blur="vDog$.startRespectingOthers.$touch"
                  :model="form?.startRespectingOthers"
                  label="De"
                  :disabled="props.disabled"
                  :read-only="!isUserAdmin && !isSameUser"
                  @keypress="isNumber($event)"
              />
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleChange"
                  :name="'endRespectingOthers'"
                  :errors="vDog$.endRespectingOthers.$errors"
                  :on-blur="vDog$.endRespectingOthers.$touch"
                  :model="form?.endRespectingOthers"
                  label="À"
                  :disabled="props.disabled"
                  :read-only="!isUserAdmin && !isSameUser"
                  @keypress="isNumber($event)"
              />
            </FormLine>

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="tookTime"
                :errors="vDog$.tookTime.$errors"
                :on-blur="vDog$.tookTime.$touch"
                label="A-t-il demandé du temps (attention, correction..)"
                :model="form?.tookTime"
                :options="dogConst.trueOrFalseConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isEasyDog"
                :errors="vDog$.isEasyDog.$errors"
                :on-blur="vDog$.isEasyDog.$touch"
                label="Considérez-vous ce chien"
                :model="form?.isEasyDog"
                :options="dogConst.isEasyDogConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>
        <div class="holding">
          <div class="holding-head">PAR RAPPORT A SON TEMPERAMENT</div>

          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="characteristics"
                :errors="vDog$.characteristics.$errors"
                :on-blur="vDog$.characteristics.$touch"
                label="Considérez-vous ce chien (plusieurs réponses possibles)"
                :model="form?.characteristics"
                :options="dogConst.considerDog"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="dogConst.considerDog"
                first-tooltip="L’objectif de cette question est de
              faire ressortir un ou des traits de tempéraments marqués. Si
              aucun ne correspond, laissez la case vide."
                second-tooltip="Précisions sur certains termes :"
                third-tooltip="- A l’écoute, attentif à l’Homme :
respect de l’Homme (par rapport à des ordres que lui donne son maître par exemple)"
                fourth-tooltip="- Sensible à
l’Homme : crainte, méfiance…"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head">PAR RAPPORT A SON MAITRE</div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isManipulable"
                :errors="vDog$.isManipulable.$errors"
                :on-blur="vDog$.isManipulable.$touch"
                label="Manipulable par son maitre (peut le toucher partout et l'enlacer au niveau du cou)"
                :model="form?.isManipulable"
                :options="dogConst.trueOrFalseConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>
        <div class="holding">
          <div class="holding-head">PAR RAPPORT AUX HUMAINS INCONNUS</div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="unknownDogTemper"
                :errors="vDog$.unknownDogTemper.$errors"
                :on-blur="vDog$.unknownDogTemper.$touch"
                label="Problèmes de comportement (dont l'agressivité)"
                :model="form?.unknownDogTemper"
                :options="dogConst.atWorkConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isMunipulableCensusTaker"
                :errors="vDog$.isMunipulableCensusTaker.$errors"
                :on-blur="vDog$.isMunipulableCensusTaker.$touch"
                label="Touchable par le recenseur (au troupeau ou en dehors)"
                :model="form?.isMunipulableCensusTaker"
                :options="dogConst.yesOrNoStringConfig"
                :disabled="props.disabled"
                first-tooltip="En présence de l’éleveur, soit dans le troupeau si c’est possible, soit si ce n’est pas possible demander
à l’éleveur de sortir le chien du troupeau"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="unknownTrustWithOthers"
                :errors="vDog$.unknownTrustWithOthers.$errors"
                :on-blur="vDog$.unknownTrustWithOthers.$touch"
                label="Avez-vous confiance en ce chien s'il rencontre des inconnus, en votre présence ?"
                :model="form?.unknownTrustWithOthers"
                :options="dogConst.watchfulAwakeningConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="unknownTrustOthersWithoutYou"
                :errors="vDog$.unknownTrustOthersWithoutYou.$errors"
                :on-blur="vDog$.unknownTrustOthersWithoutYou.$touch"
                label="Avez-vous confiance en ce chien s'il rencontre des inconnus, en votre absence ?"
                :model="form?.unknownTrustOthersWithoutYou"
                :options="dogConst.watchfulAwakeningConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head">
            PAR RAPPORT AUX AUTRES CHIENS DE PROTECTION (RELATIONNEL)
          </div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="positionInGroup"
                :errors="vDog$.positionInGroup.$errors"
                :on-blur="vDog$.positionInGroup.$touch"
                label="Position de ce chien au sein du groupe"
                :model="form?.positionInGroup"
                :options="dogConst.positionInGroupConfig"
                :disabled="props.disabled"
                first-tooltip="On cherche là-encore une tendance,
              certains chiens sont dominants avec tous les chiens, d’autres
              sont soumis avec tous les chiens, d’autres cela dépend du
              chien (= intermédiaire)"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="canWorkInGroup"
                :errors="vDog$.canWorkInGroup.$errors"
                :on-blur="vDog$.canWorkInGroup.$touch"
                label="Est-il capable de travailler en groupe ?"
                :model="form?.canWorkInGroup"
                :options="dogConst.atWorkConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="canWorkAlone"
                :errors="vDog$.canWorkAlone.$errors"
                :on-blur="vDog$.canWorkAlone.$touch"
                label="Est-il capable de travailler seul ?"
                :model="form?.canWorkAlone"
                :options="dogConst.canWorkAloneConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAgressifToOtherDogs"
                :errors="vDog$.isAgressifToOtherDogs.$errors"
                :on-blur="vDog$.isAgressifToOtherDogs.$touch"
                label="Problèmes de comportement (dont l'agressivité)"
                :model="form?.isAgressifToOtherDogs"
                :options="dogConst.atWorkConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head">PAR RAPPORT A SON ENVIRONNEMENT</div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="presentHerd"
                :errors="vDog$.presentHerd.$errors"
                :on-blur="vDog$.presentHerd.$touch"
                label="Présent au troupeau lors de la visite"
                :model="form?.presentHerd"
                :options="dogConst.presentHerdConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="watchfulAwakening"
                :errors="vDog$.watchfulAwakening.$errors"
                :on-blur="vDog$.watchfulAwakening.$touch"
                label="Est-ce un chien vigilant, en éveil ?"
                :model="form?.watchfulAwakening"
                :options="dogConst.watchfulAwakeningConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasExcessiveBarking"
                :errors="vDog$.hasExcessiveBarking.$errors"
                :on-blur="vDog$.hasExcessiveBarking.$touch"
                label="Considérez-vous ses aboiements ?"
                :model="form?.hasExcessiveBarking"
                :options="dogConst.hasExcessiveBarkingConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isGoodProtector"
                :errors="vDog$.isGoodProtector.$errors"
                :on-blur="vDog$.isGoodProtector.$touch"
                label="Est-ce un protecteur efficace du troupeau ?"
                :model="form?.isGoodProtector"
                :options="dogConst.goodProtectorConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="distanceHerd"
                :errors="vDog$.distanceHerd.$errors"
                :on-blur="vDog$.distanceHerd.$touch"
                label="Ce chien est"
                :model="form?.distanceHerd"
                :options="dogConst.distanceHerdConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="fenceRespect"
                :errors="vDog$.fenceRespect.$errors"
                :on-blur="vDog$.fenceRespect.$touch"
                label="Ce chien respecte-t-il les clôtures ?"
                :model="form?.fenceRespect"
                :options="dogConst.fenceRespectConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="runAfterVehicles"
                :errors="vDog$.runAfterVehicles.$errors"
                :on-blur="vDog$.runAfterVehicles.$touch"
                label="Ce chien court-il après les vélos/véhicules motorisés ?"
                :model="form?.runAfterVehicles"
                :options="dogConst.runAfterVehiclesConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head">APPRECIATION GLOBALE DE L'ELEVEUR</div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isAlwaysUsable"
                :errors="vDog$.isAlwaysUsable.$errors"
                :on-blur="vDog$.isAlwaysUsable.$touch"
                label="Ce chien est-il utilisable partout, tout le temps, en toutes conditions ?"
                :model="form?.isAlwaysUsable"
                :options="dogConst.isAlwaysUsableConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="recovery"
                :errors="vDog$.recovery.$errors"
                :on-blur="vDog$.recovery.$touch"
                label="Suite à un stimulus négatif, comment jugez-vous la récupération du chien ?"
                :model="form?.recovery"
                :options="dogConst.dogsRecovery"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="wantPuppy"
                :errors="vDog$.wantPuppy.$errors"
                :on-blur="vDog$.wantPuppy.$touch"
                label="Voudriez-vous un chiot de ce chien (par rapport à ses aptitudes au travail) ?"
                :model="form?.wantPuppy"
                :options="dogConst.trueOrFalseConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>

        <div class="holding">
          <div class="holding-head has-tooltip">
            CONCLUSION TECHNIQUE PAR LE RECENSEUR
            <Tooltip
                custom-class="holding-head"
                text="À remplir en présence de l’éleveur ou non. "
                second-text="S’il y a eu un suivi technique, renseignez les champs imprégnation, familiarisation, mise
            en place et fixation ; sinon, cochez « je ne peux pas juger »"
            />
          </div>
          <div class="carac-selects">
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isPotentialBreeder"
                :errors="vDog$.isPotentialBreeder.$errors"
                :on-blur="vDog$.isPotentialBreeder.$touch"
                label="Reproducteur potentiel"
                :model="form?.isPotentialBreeder"
                :options="dogConst.atWorkConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasGoodImpregnateHerdBfrTwoMounth"
                :errors="vDog$.hasGoodImpregnateHerdBfrTwoMounth.$errors"
                :on-blur="vDog$.hasGoodImpregnateHerdBfrTwoMounth.$touch"
                label="Imprégnation au troupeau avant 2 mois (si suivi technique)"
                :model="form?.hasGoodImpregnateHerdBfrTwoMounth"
                :options="dogConst.canNotJudgeConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasGoodFamiliarizationHumansBfrTwoMounths"
                :errors="vDog$.hasGoodFamiliarizationHumansBfrTwoMounths.$errors"
                :on-blur="vDog$.hasGoodFamiliarizationHumansBfrTwoMounths.$touch"
                label="Familiarisation aux humains avant 2 mois (si suivi technique)"
                :model="form?.hasGoodFamiliarizationHumansBfrTwoMounths"
                :options="dogConst.canNotJudgeConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="hasGoodFamiliarizationHumansAfterTwoMounths"
                :errors="vDog$.hasGoodFamiliarizationHumansAfterTwoMounths.$errors"
                :on-blur="vDog$.hasGoodFamiliarizationHumansAfterTwoMounths.$touch"
                label="Familiarisation aux humains après 2 mois (si suivi technique)"
                :model="form.hasGoodFamiliarizationHumansAfterTwoMounths"
                :options="dogConst.canNotJudgeConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="wasEasySetupPuppy"
                :errors="vDog$.wasEasySetupPuppy.$errors"
                :on-blur="vDog$.wasEasySetupPuppy.$touch"
                label="Mise en place en tant que chiots (si suivi technique)"
                :model="form?.wasEasySetupPuppy"
                :options="dogConst.asPuppyConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select dog-input"
                :on-change="handleChange"
                name="isClingyWithHerd"
                :errors="vDog$.isClingyWithHerd.$errors"
                :on-blur="vDog$.isClingyWithHerd.$touch"
                label="Fixation au troupeau (si suivi technique)"
                :model="form?.isClingyWithHerd"
                :options="dogConst.canNotJudgeConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>
      </div>

      <div class="notes">
        <div class="notes-title">
          <h1>Appréciation globale du recenseur</h1>
          <Tooltip
              text="Permet de reporter des choses importantes qui n’auraient pas trouvé leur place dans la grille ou
       des propositions d’évolution de la grille."
              custom-class="dog-input"
          />
        </div>

        <textarea
            :value="form?.notes"
            :disabled="props.disabled || (!isSameUser && !isUserAdmin)"
            @input="form.notes = $event.target.value"
            :style="[disabled || (!isSameUser && !isUserAdmin) ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
        />
      </div>

      <p
          v-if="!isSameUser && isUserAdmin"
          class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
      >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}</p>

      <div class="buttons"
           v-if="isSameUser || isUserAdmin"
      >
        <Button
            v-if="!props.disabled"
            class="search-btn"
            :disabled="loading"
            @on-click="submitForm()"
        >
          ENREGISTRER LES INFORMATIONS
        </Button>

        <Button
            v-if="!props.disabled && props.census.status !== 'completed'"
            class="search-btn"
            :disabled="loading"
            @click="showValidate()"
        >
          VALIDER LE RECENSEMENT
        </Button>
      </div>

      <p
          v-else
          class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
      >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}, modification non autorisée</p>

      <span v-if="error" class="error">{{ error }}</span>
    </div>
    <div v-if="!state.isDogAboveEighteen && !loading" class="no-dogs">
      Le chien sélectionné n'a pas plus de 18 mois
    </div>

    <div v-if="state.dogs.length === 0">Aucun chien</div>

    <div v-if="loading">
      <loader-list/>
    </div>
  </form>
</template>

<script setup>
import {
  computed,
  reactive,
  ref,
  watch,
  onUnmounted,
  onMounted,
} from "vue";
import IconHolding from "../../../icons/IconHolding.vue";
import SelectInputController from "@/components/form/SelectInputController/SelectInputController.vue";
import {useRoute, useRouter} from "vue-router";
import * as dogConst from "@/config/DogConfig";
import * as exploitConst from "@/config/HoldingConfig";
import DogsService from "@/services/dogs.service";
import {useStore} from '../../../../stores';
import dogsService from "@/services/dogs.service";
import FormLine from "@/components/form/FormLine/FormLine";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import DatePickerController from "@/components/form/DatePickerController/DatePickerController";
import Tooltip from "@/components/form/Tooltip";
import {createToaster} from "@meforma/vue-toaster";
import LoaderList from "@/components/loaders/LoaderList";
import {
  getFatherForm,
  getMotherForm,
  getForm,
} from "./handle-datas-dog/formatDatasForm";
import {
  getFatherBody,
  getMotherBody,
  getBody,
  formatBody,
} from "./handle-datas-dog/formatDatasBodies";
import {handleSubmitDog} from "./handle-datas-dog/dogFormSubmit";
import SynchronizerCensusHelper from "../../../../helpers/synchronizerCensusHelper";
import useVuelidate from "@vuelidate/core";
import {email, helpers, required, requiredIf, minLength} from "@vuelidate/validators";
import arrowBack from "@/assets/images/arrow-back.svg";

const toaster = createToaster({position: "bottom"});

const props = defineProps({
  dogs: Array,
  exploitation: Object,
  disabled: Boolean,
  censusId: Number,
  isOffline: Boolean,
  censusProgression: String,
  showModal: Boolean,
  censusUserId: Number,
  census: Object,
  refreshDatas: Function,
  currentPage: String,
});
let correctDog = computed(() => store.getCorrectDog);
const emit = defineEmits(["showModal"]);
const error = ref();
const controller = ref(new AbortController());
const route = useRoute();
const router = useRouter();
const store = useStore();
const loading = ref(false);
const lofRegex = /[a-zA-Z]{3}-\d{10}-?\d{4}-?\d{1,2}/;
const getCurrentOwner = () => {
  const dogOwner = props.census.dogs.find(
      (dog) => dog.ownerIsOwnerExploitation && dog.owner.id
  );
  return dogOwner?.owner;
};
const state = reactive({
  noFatherInfo: null,
  noMotherInfo: null,
  dogId: props.dogs && props.dogs.length ? props.dogs[0].id : null,
  isNumLofCorrect: true,
  error: null,
  isDogAboveEighteen: null,
  leave: false,
  dataToApplyOnOthers: null,
  specNbPersons: {},
  ownerExploitationAlreadyExist: getCurrentOwner(),
  specieOther: null,
  dogs: props.dogs
});
const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return props.censusUserId === currentUser.value.id;
});
const dogList = computed(() => {
  return state.dogs.sort((a, b) => a.name.localeCompare(b.name)).map((dog) => {
    return {label: `${dog.name}`, value: dog.id, test: props.census.id};
  });
});
const threeDigtsRegex = /[a-zA-Z]{3}\d{3}/;
const fifteenDigitsRegex = /\d{15}/;

watch(
    () => correctDog.value,
    () => {
      filterDogsAboveEighteenMonths();
      loading.value = false;
    }
);

onMounted(() => {
  store.correctDog = props.dogs[0];
  state.noFatherInfo = correctDog.value?.father ? false : true;
  state.noMotherInfo = correctDog.value?.mother ? false : true;
  // TODO: apply better fix
  if (correctDog.value) {
    changeDog(dogList.value.find(d => d.value == correctDog.value.id))
  } else {
    changeDog(dogList.value[0])
  }
  filterDogsAboveEighteenMonths();
});

const filterDogsAboveEighteenMonths = () => {
  const today = new Date();
  const birthDate = new Date(correctDog.value?.birthdate);
  const monthsRef = new Date(1000 * 60 * 60 * 24 * 30 * 18);
  state.isDogAboveEighteen =
      today.getTime() - birthDate.getTime() > monthsRef.getTime();
};

const fatherForm = reactive(getFatherForm(correctDog));
const motherForm = reactive(getMotherForm(correctDog));
let form = reactive(getForm(correctDog, props.exploitation));

const validatorWithNull = (value) => value === true || value === false || value === null

const motherRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !motherForm.phone && motherForm.ownerName;
      })),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phone: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !motherForm.email && motherForm.ownerName;
      })),
      minLength: helpers.withMessage("Le champ n'est pas au format téléphone !", minLength(10)),
    },
  };
});

const fatherRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !fatherForm.phone && fatherForm.ownerName;
      })),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phone: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !fatherForm.email && fatherForm.ownerName;
      })),
      minLength: helpers.withMessage("Le champ n'est pas au format téléphone !", minLength(10)),
    },
  };
});

const dogRules = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    gender: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    birthdate: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    identificationType: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    identificationNum: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    appearance: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    numLof: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.appearance === 'pure_lof';
      })),
    },
    breed: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return (form.appearance === 'standard' || form.appearance === 'cross' || form.appearance === 'pure_lof');
      })),
    },
    isIdentified: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    specie: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    // specNbPersons: {
    //   required: helpers.withMessage("Le champ est requis !", requiredIf(function() {
    //       return form.specie?.length > 0 && form.specie !== '';
    //     })),
    // },
    typeOfSystem: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    typeOfDriving: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return (form.typeOfSystem !== 'indoor');
      })),
    },
    humainPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherDogsPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    oustiderHumanPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    typeOfPredation: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherTypeOfPredation: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.typeOfPredation && form.typeOfPredation.includes('other');
      })),
    },
    isOtherSpecie: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherSpecie: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isOtherSpecie;
      })),
    },
    otherTypeOfSystem: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isOtherSpecie;
      })),
    },
    otherTypeOfDriving: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isOtherSpecie && form.otherTypeOfSystem !== 'indoor';
      })),
    },
    isBornHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    beforeTwoMonth: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isFirstForBreeder: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isHealthy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAdaptedMorpho: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAdaptedSize: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedDemarche: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedAplombs: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedBackLine: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedJaw: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedPigmentation: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    hasTesticleConcerned: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    adaptedMorphoCommentary: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.isAdaptedMorpho === 'false';
      })),
    },
    hasHealthyTrouble: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherProblem: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.hasHealthyTrouble;
      })),
    },
    hipDysplasia: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    elbowDysplasia: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    exploitationAge: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    introductionCondition: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    introductionConditionPuppies: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.introductionCondition === 'other_puppies';
      })),
    },
    introductionConditionAdults: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.introductionCondition === 'with_adults';
      })),
    },
    parcel: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    parcelHeifers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    parcelOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form?.parcelHeifers?.includes('others');
      })),
    },
    behaviorWithHuman: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    temper: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    respectingOthers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    startRespectingOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.respectingOthers === 'little' || form.respectingOthers === 'a_lot';
      })),
    },
    endRespectingOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return form.respectingOthers === 'little' || form.respectingOthers === 'a_lot';
      })),
    },
    tookTime: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isEasyDog: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    characteristics: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isManipulable: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownDogTemper: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isMunipulableCensusTaker: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownTrustWithOthers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownTrustOthersWithoutYou: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    positionInGroup: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    canWorkInGroup: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    canWorkAlone: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAgressifToOtherDogs: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    presentHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    watchfulAwakening: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasExcessiveBarking: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isGoodProtector: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    distanceHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    fenceRespect: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    runAfterVehicles: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAlwaysUsable: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    recovery: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    wantPuppy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isPotentialBreeder: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasGoodImpregnateHerdBfrTwoMounth: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasGoodFamiliarizationHumansBfrTwoMounths: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasGoodFamiliarizationHumansAfterTwoMounths: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    wasEasySetupPuppy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isClingyWithHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },


  };
});

const vFather$ = useVuelidate(fatherRules, fatherForm);
const vMother$ = useVuelidate(motherRules, motherForm);
const vDog$ = useVuelidate(dogRules, form);

watch(
    () => form.introductionCondition,
    (introductionCondition) => {
      if (introductionCondition === "alone") {
        form.introductionConditionPuppies = null;
        form.introductionConditionAdults = null;
      } else if (introductionCondition === "other_puppies") {
        form.introductionConditionAdults = null;
      } else if (introductionCondition === "with_adults") {
        form.introductionConditionPuppies = null;
      }
    }
);

function formatSpecie(spec) {
  if (spec === 'other') {
    return `Autre ${state.specieOther ? '(' + state.specieOther + ')' : ''}`
  } else if (spec) {
    return (spec[0].toUpperCase() + spec.slice(1).toLowerCase()).replace(
        "_",
        " "
    );
  }
}

async function changeDog(item) {
  loading.value = true;
  const updatedDog = !props.isOffline
      ? await dogsService.getById(item.value, controller)
      : (await dogsService.getById(item.value, controller, props.census.id)) ??
      props.census.dogs.find((d) => d.id == item.value);
  loading.value = false;
  store.setCorrectDog(updatedDog);
  // social["reason"] = updatedDog.owner?.companyname ? "social" : "particular";

  if (updatedDog?.nbPersons && isNaN(updatedDog?.nbPersons)) {
    state.specNbPersons = updatedDog.nbPersons ? JSON.parse(updatedDog.nbPersons) : {};
  }
  if (updatedDog?.specie?.length > 0) {
    state.specieOther = updatedDog?.specie?.split("##")[1];
    form.specie = updatedDog?.specie?.length > 0 ? updatedDog?.specie?.split("##")[0].split(",") : [];
  }
  if (updatedDog) {
    state.noFatherInfo = updatedDog?.father ? false : true;
    state.noMotherInfo = updatedDog?.mother ? false : true;
    state.dogId = updatedDog.id;
    form.name = updatedDog.name;
    form.behaviorWithHuman = updatedDog.behaviorWithHuman;
    form.birthName = updatedDog?.birthName;
    form.gender = updatedDog?.gender;
    form.birthdate = updatedDog?.birthdate;
    form.identificationNum = updatedDog?.identificationNum;
    form.identificationType = updatedDog?.identificationType;
    form.verificationPuce = updatedDog?.verificationPuce;
    form.characteristics = updatedDog?.characteristics;
    form.numLof = updatedDog?.numLof;
    form.confirmLof = updatedDog?.confirmLof;
    form.isLof = updatedDog?.isLof;
    form.breed = updatedDog?.breed;
    form.appearance = updatedDog?.appearance;
    form.isIdentified = updatedDog?.isIdentified;
    form.isBornHerd = updatedDog?.isBornHerd;
    form.beforeTwoMonth = updatedDog?.beforeTwoMonth;
    form.month = updatedDog?.month;
    form.isFirstForBreeder = updatedDog?.isFirstForBreeder;
    form.isHealthy = updatedDog?.isHealthy;
    form.isAdaptedMorpho = updatedDog?.isAdaptedMorpho;
    form.isAdaptedSize = updatedDog?.isAdaptedSize;
    form.isAdaptedDemarche = updatedDog?.isAdaptedDemarche;
    form.isAdaptedAplombs = updatedDog?.isAdaptedAplombs;
    form.isAdaptedBackLine = updatedDog?.isAdaptedBackLine;
    form.isAdaptedJaw = updatedDog?.isAdaptedJaw;
    form.isAdaptedPigmentation = updatedDog?.isAdaptedPigmentation;
    form.hasHealthyTrouble = updatedDog?.hasHealthyTrouble;
    form.hipDysplasia = updatedDog?.hipDysplasia;
    form.parcel = updatedDog?.parcel;
    form.parcelHeifers = updatedDog?.parcelHeifers;
    form.parcelOthers = updatedDog?.parcelOthers;
    form.elbowDysplasia = updatedDog?.elbowDysplasia;
    form.otherProblem = updatedDog?.otherProblem;
    form.exploitationAge = updatedDog?.exploitationAge;
    form.introductionCondition = updatedDog?.introductionCondition;
    form.introductionConditionPuppies =
        updatedDog?.introductionConditionPuppies;
    form.introductionConditionAdults = updatedDog?.introductionConditionAdults;
    form.temper = updatedDog?.temper;
    form.aboutDogTemper = updatedDog.aboutDogTemper;
    form.respectingOthers = updatedDog?.respectingOthers;
    // form.ageRespectingOthers = updatedDog?.ageRespectingOthers;
    form.startRespectingOthers = updatedDog?.startRespectingOthers;
    form.endRespectingOthers = updatedDog?.endRespectingOthers;
    form.hasTesticleConcerned = updatedDog?.hasTesticleConcerned;
    form.isGoodProtector = updatedDog?.isGoodProtector;
    form.tookTime = updatedDog?.tookTime;
    form.isEasyDog = updatedDog?.isEasyDog;
    form.isManipulable = updatedDog?.isManipulable;
    form.isAgressif = updatedDog?.isAgressif;
    form.isMunipulableCensusTaker = updatedDog?.isMunipulableCensusTaker;
    form.trustWithOthers = updatedDog?.trustWithOthers;
    form.trustOthersWithoutYou = updatedDog?.trustOthersWithoutYou;
    form.positionInGroup = updatedDog?.positionInGroup;
    form.canWorkInGroup = updatedDog?.canWorkInGroup;
    form.canWorkAlone = updatedDog?.canWorkAlone;
    form.presentHerd = updatedDog?.presentHerd;
    form.watchfulAwakening = updatedDog?.watchfulAwakening;
    form.hasExcessiveBarking = updatedDog?.hasExcessiveBarking;
    form.distanceHerd = updatedDog?.distanceHerd;
    form.fenceRespect = updatedDog?.fenceRespect;
    form.runAfterVehicles = updatedDog?.runAfterVehicles;
    form.isAlwaysUsable = updatedDog?.isAlwaysUsable;
    form.isPotentialBreeder = updatedDog?.isPotentialBreeder;
    form.hasGoodImpregnateHerdBfrTwoMounth =
        updatedDog?.hasGoodImpregnateHerdBfrTwoMounth;
    form.hasGoodFamiliarizationHumansBfrTwoMounths =
        updatedDog?.hasGoodFamiliarizationHumansBfrTwoMounths;
    form.hasGoodFamiliarizationHumansAfterTwoMounths =
        updatedDog?.hasGoodFamiliarizationHumansAfterTwoMounths;
    form.wasEasySetupPuppy = updatedDog?.wasEasySetupPuppy;
    form.isClingyWithHerd = updatedDog?.isClingyWithHerd;
    form.hasGoodGlobalAppreciation = updatedDog?.hasGoodGlobalAppreciation;
    form.unknownDogTemper = correctDog?.value.unknownDogTemper
        ? correctDog?.value.unknownDogTemper?.toString()
        : "";
    form.unknownIsManipulable = updatedDog?.unknownIsManipulable;
    form.unknownIsAgressif = updatedDog?.unknownIsAgressif;
    form.unknownIsMunipulableCensusTaker =
        updatedDog?.unknownIsMunipulableCensusTaker;
    form.unknownTrustWithOthers = correctDog?.value.unknownTrustWithOthers;
    form.unknownTrustOthersWithoutYou =
        correctDog?.value.unknownTrustOthersWithoutYou;
    form.adaptedMorphoCommentary = updatedDog?.adaptedMorphoCommentary;
    form.nbPersons = updatedDog?.nbPersons;
    form.humainPresence = updatedDog?.humainPresence ? updatedDog.humainPresence.split(",") : null;
    form.otherDogsPresence = updatedDog?.otherDogsPresence ? updatedDog.otherDogsPresence.split(",") : null;
    form.oustiderHumanPresence = updatedDog?.oustiderHumanPresence ? updatedDog?.oustiderHumanPresence.split(",") : null;
    form.otherTypeOfPredation = updatedDog?.otherTypeOfPredation;
    form.isOtherSpecie = updatedDog?.isOtherSpecie;
    form.otherSpecie = updatedDog?.otherSpecie ? updatedDog.otherSpecie.split(",") : null;
    form.otherTypeOfSystem = updatedDog?.otherTypeOfSystem;
    form.recovery = updatedDog?.recovery;
    form.wantPuppy = updatedDog?.wantPuppy;
    form.father = updatedDog?.father;
    form.mother = updatedDog?.mother;
    form.owner = updatedDog?.owner;
    form.master = updatedDog?.master;
    form.masterIsOwnerDog = updatedDog?.masterIsOwnerDog;
    form.notes = updatedDog?.notes;
    form.isAgressifToOtherDogs = updatedDog?.isAgressifToOtherDogs;
    form.typeOfPredation = updatedDog?.typeOfPredation;
    form.typeOfDriving = updatedDog?.typeOfDriving;
    form.typeOfSystem = updatedDog?.typeOfSystem;
    form.otherTypeOfDriving = updatedDog?.otherTypeOfDriving ? updatedDog.otherTypeOfDriving.split(",") : null;
    form.otherStartAtPeriodHousing = updatedDog?.otherStartAtPeriodHousing;
    form.otherEndAtPeriodHousing = updatedDog?.otherEndAtPeriodHousing;
    form.ownerIsOwnerExploitation = updatedDog?.ownerIsOwnerExploitation;
    motherForm.name = updatedDog?.mother?.name;
    motherForm.identificationNum = updatedDog?.mother?.identificationNum;
    motherForm.breed = updatedDog?.mother?.breed;
    motherForm.appearance = updatedDog?.mother?.appearance;
    motherForm.atWork = updatedDog ? updatedDog?.mother?.atWork : null;
    motherForm.isCertifiedSonship = updatedDog
        ? updatedDog?.mother?.isCertifiedSonship
        : null;
    motherForm.numLof = updatedDog?.mother?.numLof;
    motherForm.ownerName = updatedDog?.mother?.ownerName;
    motherForm.phoneNumber = updatedDog?.mother?.phoneNumber;
    motherForm.address = updatedDog?.mother?.address;
    motherForm.phone = updatedDog?.mother?.phone;
    motherForm.email = updatedDog?.mother?.email;
    fatherForm.name = updatedDog?.father?.name;
    fatherForm.identificationNum = updatedDog?.father?.identificationNum;
    fatherForm.breed = updatedDog?.father?.breed;
    fatherForm.appearance = updatedDog?.father?.appearance;
    fatherForm.atWork = updatedDog ? updatedDog?.father?.atWork : null;
    fatherForm.isCertifiedSonship = updatedDog
        ? updatedDog?.father?.isCertifiedSonship
        : null;
    fatherForm.numLof = updatedDog?.father?.numLof;
    fatherForm.ownerName = updatedDog?.father?.ownerName;
    fatherForm.phoneNumber = updatedDog?.father?.phoneNumber;
    fatherForm.address = updatedDog?.father?.address;
    fatherForm.phone = updatedDog?.father?.phone;
    fatherForm.email = updatedDog?.father?.email;
  }
}

const handleChangeSpecie = (value, name) => {
  const key = name.replace("nbPersons-", "");
  state.specNbPersons[key] = value;
};

const handleChangeSpecieOther = (value, name) => {
  state.specieOther = value
};

const handleChange = async (value, name) => {
  form[name] = value;
  if (name === "numLof") state.isNumLofCorrect = lofRegex.test(value);
  if (name === "identificationNum") {
    try {
      const dogs = await dogsService.getDogs(
          1,
          `&identificationNum=${value}`,
          controller
      );
      if (
          dogs["hydra:member"].length > 0 &&
          Boolean(dogs["hydra:member"][0].census)
      ) {
        return (state.error =
            "Erreur, le numéro d'identification est déjà attribué à un chien");
      }
      state.error = null;
    } catch (e) {
      console.log(e);
      state.error = "Une erreur est survenue";
    }
  }
  if (name === "specie" && state.specNbPersons !== null && state.specNbPersons !== {}) {
    const keysToDelete = Object.keys(state.specNbPersons).filter(
        (spec) => !value.includes(spec)
    );
    keysToDelete.forEach((key) => delete state.specNbPersons[key]);
  }
};

const fatherHandleChange = (value, name) => {
  fatherForm[name] = value;
  if (name === "numLof") state.isNumLofCorrect = lofRegex.test(value);
};

const motherHandleChange = (value, name) => {
  motherForm[name] = value;
  if (name === "numLof") state.isNumLofCorrect = lofRegex.test(value);
};

const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};

function handleApplyOthersDogs() {
  state.dataToApplyOnOthers = {
    nbPersons: form.nbPersons,
    specie: form.specie,
    typeOfSystem: form.typeOfSystem,
    typeOfDriving: form.typeOfDriving,
    humainPresence: form.humainPresence,
    otherDogsPresence: form.otherDogsPresence,
    oustiderHumanPresence: form.oustiderHumanPresence,
    typeOfPredation: form.typeOfPredation,
    otherTypeOfPredation: form.otherTypeOfPredation,
    isOtherSpecie: form.isOtherSpecie,
    otherTypeOfSystem: form.otherTypeOfSystem,
    otherSpecie: form.otherSpecie,
    otherTypeOfDriving: form?.otherTypeOfDriving,
    otherStartAtPeriodHousing: form.otherStartAtPeriodHousing,
    otherEndAtPeriodHousing: form?.otherEndAtPeriodHousing,
  };

  // toaster.info("Données appliquées. Prêt à être enregistrer.");
}

function isCensusReady() {
  function isParentCompleted(dog) {
    if (dog.ownerName) {
      if (dog.email || dog.phone) {
        return true
      } else return false
    } else return true
  }

  const otherDogs = state.dogs.filter(
      (d) => d.id !== correctDog.value.id
  );
  return otherDogs.every((d) => {
    if (
        d.name !== null &&
        d.gender !== null &&
        d.birthdate !== null &&
        d.identificationType !== null &&
        d.identificationNum !== null &&
        (
            (d.appearance === 'pure_lof' && d.numLof !== null && d.breed !== null) ||
            ((d.appearance === 'standard' || d.appearance === 'cross') && d.breed !== null) ||
            (d.appearance === 'undetermined')
        )
        &&
        d.isIdentified !== null &&
        d.specie !== null &&
        (
            d.typeOfSystem === 'indoor' ||
            (d.typeOfSystem !== 'indoor' && d.typeOfDriving !== null)
        ) &&
        d.humainPresence !== null &&
        d.otherDogsPresence !== null &&
        d.oustiderHumanPresence !== null &&
        (
            (d.typeOfPredation.includes('other') && d.otherTypeOfPredation !== null) ||
            d.typeOfPredation !== null
        ) &&
        (
            (d.isOtherSpecie !== null) ||
            (d.isOtherSpecie && d.otherSpecie !== null && d.otherTypeOfSystem !== 'indoor' && d.otherTypeOfSystem !== null && d.otherTypeOfDriving) ||
            (d.isOtherSpecie && d.otherSpecie !== null && d.otherTypeOfSystem === 'indoor')
        ) &&
        d.isBornHerd !== null &&
        d.beforeTwoMonth !== null &&
        d.isFirstForBreeder !== null &&
        d.isHealthy !== null &&
        (
            (d.isAdaptedMorpho !== null && d.isAdaptedMorpho !== "false") ||
            (d.isAdaptedMorpho == "false" &&
                d.isAdaptedSize !== null &&
                d.isAdaptedDemarche !== null &&
                d.isAdaptedAplombs !== null &&
                d.isAdaptedBackLine !== null &&
                d.isAdaptedJaw !== null &&
                d.isAdaptedPigmentation !== null &&
                d.hasTesticleConcerned !== null &&
                d.adaptedMorphoCommentary !== null
            )
        ) &&
        (
            (d.hasHealthyTrouble && d.otherProblem) ||
            (d.hasHealthyTrouble === false)
        ) &&
        d.hipDysplasia !== null &&
        d.elbowDysplasia !== null &&
        d.exploitationAge !== null &&
        (
            (d.introductionCondition === 'other_puppies' && d.introductionConditionPuppies !== null) ||
            (d.introductionCondition === 'with_adults' && d.introductionConditionAdults !== null) ||
            (d.introductionCondition === 'alone')
        ) &&
        d.parcel !== null &&
        d.parcelHeifers !== null &&
        (
            (d.parcelHeifers.includes('others') && d.parcelOthers !== null) ||
            !(d.parcelHeifers.includes('others'))
        ) &&
        d.behaviorWithHuman !== null &&
        d.temper !== null &&
        (
            (d.respectingOthers === "yes") ||
            ((d.respectingOthers === 'a_lot' || d.respectingOthers === 'little') && d.startRespectingOthers !== null && d.endRespectingOthers !== null)
        ) &&
        d.tookTime !== null &&
        d.isEasyDog !== null &&
        d.characteristics !== null &&
        d.isManipulable !== null &&
        d.unknownDogTemper !== null &&
        d.isMunipulableCensusTaker !== null &&
        d.unknownTrustWithOthers !== null &&
        d.unknownTrustOthersWithoutYou !== null &&
        d.positionInGroup !== null &&
        d.canWorkInGroup !== null &&
        d.canWorkAlone !== null &&
        d.isAgressifToOtherDogs !== null &&
        d.presentHerd !== null &&
        d.watchfulAwakening !== null &&
        d.hasExcessiveBarking !== null &&
        d.isGoodProtector !== null &&
        d.distanceHerd !== null &&
        d.fenceRespect !== null &&
        d.runAfterVehicles !== null &&
        d.isAlwaysUsable !== null &&
        d.recovery !== null &&
        d.wantPuppy !== null &&
        d.isPotentialBreeder !== null &&
        d.hasGoodImpregnateHerdBfrTwoMounth !== null &&
        d.hasGoodFamiliarizationHumansBfrTwoMounths !== null &&
        d.hasGoodFamiliarizationHumansAfterTwoMounths !== null &&
        d.wasEasySetupPuppy !== null &&
        d.isClingyWithHerd !== null
    ) {
      if (d.father && d.mother) {
        return (isParentCompleted(d.father) && isParentCompleted(d.mother))
      } else if (d.father) {
        return isParentCompleted(d.father)
      } else if (d.mother) {
        return isParentCompleted(d.mother)
      } else {
        return true
      }
    } else {
      return false
    }
  })
}

const showValidate = async () => {
  submitForm(function validate() {
    vDog$.value.$validate()
    vFather$.value.$validate()
    vMother$.value.$validate()
    isCensusReady()
    if (
        vDog$.value.$error ||
        (!state.noMotherInfo && vMother$.value.$error) ||
        (!state.noFatherInfo && vFather$.value.$error) ||
        !isCensusReady()
    ) {
      toaster.error(`il manque des informations pour valider le recensement`);
    } else {
      emit('showModal')
    }
  })
}
const submitForm = async (_callBack) => {
  if (!isSameUser.value && !isUserAdmin.value) return
  const fatherBody = getFatherBody(fatherForm);
  const motherBody = getMotherBody(motherForm);

  // const ownerBody = getOwnerBody(ownerForm, props.exploitation.id);
  // const masterBody = getMasterBody(masterForm, props.exploitation.id);
  const body = getBody(
      form,
      fatherForm,
      fatherBody,
      motherForm,
      motherBody,
      state
  );
  handleApplyOthersDogs()
  if (!props.isOffline) {
    try {
      if (state.dataToApplyOnOthers) {
        const otherDogs = state.dogs.filter(
            (d) => d.id !== correctDog.value.id
        );
        console.log(otherDogs)
        const promises = await Promise.all(
            otherDogs.map((otherDog) => {
              console.log('otherdog', otherDog)
              const newDatas = formatBody(
                  {
                    ...otherDog,
                    ...state.dataToApplyOnOthers,
                  },
                  state.specNbPersons
              );
              console.log('new datas', newDatas)
              DogsService.update(otherDog.id, newDatas);
            })
        );
      }
      const updatedDog = await handleSubmitDog(
          correctDog,
          body,
          props.censusId,
      );
      console.log(state.dogs.findIndex(dog => dog.id === updatedDog.id))
      if (!_callBack) {
        state.leave !== true &&
        toaster.success("Formulaire correctement enregistré");
        router.go(0);
      } else {
        if (updatedDog) {
          const dogToReplaceIndex = state.dogs.findIndex(dog => dog.id === updatedDog.id)
          if (dogToReplaceIndex !== -1) {
            state.dogs[dogToReplaceIndex] = updatedDog
          }
        }
        _callBack()
      }
    } catch (e) {
      console.log(e);
      state.leave !== true && toaster.error("Le formulaire n'est pas valide");
    }
  } else {
    const syncService = new SynchronizerCensusHelper(props.censusId);
    syncService.updateDog(correctDog.value.id, {
      correctDog: JSON.parse(JSON.stringify(correctDog.value)),
      // form: JSON.parse(JSON.stringify(form)),
      // ownerForm: ownerForm,
      // ownerBody: ownerBody,
      // masterForm: {},
      // masterBody: {},
      body: JSON.parse(JSON.stringify(body)),
    });
    syncService.persistInCache();
    props.refreshDatas();
    toaster.info("Données mises en cache, prêtes à être synchronisées");
  }
};

onUnmounted(() => {
  state.leave = true;
  // submitForm(() => null);
});
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/_variables.scss";

.buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

h1 {
  font-size: 16px;
}

.container-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 1em;

  .title {
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .border {
    margin-left: 10px;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid $censusDog;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .lof {
    display: flex;
    align-items: center;
  }

  .title-line {
    margin-top: 1em;
    margin-bottom: 1em;
    color: $censusDog;
  }
}

.lof {
  display: flex;
  align-items: center;
}

.lof-switch {
  margin-left: 1em !important;
  margin-top: 0 !important;
  margin-bottom: 7% !important;
  width: 50% !important;
}

.light-blue {
  color: $censusDog;
}

.medium-blue {
  color: $censusMaster;
}

.orange {
  color: $censusHolding;
}

.holding {
  &-head {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1em 2em;
    color: white;
    background-color: $censusDog;

    &.has-tooltip {
      position: relative;
    }

    p {
      margin: 0 !important;
      font-size: 15px;
    }
  }

  &-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 1em 1em;

    &-cat {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-top: 1em;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-icon-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }

      &-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }
    }
  }
}

.sub-title {
  font-size: 13px;
}

.sub-title-tooltip {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
  margin-bottom: 1em;
}

.carac {
  margin-top: 2em;

  &-selects {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    border-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 15px 2em;
  }
}

.date-picker {
  position: relative;
}

textarea {
  background: white;
  border: 1px solid $censusDog;
  border-radius: 30px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  margin-top: 1em;
  height: 250px;
  width: 92%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

.blured {
  filter: blur(5px);
  pointer-events: none;
}

form {
  transition: filter 200ms;
}

.has-switch {
  &-switch {
    width: 50% !important;
    margin-left: 1em;
  }

  display: flex;
  align-items: center;
}

.other-specie {
  margin-top: 1em !important;
}

.period-title {
  margin-top: 0.5em;
}

.error {
  color: $danger;
}

p {
  font-size: 14px;
}

.identity-body {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 2em 1em;
  margin-bottom: 1em;
}

.form-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #3f3f3f;
}

.house-icon {
  padding-right: 1em;
}

.little-title {
  margin: 0;
}

.notes {
  textarea {
    text-transform: uppercase;
  }
}

.notes-title {
  position: relative;
  display: flex;
  align-items: center;
}

.no-dogs {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 2em;
}

.label-is-lof label {
  width: 12%;
}
</style>
