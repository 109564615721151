<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '../../../stores';
import Step0CensusType from '../../../components/census/Step0CensusType.vue';
import Step1GetOrganization from '../../../components/census/Step1GetOrganization.vue';
import Step2SetOrganization from '../../../components/census/Step2SetOrganization.vue';


const store = useStore();
const step = computed(() => store.getCensusStep);
const route = useRoute();
const loading = ref(true);
const controller = ref(new AbortController());

onMounted(async () => {
  loading.value = false;
});

onUnmounted(() => {
  controller.value.abort();
});
</script>
<template>
  <div class="content">
    <Step0CensusType v-if="step === 0" />
    <Step1GetOrganization v-if="step === 1" />
    <Step2SetOrganization v-if="step === 2" />
  </div>
</template>
<style lang="scss" scoped>
.content {
  position: relative;
  height: 100%;
}
</style>
