<script setup>
import { reactive, onMounted, ref } from "vue";
import cacheHelper from "../../helpers/cacheHelper";
import holdingService from "../../services/holding.service";
import peopleService from "@/services/people.service";
import recensementService from "../../services/recensement.service";
import DogsService from "@/services/dogs.service";
import { useRouter } from "vue-router";
import iconClose from "../../assets/images/icon-close.svg";
import { handleSubmitDog } from "../census/DetailCensus/Dog/handle-datas-dog/dogFormSubmit";
import { handleSubmitOwnerMaster } from "../census/DetailCensus/Dog/handle-datas-dog/dogFormSubmit";

defineEmits(["onClose"]);

const router = useRouter();
const controller = ref(new AbortController());
const state = reactive({
  data: JSON.parse(localStorage.getItem("censuses_to_sync")),
  loading: true,
});

onMounted(async () => {
  await Promise.all(
    state.data.map(async (item, i) => {
      let urlOwner = null;
      const actionsOrdered = item.actions.sort((a, b) =>
        a.type.localeCompare(b.type)
      );
      let y = 0;
      for await (let action of actionsOrdered) {
        if (action.status != "error") {
          try {
            if (action.type == "update_owner_holding") {
              await peopleService.update(action.id, action.body, controller);
            } else if (action.type == "add_owner_holding") {
              const people = await peopleService.create(
                action.body,
                controller
              );
              urlOwner = `/api/people/${people.id}`;
            } else if (action.type == "update_holding") {
              let body ={ ...action.body }
              if(urlOwner){
                body = {...body, owner: urlOwner}
              } else {
                body ={ ...action.body, owner: `/api/people/${body.owner.id}` }
              }

              await holdingService.updateHolding(
                action.id,
                  body,
                controller
              );
            } else if (action.type == "update_master") {
              try {
                const { body, form, id } = action;
                const censusId = item.census.id;
                const censusAction = state.data.find(
                  (d) => d.census.id === censusId
                );
                let dogWithNewMaster = censusAction?.actions?.find(
                  (dogAction) =>
                    dogAction.type === "update_dog" &&
                    dogAction.body.correctDog.masterIsOwnerDog &&
                    !dogAction.body.form.masterIsOwnerDog &&
                    dogAction.body.form?.master?.id ===
                      dogAction.body.form?.owner?.id
                );
                const buildPeopleApiId = (id) => {
                  if (id) return `/api/people/${id}`;
                };

                if (dogWithNewMaster) {
                  const createdMasterData = await peopleService.create(body);
                  await DogsService.updateDogEntities(
                    dogWithNewMaster.body.correctDog.id,
                    {
                      exploitation: item.census.exploitation["@id"],
                      master: buildPeopleApiId(createdMasterData.id),
                    }
                  );
                } else {
                  if (!form?.ownerIsOwnerExploitation) {
                    await peopleService.update(id, body, controller);
                    await recensementService.upgradeIfComplete(
                      censusId,
                      controller
                    );
                  } else {
                    const updatedOwner = await peopleService.update(
                      id,
                      body,
                      controller
                    );
                    await holdingService.updateHolding(
                      item.census.exploitation.id,
                      { owner: buildPeopleApiId(updatedOwner.id) },
                      controller
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
            } else if (action.type == "update_dog") {
              const exploitation = item.census.exploitation;
              const censusId = item.census.id;
              try {
                const {
                  correctDog,
                  // form,
                  // ownerForm,
                  // ownerBody,
                  // masterForm,
                  // masterBody,
                  body,
                } = {...action.body};
                // const dogOwner = item.census.dogs.find(
                //   (dog) => dog.ownerIsOwnerExploitation && dog.owner.id
                // );
                await handleSubmitDog(
                  { value: correctDog },
                  // form,
                  // ownerForm,
                  // ownerBody,
                  // masterForm,
                  // masterBody,
                  body,
                  // exploitation,
                  censusId,
                  // dogOwner.owner
                );
              } catch (error) {
                console.log(error);
              }
            } else if (action.type == "update_dog_master_owner") {
              try {
                const {
                  correctDog,
                  masterBody,
                  ownerBody,
                  exploitation,
                  ownerId,
                  masterId  ,
                  masterIsOwnerExploitation,
                  ownerIsOwnerExploitation,
                  masterIsOwnerDog,
                  censusId,
                } = action;

                let updateReturn = await handleSubmitOwnerMaster(
                  {value: correctDog},
                  masterBody,
                  ownerBody,
                  exploitation,
                  ownerId,
                  masterId,
                  masterIsOwnerExploitation,
                  ownerIsOwnerExploitation,
                  masterIsOwnerDog
                );
                if (updateReturn === 'error') {
                  state.leave !== true && toaster.error("Le formulaire n'est pas valide");
                } else {
                  state.leave !== true &&
                  toaster.success("Formulaire correctement enregistré");
                  router.go(0);
                }
              } catch (error) {
                console.log(error);
              }
            }
            state.data[i].actions[y].status = "completed";
          } catch (e) {
            state.data[i].actions[y].status = "error";
          }
        }
        y++;
      }
    })
  );

  const clone = [...state.data];
  const localStorageToUpdated = JSON.parse(
    localStorage.getItem("censuses_to_sync")
  );

  let i = clone.length;
  while (i--) {
    const item = clone[i];
    let y = item.actions.length;

    while (y--) {
      const action = clone[i].actions[y];

      if (action.status == "completed") {
        clone[i].actions.splice(y, 1);
      }
    }

    if (item.actions.length == 0 && item.census) {
      clone.splice(i, 1);
      const census = cacheHelper.getRecensementById(item.census.id);
      if (census) {
        census.status = "running";
        cacheHelper.updateRecensement(census.id, census);
      }
    }
  }

  localStorage.setItem("censuses_to_sync", JSON.stringify(clone));

  state.loading = false;
});

function getNameAction(action) {
  if (action.type == "update_owner_holding") {
    return "Mise à jour du propriétaire d'exploitation";
  } else if (action.type == "add_owner_holding") {
    return "Ajout d'un propriétaire d'exploitation";
  } else if (action.type == "update_holding") {
    return "Mise à jour de l'exploitation";
  } else if (action.type == "update_dog") {
    return "Mise à jour d'un chien";
  } else if (action.type == "update_master") {
    return "Mise à jour d'un Maître";
  } else if (action.type == "update_dog_master_owner") {
    return "Mise à jour d'un Propriétaire/Ma^tre";
  } else {
    return "Action inconnue";
  }
}

function redirectToCensus(action) {
  router.push({ path: `/census/${action.censusId}` });
  this.$emit("onClose");
}

function cancelAction(i, j) {
  const clone = [...state.data];
  clone[i].actions.splice(j, 1);
  localStorage.setItem("censuses_to_sync", JSON.stringify(clone));
  state.data = clone;
}
</script>

<template>
  <div class="background-modal"></div>
  <div class="modal">
    <h2>Synchronisation des données</h2>

    <div class="list">
      <p v-if="!state.data || state.data.length == 0">Aucune données</p>
      <div class="item" v-for="(item, i) of state.data" :key="i">
        <p>Recensement {{ item.census.exploitation.name }}</p>
        <div class="actions">
          <div class="action" v-for="(action, j) of item.actions" :key="j">
            <p>{{ getNameAction(action) }}</p>
            <div v-if="action.status == 'running'" class="status running">
              En cours de traitement...
            </div>
            <div v-if="action.status == 'completed'" class="status completed">
              Synchronisé
            </div>
            <div v-if="action.status == 'error'" class="status error">
              Erreur de remplissage
            </div>

            <Button
              style="width: 120px"
              type="primary"
              @on-click="redirectToCensus(action)"
              v-if="action.status == 'error'"
            >
              Corriger
            </Button>

            <IconButton
              :icon="iconClose"
              class="transparent"
              @on-click="cancelAction(i, j)"
            />
          </div>
        </div>
      </div>
      <Button
        v-if="!state.loading"
        style="margin-top: 20px"
        @on-click="$emit('onClose')"
        >Fermer</Button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.background-modal {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000000;
  height: 100vh;
  width: 100%;
  opacity: 0.2;
  z-index: 1002;
}

.modal {
  z-index: 1004;
  position: absolute;
  width: calc(100% - 200px);
  left: 100px;
  top: 80px;
  display: flex;
  margin: auto;
  background-color: white;
  min-height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .list {
    .item {
      .actions {
        padding-left: 20px;

        .action {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
        }

        .status {
          padding: 5px 10px;
          border-radius: 5px;
          color: white;
        }

        .running {
          background-color: orange;
        }

        .completed {
          background-color: green;
        }

        .error {
          background-color: red;
        }
      }
    }
  }
}
</style>
