<script setup>
import {defineProps, ref, watch} from "vue";
import TextInputController from "../../../../components/form/TextInputController/TextInputController.vue";
import * as dogConst from "../../../../config/DogConfig";
import * as exploitConst from "../../../../config/HoldingConfig";
import DatePickerController from "../../../../components/form/DatePickerController/DatePickerController.vue";
import DogEnvironmentModal from "../../../../views/components/modal/DogEnvironmentModal.vue";
import dogsService from "../../../../services/dogs.service";
import useForm from "../../../../compositions/useForm";

const props = defineProps({
  id: Number,
  census: Object,
  dog: Object,
  dogFather: Object,
  dogMother: Object,
  currentOwner: Object,
  dogValidator: Object,
  dogFatherValidator: Object,
  dogMotherValidator: Object,
  technicalFollowUp: Boolean,
  isIdentityEditable: Boolean,
  readOnly: Boolean,
  disabled: Boolean,
  specNbPersons: Object,
  specieOther: Number,
  noFatherInfo: Boolean,
  noMotherInfo: Boolean,
  isDogAboveEighteen: Boolean,
})

const  { isRequired } = useForm()

const isNumLofCorrect = ref(true);
const controller = ref(new AbortController());
const lofRegex = /[a-zA-Z]{3}-\d{10}-?\d{4}-?\d{1,2}/;
const error = ref(null);

const emit = defineEmits([
  'update:dog',
  'update:dogFather',
  'update:dogMother',
  'update:specNbPersons',
  'update:specieOther',
  'update:noFatherInfo',
  'update:noMotherInfo',
  'update:technicalFollowUp',
  'submit',
])

const handleDogChange = async (value, name) => {
  emit('update:dog', {
    ...props.dog,
    [name]: value
  });
  if (name === "numLof") isNumLofCorrect.value = lofRegex.test(value);
  if (name === "identificationNum") {
    try {
      const dogs = await dogsService.getDogs(
          1,
          `&identificationNum=${value}`,
          controller
      );
      if (
          dogs["hydra:member"].length > 0 &&
          Boolean(dogs["hydra:member"][0].census)
      ) {
        return (error.value =
            "Erreur, le numéro d'identification est déjà attribué à un chien");
      }
      error.value = null;
    } catch (e) {
      error.value = "Une erreur est survenue";
    }
  }
  if (name === "specie" && props.specNbPersons !== null && props.specNbPersons !== {}) {
    const keysToDelete = Object.keys(props.specNbPersons).filter(
        (spec) => !value.includes(spec)
    );
    keysToDelete.forEach((key) => {
      const temp = {...props.specNbPersons};
      delete temp.value[key]
      emit('update:specNbPersons', temp)
    });
  }
}
const handleDogFatherChange = (value, name) => {
  emit('update:dogFather', {
    ...props.dogFather,
    [name]: value
  })

  if (name === "numLof") isNumLofCorrect.value = lofRegex.test(value);
}
const handleDogMotherChange = (value, name) => {
  emit('update:dogMother', {
    ...props.dogMother,
    [name]: value
  })

  if (name === "numLof") isNumLofCorrect.value = lofRegex.test(value);
}
const handleDogChangeSpecie = (value, name) => {
  const key = name.replace("nbPersons-", "");
  emit('update:specNbPersons', {...props.specNbPersons, [key]: value})
};
const handleDogChangeSpecieOther = (value) => {
  emit('update:specieOther', value)
};
const formatSpecie = (spec) => {
  if (spec === 'other') {
    return `Autre ${props.specieOther ? '(' + props.specieOther + ')' : ''}`
  } else if (spec) {
    return (spec[0].toUpperCase() + spec.slice(1).toLowerCase()).replace(
        "_",
        " "
    );
  }
}
const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
}

watch(() => props.dog.introductionCondition, (introductionCondition) => {
  if (introductionCondition === "alone") {
    handleDogChange(null, 'introductionConditionPuppies');
    handleDogChange(null, 'introductionConditionAdults');
  } else if (introductionCondition === "other_puppies") {
    handleDogChange(null, 'introductionConditionAdults');
  } else if (introductionCondition === "with_adults") {
    handleDogChange(null, 'introductionConditionPuppies');
  }
})
</script>

<template>
  <form
      @submit.prevent="emit('submit')"
  >
    <div class="holding">
      <div class="holding-head">
        <p>IDENTITÉ</p>
      </div>

      <div class="identity-body">
        <TextInputController
            :read-only="!isIdentityEditable && readOnly"
            custom-class="dog-input"
            :on-change="handleDogChange"
            :name="'name'"
            :model="dog?.name"
            :class="{'is-invalid': dogValidator.name.$invalid}"
            :errors="dogValidator.name.$errors"
            :on-blur="dogValidator.name.$touch"
            :required="isRequired(dogValidator.name)"
            label="Nom"
            placeholder=""
            :disabled="disabled"
        />

        <SelectInputController
            :key="JSON.stringify(dog?.specie)"
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'gender'"
            :model="dog?.gender"
            :class="{'is-invalid': dogValidator.gender.$invalid}"
            :errors="dogValidator.gender.$errors"
            :on-blur="dogValidator.gender.$touch"
            :required="isRequired(dogValidator.gender)"
            label="Sexe"
            placeholder=""
            :options="dogConst.sexeConfig"
            :disabled="disabled"
        />

        <DatePickerController
            v-if="dog.birthdate"
            custom-class="dog-input"
            :on-change="handleDogChange"
            :name="'birthdate'"
            :class="{'is-invalid': dogValidator.birthdate.$invalid}"
            :errors="dogValidator.birthdate.$errors"
            :on-blur="dogValidator.birthdate.$touch"
            :required="isRequired(dogValidator.birthdate)"
            :model="new Date(dog?.birthdate)"
            label="Date de naissance"
            :timepicker="false"
            :disabled="disabled"
            :read-only="!isIdentityEditable && readOnly"
        />

        <div class="border"/>

        <SelectInputController
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'identificationType'"
            :class="{'is-invalid': dogValidator.identificationType.$invalid}"
            :errors="dogValidator.identificationType.$errors"
            :on-blur="dogValidator.identificationType.$touch"
            :required="isRequired(dogValidator.identificationType)"
            :model="dog.identificationType"
            label="Type d'identification"
            placeholder=""
            :options="dogConst.identificationType"
            :disabled="disabled"
            first-tooltip="Si le chien n’est pas identifié, il faut informer l’éleveur de son obligation d’identification.
            Si plusieurs chiens ne sont pas identifiés alors ne pas procéder au recensement."
        />

        <TextInputController
            :read-only="!isIdentityEditable && readOnly"
            custom-class="dog-input"
            :on-change="handleDogChange"
            :name="'identificationNum'"
            :class="{'is-invalid': dogValidator.identificationNum.$invalid}"
            :errors="dogValidator.identificationNum.$errors"
            :on-blur="dogValidator.identificationNum.$touch"
            :required="isRequired(dogValidator.identificationNum)"
            :model="dog.identificationNum"
            label="N° d'identification"
            placeholder=""
            :disabled="disabled"
        />

        <div v-if="error" class="error">
          {{ error }}
        </div>

        <div class="form-line"/>
        <SelectInputController
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'appearance'"
            :model="dog.appearance"
            :class="{'is-invalid': dogValidator.appearance.$invalid}"
            :errors="dogValidator.appearance.$errors"
            :on-blur="dogValidator.appearance.$touch"
            :required="isRequired(dogValidator.appearance)"
            label="Type racial"
            :options="dogConst.appearanceConfig"
            :disabled="disabled"
        />

        <div
            v-if="dog.appearance === 'pure_lof'"
            class="has-switch label-is-lof"
        >
          <TextInputController
              :read-only="!isIdentityEditable && readOnly"
              class="has-switch-input"
              custom-class="dog-input"
              :on-change="handleDogChange"
              :name="'numLof'"
              :class="{'is-invalid': dogValidator.numLof.$invalid}"
              :errors="dogValidator.numLof.$errors"
              :on-blur="dogValidator.numLof.$touch"
              :required="isRequired(dogValidator.numLof)"
              :model="dog.numLof"
              label="N° lof"
              placeholder=""
              :disabled="disabled"
              tooltip="S’il est né de parents LOF mais qu’il n’a
              pas été confirmé, le préciser"
          />
          <RadioYesNo
              label="Confirmé"
              :is-italic="true"
              :default-value="dog.isLof"
              :disabled="disabled"
              :read-only="!isIdentityEditable && readOnly"
              @on-change="handleDogChange"
              id="isLof"
          />
        </div>

        <TextInputController
            v-if="dog.appearance === 'pure_lof'"
            :read-only="!isIdentityEditable && readOnly"
            custom-class="dog-input"
            :on-change="handleDogChange"
            :name="'birthName'"
            :model="dog.birthName"
            label="Nom de naissance"
            placeholder=""
            :disabled="disabled"
        />

        <SelectInputController
            v-if="
              dog.appearance === 'cross'
            "
            :key="JSON.stringify(dog?.breed)"
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'breed'"
            :class="{'is-invalid': dogValidator.breed.$invalid}"
            :errors="dogValidator.breed.$errors"
            :on-blur="dogValidator.breed.$touch"
            :required="isRequired(dogValidator.breed)"
            :model="dog?.breed"
            :label="
              'Apparence(s) raciale(s) (4 max)*'
            "
            :is-multiple="true"
            :options="dogConst.raceConfig"
            :disabled="disabled"
            :config="dogConst.raceConfig"
            :maxMultiple="4"
        />

        <SelectInputController
            v-if="
              dog.appearance === 'standard' ||
              dog.appearance === 'pure_lof'
            "
            :key="JSON.stringify(dog?.breed)"
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'breed'"
            :class="{'is-invalid': dogValidator.breed.$invalid}"
            :errors="dogValidator.breed.$errors"
            :on-blur="dogValidator.breed.$touch"
            :required="isRequired(dogValidator.breed)"
            :model="dog.breed"
            :label="
              dog.appearance === 'pure_lof' ? 'Race' : 'Apparence raciale'
            "
            :is-multiple="false"
            :options="dogConst.raceConfig"
            :disabled="disabled"
            :config="dogConst.raceConfig"
        />

        <SelectInputController
            :read-only="!isIdentityEditable && readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            :name="'isIdentified'"
            :class="{'is-invalid': dogValidator.isIdentified.$invalid}"
            :errors="dogValidator.isIdentified.$errors"
            :on-blur="dogValidator.isIdentified.$touch"
            :required="isRequired(dogValidator.isIdentified)"
            label="Identification / Empreinte génétique déjà réalisée"
            :model="dog.isIdentified"
            :options="dogConst.atWorkConfig"
            :disabled="disabled"
        />
      </div>
    </div>

    <div v-if="isDogAboveEighteen" class="holding">
      <div class="holding-head">
        <p>INFORMATIONS SUR LE PERE</p>
      </div>
      <div class="holding-body">
        <RadioYesNo
            label="Informations sur le père"
            :is-italic="true"
            :default-value="!noFatherInfo"
            :disabled="disabled"
            :read-only="readOnly"
            @on-change="(newValue) => $emit('update:noFatherInfo', !newValue)"
            id="noFatherInfo"
        />

        <div v-if="!noFatherInfo" class="holding-body-cat">
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'name'"
                  :model="dogFather?.name"
                  label="Nom"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'identificationNum'"
                  :model="dogFather?.identificationNum"
                  label="N° d'identification"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>

          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogFatherChange"
                  name="appearance"
                  label="Type racial"
                  :model="dogFather?.appearance"
                  :options="dogConst.appearanceConfig"
                  :disabled="disabled"
              />
            </div>
          </div>

          <div
              v-if="dogFather?.appearance === 'pure_lof'"
              class="holding-body-row"
          >
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'numLof'"
                  :model="dogFather?.numLof"
                  label="N° lof (facultatif)"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>

          <div
              class="holding-body-row"
              v-if="dogFather.appearance === 'cross'"
          >
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'breed'"
                  :model="dogFather?.breed"
                  :label="
                    'Apparence(s) raciale(s) (4 max)'
                  "
                  :is-multiple="true"
                  :options="dogConst.raceConfig"
                  :disabled="disabled"
                  :config="dogConst.raceConfig"
                  :maxMultiple="4"
              />
            </div>
          </div>
          <div
              class="holding-body-row"
              v-if="dogFather.appearance === 'standard' ||
              dogFather?.appearance === 'pure_lof'"
          >
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'breed'"
                  :model="dogFather?.breed"
                  :label="dogFather?.appearance === 'pure_lof' ? 'Race' : 'Apparence raciale'"
                  :is-multiple="false"
                  :options="dogConst.raceConfig"
                  :disabled="disabled"
                  :config="dogConst.raceConfig"
              />
            </div>
          </div>

          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogFatherChange"
                  name="isCertifiedSonship"
                  label="Filiation certifiée (génétiquement)"
                  :model="dogFather?.isCertifiedSonship"
                  :options="dogConst.trueOrFalseConfig"
                  :disabled="disabled"
                  first-tooltip="Filiation certifiée génétiquement (par
                  un test : certification de parenté)"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogFatherChange"
                  name="atWork"
                  label="Au travail"
                  :model="dogFather?.atWork"
                  :options="dogConst.atWorkConfig"
                  :disabled="disabled"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'ownerName'"
                  :model="dogFather?.ownerName"
                  label="Nom du propriétaire"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'phone'"
                  :type="'phone'"
                  :model="dogFather?.phone"
                  :class="{'is-invalid': dogFatherValidator.phone.$invalid}"
                  :errors="dogFatherValidator.phone.$errors"
                  :on-blur="dogFatherValidator.phone.$touch"
                  :required="dogFather?.ownerName && !dogFather?.email"
                  label="Num. de téléphone"
                  placeholder=""
                  :maxlength="10"
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'email'"
                  :model="dogFather?.email"
                  label="Email"
                  :class="{'is-invalid': dogFatherValidator.email.$invalid}"
                  :errors="dogFatherValidator.email.$errors"
                  :on-blur="dogFatherValidator.email.$touch"
                  :required="dogFather?.ownerName && !dogFather?.phone"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>

          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogFatherChange"
                  :name="'address'"
                  :model="dogFather?.address"
                  label="Adresse"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDogAboveEighteen" class="holding">
      <div class="holding-head">
        <p>INFORMATIONS SUR LA MERE</p>
      </div>
      <div class="holding-body">
        <RadioYesNo
            label="Informations sur la mère"
            :is-italic="true"
            :default-value="!noMotherInfo"
            :disabled="disabled"
            :read-only="readOnly"
            @on-change="(newValue) => $emit('update:noMotherInfo', !newValue)"
            id="noMotherInfo"
        />
        <div v-if="!noMotherInfo" class="holding-body-cat">
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'name'"
                  :model="dogMother.name"
                  label="Nom"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'identificationNum'"
                  :model="dogMother.identificationNum"
                  label="N° d'identification"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogMotherChange"
                  name="appearance"
                  label="Type racial"
                  :model="dogMother.appearance"
                  :options="dogConst.appearanceConfig"
                  :disabled="disabled"
              />
            </div>
          </div>

          <div
              v-if="dogMother.appearance === 'pure_lof'"
              class="holding-body-row"
          >
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'numLof'"
                  :model="dogMother.numLof"
                  label="N° lof (facultatif)"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>

          <div
              class="holding-body-row"
              v-if="dogMother.appearance === 'cross'"
          >
            <div class="holding-body-row-input">
              <SelectInputController

                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'breed'"
                  :model="dogMother?.breed"
                  :label="
                    'Apparence(s) raciale(s) (4 max)'
                  "
                  :is-multiple="true"
                  :options="dogConst.raceConfig"
                  :disabled="disabled"
                  :config="dogConst.raceConfig"
                  :maxMultiple="4"
              />
            </div>
          </div>
          <div
              class="holding-body-row"
              v-if="dogMother.appearance === 'standard' ||
              dogMother.appearance === 'pure_lof'"
          >
            <div class="holding-body-row-input">
              <SelectInputController
                  v-if="dogMother.appearance !== 'cross'"
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'breed'"
                  :model="dogMother.breed"
                  :label="
                  dogMother.appearance === 'pure_lof' ? 'Race' :
                    'Apparence raciale'
                  "
                  :is-multiple="false"
                  :options="dogConst.raceConfig"
                  :disabled="disabled"
                  :config="dogConst.raceConfig"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogMotherChange"
                  name="atWork"
                  label="Au travail"
                  :model="dogMother.atWork"
                  :options="dogConst.atWorkConfig"
                  :disabled="disabled"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogMotherChange"
                  name="isCertifiedSonship"
                  label="Filiation certifiée (génétiquement)"
                  first-tooltip="filiation certifiée génétiquement (par
                  un test : certification de parenté)"
                  :model="dogMother.isCertifiedSonship"
                  :options="dogConst.trueOrFalseConfig"
                  :disabled="disabled"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'ownerName'"
                  :model="dogMother.ownerName"
                  label="Nom du propriétaire"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'phone'"
                  :type="'phone'"
                  :model="dogMother?.phone"
                  :class="{'is-invalid': dogMotherValidator.phone.$invalid}"
                  :errors="dogMotherValidator.phone.$errors"
                  :on-blur="dogMotherValidator.phone.$touch"
                  :required="dogMotherValidator?.ownerName && !dogFather?.email"
                  label="Num. de téléphone"
                  placeholder=""
                  :maxlength="10"
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'email'"
                  :model="dogMother.email"
                  label="Email"
                  :class="{'is-invalid': dogMotherValidator.email.$invalid}"
                  :errors="dogMotherValidator.email.$errors"
                  :on-blur="dogMotherValidator.email.$touch"
                  :required="dogMotherValidator?.ownerName && !dogFather?.phone"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-input">
              <TextInputController
                  custom-class="dog-input"
                  :on-change="handleDogMotherChange"
                  :name="'address'"
                  :model="dogMother.address"
                  label="Adresse"
                  placeholder=""
                  :disabled="disabled"
                  :read-only="readOnly"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDogAboveEighteen" class="holding">
      <div class="holding-head tw-flex tw-justify-between tw-space-x-5">
        <p>ENVIRONNEMENT DU CHIEN DANS l'EXPLOITATION</p>

        <DogEnvironmentModal
            v-if="!disabled && !readOnly && census.status !== 'scheduled'"
            :id="id"
            :census="census"
            :dog="dog"
            @update:dog="value => $emit('update:dog', value)"
            @submit="$emit('submit')"
          />
      </div>
      <div class="holding-body">
        <p>ANIMAUX</p>
        <div class="holding-body-cat">
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="specie"
                  :class="{'is-invalid': dogValidator.specie.$invalid}"
                  :errors="dogValidator.specie.$errors"
                  :on-blur="dogValidator.specie.$touch"
                  :required="isRequired(dogValidator.specie)"
                  label="Espèces (plusieurs réponses possibles)"
                  :model="dog?.specie"
                  :options="exploitConst.speciesConfig"
                  first-tooltip="On parle ici de l’espèce ou des espèces
                  sur lesquelles travaille le chien."
                  second-tooltip="S’il est à la fois sur des brebis et des chèvres, on cochera les deux."
                  third-tooltip="S’il est sur des brebis et qu’il croise de temps en temps des vaches, on ne cochera pas ici la case bovin mais on le précisera à la question « Y a-t-il d’autres espèces dans l’exploitation ?»"
                  :disabled="disabled"
                  :is-multiple="true"
                  :config="exploitConst.speciesConfig"
              />
            </div>
          </div>
          <div
              v-if="
                  dog.specie && dog.specie.includes('other')
                "
              class="holding-body-row"
          >
            <div class="holding-body-row-icon-input">
              <TextInputController
                  :read-only="readOnly"
                  custom-class="dog-input"
                  :on-change="handleDogChangeSpecieOther"
                  name="specieOther"
                  label="Autre(s) espèce(s)"
                  :model="specieOther"
              />
            </div>
          </div>
          <div v-if="dog.specie?.length > 0 && dog.specie !== ''">
            <p>EFFECTIF POUR CHAQUE ESPÈCE</p>
            <div
                v-for="spec of dog.specie"
                :key="spec"
                class="holding-body-row"
            >
              <div class="holding-body-row-icon-input">
                <TextInputController
                    custom-class="dog-input"
                    :on-change="handleDogChangeSpecie"
                    :name="'nbPersons-' + spec"
                    :label="'Effectif ' + formatSpecie(spec)"
                    placeholder=""
                    :model="specNbPersons[spec]"
                    :disabled="disabled"
                    :read-only="readOnly"
                    :type="'num'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="holding-body-cat period-title">
          <p class="little-title">SYSTEME D'ELEVAGE</p>
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  :name="'typeOfSystem'"
                  :class="{'is-invalid': dogValidator.typeOfSystem.$invalid}"
                  :errors="dogValidator.typeOfSystem.$errors"
                  :on-blur="dogValidator.typeOfSystem.$touch"
                  :required="isRequired(dogValidator.typeOfSystem)"
                  :model="dog.typeOfSystem"
                  label="Type de système"
                  placeholder=""
                  :options="exploitConst.typeOfSystem"
                  :disabled="disabled"
              />
            </div>
          </div>
          <div
              v-if="dog.typeOfSystem !== 'indoor'"
              class="holding-body-row"
          >
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  :name="'typeOfDriving'"
                  :class="{'is-invalid': dogValidator.typeOfDriving.$invalid}"
                  :errors="dogValidator.typeOfDriving.$errors"
                  :on-blur="dogValidator.typeOfDriving.$touch"
                  :required="isRequired(dogValidator.typeOfDriving)"
                  :model="dog?.typeOfDriving"
                  label="Conduite du troupeau à l'extérieur (si période en plein-air)"
                  placeholder=""
                  :options="exploitConst.typeOfConduct"
                  :disabled="disabled"
                  :is-multiple="true"
                  :config="exploitConst.typeOfConduct"
              />
            </div>
          </div>
        </div>
        <p>FREQUENTATION DE L'ENVIRONNEMENT DU CHIEN</p>
        <div class="holding-body-cat">
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :key="JSON.stringify(dog?.humainPresence)"
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="humainPresence"
                  label="Présence humaine sur l'exploitation (plusieurs réponses possibles)"
                  :model="dog?.humainPresence"
                  :class="{'is-invalid': dogValidator.humainPresence.$invalid}"
                  :errors="dogValidator.humainPresence.$errors"
                  :on-blur="dogValidator.humainPresence.$touch"
                  :required="isRequired(dogValidator.humainPresence)"
                  :options="exploitConst.humanPresences"
                  :disabled="disabled"
                  :is-multiple="true"
                  :config="exploitConst.humanPresences"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="otherDogsPresence"
                  :class="{'is-invalid': dogValidator.otherDogsPresence.$invalid}"
                  :errors="dogValidator.otherDogsPresence.$errors"
                  :on-blur="dogValidator.otherDogsPresence.$touch"
                  :required="isRequired(dogValidator.otherDogsPresence)"
                  label="Présence d'autres chiens de ferme (plusieurs réponses possibles)"
                  :model="dog?.otherDogsPresence"
                  :options="exploitConst.otherDogPresences"
                  :disabled="disabled"
                  :is-multiple="true"
                  :config="exploitConst.otherDogPresences"
              />
            </div>
          </div>
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="oustiderHumanPresence"
                  :class="{'is-invalid': dogValidator.oustiderHumanPresence.$invalid}"
                  :errors="dogValidator.oustiderHumanPresence.$errors"
                  :on-blur="dogValidator.oustiderHumanPresence.$touch"
                  :required="isRequired(dogValidator.oustiderHumanPresence)"
                  label="Fréquentation humaine de personnes extérieures (indiquez la plus forte fréquentation)"
                  :model="dog?.oustiderHumanPresence"
                  :options="exploitConst.outsiderHumainPresences"
                  :disabled="disabled"
                  first-tooltip="Elle est permanente si : "
                  second-tooltip="- En alpage/estive > 3 mois "
                  third-tooltip="- Sur la ferme (vente directe par exemple) > 2j par semaine pendant 6 mois consécutifs"
                  :is-multiple="true"
                  :config="exploitConst.outsiderHumainPresences"
              />
            </div>
          </div>
        </div>
        <p>TYPE(S) DE PREDATION POUR LEQUEL LE CHIEN EST UTILISE</p>
        <div class="holding-body-cat">
          <div class="holding-body-row">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="typeOfPredation"
                  :class="{'is-invalid': dogValidator.typeOfPredation.$invalid}"
                  :errors="dogValidator.typeOfPredation.$errors"
                  :on-blur="dogValidator.typeOfPredation.$touch"
                  :required="isRequired(dogValidator.typeOfPredation)"
                  label="Prédation (plusieurs réponses possibles)"
                  :is-multiple="true"
                  :model="dog?.typeOfPredation"
                  :options="exploitConst.typeOfPredations"
                  :disabled="disabled"
                  :config="exploitConst.typeOfPredations"
              />
            </div>
          </div>
          <div
              v-if="
                  dog.typeOfPredation && dog.typeOfPredation.includes('other')
                "
              class="holding-body-row"
          >
            <div class="holding-body-row-icon-input">
              <TextInputController
                  :read-only="readOnly"
                  custom-class="dog-input"
                  :on-change="handleDogChange"
                  name="otherTypeOfPredation"
                  :class="{'is-invalid': dogValidator.otherTypeOfPredation.$invalid}"
                  :errors="dogValidator.otherTypeOfPredation.$errors"
                  :on-blur="dogValidator.otherTypeOfPredation.$touch"
                  :required="isRequired(dogValidator.otherTypeOfPredation)"
                  label="Autre type de prédation"
                  :model="dog.otherTypeOfPredation"
              />
            </div>
          </div>
          <div class="holding-body-row other-specie">
            <div class="holding-body-row-icon-input">
              <SelectInputController
                  :read-only="readOnly"
                  custom-class="Select dog-input"
                  :on-change="handleDogChange"
                  name="isOtherSpecie"
                  :class="{'is-invalid': dogValidator.isOtherSpecie.$invalid}"
                  :errors="dogValidator.isOtherSpecie.$errors"
                  :on-blur="dogValidator.isOtherSpecie.$touch"
                  :required="isRequired(dogValidator.isOtherSpecie)"
                  label="Y a-t-il d’autres espèces sans lien avec le chien de protection dans cette exploitation ?"
                  :model="dog.isOtherSpecie"
                  :options="exploitConst.yesOrNo"
                  :disabled="disabled"
              />
            </div>
          </div>
        </div>

        <div v-if="dog.isOtherSpecie" class="holding-body-row">
          <div class="holding-body-row-icon-input">
            <SelectInputController
                :read-only="readOnly"
                custom-class="Select dog-input"
                :on-change="handleDogChange"
                name="otherSpecie"
                :class="{'is-invalid': dogValidator.otherSpecie.$invalid}"
                :errors="dogValidator.otherSpecie.$errors"
                :on-blur="dogValidator.otherSpecie.$touch"
                :required="isRequired(dogValidator.otherSpecie)"
                label="Autre espèce (plusieurs réponses possibles)"
                :model="dog?.otherSpecie"
                :options="exploitConst.otherSpeciesConfig"
                :disabled="disabled"
                :is-multiple="true"
                :config="exploitConst.otherSpeciesConfig"
            />
          </div>
        </div>
        <div v-if="dog.isOtherSpecie" class="holding-body-row">
          <div class="holding-body-row-icon-input">
            <SelectInputController
                :read-only="readOnly"
                custom-class="Select dog-input"
                :on-change="handleDogChange"
                name="otherTypeOfSystem"
                :class="{'is-invalid': dogValidator.otherTypeOfSystem.$invalid}"
                :errors="dogValidator.otherTypeOfSystem.$errors"
                :on-blur="dogValidator.otherTypeOfSystem.$touch"
                :required="isRequired(dogValidator.otherTypeOfSystem)"
                label="Autre système d'élevage"
                :model="dog.otherTypeOfSystem"
                :options="exploitConst.typeOfSystem"
                :disabled="disabled"
            />
          </div>
        </div>
        <div class="holding-body-row-icon-input">
          <SelectInputController
              v-if="dog.otherTypeOfSystem !== 'indoor' && dog.isOtherSpecie"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              :name="'otherTypeOfDriving'"
              :class="{'is-invalid': dogValidator.otherTypeOfDriving.$invalid}"
              :errors="dogValidator.otherTypeOfDriving.$errors"
              :on-blur="dogValidator.otherTypeOfDriving.$touch"
              :required="isRequired(dogValidator.otherTypeOfDriving)"
              :model="dog?.otherTypeOfDriving"
              label="Conduite du troupeau à l'extérieur (si période en plein-air)"
              placeholder=""
              :options="exploitConst.typeOfConduct"
              :disabled="disabled"
              :is-multiple="true"
              :config="exploitConst.typeOfConduct"
          />
        </div>
      </div>
    </div>

    <div v-if="isDogAboveEighteen" class="holding">
      <div class="holding-head">
        <p>CARACTERISTIQUES GENERALES</p>
      </div>

      <div class="carac-selects">
        <SelectInputController
            :read-only="readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            name="isBornHerd"
            :class="{'is-invalid': dogValidator.isBornHerd.$invalid}"
            :errors="dogValidator.isBornHerd.$errors"
            :on-blur="dogValidator.isBornHerd.$touch"
            :required="isRequired(dogValidator.isBornHerd)"
            label="Chien né au troupeau"
            :model="dog.isBornHerd"
            :options="dogConst.atWorkConfig"
            :disabled="disabled"
        />
        <SelectInputController
            :read-only="readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            name="beforeTwoMonth"
            :class="{'is-invalid': dogValidator.beforeTwoMonth.$invalid}"
            :errors="dogValidator.beforeTwoMonth.$errors"
            :on-blur="dogValidator.beforeTwoMonth.$touch"
            :required="isRequired(dogValidator.beforeTwoMonth)"
            label="Environnement du chien de 0 à 2 mois"
            :model="dog.beforeTwoMonth"
            :options="dogConst.zeroTwoMonthConfig"
            :disabled="disabled"
        />
        <SelectInputController
            :read-only="readOnly"
            custom-class="Select dog-input"
            :on-change="handleDogChange"
            name="isFirstForBreeder"
            :class="{'is-invalid': dogValidator.isFirstForBreeder.$invalid}"
            :errors="dogValidator.isFirstForBreeder.$errors"
            :on-blur="dogValidator.isFirstForBreeder.$touch"
            :required="isRequired(dogValidator.isFirstForBreeder)"
            label="1er chien de l'éleveur"
            :model="dog.isFirstForBreeder"
            :options="dogConst.trueOrFalseConfig"
            :disabled="disabled"
        />
      </div>
      <div class="holding">
        <div class="holding-head">
          <p>CARACTERISTIQUES PHYSIQUES</p>
        </div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isHealthy"
              :class="{'is-invalid': dogValidator.isHealthy.$invalid}"
              :errors="dogValidator.isHealthy.$errors"
              :on-blur="dogValidator.isHealthy.$touch"
              :required="isRequired(dogValidator.isHealthy)"
              label="Bon état général du chien"
              first-tooltip="Du point de vue sanitaire (état corporel....)"
              :model="dog.isHealthy"
              :options="dogConst.trueOrFalseConfig"
              :disabled="disabled"
          />

          <div class="sub-title-tooltip">
            ADAPTATION DE LA MORPHOLOGIE PAR RAPPORT AU TRAVAIL (EN VUE D'UNE
            REPRODUCTION)
            <Tooltip
                text="L’objectif est de sortir de la reproduction les extrêmes, pas de se conformer aux standards SCC."
                custom-class="dog-input"
            />
          </div>

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedMorpho"
              :class="{'is-invalid': dogValidator.isAdaptedMorpho.$invalid}"
              :errors="dogValidator.isAdaptedMorpho.$errors"
              :on-blur="dogValidator.isAdaptedMorpho.$touch"
              :required="isRequired(dogValidator.isAdaptedMorpho)"
              label="Globalement"
              :model="dog.isAdaptedMorpho"
              :options="dogConst.atWorkConfig"
              :disabled="disabled"
          />
          <div v-if="dog.isAdaptedMorpho === 'false'" class="sub-title">
            PRECISEZ QUEL(S) CRITERE(S) SONT NON CONFORMES (NC)
          </div>

          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedSize"
              :class="{'is-invalid': dogValidator.isAdaptedSize.$invalid}"
              :errors="dogValidator.isAdaptedSize.$errors"
              :on-blur="dogValidator.isAdaptedSize.$touch"
              :required="isRequired(dogValidator.isAdaptedSize)"
              label="Taille"
              :model="dog.isAdaptedSize"
              :options="dogConst.nonConformConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedDemarche"
              :class="{'is-invalid': dogValidator.isAdaptedDemarche.$invalid}"
              :errors="dogValidator.isAdaptedDemarche.$errors"
              :on-blur="dogValidator.isAdaptedDemarche.$touch"
              :required="isRequired(dogValidator.isAdaptedDemarche)"
              label="Démarche"
              :model="dog.isAdaptedDemarche"
              :options="dogConst.nonConformConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedAplombs"
              :class="{'is-invalid': dogValidator.isAdaptedAplombs.$invalid}"
              :errors="dogValidator.isAdaptedAplombs.$errors"
              :on-blur="dogValidator.isAdaptedAplombs.$touch"
              :required="isRequired(dogValidator.isAdaptedAplombs)"
              label="Aplombs"
              :model="dog.isAdaptedAplombs"
              :options="dogConst.nonConformConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedBackLine"
              :class="{'is-invalid': dogValidator.isAdaptedBackLine.$invalid}"
              :errors="dogValidator.isAdaptedBackLine.$errors"
              :on-blur="dogValidator.isAdaptedBackLine.$touch"
              :required="isRequired(dogValidator.isAdaptedBackLine)"
              label="Ligne de dos"
              :model="dog.isAdaptedBackLine"
              :options="dogConst.nonConformConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedJaw"
              :class="{'is-invalid': dogValidator.isAdaptedJaw.$invalid}"
              :errors="dogValidator.isAdaptedJaw.$errors"
              :on-blur="dogValidator.isAdaptedJaw.$touch"
              :required="isRequired(dogValidator.isAdaptedJaw)"
              label="Mâchoire (absence de prognathie inférieure ou supérieure)"
              :model="dog.isAdaptedJaw"
              :options="dogConst.nonConformConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAdaptedPigmentation"
              :class="{'is-invalid': dogValidator.isAdaptedPigmentation.$invalid}"
              :errors="dogValidator.isAdaptedPigmentation.$errors"
              :on-blur="dogValidator.isAdaptedPigmentation.$touch"
              :required="isRequired(dogValidator.isAdaptedPigmentation)"
              label="Pigmentation (absence de muqueuses blanches)"
              :model="dog.isAdaptedPigmentation"
              :options="dogConst.pigmentationConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasTesticleConcerned"
              :class="{'is-invalid': dogValidator.hasTesticleConcerned.$invalid}"
              :errors="dogValidator.hasTesticleConcerned.$errors"
              :on-blur="dogValidator.hasTesticleConcerned.$touch"
              :required="isRequired(dogValidator.hasTesticleConcerned)"
              label="2 testicules si concerné"
              :model="dog.hasTesticleConcerned"
              :options="dogConst.testicleConfig"
              :disabled="disabled"
          />
          <TextInputController
              v-if="dog.isAdaptedMorpho === 'false'"
              custom-class="dog-input"
              :on-change="handleDogChange"
              name="adaptedMorphoCommentary"
              :class="{'is-invalid': dogValidator.adaptedMorphoCommentary.$invalid}"
              :errors="dogValidator.adaptedMorphoCommentary.$errors"
              :on-blur="dogValidator.adaptedMorphoCommentary.$touch"
              :required="isRequired(dogValidator.adaptedMorphoCommentary)"
              :model="dog.adaptedMorphoCommentary"
              label="Commentaire"
              placeholder=""
              :disabled="disabled"
              :read-only="readOnly"
          />
        </div>
      </div>

      <div class="holding">
        <h1 class="holding-head">TESTS DE SANTE</h1>

        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasHealthyTrouble"
              :class="{'is-invalid': dogValidator.hasHealthyTrouble.$invalid}"
              :errors="dogValidator.hasHealthyTrouble.$errors"
              :on-blur="dogValidator.hasHealthyTrouble.$touch"
              :required="isRequired(dogValidator.hasHealthyTrouble)"
              label="Le chien a-t-il un problème de santé connu ?"
              first-tooltip="On parle d’un problème qui dure dans
              le temps : cardiaque, sourd, aveugle, problèmes neuro... Si
              oui, précisez lequel."
              :model="dog.hasHealthyTrouble"
              :options="dogConst.yesOrNoConfig"
              :disabled="disabled"
          />
          <TextInputController
              v-if="dog.hasHealthyTrouble"
              custom-class="dog-input"
              :on-change="handleDogChange"
              :name="'otherProblem'"
              :class="{'is-invalid': dogValidator.otherProblem.$invalid}"
              :errors="dogValidator.otherProblem.$errors"
              :on-blur="dogValidator.otherProblem.$touch"
              :required="isRequired(dogValidator.otherProblem)"
              :model="dog.otherProblem"
              label="Précisez lequel"
              placeholder=""
              :disabled="disabled"
              :read-only="readOnly"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hipDysplasia"
              :class="{'is-invalid': dogValidator.hipDysplasia.$invalid}"
              :errors="dogValidator.hipDysplasia.$errors"
              :on-blur="dogValidator.hipDysplasia.$touch"
              :required="isRequired(dogValidator.hipDysplasia)"
              label="Dysplasie des hanches"
              :model="dog.hipDysplasia"
              :options="dogConst.hipDysplasiaConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="elbowDysplasia"
              :class="{'is-invalid': dogValidator.elbowDysplasia.$invalid}"
              :errors="dogValidator.elbowDysplasia.$errors"
              :on-blur="dogValidator.elbowDysplasia.$touch"
              :required="isRequired(dogValidator.elbowDysplasia)"
              label="Dysplasie des coudes"
              :model="dog.elbowDysplasia"
              :options="dogConst.elbowDysplasiaConfig"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">PAR RAPPORT A SA MISE EN PLACE</div>
        <div class="carac-selects">
          <TextInputController
              custom-class="dog-input"
              :on-change="handleDogChange"
              :name="'exploitationAge'"
              :class="{'is-invalid': dogValidator.exploitationAge.$invalid}"
              :errors="dogValidator.exploitationAge.$errors"
              :on-blur="dogValidator.exploitationAge.$touch"
              :required="isRequired(dogValidator.exploitationAge)"
              :model="dog?.exploitationAge"
              label="Age d'arrivée sur l'exploitation (en mois)"
              placeholder=""
              :disabled="disabled"
              :read-only="readOnly"
              @keypress="isNumber($event)"
          />
        </div>
      </div>

      <div class="holding">
        <div class="carac-selects">
          <p class="sub-title">CONDITIONS D'INTRODUCTION AU TROUPEAU</p>
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="introductionCondition"
              :class="{'is-invalid': dogValidator.introductionCondition.$invalid}"
              :errors="dogValidator.introductionCondition.$errors"
              :on-blur="dogValidator.introductionCondition.$touch"
              :required="isRequired(dogValidator.introductionCondition)"
              label="Avec qui ?"
              :model="dog.introductionCondition"
              :options="dogConst.introductionConditionConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.introductionCondition === 'other_puppies'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="introductionConditionPuppies"
              :class="{'is-invalid': dogValidator.introductionConditionPuppies.$invalid}"
              :errors="dogValidator.introductionConditionPuppies.$errors"
              :on-blur="dogValidator.introductionConditionPuppies.$touch"
              :required="isRequired(dogValidator.introductionConditionPuppies)"
              label="Précisez"
              :model="dog.introductionConditionPuppies"
              :options="dogConst.introductionConditionPuppiesConfig"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="dog.introductionCondition === 'with_adults'"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="introductionConditionAdults"
              :class="{'is-invalid': dogValidator.introductionConditionPuppies.$invalid}"
              :errors="dogValidator.introductionConditionPuppies.$errors"
              :on-blur="dogValidator.introductionConditionPuppies.$touch"
              :required="isRequired(dogValidator.introductionConditionPuppies)"
              label="Précisez"
              :model="dog.introductionConditionAdults"
              :options="dogConst.introductionConditionAdultsConfig"
              :disabled="disabled"
          />

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="parcel"
              :class="{'is-invalid': dogValidator.parcel.$invalid}"
              :errors="dogValidator.parcel.$errors"
              :on-blur="dogValidator.parcel.$touch"
              :required="isRequired(dogValidator.parcel)"
              label="Comment ? (plusieurs réponses possibles)"
              :model="dog?.parcel"
              :options="dogConst.parcelConfig"
              :disabled="disabled"
              :is-multiple="true"
              :config="dogConst.parcelConfig"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="parcelHeifers"
              :class="{'is-invalid': dogValidator.parcelHeifers.$invalid}"
              :errors="dogValidator.parcelHeifers.$errors"
              :on-blur="dogValidator.parcelHeifers.$touch"
              :required="isRequired(dogValidator.parcelHeifers)"
              label="Sur quelle(s) espèce(s)"
              :model="dog?.parcelHeifers"
              :options="dogConst.parcelHeifersConfig"
              :disabled="disabled"
              :is-multiple="true"
              :config="dogConst.parcelHeifersConfig"
          />
          <TextInputController
              v-if="dog.parcelHeifers?.includes('others')"
              custom-class="dog-input"
              :on-change="handleDogChange"
              name="parcelOthers"
              :class="{'is-invalid': dogValidator.parcelOthers.$invalid}"
              :errors="dogValidator.parcelOthers.$errors"
              :on-blur="dogValidator.parcelOthers.$touch"
              :required="isRequired(dogValidator.parcelOthers)"
              :model="dog.parcelOthers"
              label="Autres"
              placeholder=""
              :disabled="disabled"
          />
          <p class="sub-title">COMPORTEMENTS LORS DE SA MISE EN PLACE</p>

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="behaviorWithHuman"
              :class="{'is-invalid': dogValidator.behaviorWithHuman.$invalid}"
              :errors="dogValidator.behaviorWithHuman.$errors"
              :on-blur="dogValidator.behaviorWithHuman.$touch"
              :required="isRequired(dogValidator.behaviorWithHuman)"
              label="Comportement vis-à-vis des humains à l'arrivée à la ferme"
              first-tooltip="Plutôt méfiant : chiot qui évite
              l’éleveur, se cache quand il le voit"
              second-tooltip="Confiant : intéressé par l’Homme ou neutre"
              third-tooltip="Plutôt collant : dépendance affective à l’Homme, en
              demande, suit l’Homme quand il part et/ou pleure"
              :model="dog.behaviorWithHuman"
              :options="dogConst.behaviorWithHumanConfig"
              :disabled="disabled"
          />

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="temper"
              :class="{'is-invalid': dogValidator.temper.$invalid}"
              :errors="dogValidator.temper.$errors"
              :on-blur="dogValidator.temper.$touch"
              :required="isRequired(dogValidator.temper)"
              label="Caractère"
              :model="dog?.temper"
              :options="dogConst.temperConfig"
              :disabled="disabled"
          />

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="respectingOthers"
              :class="{'is-invalid': dogValidator.respectingOthers.$invalid}"
              :errors="dogValidator.respectingOthers.$errors"
              :on-blur="dogValidator.respectingOthers.$touch"
              :required="isRequired(dogValidator.respectingOthers)"
              label="Respect des animaux"
              :model="dog.respectingOthers"
              :options="dogConst.isRespectingOthersConfig"
              :disabled="disabled"
          />

          <span
              v-if="
                dog.respectingOthers === 'little' ||
                dog.respectingOthers === 'a_lot'
              "
          >
              De quel âge à quel âge (en mois) ?
            </span>
          <FormLine
              v-if="
                dog.respectingOthers === 'little' ||
                dog.respectingOthers === 'a_lot'
              "
          >
            <TextInputController
                custom-class="dog-input"
                :on-change="handleDogChange"
                :name="'startRespectingOthers'"
                :class="{'is-invalid': dogValidator.startRespectingOthers.$invalid}"
                :errors="dogValidator.startRespectingOthers.$errors"
                :on-blur="dogValidator.startRespectingOthers.$touch"
                :required="isRequired(dogValidator.startRespectingOthers)"
                :model="dog.startRespectingOthers"
                label="De"
                :disabled="disabled"
                :read-only="readOnly"
                @keypress="isNumber($event)"
            />
            <TextInputController
                custom-class="dog-input"
                :on-change="handleDogChange"
                :name="'endRespectingOthers'"
                :class="{'is-invalid': dogValidator.endRespectingOthers.$invalid}"
                :errors="dogValidator.endRespectingOthers.$errors"
                :on-blur="dogValidator.endRespectingOthers.$touch"
                :required="isRequired(dogValidator.endRespectingOthers)"
                :model="dog.endRespectingOthers"
                label="À"
                :disabled="disabled"
                :read-only="readOnly"
                @keypress="isNumber($event)"
            />
          </FormLine>

          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="tookTime"
              :class="{'is-invalid': dogValidator.tookTime.$invalid}"
              :errors="dogValidator.tookTime.$errors"
              :on-blur="dogValidator.tookTime.$touch"
              :required="isRequired(dogValidator.tookTime)"
              label="A-t-il demandé du temps (attention, correction..)"
              :model="dog.tookTime"
              :options="dogConst.trueOrFalseConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isEasyDog"
              :class="{'is-invalid': dogValidator.isEasyDog.$invalid}"
              :errors="dogValidator.isEasyDog.$errors"
              :on-blur="dogValidator.isEasyDog.$touch"
              :required="isRequired(dogValidator.isEasyDog)"
              label="Considérez-vous ce chien"
              :model="dog.isEasyDog"
              :options="dogConst.isEasyDogConfig"
              :disabled="disabled"
          />
        </div>
      </div>
      <div class="holding">
        <div class="holding-head">PAR RAPPORT A SON TEMPERAMENT</div>

        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="characteristics"
              :class="{'is-invalid': dogValidator.characteristics.$invalid}"
              :errors="dogValidator.characteristics.$errors"
              :on-blur="dogValidator.characteristics.$touch"
              :required="isRequired(dogValidator.characteristics)"
              label="Considérez-vous ce chien (plusieurs réponses possibles)"
              :model="dog?.characteristics"
              :options="dogConst.considerDog"
              :disabled="disabled"
              :is-multiple="true"
              :config="dogConst.considerDog"
              first-tooltip="L’objectif de cette question est de
              faire ressortir un ou des traits de tempéraments marqués. Si
              aucun ne correspond, laissez la case vide."
              second-tooltip="Précisions sur certains termes :"
              third-tooltip="- A l’écoute, attentif à l’Homme :
respect de l’Homme (par rapport à des ordres que lui donne son maître par exemple)"
              fourth-tooltip="- Sensible à
l’Homme : crainte, méfiance…"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">PAR RAPPORT A SON MAITRE</div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isManipulable"
              :class="{'is-invalid': dogValidator.isManipulable.$invalid}"
              :errors="dogValidator.isManipulable.$errors"
              :on-blur="dogValidator.isManipulable.$touch"
              :required="isRequired(dogValidator.isManipulable)"
              label="Manipulable par son maitre (peut le toucher partout et l'enlacer au niveau du cou)"
              :model="dog.isManipulable"
              :options="dogConst.trueOrFalseConfig"
              :disabled="disabled"
          />
        </div>
      </div>
      <div class="holding">
        <div class="holding-head">PAR RAPPORT AUX HUMAINS INCONNUS</div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="unknownDogTemper"
              :class="{'is-invalid': dogValidator.unknownDogTemper.$invalid}"
              :errors="dogValidator.unknownDogTemper.$errors"
              :on-blur="dogValidator.unknownDogTemper.$touch"
              :required="isRequired(dogValidator.unknownDogTemper)"
              label="Problèmes de comportement (dont l'agressivité)"
              :model="dog.unknownDogTemper"
              :options="dogConst.atWorkConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isMunipulableCensusTaker"
              :class="{'is-invalid': dogValidator.isMunipulableCensusTaker.$invalid}"
              :errors="dogValidator.isMunipulableCensusTaker.$errors"
              :on-blur="dogValidator.isMunipulableCensusTaker.$touch"
              :required="isRequired(dogValidator.isMunipulableCensusTaker)"
              label="Touchable par le recenseur (au troupeau ou en dehors)"
              :model="dog.isMunipulableCensusTaker"
              :options="dogConst.yesOrNoStringConfig"
              :disabled="disabled"
              first-tooltip="En présence de l’éleveur, soit dans le troupeau si c’est possible, soit si ce n’est pas possible demander
à l’éleveur de sortir le chien du troupeau"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="unknownTrustWithOthers"
              :class="{'is-invalid': dogValidator.unknownTrustWithOthers.$invalid}"
              :errors="dogValidator.unknownTrustWithOthers.$errors"
              :on-blur="dogValidator.unknownTrustWithOthers.$touch"
              :required="isRequired(dogValidator.unknownTrustWithOthers)"
              label="Avez-vous confiance en ce chien s'il rencontre des inconnus, en votre présence ?"
              :model="dog.unknownTrustWithOthers"
              :options="dogConst.watchfulAwakeningConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="unknownTrustOthersWithoutYou"
              :class="{'is-invalid': dogValidator.unknownTrustOthersWithoutYou.$invalid}"
              :errors="dogValidator.unknownTrustOthersWithoutYou.$errors"
              :on-blur="dogValidator.unknownTrustOthersWithoutYou.$touch"
              :required="isRequired(dogValidator.unknownTrustOthersWithoutYou)"
              label="Avez-vous confiance en ce chien s'il rencontre des inconnus, en votre absence ?"
              :model="dog.unknownTrustOthersWithoutYou"
              :options="[...dogConst.watchfulAwakeningConfig, { value: 'do_not_know', label: 'Ne sait pas' }]"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">
          PAR RAPPORT AUX AUTRES CHIENS DE PROTECTION (RELATIONNEL)
        </div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="positionInGroup"
              :class="{'is-invalid': dogValidator.positionInGroup.$invalid}"
              :errors="dogValidator.positionInGroup.$errors"
              :on-blur="dogValidator.positionInGroup.$touch"
              :required="isRequired(dogValidator.positionInGroup)"
              label="Position de ce chien au sein du groupe"
              :model="dog.positionInGroup"
              :options="dogConst.positionInGroupConfig"
              :disabled="disabled"
              first-tooltip="On cherche là-encore une tendance,
              certains chiens sont dominants avec tous les chiens, d’autres
              sont soumis avec tous les chiens, d’autres cela dépend du
              chien (= intermédiaire)"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="canWorkInGroup"
              :class="{'is-invalid': dogValidator.canWorkInGroup.$invalid}"
              :errors="dogValidator.canWorkInGroup.$errors"
              :on-blur="dogValidator.canWorkInGroup.$touch"
              :required="isRequired(dogValidator.canWorkInGroup)"
              label="Est-il capable de travailler en groupe ?"
              :model="dog.canWorkInGroup"
              :options="dogConst.atWorkConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="canWorkAlone"
              :class="{'is-invalid': dogValidator.canWorkAlone.$invalid}"
              :errors="dogValidator.canWorkAlone.$errors"
              :on-blur="dogValidator.canWorkAlone.$touch"
              :required="isRequired(dogValidator.canWorkAlone)"
              label="Est-il capable de travailler seul ?"
              :model="dog.canWorkAlone"
              :options="dogConst.canWorkAloneConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAgressifToOtherDogs"
              :class="{'is-invalid': dogValidator.isAgressifToOtherDogs.$invalid}"
              :errors="dogValidator.isAgressifToOtherDogs.$errors"
              :on-blur="dogValidator.isAgressifToOtherDogs.$touch"
              :required="isRequired(dogValidator.isAgressifToOtherDogs)"
              label="Problèmes de comportement (dont l'agressivité)"
              :model="dog.isAgressifToOtherDogs"
              :options="dogConst.atWorkConfig"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">PAR RAPPORT A SON ENVIRONNEMENT</div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="presentHerd"
              :class="{'is-invalid': dogValidator.presentHerd.$invalid}"
              :errors="dogValidator.presentHerd.$errors"
              :on-blur="dogValidator.presentHerd.$touch"
              :required="isRequired(dogValidator.presentHerd)"
              label="Présent au troupeau lors de la visite"
              :model="dog.presentHerd"
              :options="dogConst.presentHerdConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="watchfulAwakening"
              :class="{'is-invalid': dogValidator.watchfulAwakening.$invalid}"
              :errors="dogValidator.watchfulAwakening.$errors"
              :on-blur="dogValidator.watchfulAwakening.$touch"
              :required="isRequired(dogValidator.watchfulAwakening)"
              label="Est-ce un chien vigilant, en éveil ?"
              :model="dog.watchfulAwakening"
              :options="dogConst.watchfulAwakeningConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasExcessiveBarking"
              :class="{'is-invalid': dogValidator.hasExcessiveBarking.$invalid}"
              :errors="dogValidator.hasExcessiveBarking.$errors"
              :on-blur="dogValidator.hasExcessiveBarking.$touch"
              :required="isRequired(dogValidator.hasExcessiveBarking)"
              label="Considérez-vous ses aboiements ?"
              :model="dog.hasExcessiveBarking"
              :options="dogConst.hasExcessiveBarkingConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isGoodProtector"
              :class="{'is-invalid': dogValidator.isGoodProtector.$invalid}"
              :errors="dogValidator.isGoodProtector.$errors"
              :on-blur="dogValidator.isGoodProtector.$touch"
              :required="isRequired(dogValidator.isGoodProtector)"
              label="Est-ce un protecteur efficace du troupeau ?"
              :model="dog.isGoodProtector"
              :options="dogConst.goodProtectorConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="distanceHerd"
              :class="{'is-invalid': dogValidator.distanceHerd.$invalid}"
              :errors="dogValidator.distanceHerd.$errors"
              :on-blur="dogValidator.distanceHerd.$touch"
              :required="isRequired(dogValidator.distanceHerd)"
              label="Ce chien est"
              :model="dog.distanceHerd"
              :options="dogConst.distanceHerdConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="fenceRespect"
              :class="{'is-invalid': dogValidator.fenceRespect.$invalid}"
              :errors="dogValidator.fenceRespect.$errors"
              :on-blur="dogValidator.fenceRespect.$touch"
              :required="isRequired(dogValidator.fenceRespect)"
              label="Ce chien respecte-t-il les clôtures ?"
              :model="dog.fenceRespect"
              :options="dogConst.fenceRespectConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="runAfterVehicles"
              :class="{'is-invalid': dogValidator.runAfterVehicles.$invalid}"
              :errors="dogValidator.runAfterVehicles.$errors"
              :on-blur="dogValidator.runAfterVehicles.$touch"
              :required="isRequired(dogValidator.runAfterVehicles)"
              label="Ce chien court-il après les vélos/véhicules motorisés ?"
              :model="dog.runAfterVehicles"
              :options="dogConst.runAfterVehiclesConfig"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">APPRECIATION GLOBALE DE L'ELEVEUR</div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isAlwaysUsable"
              :class="{'is-invalid': dogValidator.isAlwaysUsable.$invalid}"
              :errors="dogValidator.isAlwaysUsable.$errors"
              :on-blur="dogValidator.isAlwaysUsable.$touch"
              :required="isRequired(dogValidator.isAlwaysUsable)"
              label="Ce chien est-il utilisable partout, tout le temps, en toutes conditions ?"
              :model="dog.isAlwaysUsable"
              :options="dogConst.isAlwaysUsableConfig"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="recovery"
              :class="{'is-invalid': dogValidator.recovery.$invalid}"
              :errors="dogValidator.recovery.$errors"
              :on-blur="dogValidator.recovery.$touch"
              :required="isRequired(dogValidator.recovery)"
              label="Suite à un stimulus négatif, comment jugez-vous la récupération du chien ?"
              :model="dog.recovery"
              :options="dogConst.dogsRecovery"
              :disabled="disabled"
          />
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="wantPuppy"
              :class="{'is-invalid': dogValidator.wantPuppy.$invalid}"
              :errors="dogValidator.wantPuppy.$errors"
              :on-blur="dogValidator.wantPuppy.$touch"
              :required="isRequired(dogValidator.wantPuppy)"
              label="Voudriez-vous un chiot de ce chien (par rapport à ses aptitudes au travail) ?"
              :model="dog.wantPuppy"
              :options="dogConst.trueOrFalseConfig"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="holding">
        <div class="holding-head has-tooltip">
          CONCLUSION TECHNIQUE PAR LE RECENSEUR
          <Tooltip
              custom-class="holding-head"
              text="À remplir en présence de l’éleveur ou non. "
              second-text="S’il y a eu un suivi technique, renseignez les champs imprégnation, familiarisation, mise
            en place et fixation ; sinon, cochez « je ne peux pas juger »"
          />
        </div>
        <div class="carac-selects">
          <SelectInputController
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isPotentialBreeder"
              :class="{'is-invalid': dogValidator.isPotentialBreeder.$invalid}"
              :errors="dogValidator.isPotentialBreeder.$errors"
              :on-blur="dogValidator.isPotentialBreeder.$touch"
              :required="isRequired(dogValidator.isPotentialBreeder)"
              label="Reproducteur potentiel"
              :model="dog.isPotentialBreeder"
              :options="dogConst.atWorkConfig"
              :disabled="disabled"
          />

          <RadioYesNo
              label="Avez-vous fait un suivi technique pour ce chien?"
              :default-value="technicalFollowUp"
              :disabled="disabled"
              :read-only="readOnly"
              @on-change="(value) => $emit('update:technicalFollowUp', value)"
              id="technicalFollowUp"
          />
          <SelectInputController
              v-if="technicalFollowUp"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasGoodImpregnateHerdBfrTwoMounth"
              :class="{'is-invalid': dogValidator.hasGoodImpregnateHerdBfrTwoMounth.$invalid}"
              :errors="dogValidator.hasGoodImpregnateHerdBfrTwoMounth.$errors"
              :on-blur="dogValidator.hasGoodImpregnateHerdBfrTwoMounth.$touch"
              :required="isRequired(dogValidator.hasGoodImpregnateHerdBfrTwoMounth)"
              label="Imprégnation au troupeau avant 2 mois"
              :model="dog.hasGoodImpregnateHerdBfrTwoMounth"
              :options="dogConst.canNotJudgeConfig.filter(el => el.value != 'null')"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="technicalFollowUp"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasGoodFamiliarizationHumansBfrTwoMounths"
              :class="{'is-invalid': dogValidator.hasGoodFamiliarizationHumansBfrTwoMounths.$invalid}"
              :errors="dogValidator.hasGoodFamiliarizationHumansBfrTwoMounths.$errors"
              :on-blur="dogValidator.hasGoodFamiliarizationHumansBfrTwoMounths.$touch"
              :required="isRequired(dogValidator.hasGoodFamiliarizationHumansBfrTwoMounths)"
              label="Familiarisation aux humains avant 2 mois"
              :model="dog.hasGoodFamiliarizationHumansBfrTwoMounths"
              :options="dogConst.canNotJudgeConfig.filter(el => el.value != 'null')"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="technicalFollowUp"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="hasGoodFamiliarizationHumansAfterTwoMounths"
              :class="{'is-invalid': dogValidator.hasGoodFamiliarizationHumansAfterTwoMounths.$invalid}"
              :errors="dogValidator.hasGoodFamiliarizationHumansAfterTwoMounths.$errors"
              :on-blur="dogValidator.hasGoodFamiliarizationHumansAfterTwoMounths.$touch"
              :required="isRequired(dogValidator.hasGoodFamiliarizationHumansAfterTwoMounths)"
              label="Familiarisation aux humains après 2 mois"
              :model="dog.hasGoodFamiliarizationHumansAfterTwoMounths"
              :options="dogConst.canNotJudgeConfig.filter(el => el.value != 'null')"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="technicalFollowUp"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="wasEasySetupPuppy"
              :class="{'is-invalid': dogValidator.wasEasySetupPuppy.$invalid}"
              :errors="dogValidator.wasEasySetupPuppy.$errors"
              :on-blur="dogValidator.wasEasySetupPuppy.$touch"
              :required="isRequired(dogValidator.wasEasySetupPuppy)"
              label="Mise en place en tant que chiots"
              :model="dog.wasEasySetupPuppy"
              :options="dogConst.asPuppyConfig.filter(el => el.value != 'null')"
              :disabled="disabled"
          />
          <SelectInputController
              v-if="technicalFollowUp"
              :read-only="readOnly"
              custom-class="Select dog-input"
              :on-change="handleDogChange"
              name="isClingyWithHerd"
              :class="{'is-invalid': dogValidator.isClingyWithHerd.$invalid}"
              :errors="dogValidator.isClingyWithHerd.$errors"
              :on-blur="dogValidator.isClingyWithHerd.$touch"
              :required="isRequired(dogValidator.isClingyWithHerd)"
              label="Fixation au troupeau"
              :model="dog.isClingyWithHerd"
              :options="dogConst.canNotJudgeConfig.filter(el => el.value != 'null')"
              :disabled="disabled"
          />
        </div>
      </div>
    </div>

    <div v-if="isDogAboveEighteen" class="holding">
      <div class="notes-title">
        <h1>Appréciation globale du recenseur</h1>
        <Tooltip
            text="Permet de reporter des choses importantes qui n’auraient pas trouvé leur place dans la grille ou
       des propositions d’évolution de la grille."
            custom-class="dog-input"
        />
      </div>

      <textarea
          :value="dog.notes"
          :disabled="disabled || readOnly"
          @input="handleDogChange($event.target.value, 'notes')"
          :style="[disabled || readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
      />
    </div>

    <div v-if="!isDogAboveEighteen" class="no-dogs">
      Le chien sélectionné n'a pas plus de 18 mois
    </div>
  </form>
</template>

<style lang="scss" scoped>
@import "../../../../assets/css/_variables.scss";

.buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

h1 {
  font-size: 16px;
}

.container-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 1em;

  .title {
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .border {
    margin-left: 10px;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid $censusDog;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .lof {
    display: flex;
    align-items: center;
  }

  .title-line {
    margin-top: 1em;
    margin-bottom: 1em;
    color: $censusDog;
  }
}

.lof {
  display: flex;
  align-items: center;
}

.lof-switch {
  margin-left: 1em !important;
  margin-top: 0 !important;
  margin-bottom: 7% !important;
  width: 50% !important;
}

.light-blue {
  color: $censusDog;
}

.medium-blue {
  color: $censusMaster;
}

.orange {
  color: $censusHolding;
}

.holding {
  &-head {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1em 2em;
    color: white;
    background-color: $censusDog;

    &.has-tooltip {
      position: relative;
    }

    p {
      margin: 0 !important;
      font-size: 15px;
    }
  }

  &-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 1em 1em;

    &-cat {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-top: 1em;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-icon-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }

      &-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }
    }
  }
}

.sub-title {
  font-size: 13px;
}

.sub-title-tooltip {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
  margin-bottom: 1em;
}

.carac {
  margin-top: 2em;

  &-selects {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    border-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 15px 2em;
  }
}

.date-picker {
  position: relative;
}

textarea {
  background: white;
  border: 1px solid $censusDog;
  border-radius: 30px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  margin-top: 1em;
  height: 250px;
  width: 92%;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

.blured {
  filter: blur(5px);
  pointer-events: none;
}

form {
  transition: filter 200ms;
}

.has-switch {
  &-switch {
    width: 50% !important;
    margin-left: 1em;
  }

  display: flex;
  align-items: center;
}

.other-specie {
  margin-top: 1em !important;
}

.period-title {
  margin-top: 0.5em;
}

.error {
  color: $danger;
}

p {
  font-size: 14px;
}

.identity-body {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 2em 1em;
  margin-bottom: 1em;
}

.form-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #3f3f3f;
}

.house-icon {
  padding-right: 1em;
}

.little-title {
  margin: 0;
}

.notes {
  textarea {
    text-transform: uppercase;
  }
}

.notes-title {
  position: relative;
  display: flex;
  align-items: center;
}

.no-dogs {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 2em;
}

.label-is-lof label {
  width: 12%;
}
</style>
