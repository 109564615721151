import axios from "axios";

class HoldingService {
  getHoldings(page = 1, controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}exploitations?page=${page}`, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  getFilteredHoldings(page = 1, params, controller) {
    return axios
      .get(
        `${process.env.VUE_APP_API_URL}exploitations?page=${page}${
          params ? params : ""
        }`,
        { signal: controller?.signal ?? null }
      )
      .then((response) => {
        return response.data;
      });
  }

  getHoldingById(id, controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}exploitations/${id}`, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  updateHolding(id, body, controller) {
    return axios
      .put(`${process.env.VUE_APP_API_URL}exploitations/${id}`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  createHolding(body, controller) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}exploitations`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }
}
export default new HoldingService();
