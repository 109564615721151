import DogsService from "@/services/dogs.service";
import RecensementService from "@/services/recensement.service";
import PeopleService from "@/services/people.service";
import HoldingService from "@/services/holding.service";

async function handleSubmitDog(
  correctDog,
  body,
  censusId,
) {
  // const buildPeopleApiId = (id) => {
  //   if (id) {
  //     return `/api/people/${id}`;
  //   }
  // };
  const updatedDog = await DogsService.update(correctDog.value.id, body);
  await RecensementService.upgradeIfComplete(censusId);
  return updatedDog
  // if ((ownerForm?.firstname && ownerForm?.lastname) || ownerForm?.companyname) {
  //   if (
  //     (!correctDog.value?.owner?.id && !ownerAlreadyExist) ||
  //     (correctDog.value?.ownerIsOwnerExploitation &&
  //       !body.ownerIsOwnerExploitation)
  //   ) {
  //     const createdOwnerData = await PeopleService.create(ownerBody);
  //     await DogsService.updateDogEntities(correctDog?.value?.id, {
  //       exploitation: exploitation["@id"],
  //       owner: buildPeopleApiId(createdOwnerData?.id),
  //     });
  //     if (form.masterIsOwnerDog) {
  //       await DogsService.updateDogEntities(correctDog?.value?.id, {
  //         exploitation: exploitation["@id"],
  //         master: buildPeopleApiId(createdOwnerData?.id),
  //       });
  //     }
  //   } else {
  //     const updatedOwnerData = await PeopleService.update(
  //       ownerAlreadyExist?.id
  //         ? ownerAlreadyExist.id
  //         : correctDog.value?.owner?.id,
  //       ownerBody
  //     );
  //     await DogsService.updateDogEntities(correctDog?.value?.id, {
  //       exploitation: exploitation["@id"],
  //       owner: buildPeopleApiId(updatedOwnerData.id),
  //     });
  //     if (form.masterIsOwnerDog) {
  //       await DogsService.updateDogEntities(correctDog?.value?.id, {
  //         exploitation: exploitation["@id"],
  //         owner: buildPeopleApiId(updatedOwnerData?.id),
  //         master: buildPeopleApiId(updatedOwnerData?.id),
  //       });
  //     }
  //   }
  // }

  // if (
  //   ((masterForm?.firstname && masterForm?.lastname) ||
  //     masterForm?.companyname) &&
  //   !form.masterIsOwnerDog
  // ) {
  //   if (
  //     !correctDog.value?.master ||
  //     correctDog.value?.master?.id === correctDog.value?.owner?.id ||
  //     (correctDog.value?.master?.id && !correctDog.value?.owner?.id)
  //   ) {
  //     const createdMasterData = await PeopleService.create(masterBody);
  //     await DogsService.updateDogEntities(correctDog?.value?.id, {
  //       exploitation: exploitation["@id"],
  //       master: buildPeopleApiId(createdMasterData.id),
  //     });
  //   } else {
  //     const createdMasterData = await PeopleService.update(
  //       correctDog.value?.master?.id,
  //       masterBody
  //     );
  //     await DogsService.updateDogEntities(correctDog?.value?.id, {
  //       exploitation: exploitation["@id"],
  //       master: buildPeopleApiId(createdMasterData.id),
  //     });
  //   }
  // }
}

async function handleSubmitOwnerMaster(
  correctDog,
  form,
  ownerForm,
  exploitation,
  ownerId,
  masterId,
  masterIsOwnerExploitation,
  ownerIsOwnerExploitation,
  masterIsOwnerDog
) {
  const buildPeopleApiId = (id) => {
    if (id) {
      return `/api/people/${id}`;
    }
  };
  const requiredInputs = (formOnly) => {
    if (formOnly) {
      if (form.zipCode && form.zipCode.length <5) {
        return false
      }
      if ((form.firstname && form.lastname 
        || form.companyname) && form.email && ownerForm.email
      ) {
        return true
      }
      return false
    } else {
      if (ownerForm.zipCode && ownerForm.zipCode.length < 0 || form.zipCode && form.zipCode.length <5) {
        return false
      }
      if (
        (ownerForm.firstname && ownerForm.lastname
        || ownerForm.companyname)
        && (form.firstname && form.lastname 
        || form.companyname) && form.email && ownerForm.email
      ) {
        return true
      }
      return false
    }
  }
  if (!masterIsOwnerDog && (requiredInputs())) {
    if (ownerId > 0) {
      const updatedOwnerData = await PeopleService.update(ownerId, ownerForm);
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        owner: buildPeopleApiId(updatedOwnerData?.id),
      });
      if (ownerIsOwnerExploitation && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(updatedOwnerData?.id) }
        );
      }
    } else {
      const createdOwnerData = await PeopleService.create(ownerForm);
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        owner: buildPeopleApiId(createdOwnerData?.id),
      });
      if (ownerIsOwnerExploitation && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(createdOwnerData?.id) }
        );
      }
    }
    if (masterId > 0) {
      const updatedMasterData = await PeopleService.update(masterId, form);
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        master: buildPeopleApiId(updatedMasterData?.id),
      });
      if (masterIsOwnerExploitation && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(updatedMasterData?.id) }
        );
      }
    } else {
      const createdMasterData = await PeopleService.create(form);
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        master: buildPeopleApiId(createdMasterData?.id),
      });
      if (masterIsOwnerExploitation && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(createdMasterData?.id) }
        );
      }
    }
  } else if (masterIsOwnerDog && requiredInputs(true)) {
    if (ownerId > 0) {
      const updatedOwnerMasterData = await PeopleService.update(ownerId, {...form, ...ownerForm});
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        owner: buildPeopleApiId(updatedOwnerMasterData?.id),
        master: buildPeopleApiId(updatedOwnerMasterData?.id),
      });
      if ((ownerIsOwnerExploitation) && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(updatedOwnerMasterData?.id) }
        );
      }
    } else {
      const createdOwnerMasterData = await PeopleService.create({...form, ...ownerForm});
      await DogsService.updateDogEntities(correctDog?.value?.id, {
        exploitation: exploitation["@id"],
        owner: buildPeopleApiId(createdOwnerMasterData?.id),
        master: buildPeopleApiId(createdOwnerMasterData?.id),
      });
      if ((ownerIsOwnerExploitation) && !exploitation.owner) {
        HoldingService.updateHolding(
          exploitation.id,
          { owner: buildPeopleApiId(createdOwnerMasterData?.id) }
        );
      }
    }
  } else {
    return "error"
  }
}

export {
  handleSubmitOwnerMaster,
  handleSubmitDog,
};
