<script setup>
import { useStore } from '../../stores';
import { computed } from 'vue';

const store = useStore();

const user = computed(() => store.user);
const fullname = computed(() => store.getFullnameUser);
const initials = computed(() => store.getInitialsUser);
const isAdmin = computed(() => store.userIsAdmin);


//const fullName = computed(() => user ? `${user.firsname} ${user.lastname}` : '')

</script>

<template>
  <div
      v-if="user"
      class="profil"
  >
    <div class="avatar">
      {{ initials }}
    </div>
    <div class="user">
      <label>{{ fullname }}</label>
      <span>{{ isAdmin ? 'Administrateur' : 'Recenseur' }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.profil{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;

  .avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: $primary;
    border-radius: 5px;
    color: white;
    font-size: 16;
    font-family: 'NunitoSansBold';
  }

  .user{
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    label{
      color: black;
      font-family: 'NunitoSansExtraBold';
      font-size: 16px;
    }

    span{
      color: $primary;
      font-family: 'NunitoSans';
      text-transform: uppercase;
      font-size: 12px;
    }
  }

}
</style>