<template>
  <AuthTemplate
    :icon="iconAuth"
    title="Récupération de mot de passe"
    secondary-text="Veuillez indiquer l'adresse mail rattachée au compte pour la récupération du mot de passe"
  >
    <form @submit.prevent="submitForm()">
      <TextInputController
        :on-change="handleChange"
        :name="'email'"
        :model="state.email"
        :errors="v$.email.$errors"
        :on-blur="v$.email.$touch"
        :label="'Email'"
      >
        <template #icon>
          <IconMail />
        </template>
      </TextInputController>

      <span
        v-if="error"
        class="error"
      >{{ error }}</span>

      <Button
        type="primary"
        :disabled="v$.$invalid"
        :submit="true"
        margin-top="40px"
      >
        VALIDATION
      </Button>
      <Button
        type="transparent"
        margin-top="40px"
        @on-click="router.push({path: '/login'})"
      >
        ANNULATION
      </Button>
    </form>
  </AuthTemplate>
</template>

<script setup>
import iconAuth from '../assets/images/icon-auth.svg';
import AuthTemplate from "@/components/auth-template/AuthTemplate";
import {useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import {email, helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/auth.service";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "bottom" });
const router = useRouter();
const error = ref();
const state = reactive({
  email: '',
});

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage('Le champ est requis !', required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
  };
});
const v$ = useVuelidate(rules, state);

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};

async function submitForm() {
  try {
    error.value = null;
    const body = {
      email: state.email
    }
    const isFormCorrect = await v$.value.$validate();
    if (isFormCorrect) {
      await AuthService.forgottenPassword(body);
      toaster.success("Un email vous a été envoyé avec les instructions pour réinitialiser votre mot de passe !");
    }
  } catch (e) {
    error.value = "L'email est incorrect !";
    console.log(e);
  }
}

</script>


<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


</style>