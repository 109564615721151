<script setup>
import TextInputController from "../../../../components/form/TextInputController/TextInputController.vue";
import useForm from "../../../../compositions/useForm";

const props = defineProps({
  modelValue: Object,
  readOnly: Boolean,
  disabled: Boolean,
  validator: Object,
})

const emit = defineEmits(['update:modelValue'])

const  { isRequired } = useForm()


const handleChange = (value, name) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [name]: value
  })
}
</script>

<template>
  <form class="tx-flex tw-flex-col tw-space-y-5">
    <div class="tw-flex tw-flex-col tw-rounded-lg tw-overflow-hidden tw-bg-zinc-100">
      <div class="tw-bg-holding tw-font-bold tw-uppercase tw-text-white tw-px-5 tw-py-3">
        Identité de l'exploitation
      </div>

      <div class="tw-px-5 tw-py-10 tw-space-y-5">
        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            name="name"
            :model="modelValue.name"
            label="Nom de l'exploitation"
            placeholder=""
            :disabled="disabled"
        />

        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            :name="'edeNumber'"
            :model="modelValue.edeNumber"
            label="N° EDE de l'exploitation"
            placeholder=""
            :disabled="disabled"
        />

        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            name="siretNumber"
            :model="modelValue.siretNumber"
            label="N° SIRET"
            placeholder=""
            :disabled="disabled"
        />

        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            name="Adresse"
            :model="modelValue.address"
            label="Adresse"
            placeholder=""
            :disabled="disabled"
        />

        <FormLine>
          <TextInputController
              custom-class="holding-input"
              :read-only="readOnly"
              :on-change="handleChange"
              name="zipCode"
              :type="'zipCode'"
              :model="modelValue.zipCode"
              label="Code postal"
              placeholder=""
              :disabled="disabled"
              :maxlength="5"
          />

          <TextInputController
              custom-class="holding-input"
              :read-only="readOnly"
              :on-change="handleChange"
              name="city"
              :model="modelValue.city"
              label="Ville"
              placeholder=""
              :disabled="disabled"
          />
        </FormLine>

        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            name="mail"
            :model="modelValue.mail"
            label="Mail"
            :class="{'is-valid': validator.mail.$invalid}"
            :errors="validator.mail.$errors"
            :on-blur="validator.mail.$touch"
            :required="isRequired(validator.mail)"
            placeholder=""
            :disabled="disabled"
        />

        <TextInputController
            custom-class="holding-input"
            :read-only="readOnly"
            :on-change="handleChange"
            name="mobile"
            :type="'phone'"
            :model="modelValue.mobile"
            label="Téléphone"
            :maxlength="10"
            placeholder=""
            :disabled="disabled"
        />
      </div>
    </div>
  </form>
</template>