import { defineStore } from 'pinia';

export const useStore = defineStore({
    id: 'store',
    state: () => ({
        showMyCensusesOnly: false,
        uploadSizeError: null,
        quittingCensusDetails: false,
        quittingCensusDetailTo: null,
        leavingCensusDetail: false,
        quittingCensus: false,
        quittingCensusToPath: null,
        config: {
            loadNextData: false,
            isOnline: true,
        },
        currentCensus: {
            step: 0,
            selectedExploit: null,
            status: null,
            data: null,
        },
        user: null,
        dog: null,
        censusFilterType: {
            value: 'à venir',
            status: 'scheduled',
        },
        correctDog: null,
        dogIdNumber: null,
    }),
    actions: {
        setScroll(payload) {
            this.config.loadNextData = payload;
        },
        setIsOnline(payload) {
            this.config.isOnline = payload;
        },
        setUser(payload) {
            this.user = payload;
        },
        resetCurrentCensus() {
            this.currentCensus = {
                step: 0,
                selectedExploit: null,
                status: null,
                data: null,
            }
        },
        setCensusStep(payload) {
            this.currentCensus.step = payload;
        },
        setCensusSelectedExploit(payload) {
            this.currentCensus.selectedExploit = payload;
        },
        setCensusStatus(payload) {
            this.currentCensus.status = payload;
        },
        setCensusData(payload) {
            this.currentCensus.data = payload;
        },
        setDogData(payload) {
            this.dog = payload;
        },
        setCensusFilterType(payload) {
            this.censusFilterType = payload;
        },
        setDogIdNumber(payload) {
            this.dogIdNumber = payload;
        },
        setCorrectDog(payload) {
            this.correctDog = payload;
        },
        deleteDog(id) {
            if(this.currentCensus?.data?.dogs){
                this.currentCensus.data.dogs = this.currentCensus?.data?.dogs.filter(el => el.id === id)
            }
        },
    },
    getters: {
        loadNextData() {
            return this.config.loadNextData;
        },
        getCensusStep() {
            return this.currentCensus.step;
        },
        getCurrentCensusData() {
            return this.currentCensus.data;
        },
        getCurrentExploitation() {
            return this.currentCensus.selectedExploit;
        },
        getCurrentCensusStatus() {
            return this.currentCensus.status;
        },
        getIsOnline() {
            return this.config.isOnline;
        },
        getUser() {
            return this.user;
        },
        getFullnameUser() {
            return this.user ? `${this.user.firstname} ${this.user.lastname}` : '';
        },
        getInitialsUser() {
            return this.user
                ? `${this.user.firstname.charAt(0)}${this.user.lastname.charAt(0)}`
                : '';
        },
        userIsAdmin() {
            return this.user
                ? this.user.roles && this.user.roles.includes('ROLE_ADMIN')
                : false;
        },
        getCensusFilterType() {
            return this.censusFilterType;
        },
        getDogIdNumber() {
            return this.dogIdNumber;
        },
        getSelectedDog() {
            return this.dog ? this.dog : '';
        },
        getCorrectDog() {
            return this.correctDog;
        },
    },
});
