<script setup>
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  maxLength,
  minLength,
} from "@vuelidate/validators";
import { reactive, computed, ref, watch, onUnmounted, onMounted } from "vue";
import { useRouter } from "vue-router";
import TextInputController from "./TextInputController/TextInputController.vue";
import IconMail from "../icons/IconMail.vue";
import IconPhone from "../icons/IconPhone.vue";
import holdingService from "../../services/holding.service";

const props = defineProps({
  holding: Object,
  readOnly: Boolean,
});
const router = useRouter();
const error = ref();
const controller = ref(new AbortController());
const scope = "AddEditHolding";
const loading = ref(false);
const state = reactive({
  name: props.holding ? props.holding.name : "",
  siretNumber: props.holding ? props.holding.siretNumber : "",
  edeNumber: props.holding ? props.holding.edeNumber : "",
  zipCode: props.holding ? props.holding.zipCode : "",
  city: props.holding ? props.holding.city : "",
  mail: props.holding ? props.holding.mail : "",
  mobile: props.holding ? props.holding.mobile : "",
  address: props.holding ? props.holding.address : "",
  cpNumber: props.holding ? props.holding.dogs.length.toString() : "",
  dogsNumber: "0",
});
const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    siretNumber: {},
    edeNumber: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage(
        "Le champ doit faire 8 caractères minimum",
        minLength(8)
      ),
      maxLength: helpers.withMessage(
        "Le champ doit faire 10 caractères maximum",
        maxLength(10)
      ),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
    city: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    mail: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    address: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    mobile: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
  };
});

const v$ = useVuelidate(rules, state, { $scope: scope });

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};

watch(
  () => state,
  async function () {
    error.value = "";
  }
);

async function submitForm() {
  loading.value = true;
  const body = {
    name: state.name,
    siretNumber: state.siretNumber,
    edeNumber: state.edeNumber,
    zipCode: state.zipCode,
    city: state.city,
    mail: state.mail,
    mobile: state.mobile,
    address: state.address,
  };
  try {
    if (props.holding)
      await holdingService.updateHolding(props.holding.id, body, controller);
    loading.value = false;
    router.push({ path: `/holdings` });
  } catch (e) {
    loading.value = false;
    error.value = "Une erreur est survenue";
    console.log(e);
  }
}

const filterDogsAboveEighteenMonths = () => {
  const aboveEighteenDogsArray = props.holding.dogs.filter((dog) => {
    const today = new Date();
    const birthDate = new Date(dog.birthdate);
    const monthsRef = new Date(1000 * 60 * 60 * 24 * 30 * 18);
    return today.getTime() - birthDate.getTime() < monthsRef.getTime();
  });

  state.dogsNumber = aboveEighteenDogsArray.length.toString();
};

onMounted(() => {
  filterDogsAboveEighteenMonths();
});

onUnmounted(() => {
  controller.value.abort();
});
</script>

<template>
  <form class="form-column" style="max-height: unset">
    <TextInputController
      :on-change="handleChange"
      :name="'edeNumber'"
      :model="state.edeNumber"
      :errors="v$.edeNumber.$errors"
      :on-blur="v$.edeNumber.$touch"
      :label="`N°EDE de l'exploitation`"
      :placeholder="'EDE'"
      :read-only="readOnly"
    />
    <TextInputController
      :on-change="handleChange"
      :name="'siretNumber'"
      :label="'N°SIRET'"
      :model="state.siretNumber"
      :errors="v$.siretNumber.$errors"
      :on-blur="v$.siretNumber.$touch"
      :placeholder="'John'"
      :read-only="readOnly"
    />
    <TextInputController
      :on-change="handleChange"
      :name="'name'"
      :label="`Nom de l'explotation`"
      :model="state.name"
      :errors="v$.name.$errors"
      :on-blur="v$.name.$touch"
      :placeholder="`Nom de l'exploitation`"
      :read-only="readOnly"
    />
    <div class="dual-column-form">
      <TextInputController
        :on-change="handleChange"
        :name="'zipCode'"
        :label="'Code postal'"
        :type="'zipCode'"
        :model="state.zipCode"
        :errors="v$.zipCode.$errors"
        :on-blur="v$.zipCode.$touch"
        :placeholder="'01234'"
        :maxlength="5"
        :read-only="readOnly"
      />
      <TextInputController
        :on-change="handleChange"
        :name="'city'"
        :label="`Ville`"
        :model="state.city"
        :errors="v$.city.$errors"
        :on-blur="v$.city.$touch"
        :placeholder="'Compiègne'"
        :read-only="readOnly"
      />
    </div>
    <TextInputController
      :on-change="handleChange"
      :name="'address'"
      :label="`Adresse`"
      :model="state.address"
      :errors="v$.mail.$errors"
      :on-blur="v$.mail.$touch"
      :placeholder="'10 rue de '"
      :read-only="readOnly"
    />
    <TextInputController
      :on-change="handleChange"
      :name="'mail'"
      :label="`Email`"
      :model="state.mail"
      :errors="v$.mail.$errors"
      :on-blur="v$.mail.$touch"
      :placeholder="'Test@test.de'"
      :read-only="readOnly"
    >
      <template #icon>
        <IconMail />
      </template>
    </TextInputController>
    <TextInputController
      :on-change="handleChange"
      :name="'mobile'"
      :label="'Téléphone'"
      ::type="'phone'"
      :maxlength="10"
      :model="state.mobile"
      :errors="v$.mobile.$errors"
      :on-blur="v$.mobile.$touch"
      :placeholder="'+333 1 23 45 67'"
      :read-only="readOnly"
    >
      <template #icon>
        <IconPhone />
      </template>
    </TextInputController>

    <TextInputController
      custom-class="holding-input"
      :on-change="handleChange"
      name="cpNumber"
      :model="state.cpNumber"
      label="Nombre de CP"
      placeholder=""
      type="number"
      :disabled="props.disabled"
      :read-only="true"
    />

    <TextInputController
      custom-class="holding-input"
      :on-change="handleChange"
      name="dogsNumber"
      :model="state.dogsNumber"
      label="Nombre de chiens < 18 mois"
      placeholder=""
      :disabled="props.disabled"
      :read-only="true"
    />
    <Button
        v-if="!readOnly"
      type="primary"
      :disabled="v$.$invalid || loading"
      @on-click="submitForm()"
    >
      Valider
    </Button>
  </form>
  <div v-if="error">
    {{ error }}
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/css/_all.scss";
button {
  margin: auto;
}
</style>
