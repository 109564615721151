<script setup>
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useStore } from '../../stores';
import censusService from '../../services/census.service';
import Button from '../button/Button.vue';
import LoaderList from '../loaders/LoaderList.vue';
import HoldingsModal from '../holdings-modal/HoldingsModal.vue';
import holdingService from '../../services/holding.service';

const store = useStore();
const controller = ref(new AbortController());
const loading = ref(true);
const censusId = ref(null);
let showSearchHoldingModal = ref(false);
const state = reactive({
  page: 1,
  loading: false,
  exploits: [],
  activeExploitId: null,
  params: '',
  maxSize: null,
});


const clickHandler = (e) => {
  showSearchHoldingModal.value = true;
};

const selectButtonClickHandler = (exploit) => {
  store.setCensusSelectedExploit(exploit);
  store.setCensusStep(2);
};

async function getHoldings(newPage, params) {
  state.loading = true;
  state.page = newPage;
  const res = await holdingService.getFilteredHoldings(newPage, params, controller.value);
  state.exploits = [...state.exploits, ...res['hydra:member']];
  if (newPage === 1) {
    state.maxSize = res['hydra:totalItems'];
  }
  state.loading = false;
}

watch(
    () => state.params,
    (params) => {
      if (!state.loading.value) {
        state.exploits = [];
        getHoldings(1, params);
        showSearchHoldingModal.value = false;
      }
    }
);

onMounted(async () => {
  const census = await censusService.getCensusFiltered( 1 , `order[id]=desc&` , controller)
  censusId.value = census['hydra:member'].length > 0 ? census['hydra:totalItems'] + 1 : 1
  loading.value = false;
});

onUnmounted(() => {
  controller.value.abort();


});
</script>

<template>
  <LoaderList v-if="loading" />
  <HoldingsModal
      :is-visible="showSearchHoldingModal"
      @onClose="showSearchHoldingModal = false"
      @queryParams="(value) => (state.params = value)"
  />
  <div
      v-if="!loading"
      class="main-content"
      :class="showSearchHoldingModal ? 'blured' : ''"
  >
    <h1>{{ `Préparation du recensement n°${censusId}` }}</h1>
    <div class="page-content">
      <Button
          type="secondary-green"
          @on-click="clickHandler"
      >
        Rechercher l'exploitation
      </Button>
      <div
          v-if="state.params && state.exploits.length === 0 && !state.loading"
          class="empty-list"
      >
        Aucun résultat
      </div>
      <div
          v-if="state.loading || state.exploits.length > 0"
          class="search-content"
      >
        <LoaderList v-if="state.loading" />
        <div
            v-if="state.exploits.length > 0 && !state.loading"
            class="exploits-container"
        >
          <div
              v-for="exploit of state.exploits"
              :key="exploit.id"
              class="exploit-item active"
          >
            <div
                class="exploit-header"
                :onclick="
                () => {
                  state.activeExploitId = state.activeExploitId === exploit.id ? null : exploit.id;
                }
              "
            >
              <h3>{{ `${exploit.name} | ${exploit.city}` }}</h3>
            </div>
            <div class="exploit-details">
              <div class="exploit-left">
                <p>{{ exploit.address }}</p>
                <p>{{ exploit.zipCode }}</p>
                <p>{{ exploit.city }}</p>
                <!-- <p><strong> {{ exploit.owner }}</strong></p> -->
                <p>
                  <strong>n° EDE: {{ exploit.edeNumber }}</strong>
                </p>
              </div>
              <div class="exploit-action">
                <Button
                    size="small"
                    type="green"
                    @on-click="selectButtonClickHandler(exploit)"
                >
                  SELECTIONNER
                </Button>
              </div>
            </div>
          </div>
          <Button
              v-if="state.exploits.length !== state.maxSize"
              type="green"
              @on-click="getHoldings(state.page + 1, state.params)"
          >
            VOIR PLUS
          </Button>
        </div>
      </div>
      <Button
          type="secondary-green"
          @on-click="selectButtonClickHandler()"
      >
        Créer une exploitation
      </Button>
      <Button
          @on-click="store.currentCensus.step = 0"
      >
        Retour
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/_variables.scss';

.empty-list {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  position: relative;
  width: 100%;
  height: 70%;
  transition: filter 200ms;

  .page-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .exploit-item {
    .exploit-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $altGreen;
      gap: 10px;
      h3 {
        font-size: 15px;
      }

      img {
        transition: 500ms;
        transform: rotate(90deg);
      }
    }
    .exploit-details {
      border-bottom: 1px solid $altGreen;
      transition: 400ms;
      display: flex;
      height: 0px;
      transform: scaleY(0);
      overflow: hidden;

      > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
      }

      .exploit-left {
        p {
          font-size: 12px;
          margin-block-start: 0.25em;
          margin-block-end: 0.25em;
        }
      }
    }

    &.active {
      img {
        transform: rotate(-90deg);
      }

      .exploit-details {
        height: auto;
        transform: scaleY(1);
        padding: 10px;
      }
    }
  }
}

.blured {
  filter: blur(5px)
}
@media screen and (min-width: 1281px) {
  .main-content {
    max-width: 60vw;
  }
}
</style>
