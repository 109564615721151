<script setup>
import { useStore } from '../../stores';
import {computed, ref} from 'vue';

const store = useStore();
const isClosed = ref(false)
const isOffline = computed(() => !store.getIsOnline);
const emit = defineEmits(['onClose'])
</script>

<template>
  <Transition>
    <div
        v-if="isOffline"
        class="offline"
        :class="isClosed ? 'closed-tooltip' : ''"
    >
      <div
          class="close"
          @click="isClosed = true"
      >
        X
      </div>
      <p>Attention ! Vous avez perdu votre connexion internet, tout changement ne sera pas enregistré dans l'immédiat.</p>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.offline{
  position: fixed;
  background-color: $secondary;
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
  z-index: 10;

  p{
    margin-top: 0;
    font-family: 'NunitoSans';
  }
}

.closed-tooltip {
  transform: scale(0);
}

.close {
  text-align: end;
}

@media screen and (max-width: 1280px)
{
  .offline{
    width: 100%;
    left: 20px;
    width: calc(100% - 80px);
    bottom: 10px;
    z-index: 10;
  }
}

@media screen and (min-width: 1281px)
{
  .offline{
    left: 330px;
    top: 10px;
    width: calc(100% - 400px);
    z-index: 10;
  }
}
</style>