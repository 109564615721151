<script setup>
import { reactive } from "vue";

const state = reactive({
  show: false,
});
const props = defineProps({
  text: String,
  secondText: String,
  thirdText: String,
  fourthText: String,
  customClass: String,
});

function getFillColor(isBg = false) {
  if (props.customClass.includes("holding-input")) {
    return "#EC9A29";
  } else if (props.customClass.includes("master-input")) {
    return "#0086C9";
  } else if (props.customClass.includes("dog-input")) {
    return "#01696b";
    // return "#0F8B8D";
  } else if (props.customClass.includes("holding-head")) {
    return isBg ? "#01696b" : "#fff";
  }

  return "#659F12";
}
</script>

<template>
  <div class="icon" @click="state.show = !state.show">
    <IconTooltip :fill="getFillColor()" />
  </div>
  <div
    v-if="state.show"
    :class="'tooltip ' + props.customClass"
    :style="{ backgroundColor: getFillColor(true) }"
    @click="state.show = !state.show"
  >
    <p>{{ props.text }}</p>
    <p>{{ props.secondText }}</p>
    <p>{{ props.thirdText }}</p>
    <p>{{ props.fourthText }}</p>
  </div>
</template>
<style lang="scss" scoped>
.icon {
  padding: 14px 10px;
  cursor: pointer;
  z-index: 0;
  display: flex;
  align-items: center;
}

.tooltip {
  position: absolute;
  width: 96%;
  padding: 14px;
  background-color: #ec9a29;
  z-index: 2;
  margin-top: 56px;
  color: white;
  font-family: "NunitoSans";
  border-radius: 10px;
  &.holding-head {
    top: 20px;
    left: 0;
    width: 97.5%;
  }
}
</style>
