<script setup>
import axios from 'axios'
import {ref, onMounted} from 'vue'
import ElementDeleteModal from "../../../../views/components/modal/delete/ElementDeleteModal.vue";
import HoldingDeleteCensus from "../../../../views/components/modal/delete/holding/HoldingDeleteCensus.vue";
import {createToaster} from "@meforma/vue-toaster";
import useHoldingStore from "../../../../stores/useHoldingStore";
import useCensusStore from "../../../../stores/useCensusStore";
import useDogStore from "../../../../stores/useDogStore";

const holdingStore = useHoldingStore()
const censusStore = useCensusStore()
const dogStore = useDogStore()

const holding = ref(null)
const show = ref(false)
const loading = ref(true)
const modal = ref(null)

const props = defineProps({ holdingId: Number })
const emit = defineEmits(['deleted', 'error'])
const toaster = createToaster({ position: "bottom" });

const fetchHolding = async () => {
  loading.value = true
  holding.value = (await axios.get(`${process.env.VUE_APP_API_URL}exploitations/${props.holdingId}`)).data
  loading.value = false
}
const handleDelete = async () => {
  loading.value = true
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API_URL}exploitations/${props.holdingId}`)
    if (response.status === 204) {
      toaster.success("L'exploitation a bien été supprimée")
      if (holding.value) {
        censusStore.deleteItem(holding.value.id)

        if(holding.value.censuses?.length > 0) {
          censusStore.deleteItems(holding.value.censuses.map(census => census.id ?? census.split('/').pop()))

          dogStore.deleteItems(holding.value.dogs.map(dog => dog.id ?? dog.split('/').pop()))
        }
      }
      emit('deleted')
      modal.value.show = false;
    } else {
      emit('error', response)
      toaster.error("Une erreur est survenue lors de la suppression de l'exploitation")
    }
  } catch (e) {
    emit('error', e)
    toaster.error("Une erreur est survenue lors de la suppression de l'exploitation")
  }
  loading.value = false
  emit('deleted')
}

onMounted(() => {
  fetchHolding();
})
</script>

<template>
  <ElementDeleteModal
      :loading="loading"
      :title="`Suppression de l'exploitation n°${holding?.id} - ${holding?.name}`"
      :description="(holding?.censuses ?? []).length ? 'Les éléments suivants seront également supprimés :' : ''"
      @delete="handleDelete"
      ref="modal"
  >
    <div class="tw-space-y-2 tw-divide-y tw-divide-gray-900/10" >
      <HoldingDeleteCensus
          v-for="census in holding?.censuses ?? []"
          :key="census"
          :census-id="census.split('/').pop()"
        />
    </div>
  </ElementDeleteModal>
</template>