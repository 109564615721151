export function getParamsFilters(params) {
  let res = "";
  for (const [key, value] of Object.entries(params)) {
    if (key === "date") {
      const date =
        new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(value) + "T00:00:00";
      const seconDate =
        new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(value) + "T23:59:60";
      res += value
        ? `&censuses.date[after]=${date}&censuses.date[before]=${seconDate}`
        : "";
    }
    res += value ? `&${key}=${value}` : "";
  }
  return res;
}

export function getRecensementParamsFilters(params) {
  let res = "";
  for (let [key, value] of Object.entries(params)) {
    let replacedKey = key;
    res += value
      ? `&${
          replacedKey === "lastname"
            ? (replacedKey = "person")
            : (replacedKey = "exploitations")
        }.${key}=${value}`
      : "";
  }
  return res;
}

export function getRecensementParamsWithDateFilters(params) {
  let res = "";
  for (let [key, value] of Object.entries(params)) {
    let replacedKey = key;
    if (!key.includes("date")) {
      res += value
        ? `&${
            replacedKey === "lastname"
              ? (replacedKey = "person")
              : (key === "identificationNum") || (key === "breed")
              ? (replacedKey = "dogs")
              : (replacedKey = "exploitation")
          }.${key}=${value}`
        : "";
    } else {
      const date =
        new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(value) + "T00:00:00";
      const seconDate =
        new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(value) + "T23:59:60";
      if (key === "dateEnd" || key === "dateStart") {
        res += value
          ? `&${
              key === "dateEnd" ? (key = "date[before]") : (key = "date[after]")
            }=${date}`
          : "";
      } else if (key === "date") {
        res += value ? `&date[after]=${date}&date[before]=${seconDate}` : "";
      }
    }
  }
  return res;
}

export function getDogsParamsFilters(params) {
  let res = "";
  for (let [key, value] of Object.entries(params)) {
    let replacedKey = key;
    if (key === "edeNumber" || key === "zipCode") {
      res += value ? `&exploitation.${replacedKey}=${value}` : "";
    } else {
      res += value
        ? `&${replacedKey}${
            replacedKey === "master" || replacedKey === "census"
              ? `.${key === "master" ? (key = "firstname") : (key = "status")}`
              : ""
          }=${value}`
        : "";
    }
  }
  return res;
}
