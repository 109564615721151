class CacheHelper {
  addRecensements(dataPaginated) {
    let dataPaginatedCached = this.getRecensements();
    const dateExpirationCache = new Date();
    dateExpirationCache.setDate(dateExpirationCache.getDate() + 7);
    dataPaginated["hydra:member"].map((recensement) => {
      const alreadyExists = dataPaginatedCached["hydra:member"].find(
        (item) => recensement.id == item.id
      );
      recensement.expiration_date_cache = dateExpirationCache;
      if (!alreadyExists) {
        dataPaginatedCached["hydra:member"] = [
          ...dataPaginatedCached["hydra:member"],
          recensement,
        ];
      } else {
        const index = dataPaginatedCached["hydra:member"].findIndex(
          (item) => item.id == recensement.id
        );
        if (index != -1) {
          dataPaginatedCached["hydra:member"].splice(index, 1);
          dataPaginatedCached["hydra:member"] = [
            ...dataPaginatedCached["hydra:member"],
            recensement,
          ];
        }
      }
    });

    localStorage.setItem(
      "recensements",
      JSON.stringify(dataPaginatedCached["hydra:member"])
    );
  }

  updateRecensement(recensementId, content) {
    const existing = this.getRecensementById(recensementId);
    if (existing) {
      let dataPaginatedCached = this.getRecensements();
      dataPaginatedCached = dataPaginatedCached["hydra:member"].map(
        (recensement) => {
          if (recensement.id === content.id) {
            recensement = content;
          }

          return recensement;
        }
      );

      localStorage.setItem("recensements", JSON.stringify(dataPaginatedCached));
    }
  }

  getRecensements(status) {
    const recensements = localStorage.getItem("recensements");

    return recensements
      ? {
          "hydra:member": !status
            ? JSON.parse(recensements)
            : status === "to_validate"
            ? JSON.parse(recensements).filter((item) =>
                ["to_validate", "running"].includes(item.status)
              )
            : JSON.parse(recensements).filter((item) => item.status == status),
          "hydra:totalItems": !status
            ? JSON.parse(recensements).length
            : JSON.parse(recensements).filter((item) => item.status == status)
                .length,
        }
      : {
          "hydra:member": [],
          "hydra:totalItems": 0,
        };
  }

  getRecensementById(id) {
    const recensementsString = localStorage.getItem("recensements");
    if (recensementsString) {
      const recensements = JSON.parse(recensementsString);
      return recensements.find((recensement) => recensement.id == id);
    }

    return null;
  }

  getDogMasterList() {
    const dogMasterString = localStorage.getItem("dogMaster");
    if (dogMasterString) {
      const dogMaster = JSON.parse(dogMasterString);
      return dogMaster;
    }

    return null;
  }

  getAllCensusesToSync() {
    const censusesToSyncString = localStorage.getItem("censuses_to_sync");
    return JSON.parse(censusesToSyncString);
  }

  getCensusToSync(id) {
    const censusesToSyncString = localStorage.getItem("censuses_to_sync");

    if (censusesToSyncString) {
      const censuses = JSON.parse(censusesToSyncString);

      return censuses.find((census) => census.census.id == id);
    }

    return null;
  }

  addCensusToSync(data) {
    let censusesToSync = this.getAllCensusesToSync()
      ? this.getAllCensusesToSync()
      : [];
    const index = censusesToSync.findIndex((census) => {
      return census.census.id == data.census.id;
    });
    if (index != -1) {
      censusesToSync.splice(index, 1);
    }

    data.census.status = "to_sync";
    censusesToSync = [...censusesToSync, data];
    localStorage.setItem("censuses_to_sync", JSON.stringify(censusesToSync));

    this.updateRecensement(data.census.id, data.census);
  }

  removeOldRecensements() {
    const censuses = this.getRecensements()
      ? this.getRecensements()["hydra:member"]
      : [];
    const clone = structuredClone(censuses);
    if (censuses.length) {
      for (let i = censuses.length - 1; i >= 0; i--) {
        if (new Date(censuses[i].expiration_date_cache) < new Date()) {
          clone.splice(i, 1);
        }
      }
    }
    localStorage.setItem("recensements", JSON.stringify(clone));
  }
}

export default new CacheHelper();
