import axios from "axios";

class AuthService {
  login(body) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}auth/login`, body)
      .then((response) => {
        /*if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }*/
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        return response.data;
      });
  }

  confirmLogin(token, codeLogin) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}auth/${token}/confirm_login`, {
        codeLogin,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        return response.data;
      });
  }

  forgottenPassword(body) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}auth/forget-password`, body)
      .then((response) => {
        return response.data;
      });
  }

  changePassword(token, body) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}auth/${token}/change-password`, body)
      .then((response) => {
        return response.data;
      });
  }

  me() {
    if (navigator.onLine) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}auth/me`)
        .then((response) => {
          const user = response.data;
          //TMP
          //user.roles = ['ROLE_ADMIN', 'ROLE_USER'];
          //user.roles = ['ROLE_USER'];
          localStorage.setItem("user", JSON.stringify(response.data));

          return user;
        });
    } else {
      const user = localStorage.getItem("user");

      return user ? JSON.parse(user) : null;
    }
  }

  validateAccount(token, body) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}auth/${token}/define-password`, body)
      .then((response) => response.data);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  logout() {
    localStorage.removeItem("token");
  }
}
export default new AuthService();
