<template>
  <div class="container">
    <div
        class="dog-head"
        :class="{blured : showDogsDropdown}"
    >
      <h1>Liste des chiens de protection</h1>
      <IconButton
          :icon="iconSearch"
          class="primary"
          @on-click="showDogsDropdown = !showDogsDropdown"
      />
    </div>

    <RequiredOnlineMode v-if="isOffline && state.users.length === 0"/>
    <LoaderList v-if="loading && state.dogs.length === 0"/>

    <div
        v-if="state.isFiltered && !loading"
        class="dog-filtered-title"
    >
      Résultats de recherche
    </div>
    <div
        class="dog"
        :class="{blured : showDogsDropdown}"
    >
      <div
          v-if="state.dogs.length === 0 && !loading"
          class="empty-dog"
      >
        Aucun chien trouvé
      </div>

      <DogListComponent
          :dogs="state.dogs"
          @select="handleSelect"
      />
    </div>
  </div>

  <DogModal
      :is-visible="showDogsDropdown"
      @onClose="showDogsDropdown = false"
      @queryParams="(value) => state.params = value"
  />
  <Button
      v-if="maxSize !== state.dogs.length"
      class="btn-more"
      @click="showMore()"
  >
    Voir plus
  </Button>
  <Button
      v-if="state.isFiltered && !loading"
      class="search-btn"
      @click="getDogs(1); state.dogs = []; state.isFiltered = false;"
  >
    RETOUR AU LISTING
  </Button>
  <LoaderItem v-if="loading && state.dogs.length !== 0"/>
</template>

<script setup>

import DogsService from "../../../services/dogs.service";
import {useRouter, useRoute} from "vue-router";
import {computed, reactive, ref, watch, nextTick, onMounted} from 'vue';
import {useStore} from "../../../stores";
import iconSearch from '../../../assets/images/icon-search.svg';
import DogModal from "../../../components/dog-modal/dog-modal";
import DogListComponent from "../../../views/components/DogListComponent.vue";
import useDogStore from "../../../stores/useDogStore";

const dogStore = useDogStore()

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const state = reactive({
      dogs: [],
      breeds: [],
      params: '',
      exploitParams: '',
      isFiltered: false,
    }
);
const page = ref(0);
const maxSize = ref(0);
const store = useStore();
const controller = ref(new AbortController());
const isOffline = computed(() => !store.getIsOnline);
let showDogsDropdown = ref(false);

onMounted(() => {
  if (dogStore.items.length) {
    state.dogs = dogStore.items;
    page.value = dogStore.page;

    if (dogStore.lastItemId) {
      nextTick(() => {
        const element = document.querySelector('#dog-' + dogStore.lastItemId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
            element.focus();
          }, 150);
        }
      });
    }

    return
  }

  if (!isOffline.value) {
    const searchParams = localStorage.getItem('dogsSearchQuery')
    if (route.query.history && searchParams !== '' && searchParams !== 'none') {
      state.dogs = [];
      state.isFiltered = true;
      getDogs(1, searchParams);
    } else {
      getDogs(1);
    }
  }
})

watch(() => store.loadNextData, async function () {
  if (store.loadNextData === true && maxSize.value !== state.dogs.length) {
    await getDogs(page.value + 1);
    store.setScroll(false);
  }
});

watch(() => store.getIsOnline, function (params) {
  if (!isOffline.value && !loading.value && !state.dogs.length) {
    getDogs(1, params);
  }
});

watch(() => state.params, (params) => {
      if (!isOffline.value && !loading.value) {
        state.dogs = [];
        state.isFiltered = true;
        getDogs(1, params);
        showDogsDropdown.value = false;
      }
    }
)

watch(() => state.exploitParams, (params) => {
      if (!isOffline.value && !loading.value) {
        state.dogs = [];
        state.isFiltered = true;
        getDogs(1, params);
        showDogsDropdown.value = false;
      }
    }
)

async function showMore() {
  await getDogs(page.value + 1);
}

async function getDogs(newPage, params) {
  localStorage.setItem('dogsSearchQuery', params ?? 'none');

  loading.value = true;
  page.value = newPage;
  const res = await DogsService.getDogs(newPage, params, controller.value);
  state.dogs = [...state.dogs, ...res['hydra:member']];
  if (newPage === 1) {
    maxSize.value = res['hydra:totalItems'];
  }

  dogStore.items = state.dogs;
  dogStore.page = page.value;

  loading.value = false;
}

function goToDogsDetails(dog) {
  router.push({path: `/dogs/${dog.id}`});
}

const handleSelect = (id) => {
  dogStore.lastItemId = id;
  router.push({path: `/dogs/${id}`, params: {history: true}})
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.container {
  position: relative;
}

h1 {
  font-size: 25px;
  text-align: center;
}

.dog-filtered-title {
  font-weight: 900;
  font-size: 23px;
  color: $green;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.dog-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: filter 100ms;

  img {
    height: 50%;
    padding: 0.5em;
    border-radius: 12px;
    background-color: $altGreen;
  }
}

.dog {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dog-row {
  border: 1px solid $altGreen;
  border-radius: 20px;
  padding: 0.5em 0.7em;
  display: flex;
  align-items: center;
  //gap: 2em;
  justify-content: space-between;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    border: 1px solid rgba(101, 159, 18, 0.3);
    box-shadow: 0 2px 15px 0 rgba(101, 159, 18, 0.3);
  }


  &-title {
    font-weight: bold;
    display: flex;
    line-height: 15px;
    align-items: center;
    gap: 1em;

    &-name {
      text-align: start;
      min-width: 950px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-sex {
      text-align: center;
      font-weight: 500;
      width: 70px;
      color: #667085;
    }

    &-birthdate {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $altGreen;
      padding: 0.7em 0.8em;
      border-radius: 16px;
      color: $green;
      font-size: 16px;
      width: 100%;
      height: 20px;
      font-weight: 400;

    }
  }

  &-img {
    background-color: $sand;
    padding: 0.7em 1em;
    border-radius: 16px;
    display: flex;
    align-items: center;

  }

}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659F12;
  color: white;
  border-radius: 14px;
  margin-top: 1.5em !important;
}

.blured {
  filter: blur(4px)
}

.empty-dog {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-more {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659F12;
  color: white;
  border-radius: 20px;
  margin-top: 0.5em !important;
}

@media screen and (max-width: 650px) {
  .dog-row-title-name {
    font-weight: bold;
    max-width: 85px;
    min-width: 85px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }
}

.minor {
  .dog-row-title-birthdate {
    background-color: $altGreen;
    color: $green;
  }
}

.major {
  .dog-row-title-birthdate {
    background-color: $green;
    color: white;
  }
}

</style>