<script setup>
import UserFormTemplate from '../../../components/form/UserFormTemplate.vue';
import userService from '../../../services/user.service';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import authService from '../../../services/auth.service';
import IconArrowLeft from '../../../components/icons/IconArrowLeft.vue';

const route = useRoute();
const loading = ref(true);
const state = reactive({ user: null });
const controller = ref(new AbortController());

onMounted(async () => {
  if (route.params.userId) {
    const res = await userService.getUserById(route.params.userId, controller.value);

    state.user = res;
  } else if(route.name == 'Me'){
    const res = await authService.me()

    state.user = res;
  }
  loading.value = false;
});

onUnmounted(() => {
  controller.value.abort();
});
</script>
<template>
  <div class="content">
    <div
      v-on:click="
        () => {
          if (state.mode === 'add') {
            showForm = false;
            state.result = null;
            return;
          }
          $router.push({ path: `/users` });
        }
      "
      class="nav-button"
    >
      <IconArrowLeft /> <span>Retour</span>
    </div>
    <h1>Compte Utilisateur</h1>
    <h2>Données personnelles</h2>
    <LoaderList v-if="loading" />
    <UserFormTemplate v-if="!loading" :key="state.user" :user="state.user" />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/all';
.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}
  .content {
    padding-top: 15px;
  }
</style>
