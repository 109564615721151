<script>
export default {
    props: {
        icon: String,
        color: {
            type: String,
        },
        canClick: {
            type: Boolean,
            default: true,
        },
        width : String,
        height : String,
    },
    emits: ['onClick']
}
</script>
<template>
  <div
    :style="`width:${width ?? '45px'}; height:${height ?? '45px'}`"
    :class="{color: color, 'is-clickable' : canClick }"
    @click="canClick ? $emit('onClick') : null"
  >
    <img 
      :src="icon" 
    >
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

div{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.3s;
}
.is-clickable:hover{
    opacity: 0.6;
}

.primary{
    background-color: $altGreen
}

.secondary{
    background-color: $secondaryLight;
}
.blue{
    background-color: $blue-light;
}

.is-clickable{
    cursor: pointer;
}

.download-icon{
    img{
        width: 20px;
        margin: 10px;
    }
}

</style>