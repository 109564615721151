<script setup>
import { useStore } from '../../stores';
import iconCensusGreen from '../../assets/images/icon-census-green.svg';
import Button from '../button/Button.vue';
const store = useStore()
</script>

<template>
  <div class="main-content">
    <div class="content-block">
      <div class="icon-button-bloc">
        <IconButton
            :width="'75px'"
            :height="'75px'"
            :icon="iconCensusGreen"
            :can-click="false"
            class="secondary"
            @on-click="
            showDropdownUser = false;
            showDropdownNav = !showDropdownNav;
          "
        />
      </div>
      <h1>Commencer un nouveau recensement</h1>
      <Button
          type="green"
          @on-click="() => {store.setCensusStep(1), store.setCensusStatus('scheduled')}"
      >
        Préparation de recensement
      </Button>
      <Button
          type="secondary-green"
          @on-click="() => {store.setCensusStep(1), store.setCensusStatus('running')}"
      >
        Lancement du recensement
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/_variables.scss';

h1 {
  font-size: 26px;
}

.main-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .content-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    border-radius: 30px;
    gap: 10px;
    -webkit-box-shadow: 0px 0px 29px -9px $green;
    box-shadow: 0px 0px 29px -9px $green;
  }
}
@media screen and (min-width: 1281px) {
  .main-content {
    max-width: 60vw;
    margin:auto;
    justify-content: center;
  }
}
</style>
