<script setup>
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  maxLength,
  minLength,
  requiredIf,
  requiredUnless,
} from "@vuelidate/validators";
import { reactive, computed, ref, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import TextInputController from "./TextInputController/TextInputController.vue";
import IconMail from "../icons/IconMail.vue";
import IconPhone from "../icons/IconPhone.vue";
import holdingService from "../../services/holding.service";
import DatePickerController from "./DatePickerController/DatePickerController.vue";
import ConfirmAbord from "../recensement-modals/ConfirmAbord.vue";
import { useStore } from "../../stores";
import censusService from "../../services/census.service";
import { validateHoldingDontExist } from "../../helpers/validators";
import peopleService from "../../services/people.service";

const props = defineProps({ holding: Object });
const router = useRouter();
const store = useStore();
const error = ref();
const controller = ref(new AbortController());
const scope = "AddEditHolding";
const loading = ref(false);
const state = reactive({
  name: props.holding ? props.holding.name : "",
  siretNumber: props.holding ? props.holding.siretNumber : "",
  edeNumber: props.holding?.edeNumber ? props.holding.edeNumber : "",
  zipCode: props.holding ? props.holding.zipCode : "",
  city: props.holding ? props.holding.city : "",
  mail: props.holding ? props.holding.mail : "",
  mobile: props.holding ? props.holding.mobile : "",
  address: props.holding ? props.holding.address : "",
  date: "",
});

const datePickerFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

watch(state, async function (value) {
  error.value = "";
});

const checkIfEmpty = (value) => value.length > 0;

const rules = computed(() => {
  return {
    edeNumber: {
      minLength: helpers.withMessage(
        "Le numéro EDE doit comporter minimum 8 caractères !",
        minLength(8)
      ),
      maxLength: helpers.withMessage(
        "Le numéro EDE doit comporter maximum 10 caractères !",
        maxLength(10)
      ),
      requiredIf: helpers.withMessage(
        "Ce champ ou le numéro SIRET sont requis !",
        requiredIf(state.siretNumber.length === 0)
      ),
    },
    siretNumber: {
      minLength: helpers.withMessage(
        "Le numéro SIRET doit comporter 14 caractères !",
        minLength(14)
      ),
      maxLength: helpers.withMessage(
        "Le numéro SIRET doit comporter 14 caractères !",
        maxLength(14)
      ),
      requiredIf: helpers.withMessage(
        "Ce champ ou le numéro EDE sont requis !",
        requiredIf(state.edeNumber.length === 0)
      ),
    },
    name: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
    city: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    mobile: {
      required: helpers.withMessage(
        "Ce champ ou le champ mail sont requis",
        requiredUnless(() => {
          return state.mail !== "";
        })
      ),
    },
    date: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    mail: {
      mail: helpers.withMessage("Le champ n'est pas du format email !", email),
      required: helpers.withMessage("Le champ est requis !", required),
    },
  };
});

const v$ = useVuelidate(rules, state, { $scope: scope });

const handleChangeWithValidate = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};

const handleChange = (value, name) => {
  state[name] = value;
};
const storedDog = computed(() => store.getSelectedDog);

async function submitForm() {
  loading.value = true;

  try {
    let holding = props.holding;
    const body = {
      name: state.name,
      siretNumber: state.siretNumber,
      edeNumber: state.edeNumber === "" ? null : state.edeNumber,
      zipCode: state.zipCode,
      city: state.city === "" ? null : state.city,
      mail: state.mail === "" ? null : state.mail,
      mobile: state.mobile === "" ? null : state.mobile,
      address: state.address === "" ? null : state.address,
    };
    if (!holding) {
      if (
          !!state.edeNumber && !(await validateHoldingDontExist(state.edeNumber))
      ) {
        error.value =
          "Ce numéro d’EDE existe déjà, veuillez rechercher l’exploitation existante";
        loading.value = false;
        return false;
      }
      holding = await holdingService.createHolding(body, controller);
      const createdOwner = await peopleService.create(
        {
          firstname: null,
          lastname: null,
          companyname: state.name,
          address: state.address,
          zipCode: state.zipCode,
          city: state.city,
          email: state.mail,
          phoneNumber: state.mobile,
          exploitation: `/api/exploitations/${holding.id}`,
        }
      );
      await holdingService.updateHolding(
        holding.id,
        { owner: `/api/people/${createdOwner.id}` },
        controller
      );
    }
    const censusBody = {
      date: state.date,
      status: store.getCurrentCensusStatus,
      exploitation: holding["@id"],
    };
    const census = await censusService.createCensus(censusBody, controller);
    await holdingService.updateHolding(holding.id, body, controller);
    if (storedDog.value) {
      await censusService.updateCensus(
        census.id,
        { dogs: [storedDog.value["@id"]] },
        controller
      );
    }
    loading.value = false;
    store.setCensusData(census);
    router.push({ path: `/census/${census.id}/dogs` });
  } catch (e) {
    loading.value = false;
    error.value = "Une erreur est survenue";
    console.log(e);
  }
}

onUnmounted(() => {
  controller.value.abort();
});
</script>

<template>
  <form class="form-column">
    <TextInputController
      :on-change="handleChangeWithValidate"
      :name="'edeNumber'"
      :model="state.edeNumber"
      :errors="v$.edeNumber.$errors"
      :on-blur="v$.edeNumber.$touch"
      :label="`N°EDE de l'exploitation* (si SIRET non rempli)`"
    />
    <TextInputController
      :on-change="handleChangeWithValidate"
      :name="'siretNumber'"
      :label="'N°SIRET* (si EDE non rempli)'"
      :model="state.siretNumber"
      :errors="v$.siretNumber.$errors"
      :on-blur="v$.siretNumber.$touch"
    />
    <TextInputController
      :on-change="handleChangeWithValidate"
      :name="'name'"
      :label="`Nom de l'exploitation*`"
      :model="state.name"
      :errors="v$.name.$errors"
      :on-blur="v$.name.$touch"
    />
    <div class="dual-column-form">
      <TextInputController
        :on-change="handleChangeWithValidate"
        :name="'zipCode'"
        :type="'zipCode'"
        :label="'Code postal*'"
        :model="state.zipCode"
        :errors="v$.zipCode.$errors"
        :on-blur="v$.zipCode.$touch"
        :maxlength="5"
      />
      <TextInputController
        :on-change="handleChangeWithValidate"
        :name="'city'"
        :label="`Ville*`"
        :model="state.city"
        :errors="v$.city.$errors"
        :on-blur="v$.city.$touch"
      />
    </div>
    <TextInputController
      :on-change="handleChange"
      :name="'address'"
      :label="`Adresse`"
      :model="state.address"
    />
    <TextInputController
      :on-change="handleChange"
      :name="'mail'"
      :label="`Email`"
      :model="state.mail"
      :errors="v$.mail.$errors"
      :on-blur="v$.mail.$touch"
    >
      <template #icon>
        <IconMail />
      </template>
    </TextInputController>
    <TextInputController
      :on-change="handleChange"
      :name="'mobile'"
      :label="'Téléphone'"
      :type="'phone'"
      :maxlength="10"
      :model="state.mobile"
      :errors="v$.mobile.$errors"
      :on-blur="v$.mobile.$touch"
    >
      <template #icon>
        <IconPhone />
      </template>
    </TextInputController>

    <DatePickerController
      custom-class="green-input"
      label="Date du recensement*"
      name="date"
      :errors="v$.date.$errors"
      :on-blur="v$.date.$touch"
      :model="state.date"
      :on-change="handleChange"
      :timepicker="true"
    />

    <div v-if="error">
      {{ error }}
    </div>

    <div class="dual-column-form">
      <confirm-abord :disabled="loading" />

      <Button
          type="primary"
          :disabled="v$.$invalid || loading"
          @on-click="submitForm()"
      >
        Valider
      </Button>
    </div>
  </form>
</template>
<style lang="scss" scoped>
@import "../../assets/css/_all.scss";
button {
  margin: auto;
}
</style>
