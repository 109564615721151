<script setup>
import { computed, onUnmounted, reactive, ref } from "vue";
import HoldingService from "../../../../services/holding.service";
import RecensementService from "../../../../services/recensement.service";
import SynchronizerCensusHelper from "../../../../helpers/synchronizerCensusHelper";
import { useStore } from '../../../../stores';
import { createToaster } from "@meforma/vue-toaster";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  required,

} from "@vuelidate/validators";

const props = defineProps({
  holding: Object,
  masters: Array,
  isOffline: Boolean,
  censusId: Number,
  disabled: Boolean,
  censusProgression: String,
  showModal: Boolean,
  dogs: Array,
  censusUserId: Number,
  census: Object,
});

const controller = ref(new AbortController());
const error = ref();
const state = {
  aboveEighteenDogs: 0,
  leave: false,
};
const store = useStore();
const emit = defineEmits(["showModal"]);
const toaster = createToaster({ position: "bottom" });

const masterOwner = computed(() => {
  if (props.holding && props.holding.owner) {
    return props.masters.find((master) => master.id == props.holding.owner.id);
  }
  return null;
});

const form = reactive({
  edeNumber: props.holding ? props.holding.edeNumber : "",
  siretNumber: props.holding ? props.holding.siretNumber : "",
  name: props.holding ? props.holding.name : "",
  zipCode: props.holding ? props.holding.zipCode : "",
  address: props.holding ? props.holding.address : "",
  city: props.holding ? props.holding.city : "",
  mail: props.holding ? props.holding.mail : "",
  mobile: props.holding ? props.holding.mobile : "",
});
const formOwner = reactive({
  firstname:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.firstname
          : "",
  lastname:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.lastname
          : "",
  address:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.address
          : "",
  zipCode:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.zipCode
          : "",
  city:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.city
          : "",
  email:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.email
          : "",
  phoneNumber:
      !masterOwner.value && props.holding && props.holding.owner
          ? props.holding.owner.phoneNumber
          : "",
  exploitation: `/api/exploitations/${props.holding.id}`,
});

const handleChange = (value, name) => {
  form[name] =
      name === "nbPersons" || name === "cpNumber" ? parseInt(value) : value;
  store.setCensusSelectedExploit(null);
  setTimeout(() => {
    store.setCensusSelectedExploit(form);
  }, 150);
};

const rules = computed(() => {
  return {
    mail: {
      required: helpers.withMessage("Le champ est requis !", required),
      mail: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
  };
});

const v$ = useVuelidate(rules, form);

const currentUser = computed(() => store.getUser);

const isUserAdmin = computed(() => store.userIsAdmin);

const isSameUser = computed(() => {
  return props.censusUserId === currentUser.value.id;
});

async function submitForm() {
  try {
    if (!props.isOffline) {
      await HoldingService.updateHolding(props.holding.id, form, controller);
      await RecensementService.upgradeIfComplete(props.censusId, controller);
      state.leave !== true &&
      toaster.success("Formulaire correctement enregistré");
    } else {
      const syncService = new SynchronizerCensusHelper(props.censusId);
      syncService.updateHolding(props.holding.id, form, formOwner);
      syncService.persistInCache();
      toaster.info("Données mises en cache, prêtes à être synchronisées");
    }
  } catch (e) {
    state.leave !== true && toaster.error("Une erreur est survenue");
  }
}

onUnmounted(() => {
  state.leave = true;
  submitForm();
});
</script>
<template>
  <form @submit.prevent="submitForm()">
    <div class="container-form">
      <TitleLine title="Identité de l'exploitation" />

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'name'"
          :model="form.name"
          label="Nom de l'exploitation"
          placeholder=""
          :disabled="props.disabled"
      />

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'edeNumber'"
          :model="form.edeNumber"
          label="N° EDE de l'exploitation"
          placeholder=""
          :disabled="props.disabled"
      />

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'siretNumber'"
          :model="form.siretNumber"
          label="N° SIRET"
          placeholder=""
          :disabled="props.disabled"
      />

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'Adresse'"
          :model="form.address"
          label="Adresse"
          placeholder=""
          :disabled="props.disabled"
      />

      <FormLine>
        <TextInputController
            custom-class="holding-input"
            :read-only="!isSameUser && !isUserAdmin"
            :on-change="handleChange"
            :name="'zipCode'"
            :type="'zipCode'"
            :model="form.zipCode"
            label="Code postal"
            placeholder=""
            :disabled="props.disabled"
            :maxlength="5"
        />

        <TextInputController
            custom-class="holding-input"
            :read-only="!isSameUser && !isUserAdmin"
            :on-change="handleChange"
            :name="'city'"
            :model="form.city"
            label="Ville"
            placeholder=""
            :disabled="props.disabled"
        />
      </FormLine>

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'mail'"
          :model="form.mail"
          label="Mail"
          :errors="v$.mail.$errors"
          :on-blur="v$.mail.$touch"
          placeholder=""
          :disabled="props.disabled"
      />

      <TextInputController
          custom-class="holding-input"
          :read-only="!isSameUser && !isUserAdmin"
          :on-change="handleChange"
          :name="'mobile'"
          :type="'phone'"
          :model="form.mobile"
          label="Téléphone"
          :maxlength="10"
          placeholder=""
          :disabled="props.disabled"
      />
    </div>

    <p
        v-if="isUserAdmin && !isSameUser"
        class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}</p>


    <div class="tw-flex tw-flex-col"
         v-if="isSameUser || isUserAdmin"
    >
      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-5">

        <Button
            v-if="!props.disabled"
            class="search-btn"
            @on-click="submitForm()"
        >
          ENREGISTRER LES INFORMATIONS
        </Button>

        <Button
            v-if="!props.disabled && props.census.status !== 'completed'"
            class="search-btn"
            :disabled="censusProgression !== '100'"
            @click="emit('showModal')"
        >
          VALIDER LE RECENSEMENT
        </Button>
      </div>
        <span v-if="error" class="error">{{ error }}</span>
    </div>

    <p
        v-else
        class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}, modification non autorisée</p>
  </form>
</template>
<style lang="scss" scoped>
@import "../../../../assets/css/_variables.scss";

.container-form {
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      text-transform: uppercase;
      font-size: 15px;
    }

    .space {
      margin-left: 10px;
      display: flex;
      flex-grow: 1;
      border-bottom: 1px solid black;
    }
  }
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;

  &:last-child {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
}

.blured {
  filter: blur(5px);
  pointer-events: none;
}

form {
  transition: filter 200ms;
}
</style>
