import axios from "axios";
import CacheHelper from "../helpers/cacheHelper";

const dogs = [
  {
    tatou: "",
    etat: "modifiable",
    codins: "1234",
    codEsp: 1,
    dtdeces: null,
    croise: 0,
    race: "MALYNOIS",
    noma: "TYSON",
    sterilise: 0,
    dtnai: "2022-02-28",
    codsexe: "M",
    robe: "FAUVE CHARBONNE",
    poil: "",
    mordeur: false,
    codRace: "",
    nomNai: "",
    signesPar: "",
    codCatD: null,
  },
  {
    tatou: "",
    etat: "modifiable",
    codins: "5678",
    codEsp: 1,
    dtdeces: null,
    croise: 0,
    race: "MALYNOIS",
    noma: "TYSON",
    sterilise: 0,
    dtnai: "2022-02-28",
    codsexe: "M",
    robe: "FAUVE CHARBONNE",
    poil: "",
    mordeur: false,
    codRace: "",
    nomNai: "",
    signesPar: "",
    codCatD: null,
  },
  {
    tatou: "",
    etat: "modifiable",
    codins: "9101",
    codEsp: 1,
    dtdeces: null,
    croise: 0,
    race: "MALYNOIS",
    noma: "TYSON",
    sterilise: 0,
    dtnai: "2022-02-28",
    codsexe: "M",
    robe: "FAUVE CHARBONNE",
    poil: "",
    mordeur: false,
    codRace: "",
    nomNai: "",
    signesPar: "",
    codCatD: null,
  },
  {
    tatou: "",
    etat: "modifiable",
    codins: "3910",
    codEsp: 1,
    dtdeces: null,
    croise: 0,
    countryOrigin: "FRANCE",
    race: "MALINOIS",
    noma: "RUBY",
    sterilise: 0,
    dtnai: "2022-02-28",
    codsexe: "F",
    robe: "FAUVE CHARBONNE",
    poil: "",
    mordeur: false,
    codRace: "",
    nomNai: "",
    signesPar: "",
    codCatD: 3,
  },
];

class DogsService {
  getDogs(page = 1, params, controller) {
    return axios
      .get(
        `${process.env.VUE_APP_API_URL}dogs?page=${page}${
          params ? params : ""
        }`,
        { signal: controller.signal }
      )
      .then((response) => {
        return response.data;
      });
  }

  getById(id, controller, censusId = null) {
    id = parseInt(id);
    if (censusId) {
      const cachedValue = CacheHelper.getCensusToSync(censusId);
      if (cachedValue && cachedValue.actions.length) {
        return cachedValue.census?.dogs.find((d) => d.id === id) ?? null;
      }

      if(!navigator.onLine){
        const census = CacheHelper.getRecensementById(censusId)
        if (census?.dogs?.length) {
          return census.dogs.find((d) => d.id === id) ?? null;
        }
      }
    }

    if (!censusId) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}dogs/${id}`, {
          signal: controller.signal,
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  createDog(body, controller) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}dogs`, body, {
        signal: controller.signal,
      })
      .then((response) => {
        return response.data;
      });
  }

  updateDog(id, body, controller) {
    return axios
      .put(`${process.env.VUE_APP_API_URL}dogs/${id}`, body, {
        signal: controller.signal,
      })
      .then((response) => {
        return response.data;
      });
  }

  updateDogEntities(id, body, controller) {
    return axios
      .patch(`${process.env.VUE_APP_API_URL}dogs/${id}`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  update(id, body, controller) {
    return axios
      .put(`${process.env.VUE_APP_API_URL}dogs/${id}`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  getDogExternalInformation(dogIdentifier, controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}dogs/${dogIdentifier}/icad`, {
        signal: controller.signal,
      })
      .then((response) => response.data);
  }

  deleteDog(id, controller) {
    return axios
      .delete(`${process.env.VUE_APP_API_URL}dogs/${id}`, {
        signal: controller.signal,
      })
      .then((response) => response.data);
  }

  getDogImage(id, controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}dogs/${id}/image`, {
        signal: controller.signal,
      })
      .then((response) => {
        return response.data;
      });
  }

  setDogImage(id, file, controller) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}dogs/${id}/image`, file, {
        signal: controller.signal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new DogsService();
