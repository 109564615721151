<script setup>
import { ref, onMounted, computed } from 'vue'

import axios from "../../../libraries/axios";
import {createToaster} from "@meforma/vue-toaster";
import ModalComponent from "../../../views/components/modal/ModalComponent.vue";

const census = ref(null)
const modal = ref(false)
const loading = ref(true)

const props = defineProps({ censusId: Number })
const emit = defineEmits(['success', 'error'])
const toaster = createToaster({ position: "bottom" });

const fetchCensus = async () => {
  loading.value = true
  census.value = (await axios.get(`censuses/${props.censusId}`)).data
  loading.value = false
}
const handleReset = async () => {
  loading.value = true
  try {
    const response = await axios.patch(
        `censuses/${props.censusId}`,
        {
          status: 'running',
          validationDate: null
        }
    )

    if (response.status === 200) {
      toaster.success("Le recensement a bien été redémarré")
      emit('success')
      modal.value = false;
    } else {
      emit('error', response)
      toaster.error("Une erreur est survenue lors de la redémarrage du recensement")
    }
  } catch (e) {
    emit('error', e)
    toaster.error("Une erreur est survenue lors de la redémarrage du recensement")
  }
  loading.value = false
}

onMounted(() => {
  fetchCensus();
})
const title = computed(() => "Edition du recensement n°" + census.value?.id + " - " + new Date(census.value?.date).toLocaleDateString() + " créé par " + census.value?.person.firstname + ' ' + census.value?.person.lastname)
</script>

<template>
  <ModalComponent
      v-model="modal"
      :title="title">
      <template #trigger>
        <div
            @click="modal = true"
            class="tw-bg-master tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-py-1 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-2 tw-no-underline"
        >
          <svg
              class="tw-h-5 tw-w-5"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
            <path fill-rule="evenodd" d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z" clip-rule="evenodd" />
          </svg>

          <span class="max-md:tw-hidden">Editer</span>
        </div>
      </template>

    <template #description>
      <span>Le recensement est terminé, souhaitez-vous le passer en en cours?</span>
      <span class="tw-text-red-600">La date de validation du recensement sera supprimée.</span>
    </template>

    <template #actions>
      <div
          @click="modal = false"
          class="tw-cursor-pointer tw-px-2 tw-py-2 tw-bg-gray-100 tw-rounded-xl tw-flex-1 tw-text-center">
        Annuler
      </div>

      <div
          @click="handleReset"
          class="tw-cursor-pointer tw-px-2 tw-py-2 tw-bg-holding tw-text-white tw-rounded-xl tw-flex-1 tw-text-center"
      >
        Modifier
      </div>
    </template>
  </ModalComponent>
</template>