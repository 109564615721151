<script setup>
import {onMounted, ref, reactive, watch, computed} from "vue";
import DogForm from "../../../components/form/DogForm.vue";
import TextInputController from "../../../components/form/TextInputController/TextInputController.vue";
import logoSvg from "../../../assets/images/logo-icad-2.svg";
import IconArrowLeft from "../../../components/icons/IconArrowLeft.vue";
import { useRoute } from "vue-router";
import censusService from "../../../services/census.service";
import dogsService from "../../../services/dogs.service";
import ModalDogAlreadyExist from "./ModalDogAlreadyExist.vue";
import { useStore } from "../../../stores";

const loading = ref(true);
let showForm = ref(false);
const controller = new AbortController();
const route = useRoute();
const store = useStore();
const state = reactive({
  value: "",
  error: "",
  result: null,
  loading: false,
  mode: "add",
  dogFoundInBdd: null,
  dataToApplyOnOthers: null,
});

const census = reactive({
  data: null,
});

const isOffline = computed(() => !store.getIsOnline);

watch(
    () => state.result,
    () => {
      if (state.result) {
        console.log("watch", state.result);
        store.setCorrectDog(state.result)
      }
    }
);

const handleChange = (value) => {
  state.error = "";
  state.value = value;
};
const handleSearchClick = async () => {
  try {
    /* Call Service  */
    state.loading = true;
    let dogs = await dogsService.getDogs(
        1,
        "&identificationNum=" + state.value,
        controller
    );
    if (dogs["hydra:member"].length > 0) {
      const dogBdd = dogs["hydra:member"][0];
      state.dogFoundInBdd = dogBdd;
    } else {
      const dog = await dogsService.getDogExternalInformation(
          state.value,
          controller
      );
      if (dog) {
        state.result = dog;
        showForm.value = true;
      } else {
        state.error =
            "Erreur, aucun chien ne correspond au numéro d'identification fournie";
      }
    }
    state.loading = false;
  } catch (error) {
    state.loading = false;
    state.error =
        " Nous n’avons trouvé aucun résultat. Veuillez vérifier votre saisie";
  }
};

const handleNewEntryHandler = async () => {
  try {
    showForm.value = true;
  } catch (e) {
    console.log(e);
    state.error = "Une erreur est survenue";
  }
};

const isNotEmpty = (value) => {
  return value && value !== "";
};

const getIdentificationNumber = () => {
  if (isNotEmpty(state.result?.identificationNum))
    return state.result?.identificationNum;
  if (isNotEmpty(state.result?.codins)) return state.result?.codins;
  if (isNotEmpty(state.result?.tatou)) return state.result?.tatou;

  return state.value;
};

onMounted(async () => {
  census.data = await censusService.getCensus(route.params.censusId, controller, !isOffline.value);
  if (census.data.dogs && census.data.dogs.length > 0) {
    handleApplyOthersDogs(census.data.dogs[0])
  }
  if (route.params.dogId) {
    state.result = await dogsService.getById(route.params.dogId, controller);
    state.mode = "edit";
    showForm.value = true;
  }
  loading.value = false;
});

function handleApplyOthersDogs(dog) {
  console.log(dog)
  state.dataToApplyOnOthers = {
    // specNbPersons: dog.nbPersons ? JSON.parse(dog.nbPersons) : {},
    nbPersons: dog.nbPersons || '{}',
    specie: dog.specie,
    typeOfSystem: dog.typeOfSystem,
    typeOfDriving: dog.typeOfDriving,
    humainPresence: dog.humainPresence,
    otherDogsPresence: dog.otherDogsPresence,
    oustiderHumanPresence: dog.oustiderHumanPresence,
    typeOfPredation: dog.typeOfPredation,
    otherTypeOfPredation: dog.otherTypeOfPredation,
    isOtherSpecie: dog.isOtherSpecie,
    otherTypeOfSystem: dog.otherTypeOfSystem,
    otherSpecie: dog.otherSpecie,
    otherTypeOfDriving: dog?.otherTypeOfDriving,
    otherStartAtPeriodHousing: dog.otherStartAtPeriodHousing,
    otherEndAtPeriodHousing: dog?.otherEndAtPeriodHousing,
  };
}

const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return census.data.person.id === currentUser.value.id;
});
</script>

<template>
  <ModalDogAlreadyExist
      v-if="state.dogFoundInBdd"
      @on-close="() => (state.dogFoundInBdd = null)"
      :census="census.data"
      :dogFound="state.dogFoundInBdd"
  />
  <LoaderList v-if="loading" />
  <div v-if="!loading" class="main-content">
    <div class="page-content">
      <section v-if="!showForm">
        <div
            class="nav-button"
            @click="
            () => {
              $router.push({ path: `/census/${census.data.id}/dogs` });
            }
          "
        >
          <IconArrowLeft /> <span>Retour</span>
        </div>
        <form
            v-if="isUserAdmin || isSameUser"
            class="form-column">
          <div class="search-dog">
            <h1>Rechercher dans la base I-CAD</h1>
            <TextInputController
                :disable="isOffline"
                :on-change="handleChange"
                :name="'name'"
                :model="state.value"
                :errors="[]"
                :label="`Numéro d'identification`"
                :placeholder="''"
                :maxlength="15"
            />

            <Button
                button-type="button"
                type="green"
                :disabled="state.value.length === 0 || state.loading"
                @on-click="
                () => {
                  handleSearchClick();
                }
              "
            >
              <span>Rechercher dans la base I-CAD</span>
              <img :src="logoSvg" alt="i-cad" />
            </Button>
            <div v-if="state.error" class="error">
              {{ state.error }}
            </div>
          </div>
          <h1 class="add-title">Ajout d'un chien</h1>

          <Button
              type="green"
              :disabled="state.loading"
              @on-click="
              () => {
                handleNewEntryHandler();
              }
            "
          >
            Nouveau chien
          </Button>
        </form>
        <p v-else class="tw-text-xl tw-font-bold tw-py-10 tw-text-error">
          Vous n'avez pas les droits pour ajouter un chien dans ce recensement
        </p>

      </section>
      <section v-if="showForm" class="form-section">
        <div class="header-buttons">
          <div
              class="nav-button"
              @click="
              () => {
                if (state.mode === 'add') {
                  showForm = false;
                  state.result = null;
                  return;
                }
                $router.push({ path: `/census/${census.data.id}/dogs` });
              }
            "
          >
            <IconArrowLeft /> <span>Retour</span>
          </div>
          <div class="go-next" @click="() => $router.push({ path: `/census/${census.data.id}`, query: { dog: state.result.id }})">
            <IconArrowLeft class="right-arrow"/> <span>Accéder au recensement</span>
          </div>
        </div>

        <div class="dog-identity-card">
          <p>IDENTIFICATION DU CHIEN</p>
          <h2>
            N°
            {{
              state.result?.identificationNum
                  ? state.result.identificationNum
                  : state.value.length > 0
                      ? state.value
                      : "Inconnu"
            }}
          </h2>
        </div>
        <DogForm
            :identificationNum="getIdentificationNumber()"
            :mode="state.mode"
            :dog="state.result"
            :census="census.data"
            :dataToApplyOnOthers="state.dataToApplyOnOthers"
        />
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../assets/css/all";

.dog-identity-card {
  background-color: $green;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
  padding: 20px;
  margin: 10px 0 20px 0;

  h2 {
    font-size: $h2;
    margin: 0;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
}

button {
  margin: auto;
}
button.green {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px;
}
.icon-button-bloc {
  img {
    width: 98px;
    height: 100px;
  }
}
.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

@media screen and (min-width: 1281px) {
  .main-content {
    padding-top: 15px;
  }
}

.search-dog {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  background-color: $sand;
  min-height: 150px;
  border-radius: 16px;
  margin-top: 40px;

  h1 {
    margin-top: 0 !important;
    margin-bottom: 0;
    text-align: center;
    color: $green;
    font-size: 21px;
  }
}

.add-title {
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 21px;
}

.error {
  color: $danger;
}
.header-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.go-next {
  background-color: $green;
  display: flex;
  justify-content: space-around;
  padding: 0.25em;
  align-items: center;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  width: 200px;
  cursor: pointer;
  font-size: 16px;
}

.right-arrow {
  transform: rotate(180deg);
}
</style>
