export const speciesConfig = [
  { value: "ovin_lait", label: "Ovins lait" },
  { value: "ovin_viande", label: "Ovins viande" },
  { value: "ovin_laine", label: "Ovins laine" },
  { value: "caprin_lait", label: "Caprins lait" },
  { value: "caprin_viande", label: "Caprins viande" },
  { value: "caprin_laine", label: "Caprins laine" },
  { value: "volailles", label: "Volailles" },
  { value: "bovins_lait", label: "Bovins lait" },
  { value: "bovins_viande", label: "Bovins viande" },
  { value: "camelides", label: "Camélidés" },
  { value: "chevaux", label: "Chevaux" },
  { value: "porcins", label: "Porcins" },
  { value: "asins", label: "Asins" },
  { value: "other", label: "Autre" },
];

export const otherSpeciesConfig = [
  { value: "other_ovin_lait", label: "Ovins lait" },
  { value: "other_ovin_viande", label: "Ovins viande" },
  { value: "other_ovin_laine", label: "Ovins laine" },
  { value: "other_caprin_lait", label: "Caprins lait" },
  { value: "other_caprin_viande", label: "Caprins viande" },
  { value: "other_caprin_laine", label: "Caprins laine" },
  { value: "other_volailles", label: "Volailles" },
  { value: "other_bovins_lait", label: "Bovins lait" },
  { value: "other_bovins_viande", label: "Bovins viande" },
  { value: "other_camelides", label: "Camélidés" },
  { value: "other_chevaux", label: "Chevaux" },
  { value: "asins", label: "Asins" },
  { value: "other_porcins", label: "Porcins" },
];

export const typeOfSystem = [
  { value: "totally_outdoor", label: "Plein-air intégral" },
  { value: "outdoor_and_indoor", label: "Plein-air et bâtiment" },
  { value: "indoor", label: "Uniquement en bâtiment" },
];

export const typeOfConduct = [
  { value: "electric", label: "Parcs électriques" },
  { value: "ursus", label: "Parcs Ursus" },
  { value: "free", label: "Garde dirigée / parcours libre (sur la journée)" },
  { value: "guarded", label: "Gardiennage" },
  { value: "ungarded", label: "Troupeau non gardé (sur plusieurs jours)" },
];

export const humanPresences = [
  { value: "no", label: "Non" },
  { value: "children", label: "Oui, enfant(s)" },
  {
    value: "breeder",
    label: "Oui, plusieurs éleveurs (associés, salariés...)",
  },
];

export const otherDogPresences = [
  { value: "no", label: "Non" },
  { value: "yes_all_times", label: "Oui, contenus" },
  { value: "yes_free", label: "Oui, libres" },
];

export const outsiderHumainPresences = [
  { value: "strong", label: "Forte" },
  { value: "medium", label: "Moyenne" },
  { value: "permanent", label: "Permanente" },
  { value: "punctual", label: "Ponctuelle" },
  { value: "null", label: "Nulle" },
];

export const typeOfPredations = [
  { value: "wolves", label: "Loups" },
  { value: "bears", label: "Ours" },
  { value: "lynx", label: "Lynx" },
  { value: "foxes", label: "Renards" },
  { value: "boars", label: "Sangliers" },
  { value: "crows", label: "Corbeaux" },
  { value: "vultures", label: "Vautours" },
  { value: "dogs", label: "Chiens" },
  { value: "flight", label: "Vol" },
  { value: "other", label: "Autres" },
];

export const yesOrNo = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];
