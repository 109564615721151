<script setup>
import { reactive } from "vue";
import IconArrowDown from "@/assets/images/arrow-down.svg";


const props = defineProps({
  items: Array,
  default: String,
  type: String, //dog - master
});
const state = reactive({
  isVisible: false,
  current: props.default ? props.items.find(d => d.value == props.default.id) ? props.items.find(d => d.value == props.default.id) : props.items[0] : props.items[0],
});
const emit = defineEmits(["onClick", "onAction"]);

function changeCurrent(item) {
  state.current = item;
  state.isVisible = false;
  emit("onClick", item);
}
</script>
<template>
  <div class="container">
    <div
      class="select"
      :class="{
        selectDog: props.type === 'dog',
        selectMaster: props.type === 'master',
      }"
      @click="state.isVisible = !state.isVisible"
    >
      <label>{{ state.current?.label }}</label>
      <img :src="IconArrowDown" />
    </div>
    <transition name="bounce">
      <div
        v-if="state.isVisible"
        class="dropdown"
        :class="{
          dropdownDogBg: props.type === 'dog',
          dropdownMasterBg: props.type === 'master',
        }"
      >
        <template v-for="item in props.items" :key="item.value">
          <div
            class="item"
            :class="{
              dog: props.type === 'dog',
              master: props.type === 'master',
            }"
            @click="changeCurrent(item)"
          >
            {{ item.label }}
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";




.bar-bottom {
  background-color: white;
  height: 20px;
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: sticky;
  top: 35px;
  z-index: 4;
}
.select {
  color: white;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-family: "NunitoSans";
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}
.selectDog {
  background-color: $censusDog;
}
.selectMaster {
  background-color: $censusMaster;
}
.dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .item {
    padding: 20px 0px;
    margin: 0px 40px;
    font-family: "NunitoSans";
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &.dog {
      color: white !important;
      border-bottom: 1px solid rgb(111, 144, 107, 0.4) !important;
    }

    &.master {
      color: white !important;
      border-bottom: 1px solid rgba(199, 187, 154, 0.5) !important;
    }
  }

  .item:hover {
    color: #0a0d06;
  }

  a:last-child {
    .item {
      border-bottom: 0;
    }
  }
}

.go-back {
  padding: 10px 40px;
  color: white;
  font-family: "NunitoSans";

  img {
    margin-right: 1em;
  }
}

.dropdownDogBg {
  background-color: $censusDog !important;
}
.dropdownMasterBg {
  background-color: $censusMaster !important;
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
