import {createRouter, createWebHistory} from "vue-router";
import BaseTemplate from "../components/base-template/BaseTemplate.vue";
import Home from '../views/logged/home/Home.vue'
import Holding from '../views/logged/holding/HoldingList.vue'
import EditHolding from '../views/logged/holding/EditHolding.vue'
import RecensementList from '../views/logged/recensement/RecensementList.vue'
import Login from '../views/Login.vue'
import LoginConfirmation from '../views/LoginConfirmation.vue'
import ValidateAccount from '../views/ValidateAccount.vue'
import ListUsers from '../views/logged/users/ListUsers.vue';
import DogsList from '../views/logged/dogs/DogsList.vue';
import DogPage from '../views/logged/dogs/DogPage.vue';
import AddEditDog from '../views/logged/dogs/AddEditDog.vue';
import AddOrEditUser from '../views/logged/users/AddOrEditUser.vue';
import AddCensus from '../views/logged/census/AddCensus.vue';
import DetailCensus from '../views/logged/census/DetailCensus.vue';
import Step3ManageDogs from '../components/census/Step3ManageDogs.vue';
import ForgottenPassword from '../views/ForgottenPassword.vue';
import NewPassword from '../views/NewPassword.vue';
import PolitiqueDeConfidentialite from '../views/PolitiqueDeConfidentialite.vue';


import NotFound from '../views/NotFound.vue';
import {useStore} from '../stores';
import CensusDetailsPage from "../views/pages/census/CensusDetailsPage.vue";
import HoldingDetailsPage from "../views/pages/holding/HoldingDetailsPage.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: BaseTemplate,
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
            },
            {
                path: '/users',
                component: ListUsers,
                name: 'ListUsers'
            },
            {
                path: '/users/add',
                component: AddOrEditUser,
                name: 'Add User'
            },
            {
                path: '/users/:userId',
                component: AddOrEditUser,
                name: 'Edit User'
            },
            {
                path: '/me',
                component: AddOrEditUser,
                name: 'Me'
            },
            {
                path: '/holdings/:holdingId',
                component: HoldingDetailsPage,
                props: true,
            },
            {
                path: '/holdings/:holdingId/edit',
                component: EditHolding,
            },
            {
                path: '/census/add',
                component: AddCensus
            },
            {
                path: '/census/:censusId',
                component: DetailCensus
            },
            {
                path: '/census/:censusId/dogs',
                component: Step3ManageDogs
            },
            {
                path: '/census/:censusId/dogs/add',
                component: AddEditDog
            },
            {
                path: '/census/:censusId/dogs/:dogId',
                component: AddEditDog
            },
            {
                name: 'census_details_page',
                path: '/census/:censusId/dog/:dogId',
                component: CensusDetailsPage,
                props: true,
            },
            {
                path: '/holdings',
                component: Holding,
                name: "Holdings"
            },
            {
                path: '/census',
                component: RecensementList,
                name: 'RecensementList'
            },
            {
                path: '/dogs',
                component: DogsList,
                name: 'DogsList'
            },
            {
                path: '/dogs/:dogId',
                component: DogPage,
                name: 'DogPage'
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/login/:token/confirmation',
        name: 'LoginConfirmation',
        component: LoginConfirmation
    },
    {
        path: '/forgot',
        name: 'ForgottenPassword',
        component: ForgottenPassword
    },
    {
        path: '/forgot/password',
        name: 'NewPassword',
        component: NewPassword,

    },
    {
        path: '/validate-account/:token',
        name: 'ValidateAccount',
        component: ValidateAccount
    },
    {
        path: '/politique-de-confidentialite',
        name: 'PolitiqueDeConfidentialite',
        component: PolitiqueDeConfidentialite
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
    const id = from.params.censusId ?? null;
    if (id && from.path.includes('/census') && !to.path.includes('/census/' + id)) {
        const store = useStore()

        if (store.currentCensus.step > 1 && store.currentCensus.step < 4) {
            store.quittingCensus = true;
            store.quittingCensusToPath = to.path;

            await next(from);
            return;
        }
    }
    await next()
})

export default router;
