<template>
  <ContentLoader viewBox="0 0 300 250">
    <rect
      x="0"
      y="0"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="25"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="50"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="75"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="100"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="125"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="150"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="175"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="200"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="225"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
    <rect
      x="0"
      y="250"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
};
</script>
