<script setup>
import { ref } from 'vue'
import TextLoading from "../../../../views/components/loading/TextLoading.vue";
import ButtonLoading from "../../../../views/components/loading/ButtonLoading.vue";

const show = ref(false)

const props = defineProps({
  title: {
    type: String,
    default: 'Suppression de l\'élément',
  },
  description: {
    type: String,
    default: 'Ces élements seront tous supprimé, veillez confirmer la suppression',
  },
  loading: Boolean,
})
const emit = defineEmits(['delete'])
defineExpose({
  show,
})
</script>

<template>
  <div
      @click="show = true"
      class="tw-bg-error tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-py-1 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-2 tw-no-underline"
  >
    <svg class="tw-h-5 tw-w-5"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>

    <span class="max-md:tw-hidden">Supprimer</span>
  </div>

  <teleport to="body">
    <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-from-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-from-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
    >
      <div
          v-if="show"
          style="background-color: rgb(0 0 0 / 0.2)"
          class="tw-absolute tw-flex tw-w-vw tw-h-vh tw-w-full tw-h-screen tw-inset-0 tw-justify-center tw-items-center tw-z-10"
      >
        <div
            class="tw-absolute tw-flex tw-flex-col tw-m-auto tw-rounded-xl tw-bg-white tw-min-h-xl  tw-p-5"
        >
          <h2 class="tw-text-lg lg:tw-text-2xl tw-font-bold">
            <TextLoading :loading="loading" :text="title" />
          </h2>

          <slot name="description">
            <p v-if="description" class="tw-text-red-600 tw-font-semibold">
              <TextLoading :loading="loading" :text="description" />
            </p>
          </slot>

          <div class="tw-flex tw-flex-col tw-gap-3">
            <div
                class="tw-overflow-y-auto tw-max-h-72 lg:tw-max-h-96 tw-bg-gray-100 tw-px-5 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col tw-gap-3" v-if="loading">
                <p><TextLoading loading text="Lorem ipsum dolor sit amet, consectetur adipisicing elit."/></p>
                <p><TextLoading loading text="Alias eaque est eum nihil recusandae saepe sed soluta veniam voluptates, voluptatibus!"/></p>
                <p><TextLoading loading text="Asperiores atque corporis debitis delectus impedit magni, nisi ut! Tempora."/></p>
              </div>
              <slot v-else />
            </div>

            <div class="tw-flex tw-gap-5 tw-items-center tw-justify-center">
              <ButtonLoading
                  :loading="loading"
                  @click="show = false"
                  class="tw-cursor-pointer tw-px-2 tw-py-2 tw-bg-gray-100 tw-rounded-xl tw-flex-1 tw-text-center"
              >
                Annuler
              </ButtonLoading>

              <ButtonLoading
                  :loading="loading"
                  @click="emit('delete')"
                  class="tw-cursor-pointer tw-px-2 tw-py-2 tw-bg-error tw-text-white tw-rounded-xl tw-flex-1 tw-text-center"
              >
                Supprimer
              </ButtonLoading>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>