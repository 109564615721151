<script setup>
import { useStore } from "../../stores";
import { reactive, defineEmits, watch, computed } from "vue";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  required,
  requiredUnless,
  minLength
} from "@vuelidate/validators";
import peopleService from "../../services/people.service";
import SelectInputController from "@/components/form/SelectInputController/SelectInputController";

const props = defineProps({
  census: Object,
  emitTarget: String,
  people: Object,
});

const store = useStore();
const emit = defineEmits(["submit", "onClose"]);
const controller = new AbortController();
const social = reactive({
  reason: props.people?.companyname ? "social" : "particular",
});
const state = reactive({
  lastname: props.people ? props.people.lastname : null,
  firstname: props.people ? props.people.firstname : null,
  companyname: props.people ? props.people.companyname : null,
  // profileMatch: props.people ? props.people.profileMatch : null,
  address: props.people ? props.people.address : "",
  zipCode: props.people ? props.people.zipCode : "",
  city: props.people ? props.people.city : "",
  email: props.people ? props.people.email : "",
  phoneNumber: props.people ? props.people.phoneNumber : "",
});

watch(
    () => social?.reason,
    (reason) => {
      if (reason === "social") {
        state.firstname = null;
        state.lastname = null;
      } else {
        state.companyname = null;
      }
    }
);

const handleChange = (value, name) => {
  state[name] = value;
  if (v$.value[name]) {
    v$.value[name].$validate();
  }
};

const socialHandleChange = (value, name) => {
  social[name] = value;
};

const rules = computed(() => {
  return {
    lastname: {
      required: helpers.withMessage(
          "Le champ est requis !",
          requiredUnless(() => {
            return social.reason === "social";
          })
      ),
    },
    firstname: {
      required: helpers.withMessage(
          "Le champ est requis !",
          requiredUnless(() => {
            return social.reason === "social";
          })
      ),
    },
    companyname: {
      required: helpers.withMessage(
          "Le champ est requis !",
          requiredUnless(() => {
            return social.reason === "particular";
          })
      ),
    },
    // profileMatch: {
    //   required: helpers.withMessage("Le champ est requis !", required),
    // },
    address: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
    city: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    email: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phoneNumber: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
  };
});

const v$ = useVuelidate(rules, state);

const submitForm = async () => {
  try {
    if (props.people?.id !== -2) {
      let res;
      if (props.people) {
        res = await peopleService.update(
            props.people.id,
            { ...state, exploitation: props.census.exploitation["@id"] },
            controller
        );
      } else {
        res = await peopleService.create(
            {
              ...state,
              exploitation: props.census.exploitation["@id"],
            },
            controller
        );
      }
      emit("submit", { emitTarget: props.emitTarget, personId: res.id });
    } else
      emit("submit", {
        emitTarget: props.emitTarget,
        personId: -2,
        newValue: state,
      });
  } catch (e) {
    console.log(e);
  }
};
</script>
<template>
  <transition name="bounce">
    <div class="dropdown">
      <div class="modal-title">
        {{
          props.people
              ? "Edition d'un membre de l'exploitation"
              : "Ajout d'un membre de l'exploitation"
        }}
      </div>
      <div
          class="close-modal"
          @click="$emit('onClose', { target: props.emitTarget })"
      >
        X
      </div>
      <form class="form-column">
        <SelectInputController
            :on-change="socialHandleChange"
            :name="'reason'"
            :label="'Raison sociale'"
            :model="social?.reason"
            :options="[
            { value: 'particular', label: 'Particulier' },
            { value: 'social', label: 'Raison Sociale' },
          ]"
        />
        <TextInputController
            v-if="social?.reason === 'particular'"
            :on-change="handleChange"
            :name="'lastname'"
            :model="state.lastname"
            :errors="v$.lastname?.$errors"
            :on-blur="v$.lastname?.$touch"
            :label="'Nom'"
        />
        <TextInputController
            v-if="social?.reason === 'particular'"
            :on-change="handleChange"
            :name="'firstname'"
            :label="'Prénom'"
            :model="state.firstname"
            :errors="v$.firstname?.$errors"
            :on-blur="v$.firstname?.$touch"
        />
        <TextInputController
            v-if="social?.reason === 'social'"
            :on-change="handleChange"
            :name="'companyname'"
            :label="'Nom de la société'"
            :model="state.companyname"
            :errors="v$.companyname?.$errors"
            :on-blur="v$.companyname?.$touch"
        />

        <div class="dual-column-form">
          <TextInputController
              :on-change="handleChange"
              :name="'zipCode'"
              :label="'Code postal'"
              :type="'zipCode'"
              :model="state.zipCode"
              :errors="v$.zipCode.$errors"
              :on-blur="v$.zipCode.$touch"
              :read-only="!!props.holding"
              :maxlength="5"
          />
          <TextInputController
              :on-change="handleChange"
              :name="'city'"
              :label="`Ville`"
              :model="state.city"
              :errors="v$.city.$errors"
              :on-blur="v$.city.$touch"
              :read-only="!!props.holding"
          />
        </div>
        <TextInputController
            :on-change="handleChange"
            :name="'address'"
            :label="`Adresse`"
            :model="state.address"
            :errors="v$.address.$errors"
            :on-blur="v$.address.$touch"
            :read-only="!!props.holding"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'email'"
            :label="'Email'"
            :model="state.email"
            :errors="v$.email.$errors"
            :on-blur="v$.email.$touch"
        >
          <template #icon>
            <IconMail />
          </template>
        </TextInputController>
        <TextInputController
            :on-change="handleChange"
            :name="'phoneNumber'"
            :type="'phone'"
            :label="'Téléphone'"
            :model="state.phoneNumber"
            :maxlength="10"
            :errors="v$.phoneNumber.$errors"
            :on-blur="v$.phoneNumber.$touch"
        >
          <template #icon>
            <IconPhone />
          </template>
        </TextInputController>
        <!-- <SelectInputController
          :on-change="handleChange"
          :name="'profileMatch'"
          :label="'Le maître correspond-il au profil pour intégrer le réseau de producteurs de chien selon vous ?'"
          :model="state.profileMatch"
          :errors="v$.profileMatch?.$errors"
          :on-blur="v$.profileMatch?.$touch"
          :options="[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]"
        /> -->
        <Button type="primary" :disabled="v$.$invalid" @on-click="submitForm()">
          Valider
        </Button>
      </form>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_all.scss";

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #ffff;
  z-index: 3;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    margin-top: 2em;
    margin-bottom: 1.5em;
    color: #659f12;
  }

  .close-modal {
    position: absolute;
    left: 91%;
    top: 4%;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    position: relative;

    input {
      height: 30px !important;
      border: 1px solid #c8d0c6;
      border-radius: 6px;
      width: 100%;
    }

    label {
      margin-top: 1.5em;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 0.5em;
      padding-left: 0.2em;
    }
  }

  .row-inputs {
    display: flex;

    div:first-child {
      width: 85%;
    }

    .city-input {
      width: 80%;
    }
  }

  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: #659f12;
    color: white;
    border-radius: 14px;
    margin-top: 5% !important;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
