<script setup>
    import {computed} from 'vue';
    import {useRoute} from 'vue-router';

    const props = defineProps({
        icon: String,
        name: String,
        url: String,
    });

    const route = useRoute();

    const isCurrentRoute = computed(() => {
      if (props.url === '/') {
        return route.path === '/';
      }

      if (props.url === '/census') {
        return route.path === '/census';
      }

      if (props.url === '/dogs') {
        return route.path === '/dogs' || route.path.startsWith('/dogs/');
      }

      if (props.url === '/census/add') {
        return route.path.includes('/census/');
      }

      return route.path.includes(props.url);
    })
</script>

<template>
  <router-link
    :to="url"
    :class="{active: isCurrentRoute}"
  >
    <div class="item-menu">
      <div class="container-img">
        <img :src="icon">
      </div>
      <label>{{ name }}</label>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

a{
    text-decoration: none;
    padding: 10px;
    border-radius: 10px;
    transition: 0.5s;
}
.active{
    background-color: $altGreen;

    label{
        color: $primary!important;
    }
}
.item-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s;

    .container-img, img, label{
        cursor: pointer;
    }

    .container-img{
        min-width: 70px;

        img{
            height: 30px;
        }

    }

    label{
        color: $black;
    }
}

.item-menu:hover{
    opacity: 0.7;
}
</style>