// storeFactory.js
import { defineStore } from "pinia";
import { ref } from "vue";

export const createListStore = (storeName) => {
    return defineStore(storeName, () => {
        const items = ref([]);
        const page = ref(1);
        const lastItemId = ref(0);

        const deleteItem = (id) => {
            items.value = items.value.filter((item) => item.id !== id);
        };

        const deleteItems = (ids) => {
            items.value = items.value.filter((item) => !ids.includes(item.id));
        };

        return {
            items,
            page,
            lastItemId,
            deleteItem,
            deleteItems,
        };
    });
};

export default createListStore;