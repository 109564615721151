const dogEnvironmentFields = [
    {
        name: "specie",
        type: 'choice',
    },
    {
        name: "specieOther",
        type: 'string',
    },
    {
        name: "nbPersons",
        type: 'string',
    },
    {
        name: "typeOfSystem",
        type: 'string',
    },
    {
        name: "typeOfDriving",
        type: 'string',
    },
    {
        name: "humainPresence",
        type: 'string',
    },
    {
        name: "otherDogsPresence",
        type: 'string',
    },
    {
        name: "oustiderHumanPresence",
        type: 'string',
    },
    {
        name: "typeOfPredation",
        type: 'string',
    },
    {
        name: "otherTypeOfPredation",
        type: 'string',
    },
    {
        name: "isOtherSpecie",
        type: 'string',
    },
    {
        name: "otherSpecie",
        type: 'string',
    },
    {
        name: "otherTypeOfSystem",
        type: 'string',
    },
    {
        name: "otherTypeOfDriving",
        type: 'string',
    },
]

export default dogEnvironmentFields