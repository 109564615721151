<script setup>
import { useStore } from "../../stores";
import { reactive, defineEmits } from "vue";
import {
  getDogsParamsFilters,
} from "@/helpers/queryhelper";
import SelectInputController from "@/components/form/SelectInputController/SelectInputController";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import { raceConfig, sexeConfig } from "@/config/DogConfig";
import { departments } from "../../config/DepartmentConfig";

const props = defineProps({
  isVisible: Boolean,
});

const store = useStore();
const emit = defineEmits(["queryParams", "exploitParams", "onClose"]);
const departmentsEntries = departments.map((dpt) => {
  return {
    label: `(${dpt.num_dep}) ${dpt.dep_name}`,
    value: dpt.num_dep,
  };
});
const inputValues = reactive({
  identificationNum: "",
  name: "",
  gender: "",
  breed: "",
  steril: "",
  "census.person.lastname": "",
  "census.person.firstname": "",
  census: "",
  potentialBreeder: null,
  edeNumber: "",
  department: "",
});

const form = reactive({ ...inputValues });

function resetForm() {
  Object.assign(form, inputValues);
}

const handleChange = (value, name) => {
  form[name] = value;
};

function submitForm() {
  try {
    const params = getDogsParamsFilters(form);
    emit("queryParams", params);
    resetForm();
  } catch (e) {
    console.log(e);
  }
}
</script>
<template>
  <transition name="bounce">
    <div v-if="props.isVisible" class="dropdown">
      <form>
        <div class="modal-title">Recherche d'un chien</div>
        <div class="close-modal" @click="$emit('onClose')">X</div>
        <SelectInputController
            name="zipCode"
            :model="inputValues.zipCode"
            :on-change="handleChange"
            :options="departmentsEntries"
            label="DEPARTEMENT"
            :is-department-input="true"
            :maxlength="5"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'identificationNum'"
            :label="`N° D'IDENTIFICATION`"
            :model="form.identificationNum"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'name'"
            :label="'NOM DU CHIEN'"
            :model="form.name"
            :placeholder="''"
        />
        <SelectInputController
            :on-change="handleChange"
            :name="'gender'"
            :label="'SEXE'"
            :model="form.gender"
            :placeholder="'Mâle'"
            :options="sexeConfig"
        />
        <SelectInputController
            :on-change="handleChange"
            :name="'breed'"
            :label="'RACE/APPARENCE RACIALE'"
            :model="form.breed"
            :placeholder="''"
            :options="raceConfig"
        />
        <SelectInputController
            :on-change="handleChange"
            :name="'potentialBreeder'"
            :label="'REPRODUCTEUR POTENTIEL'"
            :model="form.potentialBreeder"
            :placeholder="'Oui'"
            :options="[
            { value: 'true', label: 'Oui' },
            { value: 'false', label: 'Non' },
          ]"
        />
        <SelectInputController
            :on-change="handleChange"
            :name="'census'"
            :label="'ETAT DE SAISI'"
            :model="form.census"
            :placeholder="'état'"
            :options="[
            { value: 'scheduled', label: 'Recensements terminé' },
            { value: 'running', label: 'Recensements en cours' },
            { value: 'to_sync', label: 'Recensements à synchroniser' },
            { value: 'incoming', label: 'Recensements à venir' },
          ]"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'census.person.lastname'"
            :label="'NOM DU RECENSEUR'"
            :model="form['census.person.lastname']"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'census.person.firstname'"
            :label="'PRÉNOM DU RECENSEUR'"
            :model="form['census.person.firstname']"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'edeNumber'"
            :label="'N° EDE'"
            :model="form.edeNumber"
            :placeholder="''"
        />
      </form>
      <Button class="search-btn" @on-click="submitForm()"> RECHERCHER </Button>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 125%;
  background-color: white;
  z-index: 1;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    margin-top: 2em;
    margin-bottom: 1.5em;
    color: $green;
  }

  form {
    padding: 0.5em 1em 0 1em;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    position: relative;
    gap: 1em;
    width: 100%;

    .close-modal {
      position: absolute;
      left: 91%;
      top: 4%;
      font-weight: 600;
    }

    input {
      height: 30px !important;
      border: 1px solid $altGreen;
      border-radius: 6px;
      width: 100%;
    }

    label {
      margin-top: 1.5em;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 0.5em;
      padding-left: 0.2em;
    }
  }

  .row-inputs {
    display: flex;

    div:first-child {
      width: 85%;
    }

    .city-input {
      width: 80%;
    }
  }

  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: #659f12;
    color: white;
    border-radius: 20px;
    margin-top: 2em !important;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-height: 741px) {
  form {
    margin-top: 10em;
  }

  .modal-title {
    margin-bottom: 0 !important;
  }

  .close-modal {
    position: absolute;
    left: 91%;
    top: 4%;
    font-weight: 600;
  }
}

.row-date-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1em;

  p {
    width: 100%;
    text-align: start;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }

  div {
    width: 100%;
  }
}
</style>
