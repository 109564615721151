<script setup>
import kpiRecensement from "../../../assets/images/kpi-recensement.svg";
import syncIcon from "../../../assets/images/icon-sync.svg";
import kpiUser from "../../../assets/images/kpi-user.svg";
import { useRouter } from "vue-router";
import { onMounted, computed, reactive, ref } from "vue";
import CensusService from "@/services/census.service";
import rightArrow from "../../../assets/images/arrow-right.svg";
import { useStore } from "../../../stores";

const state = reactive({
  nbRunningRecensements: null,
  toSyncRecensements: [],
  incomingRecensements: [],
  runningRecensements: [],
  params: "",
  status: "",
});
const store = useStore();
const router = useRouter();
const loading = ref(false);
const page = ref(0);
const maxSize = ref(0);
const selectedRow = ref();
const controller = ref(new AbortController());
const isOffline = computed(() => !store.getIsOnline);
const currentuser = computed(() => store.getUser);

onMounted(() => {
  getRecensements(1);
});

async function getRecensements(newPage) {
  loading.value = true;
  page.value = newPage;
  const res = await CensusService.getIncomingCensusesByUser(
      controller.value,
      currentuser.value.id,
      !isOffline.value,
      store.showMyCensusesOnly ? currentuser.value.id : null,
  );
  state.incomingRecensements = [
    ...state.incomingRecensements,
    ...res["hydra:member"],
  ];
  const runningRes = await CensusService.getRunningCensus(
      controller.value,
      !isOffline.value,
      store.showMyCensusesOnly ? currentuser.value.id : null,
  );
  state.runningRecensements = [
    ...state.runningRecensements,
    ...runningRes["hydra:member"],
  ];
  state.nbRunningRecensements = runningRes["hydra:totalItems"];
  const toSyncRes = await CensusService.getToSyncCensuses(
      controller.value,
      !isOffline.value,
  store.showMyCensusesOnly ? currentuser.value.id : null,
  );
  state.toSyncRecensements = [
    ...state.toSyncRecensements,
    ...toSyncRes["hydra:member"],
  ];
  if (newPage === 1) {
    maxSize.value = res["hydra:totalItems"];
  }
  loading.value = false;
}

const switchShowUserOnly = async () => {
  store.showMyCensusesOnly = !store.showMyCensusesOnly;
  state.recensements = [];
  state.incomingRecensements = [];
  state.runningRecensements = [];
  state.toSyncRecensements = [];
  page.value = 1;
  await getRecensements(page.value);
};

</script>
<template>
  <div class="home">
    <div class="tw-flex tw-items-center tw-justify-between">
      <h2>Tableau de bord</h2>

      <div class="tw-text-primary tw-bg-primary-light tw-rounded-full tw-py-2 tw-flex tw-px-3 tw-cursor-pointer tw-space-x-2" @click="switchShowUserOnly" style="width: auto">
        <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-w-6 tw-h-6">
          <path
              v-if="store.showMyCensusesOnly"
              stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          <path
              v-else
              stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
        </svg>

        <span class="tw-hidden md:tw-flex">{{ store.showMyCensusesOnly ? 'Mes Recensements' : 'Tous les recensements' }}</span>
      </div>
    </div>

    <div class="list-kpis">
      <KpiDashboard
          :icon="kpiRecensement"
          line1="Recensements"
          line2="en cours"
          :total="state.nbRunningRecensements"
          type="primary"
          @on-select="
          router.push({ path: `/census` }) &&
            store.setCensusFilterType({
              value: 'en cours',
              status: 'running',
            })
        "
          @on-add="router.push({ path: `/census/add` })"
      />
      <AdminPermission>
        <KpiDashboard
            :icon="kpiUser"
            line1="Comptes"
            line2="utilisateurs"
            type="secondary"
            @on-add="router.push({ path: `/users/add` })"
            @on-select="router.push({ path: `/users` })"
        />
      </AdminPermission>
      <RecenseurPermission>
        <KpiDashboard
            :icon="syncIcon"
            line1="Recensements à"
            line2="synchroniser"
            type="secondary"
            :total="state.toSyncRecensements.length"
        />
      </RecenseurPermission>
    </div>

    <RequiredOnlineMode
        v-if="isOffline && state.incomingRecensements.length === 0"
    />
    <LoaderList
        v-if="loading && state.incomingRecensements.length === 0"
        class="loader"
    />

    <div v-if="!loading" class="recensement">
      <div class="title">Recensements à venir</div>
      <div
          v-if="state.incomingRecensements.length === 0 && !loading"
          class="empty-recensement"
      >
        Aucun recensement à venir
      </div>

      <div
          v-for="(recensement, index) in state.incomingRecensements"
          :key="recensement.id"
          class="recensement-row"
          :class="selectedRow === index ? 'selected' : ''"
          @click="
          selectedRow = index;
          router.push({ path: `/census/${recensement.id}/dogs` });
        "
      >
        <div class="recensement-row-name">
          &nbsp;{{ recensement.exploitation.name }}
        </div>
        <div class="recensement-row-date">
          {{ new Date(recensement.date).toLocaleDateString() }}
        </div>

        <img class="recensement-row-icon" :src="rightArrow" alt="right-arrow" />
      </div>
      <Button
          v-if="!loading && state.incomingRecensements.length !== 0"
          class="search-btn"
          @click="router.push({ path: '/census' })"
      >
        VOIR TOUS LES RECENSEMENTS A VENIR
      </Button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.list-kpis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  margin-top: 1em;
  font-weight: 700;
}

.recensement {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 1em;
  transition: filter 200ms;

  &-row {
    border: 1px solid rgba(101, 159, 18, 0.3);
    border-radius: 20px;
    padding: 0.5em 0.7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 200ms;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(101, 159, 18, 0.3);
      box-shadow: 0 2px 15px 0 rgba(101, 159, 18, 0.3);
    }

    &-name {
      font-weight: bold;
      font-size: 16px;
      width: 40%;
      max-width: 40%;
      display: flex;
      text-overflow: ellipsis;

      white-space: nowrap;
      overflow: hidden;

      div {
        font-weight: 400;
      }
    }

    &-progress {
      width: 30%;
      display: flex;
      flex-direction: column;

      label {
        margin-top: 0.5em;
        font-size: 12px;
        text-align: end;
      }
    }

    &-date {
      display: flex;
      justify-content: center;
      background-color: $altGreen;
      padding: 0.7em 0.8em;
      border-radius: 16px;
      color: $green;
      font-size: 16px;
      width: 25%;
      font-weight: 100;
    }

    &-icon {
      background-color: $sand;
      padding: 0.7em 1em;
      border-radius: 16px;
    }
  }
}

.loader {
  margin-top: 1em;
}

.empty-recensement {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5em;
}

.search-btn {
  background-color: $altGreen;
  color: $green;
  margin-top: 0.4em !important;
}

h2 {
  font-weight: bold;
  margin-bottom: 0.4em;
}

.selected {
  box-shadow: 0 2px 30px rgba(135, 177, 125, 0.5);
}
</style>
