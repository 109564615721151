<script setup>
import {computed, defineProps, ref} from "vue";
import ButtonLoading from "../../../views/components/loading/ButtonLoading.vue";
import CopyIcon from "../../../views/components/icons/CopyIcon.vue";
import dogEnvironmentFields from "../../../fields/dog/dogEnvironmentFields";
import useDog from "../../../compositions/useDog";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  census: {
    type: Object,
    required: true,
  },
  dog: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'update:dog',
  'submit',
])

const {getDogForm} = useDog()

const show = ref(false)
const selected = ref(null)

const others = computed(() => props.census.dogs.filter(el => {
  if (el.id === props.id || el.isMinor) {
    return false;
  }

  const other = getDogForm(el);

  for (const field of dogEnvironmentFields) {
    if (!!other[field.name]) {
      const value = JSON.stringify(props.dog[field.name])
      const otherValue = JSON.stringify(other[field.name]);

      if (value !== otherValue) {
        return true;
      }
    }
  }

  return false;
}))

const submit = () => {
  if (!selected.value) {
    return;
  }
  const other = getDogForm(selected.value);
  const value = {};
  for (const dogEnvironmentField of dogEnvironmentFields) {
    value[dogEnvironmentField.name] = other[dogEnvironmentField.name];
  }

  emit('update:dog', {...props.dog, ...value});
  emit('submit');
  show.value = false;
}
</script>

<template>
  <div
      @click="show = true"
      class="hover:tw-bg-white hover:tw-text-dog tw-text-white tw-cursor-pointer tw-aspect-square tw-rounded-lg tw-p-1.5 tw-ring-1 tw-ring-white tw-flex tw-justify-center tw-text-xl"
  >
    <CopyIcon
        class="tw-h-5 tw-w-5"/>
  </div>

  <teleport to="body">
    <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-from-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-from-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
    >
      <div
          v-if="show"
          class="tw-absolute tw-flex tw-w-vw tw-h-vh tw-w-full tw-h-screen tw-inset-0 tw-justify-center tw-items-center tw-z-10 "
      >
        <div @click="show = false" class="tw-absolute tw-inset-0" style="background: rgba(0,0,0,0.2)"></div>

        <div
            class="tw-absolute tw-flex tw-flex-col tw-m-auto tw-rounded-xl tw-bg-white tw-min-h-xl  tw-p-5"
        >
          <h2 class="tw-text-lg lg:tw-text-2xl tw-font-bold">
            Choix de l'environnement à importer
          </h2>

          <p class="tw-text-red-600 tw-font-semibold">
            Attention les données de l'environnement du chien {{ dog.name }} seront écrasées
            {{ selected && selected.name ? `par ${selected.name}` : '' }}
          </p>

          <div class="tw-flex tw-flex-col tw-gap-3">
            <div
                class="tw-px-5 tw-rounded-lg"
            >
              <SelectInputController
                  custom-class="tw-w-full tw-mt-5"
                  :on-change="(value) => selected = value"
                  label="Chien à copier"
                  :model="selected"
                  :options="others.map(el => ({ value: el, label: el.name }))"
              />
            </div>

            <div class="tw-flex tw-gap-5 tw-items-center tw-justify-center tw-justify-between">
              <div>

              </div>
              <ButtonLoading
                  @click="show = false"
                  class="tw-cursor-pointer tw-px-2 tw-py-2 tw-bg-gray-100 tw-rounded-xl tw-flex-1 tw-text-center"
              >
                Annuler
              </ButtonLoading>

              <ButtonLoading
                  @click="submit"
                  :class="[selected ? 'tw-cursor-pointer' : 'tw-cursor-not-allowed tw-opacity-50']"
                  class="tw-px-2 tw-py-2 tw-bg-primary tw-text-white tw-rounded-xl tw-flex-1 tw-text-center"
              >
                Copier
              </ButtonLoading>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
