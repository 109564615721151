function getFatherForm(correctDog) {
  return {
    name: correctDog.value?.father?.name,
    identificationNum: correctDog.value?.father?.identificationNum,
    breed: correctDog.value?.father?.breed,
    appearance: correctDog.value?.father?.appearance,
    atWork: correctDog.value ? correctDog.value?.father?.atWork : null,
    isCertifiedSonship: correctDog.value
      ? correctDog.value?.father?.isCertifiedSonship
      : null,
    numLof: correctDog.value?.father?.numLof,
    ownerName: correctDog.value?.father?.ownerName,
    phoneNumber: correctDog.value?.father?.ownerName,
    address: correctDog.value?.father?.address,
    phone: correctDog.value?.father?.phone,
    email: correctDog.value?.father?.email,
  };
}

function getMotherForm(correctDog) {
  return {
    name: correctDog.value?.mother?.name,
    identificationNum: correctDog.value?.mother?.identificationNum,
    breed: correctDog.value?.mother?.breed,
    appearance: correctDog.value?.mother?.appearance,
    atWork: correctDog.value ? correctDog.value?.mother?.atWork : null,
    isCertifiedSonship: correctDog.value
      ? correctDog.value?.mother?.isCertifiedSonship
      : null,
    numLof: correctDog.value?.mother?.numLof,
    ownerName: correctDog.value?.mother?.ownerName,
    phoneNumber: correctDog.value?.mother?.ownerName,
    address: correctDog.value?.mother?.address,
    phone: correctDog.value?.mother?.phone,
    email: correctDog.value?.mother?.email,
  };
}

function getOwnerForm(correctDog, exploitationId) {
  return {
    firstname: correctDog.value?.owner
      ? correctDog.value?.owner?.firstname
      : "",
    lastname: correctDog.value?.owner ? correctDog.value?.owner?.lastname : "",
    companyname: correctDog.value?.owner
      ? correctDog.value?.owner?.companyname
      : "",
    address: correctDog.value?.owner ? correctDog.value?.owner?.address : "",
    zipCode: correctDog.value?.owner ? correctDog.value?.owner?.zipCode : "",
    city: correctDog.value?.owner ? correctDog.value?.owner?.city : "",
    email: correctDog.value?.owner ? correctDog.value?.owner?.email : "",
    phoneNumber: correctDog.value?.owner
      ? correctDog.value?.owner?.phoneNumber
      : "",
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}

function getMasterForm(correctDog, exploitationId) {
  return {
    firstname: correctDog.value?.master
      ? correctDog.value?.master?.firstname
      : "",
    lastname: correctDog.value?.master
      ? correctDog.value?.master?.lastname
      : "",
    companyname: correctDog.value?.master
      ? correctDog.value?.master?.companyname
      : "",
    address: correctDog.value?.master ? correctDog.value?.master?.address : "",
    zipCode: correctDog.value?.master ? correctDog.value?.master?.zipCode : "",
    city: correctDog.value?.master ? correctDog.value?.master?.city : "",
    email: correctDog.value?.master ? correctDog.value?.master?.email : "",
    phoneNumber: correctDog.value?.master
      ? correctDog.value?.master?.phoneNumber
      : "",
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}

function getFullMasterForm(correctDog, exploitationId) {
  return {
    firstname: correctDog.value?.master
      ? correctDog.value?.master?.firstname
      : "",
    lastname: correctDog.value?.master
      ? correctDog.value?.master?.lastname
      : "",
    companyname: correctDog.value?.master
      ? correctDog.value?.master?.companyname
      : "",
    address: correctDog.value?.master ? correctDog.value?.master?.address : "",
    zipCode: correctDog.value?.master ? correctDog.value?.master?.zipCode : "",
    city: correctDog.value?.master ? correctDog.value?.master?.city : "",
    email: correctDog.value?.master ? correctDog.value?.master?.email : "",
    phoneNumber: correctDog.value?.master
      ? correctDog.value?.master?.phoneNumber
      : "",
    yearRegister: correctDog.value?.master ? correctDog.value?.master?.yearRegister : null,
    hasCpFormation: correctDog.value?.master ? correctDog.value?.master?.hasCpFormation?.split(",") : [],
    hasTechnicalFormation: correctDog.value?.master ? correctDog.value?.master?.hasTechnicalFormation?.split(",") : [],
    isJoiningProductionNetwork: correctDog.value?.master ? correctDog.value?.master?.isJoiningProductionNetwork : null,
    profileMatch: correctDog.value?.master ? correctDog.value.master?.profileMatch : null,
    hasRegularVisit: correctDog.value?.master ? correctDog.value.master?.hasRegularVisit : "",
    breedChart: correctDog.value?.master ? correctDog.value.master?.breedChart : null,
    hasSelfFormation: correctDog.value?.master ? correctDog.value.master?.hasSelfFormation  : null,
    batchCaring: correctDog.value?.master ? correctDog.value.master?.batchCaring : "",
    brokenDogs: correctDog.value?.master ? correctDog.value.master?.brokenDogs : "",
    puppyGrowth: correctDog.value?.master ? correctDog.value.master?.puppyGrowth : "",
    usedCp: correctDog.value?.master ? correctDog.value.master?.usedCp?.toString() : "",
    notes: correctDog.value?.master ? correctDog.value.master?.notes : "",
    couplings: correctDog.value?.master ? correctDog.value.master?.couplings : null,
    masterIsOwnerExploitation: correctDog.value?.master ? correctDog.value.master?.masterIsOwnerExploitation : false,
    // masterOwner: masterOwner.value != null ? masterOwner.value.id : null,
    hasGrownPuppy: correctDog.value?.master ? correctDog.value.master?.hasGrownPuppy : null,
    exploitation: `/api/exploitations/${exploitationId}`,
  };
}

function getForm(correctDog, exploitation) {
  return {
    name: correctDog.value?.name,
    birthName: correctDog.value?.birthName ? correctDog.value?.birthName : "",
    gender: correctDog.value?.gender,
    birthdate: correctDog.value ? correctDog.value?.birthdate : null,
    identificationNum: correctDog.value?.identificationNum,
    identificationType: correctDog.value?.identificationType,
    verificationPuce: correctDog.value?.verificationPuce,
    characteristics: correctDog.value?.characteristics,
    numLof: correctDog.value?.numLof,
    confirmLof: correctDog.value?.confirmLof,
    isLof: correctDog.value ? correctDog.value?.isLof : null,
    breed: correctDog.value
      ? typeof correctDog.value?.breed === "string"
        ? correctDog.value?.breed?.split(",")
        : correctDog.value?.breed
      : null,
    isIdentified: correctDog.value ? correctDog.value?.isIdentified : null,
    isBornHerd: correctDog.value ? correctDog.value?.isBornHerd : null,
    beforeTwoMonth: correctDog.value ? correctDog.value?.beforeTwoMonth : null,
    month: correctDog.value ? correctDog.value?.month : null,
    isFirstForBreeder: correctDog.value
      ? correctDog.value?.isFirstForBreeder
      : null,
    isHealthy: correctDog.value ? correctDog.value?.isHealthy : null,
    isAdaptedMorpho: correctDog.value
      ? correctDog.value?.isAdaptedMorpho
      : null,
    hasTesticleConcerned: correctDog.value
      ? correctDog.value?.hasTesticleConcerned
      : null,
    isAdaptedSize: correctDog.value ? correctDog.value?.isAdaptedSize : null,
    isAdaptedDemarche: correctDog.value
      ? correctDog.value?.isAdaptedDemarche
      : null,
    isAdaptedAplombs: correctDog.value
      ? correctDog.value?.isAdaptedAplombs
      : null,
    isAdaptedBackLine: correctDog.value
      ? correctDog.value?.isAdaptedBackLine
      : null,
    isAdaptedJaw: correctDog.value ? correctDog.value?.isAdaptedJaw : null,
    isAdaptedPigmentation: correctDog.value
      ? correctDog.value?.isAdaptedPigmentation
      : null,
    hasHealthyTrouble: correctDog.value
      ? correctDog.value?.hasHealthyTrouble
      : null,
    hipDysplasia: correctDog.value ? correctDog.value?.hipDysplasia : null,
    parcel: correctDog.value ? correctDog.value?.parcel : null,
    parcelHeifers: correctDog.value
      ? typeof correctDog.value?.parcelHeifers === "string"
        ? correctDog.value?.parcelHeifers?.split(",")
        : correctDog.value?.parcelHeifers
      : null,
    parcelOthers: correctDog.value
      ? correctDog.value?.parcelHeifers?.includes("others")
        ? correctDog.value?.parcelOthers
        : null
      : null,
    elbowDysplasia: correctDog.value ? correctDog.value?.elbowDysplasia : null,
    otherProblem: correctDog.value ? correctDog.value?.otherProblem : null,
    exploitationAge: correctDog.value
      ? correctDog.value?.exploitationAge
      : null,
    introductionCondition: correctDog.value
      ? correctDog.value?.introductionCondition
      : null,
    introductionConditionPuppies: correctDog.value
      ? correctDog.value?.introductionCondition === "other_puppies"
        ? correctDog.value?.introductionConditionPuppies
        : null
      : null,
    introductionConditionAdults: correctDog.value
      ? correctDog.value?.introductionCondition === "with_adults"
        ? correctDog.value?.introductionConditionAdults
        : null
      : null,
    behaviorWithHuman: correctDog.value
      ? correctDog.value?.behaviorWithHuman
      : null,
    temper: correctDog.value ? correctDog.value?.temper : null,
    aboutDogTemper: correctDog.value
      ? typeof correctDog.value?.parcelHeifers === "string"
        ? correctDog?.value?.aboutDogTemper?.split(",")
        : correctDog?.value?.aboutDogTemper
      : [],
    respectingOthers: correctDog.value
      ? correctDog.value?.respectingOthers
      : null,
    // ageRespectingOthers: correctDog.value
    //   ? correctDog.value?.ageRespectingOthers
    //   : null,
    startRespectingOthers: correctDog.value
      ? correctDog.value?.startRespectingOthers
      : null,
    endRespectingOthers: correctDog.value
      ? correctDog.value?.endRespectingOthers
      : null,
    isGoodProtector: correctDog.value
      ? correctDog.value?.isGoodProtector
      : null,
    tookTime: correctDog.value ? correctDog.value?.tookTime : null,
    isEasyDog: correctDog.value ? correctDog.value?.isEasyDog : null,
    isManipulable: correctDog.value ? correctDog.value?.isManipulable : null,
    isAgressif: correctDog.value ? correctDog.value?.isAgressif : null,
    isMunipulableCensusTaker: correctDog.value
      ? correctDog.value?.isMunipulableCensusTaker
      : null,
    trustWithOthers: correctDog.value
      ? correctDog.value?.trustWithOthers
      : null,
    trustOthersWithoutYou: correctDog.value
      ? correctDog.value?.trustOthersWithoutYou
      : null,
    positionInGroup: correctDog.value
      ? correctDog.value?.positionInGroup
      : null,
    canWorkInGroup: correctDog.value ? correctDog.value?.canWorkInGroup : null,
    canWorkAlone: correctDog.value ? correctDog.value?.canWorkAlone : null,
    presentHerd: correctDog.value ? correctDog.value?.presentHerd : null,
    watchfulAwakening: correctDog.value
      ? correctDog.value?.watchfulAwakening
      : null,
    hasExcessiveBarking: correctDog.value
      ? correctDog.value?.hasExcessiveBarking
      : null,
    distanceHerd: correctDog.value ? correctDog.value?.distanceHerd : null,
    fenceRespect: correctDog.value ? correctDog.value?.fenceRespect : null,
    runAfterVehicles: correctDog.value
      ? correctDog.value?.runAfterVehicles
      : null,
    isAlwaysUsable: correctDog.value ? correctDog.value?.isAlwaysUsable : null,
    isPotentialBreeder: correctDog.value
      ? correctDog.value?.isPotentialBreeder
      : null,
    hasGoodImpregnateHerdBfrTwoMounth: correctDog.value
      ? correctDog.value?.hasGoodImpregnateHerdBfrTwoMounth
      : null,
    hasGoodFamiliarizationHumansBfrTwoMounths: correctDog.value
      ? correctDog.value?.hasGoodFamiliarizationHumansBfrTwoMounths
      : null,
    hasGoodFamiliarizationHumansAfterTwoMounths: correctDog.value
      ? correctDog.value?.hasGoodFamiliarizationHumansAfterTwoMounths
      : null,
    wasEasySetupPuppy: correctDog.value
      ? correctDog.value?.wasEasySetupPuppy
      : null,
    isClingyWithHerd: correctDog.value
      ? correctDog.value?.isClingyWithHerd
      : null,
    hasGoodGlobalAppreciation: correctDog.value
      ? correctDog.value?.hasGoodGlobalAppreciation
      : null,
    unknownDogTemper: correctDog.value
      ? correctDog?.value.unknownDogTemper
      : null,
    unknownIsManipulable: correctDog.value
      ? correctDog.value?.unknownIsManipulable
      : null,
    unknownIsAgressif: correctDog.value
      ? correctDog.value?.unknownIsAgressif
      : null,
    unknownIsMunipulableCensusTaker: correctDog.value
      ? correctDog.value?.unknownIsMunipulableCensusTaker
      : null,
    unknownTrustWithOthers: correctDog.value
      ? correctDog?.value.unknownTrustWithOthers
      : null,
    unknownTrustOthersWithoutYou: correctDog.value
      ? correctDog?.value.unknownTrustOthersWithoutYou
      : null,
    adaptedMorphoCommentary: correctDog.value
      ? correctDog.value?.adaptedMorphoCommentary
      : null,
    recovery: correctDog.value ? correctDog.value?.recovery : null,
    wantPuppy: correctDog.value ? correctDog.value?.wantPuppy : null,
    isAgressifToOtherDogs: correctDog.value
      ? correctDog.value?.isAgressifToOtherDogs
      : null,
    owner: correctDog.value ? getOwnerForm(correctDog, exploitation.id) : null,
    master: correctDog.value
      ? getMasterForm(correctDog, exploitation.id)
      : null,
    father: correctDog.value ? getFatherForm(correctDog) : null,
    mother: correctDog.value ? getMotherForm(correctDog) : null,
    typeOfSystem: correctDog.value ? correctDog.value.typeOfSystem : "",
    typeOfPredation: correctDog.value
      ? typeof correctDog.value?.typeOfPredation === "string"
        ? correctDog.value?.typeOfPredation?.split(",")
        : correctDog.value?.typeOfPredation
      : "",
    typeOfDriving: correctDog.value ? correctDog.value?.typeOfDriving : null,
    otherTypeOfDriving: correctDog.value
      ? typeof correctDog.value?.otherTypeOfDriving === "string"
        ? correctDog.value?.otherTypeOfDriving?.split(",")
        : correctDog.value?.otherTypeOfDriving
      : null,
    otherStartAtPeriodHousing: correctDog.value
      ? correctDog.value?.otherStartAtPeriodHousing
      : null,
    otherEndAtPeriodHousing: correctDog.value
      ? correctDog.value?.otherEndAtPeriodHousing
      : null,
    masterIsOwnerDog: correctDog.value
      ? correctDog.value?.masterIsOwnerDog
      : null,
    ownerIsOwnerExploitation: correctDog?.value
      ? correctDog.value.ownerIsOwnerExploitation
      : null,
    specie: correctDog.value
      ? typeof correctDog.value?.specie === "string"
        ? correctDog.value?.specie?.split(",")
        : correctDog.value?.specie
      : null,
    specieOther:
      correctDog.value && correctDog.value.specie?.includes("other")
        ? correctDog.value?.specieOther
        : "",
    nbPersons: correctDog.value ? correctDog.value?.nbPersons : "",
    humainPresence: correctDog.value
      ? typeof correctDog.value?.humainPresence === "string"
        ? correctDog.value?.humainPresence?.split(",")
        : correctDog.value?.humainPresence
      : "",
    otherDogsPresence: correctDog.value
      ? typeof correctDog.value?.otherDogsPresence === "string"
        ? correctDog.value?.otherDogsPresence?.split(",")
        : correctDog.value?.otherDogsPresence
      : "",
    oustiderHumanPresence: correctDog.value
      ? typeof correctDog.value?.oustiderHumanPresence === "string"
        ? correctDog.value?.oustiderHumanPresence?.split(",")
        : correctDog.value?.oustiderHumanPresence
      : "",
    otherTypeOfPredation: correctDog.value
      ? correctDog.value?.otherTypeOfPredation
      : "",
    isOtherSpecie: correctDog.value ? correctDog.value?.isOtherSpecie : null,
    otherSpecie: correctDog.value
      ? typeof correctDog.value?.otherSpecie === "string"
        ? correctDog.value?.otherSpecie?.split(",")
        : correctDog.value?.otherSpecie
      : null,
    otherTypeOfSystem: correctDog.value
      ? correctDog.value?.otherTypeOfSystem
      : null,
    notes: correctDog.value ? correctDog.value?.notes : null,
  };
}

export { getFatherForm, getMotherForm, getOwnerForm, getMasterForm, getFullMasterForm, getForm };
