<template>
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 0H3C1.34316 0 0 1.34306 0 3V15C0 16.6568 1.34316 18 3 18H21C22.6569 18 24 16.6568 24 15V3C24 1.34306 22.6569 0 21 0ZM3 1.5H21C21.8271 1.5 22.5 2.17289 22.5 3V4.68787L13.3506 11.5503C12.5537 12.1465 11.4463 12.1465 10.6494 11.5503L1.5 4.68787V3C1.5 2.17289 2.17289 1.5 3 1.5ZM22.5 15C22.5 15.8271 21.8271 16.5 21 16.5H3C2.17289 16.5 1.5 15.8271 1.5 15V6.56222L9.75 12.75C10.4121 13.2473 11.206 13.4956 12 13.4956C12.794 13.4956 13.5879 13.2473 14.25 12.75L22.5 6.56222V15Z"
      fill="#659F12"
    />
  </svg>
</template>
