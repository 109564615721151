<script setup>
</script>
<template>
  <div class="line">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.line{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
</style>
