<script setup>

import {ref} from "vue";
import { useStore } from "../../stores";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

defineProps({
  disabled: Boolean,
  items: Array,
  type: String,

})

const emit = defineEmits(['abord'])

const open = ref(false)


const abord = () => {
  store.resetCurrentCensus();
  router.push({ path: `/census/add` });

  open.value = false
}
</script>
<template>

  <button
      :disabled="disabled"
      type="button"
      @click="open = true"
      class="tw-bg-error tw-text-white tw-py-3 tw-border-none tw-uppercase tw-px-10 tw-rounded-2xl tw-cursor-pointer tw-font-medium"
      :class="{'tw-opacity-50': disabled}"
  >
    Abandonner
  </button>

  <transition name="bounce">

    <div
        v-if="open"
        class="tw-fixed tw-inset-0 tw-bg-white tw-bg-opacity-50 tw-z-50 tw-flex tw-items-center tw-justify-center"
    >

      <div class="tw-rounded-lg tw-p-10 tw-bg-white tw-shadow-lg">
        <h1 class="tw-text-xl">
          Êtes-vous sûr de vouloir abandonner ce recensement ?
        </h1>
        <div class="tw-flex tw-justify-center tw-w-full tw-space-x-5">
          <button
              type="button"
              @click="open = false"
              class="tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
          >
            Annuler
          </button>

          <button
              type="button"
              @click="abord"
              class="tw-bg-error tw-text-white tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
          >
            Abandonner
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .dropdown {
    min-width: 90%;
    left: 2.5%;
    padding: 0.5em;
  }
}

</style>