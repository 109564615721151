<script setup>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { reactive, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import userService from "../../services/user.service";
import TextInputController from "./TextInputController/TextInputController.vue";
import IconMail from "../icons/IconMail.vue";
import IconPhone from "../icons/IconPhone.vue";
import SelectInputController from "./SelectInputController/SelectInputController.vue";
import { useStore } from "../../stores";
import { yesOrNoConfig } from "@/config/DogConfig";
import AdminPermission from "@/components/permissions/AdminPermission";
import { createToaster } from "@meforma/vue-toaster";
import ConfirmDeleteUser from "./ConfirmDeleteUser"
import version from "../../../version.json"

const store = useStore();

function getCurrentUser() {
  return store.getUser;
}

const setUserRoles = (roles) => {
  const defaultRole = "ROLE_USER";
  const copyRoles = [...roles];
  copyRoles.splice(copyRoles?.indexOf(defaultRole), 1);
  return copyRoles;
};
const isAdmin = computed(() => store.userIsAdmin);

const props = defineProps({ user: Object });
const router = useRouter();
const error = ref();
const loading = ref(false);
const scope = `UserForm`;
const controller = new AbortController();
const state = reactive({
  lastname: props.user ? props.user.lastname : "",
  firstname: props.user ? props.user.firstname : "",
  email: props.user ? props.user.email : "",
  phoneNumber: props.user ? props.user.phoneNumber : "",
  roles: props.user ? setUserRoles(props.user.roles) : "",
  active: props.user ? props.user.active : "",
});
const toaster = createToaster({ position: "bottom" });
let showConfirmModal = ref(false);

const rules = computed(() => {
  return {
    lastname: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    firstname: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    email: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phoneNumber: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    roles: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
  };
});

const v$ = useVuelidate(rules, state, { $scope: scope });

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};

watch(
    () => state,
    async function () {
      error.value = "";
    }
);

async function submitForm() {
  loading.value = true;
  const body = {
    firstname: state.firstname,
    lastname: state.lastname,
    email: state.email,
    phoneNumber: state.phoneNumber,
    roles: Array.isArray(state.roles) ? state.roles : [state.roles],
    active: state.active,
  };
  try {
    if (props.user)
      await userService.updateUser(body, props.user.id, controller);
    else {
      await userService.addUser(body, controller);
    }
    loading.value = false;
    isAdmin.value && router.push({ path: `/users` });
    toaster.success("Formulaire correctement enregistré");
  } catch (e) {
    loading.value = false;
    error.value = "Une erreur est survenue";
    console.log(e);
  }
}

async function deleteUser() {
  loading.value = true;
  await userService.delete(props.user.id, controller);
  showConfirmModal.value = false
  router.push({ path: `/users` });
}
</script>

<template>
  <form class="form-column">
    <TextInputController
        :on-change="handleChange"
        :name="'lastname'"
        :model="state.lastname"
        :errors="v$.lastname.$errors"
        :on-blur="v$.lastname.$touch"
        :label="'Nom'"
        :placeholder="'Doe'"
    />
    <TextInputController
        :on-change="handleChange"
        :name="'firstname'"
        :label="'Prénom'"
        :model="state.firstname"
        :errors="v$.firstname.$errors"
        :on-blur="v$.firstname.$touch"
        :placeholder="'John'"
    />
    <TextInputController
        :on-change="handleChange"
        :name="'email'"
        :label="'Email'"
        :model="state.email"
        :errors="v$.email.$errors"
        :on-blur="v$.email.$touch"
        :placeholder="'Test@test.fr'"
    >
      <template #icon>
        <IconMail />
      </template>
    </TextInputController>
    <TextInputController
        :on-change="handleChange"
        :name="'phoneNumber'"
        :type="'phone'"
        :label="'Téléphone'"
        :model="state.phoneNumber"
        :errors="v$.phoneNumber.$errors"
        :on-blur="v$.phoneNumber.$touch"
        :maxlength="10"
        :placeholder="'+333 1 23 45 67'"
    >
      <template #icon>
        <IconPhone />
      </template>
    </TextInputController>

    <AdminPermission>
      <SelectInputController
          :name="'roles'"
          :model="state.roles[0]"
          :on-change="handleChange"
          :options="[
          { value: 'ROLE_CENSUS_TAKER', label: 'Recenseur' },
          { value: 'ROLE_ADMIN', label: 'Administrateur' },
        ]"
          :label="'Groupe'"
          :errors="v$.roles.$errors"
          :on-blur="v$.roles.$touch"
      />
      <SelectInputController
          :on-change="handleChange"
          :name="'active'"
          :model="state.active"
          :options="yesOrNoConfig"
          :label="'Actif'"
          :placeholder="'Doe'"
      />
    </AdminPermission>
    <Button
        type="transparent"
        margin-top="40px"
        @on-click="router.push({ path: '/politique-de-confidentialite' })"
    >
      Politique de confidentialité
    </Button>
    <Button
        v-if="user && getCurrentUser() && getCurrentUser().id != user.id"
        type="danger"
        :disabled="loading"
        @on-click="showConfirmModal = true"
    >
      Supprimer
    </Button>

    <Button
        type="primary"
        :disabled="v$.$invalid || loading"
        @on-click="submitForm()"
    >
      Valider
    </Button>

    <ConfirmDeleteUser
        :is-visible="showConfirmModal"
        @on-close="showConfirmModal = false"
        @delete="
        () => {
          deleteUser();
        }
      "
    />

    <p class="tw-text-center">version {{ version.version }}</p>
  </form>
  <div v-if="error">
    {{ error }}
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/css/_all.scss";
button {
  margin: auto;
}
</style>
