<script setup>
import iconShield from '../assets/images/icon-shield.svg';
import useVuelidate from '@vuelidate/core'
import {helpers} from '@vuelidate/validators'
import {reactive, computed, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import AuthService from '../services/auth.service';

const route = useRoute();
const router = useRouter()
const error = ref();


const state = reactive({
  token: route.params.token,
  codeLogin: '',
})

const rules = computed(() => {
      return {
        codeLogin: {
          required: helpers.withMessage('Le champ doit comporter 6 caractères !', (value) => {
            return value.length === 6
          }),
        },
      }
    }
)
const v$ = useVuelidate(rules, state)

async function submitForm() {
  console.log('submit')
  try {
    error.value = null;
    await AuthService.confirmLogin(route.params.token, state.codeLogin);
    await router.push({path: `/`});
  } catch (e) {
    if (e.response.data && e.response.data.info) {
      error.value = e.response.data.info;
    } else {
      error.value = 'Une erreur inattendue est survenue, veuillez contacter un administrateur !'
    }
  }
}


function cancel() {
  router.push({path: `/login`})
}

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};
</script>

<template>
  <AuthTemplate
    :icon="iconShield"
    title="Double identification"
    secondary-text="Merci de renseigner le code à 6 chiffres que vous avez reçu par mail"
  >
  <form @submit.prevent="submitForm()">
    <div
      :class="{ error: v$.codeLogin.$errors.length }"
      class="field"
    >
      <TextInputController
        :on-change="handleChange"
        :name="'codeLogin'"
        :model="state.codeLogin"
        :errors="v$.codeLogin.$errors"
        :on-blur="v$.codeLogin.$touch"
        :is-on-change="true"
        label="Code de validation"
        :placeholder="'Ex : 685745'"
      >
      </TextInputController>
   
    </div>

    <span
      v-if="error"
      class="error"
    >{{ error }}</span>

    <Button
      type="primary"
      :submit="true"
    >
      Validation
    </Button>
    <Button
      type="secondary"
      margin-top="20px"
      @on-click="cancel()"
    >
      Annulation
    </Button>
    </form>
    <div style="margin-bottom: 100px;" />
  </AuthTemplate>
</template>
<style lang="scss" scoped>
.field {
  width: 100%;
  margin-bottom: 30px;

  input {
    margin-top: 10px;
    height: 40px;
    width: 100%;
  }
}
</style>
