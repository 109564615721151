import axios from "axios";
import CacheHelper from "../helpers/cacheHelper";

class CensusService {
  getCensus(id, controller, callAPi = false) {
    id = parseInt(id);
    const cachedValue = CacheHelper.getCensusToSync(id);
    if (cachedValue && cachedValue.actions.length) {
      return cachedValue.census;
    }

    if(!navigator.onLine){
      const census = CacheHelper.getRecensementById(id)
      if (census) {
        return census;
      }
    }

    if (callAPi) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}censuses/${id}`, {
          signal: controller?.signal ?? null,
        })
        .then((response) => response.data);
    } else return CacheHelper.getRecensementById(id);
  }

  getCensusFiltered(page = 1, params, controller) {
    return axios
      .get(
        `${process.env.VUE_APP_API_URL}censuses?page=${page}${
          params ? params : ""
        }`,
        { signal: controller?.signal ?? null }
      )
      .then((response) => {
        return response.data;
      });
  }

  getRunningCensus(controller, isOnline = true, personId = null) {
    if (isOnline) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}censuses?status=running` + ( personId ? `&person.id=${personId}` : ''), {
          signal: controller?.signal ?? null,
        })
        .then((response) => {
          CacheHelper.addRecensements(response.data);
          return response.data;
        });
    } else return CacheHelper.getRecensements("running");
  }

  getCensusById(id, controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}censuses/${id}`, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  createCensus(body, controller) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}censuses`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  updateCensus(id, body, controller) {
    return axios
      .patch(`${process.env.VUE_APP_API_URL}censuses/${id}`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  getIncomingCensuses(controller, isOnline = true) {
    if (isOnline) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}censuses?status=scheduled`, {
          signal: controller?.signal ?? null,
        })
        .then((response) => {
          CacheHelper.addRecensements(response.data);
          return response.data;
        });
    } else return CacheHelper.getRecensements("scheduled");
  }

  getIncomingCensusesByUser(controller, userId, isOnline = true, personId = null) {
    if (isOnline) {
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}censuses?status=scheduled&order[date]=desc&page=1&limit=10` + ( personId ? `&person.id=${personId}` : ''),
          { signal: controller?.signal ?? null }
        )
        .then((response) => {
          CacheHelper.addRecensements(response.data);
          return response.data;
        });
    } else return CacheHelper.getRecensements("scheduled");
  }

  getToSyncCensuses(controller, isOnline = true, personId = null) {
    if (isOnline) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}censuses?status=to_sync` + ( personId ? `&person.id=${personId}` : ''), {
          signal: controller?.signal ?? null,
        })
        .then((response) => {
          return response.data;
        });
    } else return CacheHelper.getRecensements("to_sync");
  }
}
export default new CensusService();
