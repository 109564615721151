<script setup>
import { ref } from "vue";
import BaseFormComponent from "../BaseFormComponent/BaseFormComponent.vue";

const props = defineProps({
  name: String,
  label: String,
  model: [String, Number],
  placeholder: String,
  onBlur: Function,
  onKeydown: Function,
  errors: Object,
  readOnly: Boolean,
  type: String,
  multipleLines: Boolean,
  isOnChange: Boolean,
  rows: Number,
  tooltip: String,
  customClass: String,
  disabled: Boolean,
  onChange: Function,
  maxlength: Number,
  required: Boolean,
});
const isFulfilled = ref(true);
const numberRegex = /^[0-9]+$/

const changeHandler = (e) => {
  const value = e.target.value;
  isFulfilled.value = !!value;
  props.onKeydown(e.target.value, props.name);
};

const onChangeHandler = (e) => {
  let value = e.target.value
  if (props.type === 'phone' || props.type === 'zipCode' || props.type === 'num') {
    if (numberRegex.exec(e.target.value) !== null) {
      e.target.value = numberRegex.exec(value).input
    } else {
      e.target.value = value.slice(0, -1)
    }
  }
  isFulfilled.value = !!value;

  props.onChange(e.target.value, props.name);
};

const blurHandler = (e) => {
  props.onBlur();
};
</script>

<template>
  <BaseFormComponent
    :custom-class="'InputText ' + props.customClass"
    title-style="middle"
    :is-fulfilled="isFulfilled"
    :errors="props.errors"
    :title="label"
    :multiple-lines="multipleLines"
    :required="required"
  >
    <template #icon>
      <slot name="icon" />
    </template>
    <template #form-component>
      <input
        v-if="!multipleLines && !isOnChange"
        :class="'input'"
        :value="props.model || props.model == 0 ? props.model : ''"
        :onBlur="props.onBlur"
        :placeholder="props.placeholder"
        @input="onChangeHandler"
        :type="type ? type : 'text'"
        :readonly="!!props.readOnly"
        :disabled="props.disabled"
        @keypress.delete="changeHandler"
        :maxlength="maxlength ? maxlength : undefined"
        :style="[props.disabled || props.readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
      />
      <input
        v-if="!multipleLines && isOnChange"
        :class="'input'"
        :value="props.model ? props.model : ''"
        :onBlur="props.onBlur"
        :placeholder="props.placeholder"
        :onchange="onChangeHandler"
        :type="type ? type : 'text'"
        :readonly="!!props.readOnly"
        :disabled="props.disabled"
        @keypress.delete="onChangeHandler"
        :maxlength="maxlength ? maxlength : undefined"
        :style="[props.disabled || props.readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
      />
      <textarea
        v-if="multipleLines"
        :class="'input'"
        :value="props.model ? props.model : ''"
        :onBlur="props.onBlur"
        :placeholder="props.placeholder"
        :onkeydown="changeHandler"
        :rows="props.rows ? props.rows : 4"
        :disabled="props.disabled"
        @keypress.delete="changeHandler"
        :style="[props.disabled || props.readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
      />
      <Tooltip
        v-if="props.tooltip"
        :text="props.tooltip"
        :custom-class="props.customClass"
      />
    </template>
  </BaseFormComponent>
</template>
<style lang="scss" scoped>
@import "../../../assets/css/_all.scss";
@import "./TextInputController.scss";
input {
  text-transform: uppercase;
}
</style>
