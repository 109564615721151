<script setup>
import { useStore } from "../../stores";
import { reactive, defineEmits } from "vue";
import { getRecensementParamsWithDateFilters } from "@/helpers/queryhelper";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import DatePickerController from "@/components/form/DatePickerController/DatePickerController";
import { departments } from "../../config/DepartmentConfig";
import { raceConfig } from "@/config/DogConfig";

defineProps({
  isVisible: Boolean,
});

const store = useStore();
const emit = defineEmits(["queryParams", "onClose"]);
const departmentsEntries = departments.map((dpt) => {
  return {
    label: `(${dpt.num_dep}) ${dpt.dep_name}`,
    value: dpt.num_dep,
  };
});

const inputValues = reactive({
  name: "",
  zipCode: "",
  city: "",
  address: "",
  lastname: "",
  edeNumber: "",
  date: "",
  identificationNum: "",
  breed: "",
  dateStart: "",
  dateEnd: "",
});

const handleChange = (value, name) => {
  inputValues[name] = value;
};

function submitForm() {
  try {
    const params = getRecensementParamsWithDateFilters(inputValues);
    emit("queryParams", params);
  } catch (e) {
    console.log(e);
  }
}
function resetFilters() {
  inputValues["name"] = "";
  inputValues["zipCode"] = "";
  inputValues["city"] = "";
  inputValues["address"] = "";
  inputValues["lastname"] = "";
  inputValues["edeNumber"] = "";
  inputValues["date"] = "";
  inputValues["identificationNum"] = "";
  inputValues["breed"] = "";
  inputValues["dateStart"] = "";
  inputValues["dateEnd"] = "";
}
</script>
<template>
  <transition name="bounce">
    <div v-if="isVisible" class="dropdown">
      <form>
        <div class="modal-title">RECHERCHE D'UN RECENSEMENT</div>
        <div class="close-modal" @click="$emit('onClose')">X</div>

        <TextInputController
            :on-change="handleChange"
            :name="'name'"
            :label="`NOM DE L'EXPLOITATION`"
            :model="inputValues.name"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'address'"
            :label="`ADRESSE`"
            :model="inputValues.address"
            :placeholder="''"
        />

        <div class="row-inputs">
          <div>
            <SelectInputController
                name="zipCode"
                :model="inputValues.zipCode"
                :on-change="handleChange"
                :options="departmentsEntries"
                label="DEPARTEMENT"
                :is-department-input="true"
                :maxlength="5"
            />
          </div>

          <div class="city-input">
            <TextInputController
                :on-change="handleChange"
                :name="'city'"
                :label="`VILLE`"
                :model="inputValues.city"
                :placeholder="''"
            />
          </div>
        </div>
        <div class="row-date-inputs">
          <p>Du</p>

          <div>
            <DatePickerController
                :on-change="handleChange"
                :model="inputValues.dateStart"
                name="dateStart"
                label="Début"
                custom-class="green-input"
            />
          </div>
          <p>Au</p>
          <div>
            <DatePickerController
                :on-change="handleChange"
                :model="inputValues.dateEnd"
                name="dateEnd"
                label="Fin"
                custom-class="green-input"
            />
          </div>
        </div>

        <TextInputController
            :on-change="handleChange"
            :name="'edeNumber'"
            :label="`N° EDE DE L'EXPLOITATION`"
            :model="inputValues.edeNumber"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'lastname'"
            :label="`NOM DU RECENSEUR`"
            :model="inputValues.lastname"
            :placeholder="''"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'identificationNum'"
            :label="`N° IDENTIFICATION DU CHIEN`"
            :model="inputValues.identificationNum"
            :placeholder="''"
        />
        <SelectInputController
            name="breed"
            :model="inputValues.breed"
            :on-change="handleChange"
            :options="raceConfig"
            label="Race/Apparence raciable"
        />

        <DatePickerController
            class="date-picker"
            label="Date du recensement"
            name="date"
            :model="inputValues.date"
            :on-change="handleChange"
            custom-class="green-input"
        />
      </form>
      <Button class="search-btn" @on-click="submitForm()"> RECHERCHER </Button>
      <Button class="reset-btn" @on-click="resetFilters()">
        REINITIALISER
      </Button>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 125%;
  background-color: white;
  z-index: 1;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    margin-top: 2em;
    margin-bottom: 1.5em;
    color: $green;
  }

  form {
    padding: 0.5em 1em 0 1em;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    position: relative;
    gap: 1em;

    .close-modal {
      position: absolute;
      left: 91%;
      top: 4%;
      font-weight: 600;
    }

    input {
      height: 30px !important;
      border: 1px solid $altGreen;
      border-radius: 6px;
      width: 100%;
    }

    label {
      margin-top: 1.5em;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 0.5em;
      padding-left: 0.2em;
    }
  }

  .row-inputs {
    display: flex;

    div:first-child {
      width: 85%;
    }

    .city-input {
      width: 80%;
    }
  }

  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: #659f12;
    color: white;
    border-radius: 20px;
    margin-top: 2em !important;
  }

  .reset-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: grey;
    color: white;
    border-radius: 20px;
    margin-top: 2em !important;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-height: 741px) {
  form {
    margin-top: 10em;
  }

  .modal-title {
    margin-bottom: 0 !important;
  }

  .close-modal {
    position: absolute;
    left: 91%;
    top: 4%;
    font-weight: 600;
  }
}

.row-date-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1em;

  p {
    width: 100%;
    text-align: start;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }

  div {
    width: 100%;
  }
}
</style>
