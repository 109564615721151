<script setup>
import {useStore} from '../../stores';


defineProps({
  isVisible: Boolean,
  items: Array,
  type: String,
})

const store = useStore();
const emit = defineEmits(['onClick'])

function canShowItem(item) {
  if (!item.isAdmin) {
    return true;
  }

  return store.userIsAdmin;
}
</script>
<template>
  <transition name="bounce">
    <div
        v-if="isVisible"
        class="dropdown"
    >
      <template
          v-for="item in items"
          :key="item.name"
      >
        <div
            v-if="!item.link && canShowItem(item)"
            class="item"
            :class="{primary: type === 'primary', secondary: type === 'secondary'}"
            @click="emit('onClick', item)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  background-color: #D1E2B8;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .item {
    padding: 20px 0;
    margin: 0 40px;
    font-family: 'NunitoSansBold';
    transition: 0.3s;
    cursor: pointer;
    text-align: center;

    &.primary {
      color: $primary;
      border-bottom: 1px solid rgb(111, 144, 107, 0.4);
    }

    &.secondary {
      color: #EC9A29;
      border-bottom: 1px solid rgb(217, 169, 23, 0.5);
    }
  }

  .item:hover {
    color: #0a0d06;
  }

  a:last-child {
    .item {
      border-bottom: 0px;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

</style>