<script setup>
import {
  onMounted,
  ref,
  reactive,
  computed,


} from "vue";
import censusService from "../../../services/census.service";
import TabCensus from "../../../components/census/TabCensus.vue";
import DogDetail from "../../../components/census/DetailCensus/Dog/DogDetail.vue";
import OwnerDetail from "../../../components/census/DetailCensus/Dog/Owner/OwnerDetail.vue";
import HoldingDetail from "../../../components/census/DetailCensus/Holding/HoldingDetail.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "../../../stores";
import recensementService from "@/services/recensement.service";
import ConfirmValidation from "@/components/recensement-modals/confirm-validation";
import TopArrow from "@/assets/images/black-top-arrow.png";
import holdingService from "@/services/holding.service";

const route = useRoute();

const state = reactive({
  current: "dog",
  loading: true,
  error: null,
  census: null,
  exploitations: null,
  masters: [],
});
const store = useStore();
const router = useRouter();
const controller = ref(new AbortController());
const showConfirmModal = ref(false);
const isOffline = computed(() => !store.getIsOnline);
const userIsAdmin = computed(() => store.userIsAdmin);
let correctDog = computed(() => store.getCorrectDog);

onMounted(async () => {
  await refreshDatas();
  // TODO: apply better fix
  // if (route.query.dog && state.census.dogs.find(d => d.id == route.query.dog)) {
  //   store.setCorrectDog(state.census.dogs.find(d => d.id == route.query.dog));
  // } else {
    store.setCorrectDog(state.census.dogs[0]);
  // }
});

const validateCensus = async () => {
  try {
    const body = {
      status: "completed",
    };
    await censusService.updateCensus(route.params.censusId, body, controller);
    store.setCensusFilterType({
      name: "Recensements terminés",
      value: "terminés",
      status: "completed",
    });
    await router.push({ path: "/census" });
  } catch (e) {
    console.log(e);
  }
};

const refreshDatas = async () => {
  try {
    state.census = await censusService.getCensus(
        route.params.censusId,
        controller,
        !isOffline.value
    );
    if (state.census?.dogs.length === 0) {
      router.push(`/census/${state.census.id}/dogs`);
    }
    store.setCensusData(state.census);
    const masters = state.census?.dogs
        ? state.census.dogs.filter((dog) => dog.master).map((d) => d.master)
        : [];
    let masterIds = [];
    state.masters = masters.filter((master) => {
      if (!masterIds.includes(master.id)) {
        masterIds.push(master.id);
        return master;
      }
    });

    state.exploitations = !isOffline.value
        ? await holdingService.getHoldingById(
            state.census.exploitation.id,
            controller
        )
        : state.census.holding;
    state.loading = false;
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 404) {
      state.error = "Le recensement est introuvable !";
    } else {
      state.error =
          "Une erreur inattendue est survenue, veuillez contacter un administrateur !";
    }
    state.loading = false;
  }
};
</script>
<template>
  <LoaderList v-if="state.loading" />
  <ConfirmValidation
      :is-visible="showConfirmModal"
      @onClick="validateCensus"
      @onClose="showConfirmModal = false"
  />
  <div v-if="!state.loading && state.census" id="content" class="content">
    <TabCensus :current="state.current" @onChange="state.current = $event" />

    <div
        v-if="
        recensementService.getRateCompletion(state.census) === '100' &&
        state.census.status !== 'completed'
      "
        class="census-validation-msg"
        @click="showConfirmModal = true"
    >
      Le recensement est prêt à être validé
    </div>
    <div className="detail">
      <a class="btn" href="#content"><img :src="TopArrow" alt="arrow" /></a>
      <DogDetail
          v-if="state.census && state.census.dogs && correctDog"
          v-show="state.current === 'dog'"
          :current-page="state.current"
          :dogs="state.census.dogs"
          :census="state.census"
          :exploitation="state.census.exploitation"
          :disabled="state.census.status === 'completed' && !userIsAdmin"
          :census-id="state.census.id"
          :is-offline="isOffline"
          :census-progression="recensementService.getRateCompletion(state.census)"
          :show-modal="showConfirmModal"
          :census-user-id="state.census.person.id"
          @showModal="showConfirmModal = true"
          :refreshDatas="refreshDatas"
      />
      <OwnerDetail
          v-if="state.census && state.census.dogs && correctDog"
          v-show="state.current === 'owner'"
          :current-page="state.current"
          :dogs="state.census.dogs"
          :census="state.census"
          :exploitation="state.census.exploitation"
          :disabled="state.census.status === 'completed' && !userIsAdmin"
          :census-id="state.census.id"
          :is-offline="isOffline"
          :census-progression="recensementService.getRateCompletion(state.census)"
          :show-modal="showConfirmModal"
          :census-user-id="state.census.person.id"
          @showModal="showConfirmModal = true"
          :refreshDatas="refreshDatas"
          :masters="state.masters"
      />
      <!-- <MasterDetail
        v-if="state.census"
        v-show="state.current === 'master'"
        :masters="state.masters"
        :holding="state.census.exploitation"
        :disabled="state.census.status === 'completed' && !userIsAdmin"
        :census="state.census"
        :census-id="state.census.id"
        :is-offline="isOffline"
        :census-progression="recensementService.getRateCompletion(state.census)"
        :show-modal="showConfirmModal"
        :census-user-id="state.census.person.id"
        @showModal="showConfirmModal = true"
        :refreshDatas="refreshDatas"
      /> -->
      <HoldingDetail
          v-if="state.census && state.census.exploitation"
          v-show="state.current === 'holding'"
          :current-page="state.current"
          :holding="state.census.exploitation"
          :masters="state.masters ? state.masters : []"
          :is-offline="isOffline"
          :dogs="state.census.dogs"
          :census="state.census"
          :census-id="state.census.id"
          :disabled="state.census.status === 'completed' && !userIsAdmin"
          :census-progression="recensementService.getRateCompletion(state.census)"
          :show-modal="showConfirmModal"
          :census-user-id="state.census.person.id"
          @showModal="showConfirmModal = true"
      />
    </div>
  </div>
  <span v-if="state.error" className="error">{{ state.error }}</span>
</template>
<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.detail {
  margin-top: 10px;
}

.btn {
  position: absolute;
  top: 92%;
  left: 85%;
  z-index: 2;

  img {
    width: 40px;
  }
}

.census-validation-msg {
  width: 100%;
  text-decoration: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  background-color: $green;
  justify-content: center;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
}

@media screen and (max-width: 1280px) {
  .page main {
    flex: 1;
    //overflow: unset !important;
    padding: 0px 30px;
  }
}

@media screen and (min-width: 1281px) {
  .content {
    padding-top: 15px;
  }
}
</style>
