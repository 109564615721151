<script setup>
import { useStore } from "../../stores";
import { reactive, defineEmits } from "vue";
import {
  getParamsFilters,

} from "@/helpers/queryhelper";
import { departments } from "../../config/DepartmentConfig";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import SelectInputController from "../form/SelectInputController/SelectInputController.vue";
import DatePickerController from "@/components/form/DatePickerController/DatePickerController";

defineProps({
  isVisible: Boolean,
});

const store = useStore();
const emit = defineEmits(["queryParams", "onClose"]);
const controller = new AbortController();
let isFormValid = false;
const departmentsEntries = departments.map((dpt) => {
  return {
    label: `(${dpt.num_dep}) ${dpt.dep_name}`,
    value: dpt.num_dep,
  };
});

const inputValues = reactive({
  name: "",
  address: "",
  zipCode: "",
  city: "",
  edeNumber: "",
  siretNumber: "",
  date: "",
});

const handleChange = (value, name) => {
  inputValues[name] = value;
};

function submitForm() {
  try {
    const params = getParamsFilters(inputValues);
    emit("queryParams", params);
  } catch (e) {
    console.log(e);
  }
}
</script>
<template>
  <transition name="bounce">
    <div v-if="isVisible" class="dropdown">
      <form>
        <div class="modal-title">Recherche d'une exploitation</div>
        <div class="close-modal" @click="$emit('onClose')">X</div>
        <TextInputController
            :on-change="handleChange"
            :name="'name'"
            :label="`NOM DE L'EXPLOITATION`"
            :model="inputValues.name"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'address'"
            :label="`ADRESSE`"
            :model="inputValues.address"
        />
        <div class="row-inputs">
          <div>
            <SelectInputController
                name="zipCode"
                :model="inputValues.zipCode"
                :on-change="handleChange"
                :options="departmentsEntries"
                label="DEPARTEMENT"
                :is-department-input="true"
                :maxlength="5"
            />
          </div>

          <div class="city-input">
            <TextInputController
                :on-change="handleChange"
                :name="'city'"
                :label="`VILLE`"
                :model="inputValues.city"
            />
          </div>
        </div>
        <DatePickerController
            class="date-picker"
            label="DATE DU RECENSEMENT"
            :name="'date'"
            :model="inputValues.date"
            :on-change="handleChange"
            custom-class="green-input"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'edeNumber'"
            :label="`N° EDE DE L'EXPLOITATION`"
            :model="inputValues.edeNumber"
        />
        <TextInputController
            :on-change="handleChange"
            :name="'siretNumber'"
            :label="`N° SIRET`"
            :model="inputValues.siretNumber"
        />
      </form>
      <Button
          class="search-btn"
          :disabled="
          inputValues.name === '' &&
          inputValues.address === '' &&
          inputValues.city === '' &&
          inputValues.zipCode === '' &&
          inputValues.edeNumber === '' &&
          inputValues.date === '' &&
          inputValues.siretNumber === ''
        "
          @on-click="submitForm()"
      >
        RECHERCHER
      </Button>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #ffff;
  z-index: 1;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    margin-top: 2em;
    margin-bottom: 1.5em;
    color: #659f12;
  }

  form {
    padding: 0.5em 1em 0 1em;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    gap: 15px;
    position: relative;

    .close-modal {
      position: absolute;
      left: 91%;
      top: 4%;
      font-weight: 600;
    }

    input {
      height: 30px !important;
      border-radius: 6px;
      width: 100%;
    }

    label {
      margin-top: 1.5em;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 0.5em;
      padding-left: 0.2em;
    }
  }

  .row-inputs {
    display: flex;

    div:first-child {
      width: 98%;
    }

    .city-input {
      width: 80%;
    }
  }

  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: #659f12;
    color: white;
    border-radius: 14px;
    margin-top: 5% !important;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.error {
  text-align: center;
  color: $danger;
  margin-bottom: 0.5em;
}
</style>
