<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useStore } from '../../stores';
import censusService from '../../services/census.service';
import LoaderList from '../loaders/LoaderList.vue';
import CensusHoldingCreation from '../form/CensusHoldingCreation.vue';

const store = useStore();
const controller = ref(new AbortController());
const loading = ref(true);
const censusId = ref(null);


onMounted(async () => {
  const census = await censusService.getCensusFiltered( 1 , `&order[id]=desc` , controller)
  censusId.value = census['hydra:member'].length > 0 ? census['hydra:totalItems'] + 1 : 1


  loading.value = false;
});

onUnmounted(() => {
  controller.value.abort();
});
</script>

<template>
  <LoaderList v-if="loading" />
  <div
      v-if="!loading"
      class="main-content"
  >
    <h1>{{ `Préparation du recensement n°${censusId}` }}</h1>
    <div class="page-content">
      <CensusHoldingCreation :holding="store.getCurrentExploitation" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/_variables.scss';

.main-content {
  position: relative;
  width: 100%;

  .page-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
@media screen and (min-width: 1281px) {
  .main-content {
    max-width: 60vw;
  }
}
</style>
