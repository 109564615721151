<script setup>
import { reactive, watch } from "vue";

const props = defineProps({
  label: String,
  isItalic: Boolean,
  isUppercase: Boolean,
  customClass: String,
  defaultValue: Boolean,
  disabled: Boolean,
  readOnly: Boolean,
  id: String,
  required: Boolean,
});

const emit = defineEmits(["onChange"]);

const state = reactive({
  checked: props.defaultValue ? props.defaultValue : false,
});

watch(
  () => props.defaultValue,
  function (defaultValue) {
    state.checked = defaultValue;
  }
);

watch(
  () => state.checked,
  function (checked) {
    emit("onChange", checked);
  }
);
</script>
<template>
  <div class="radios-container" :class="{ active: state.checked }">
    <legend :class="{ uppercase: props.isUppercase, italic: props.isItalic }">
      {{ label }}{{ required ? ' *' : '' }}
    </legend>

    <div class="radios">
      <div class="radio">
        <input
          type="radio"
          :id="'yes' + id"
          :name="'yes' + id"
          :value="true"
          v-model="state.checked"
          :disabled="disabled || readOnly"
        />
        <label :for="'yes' + id">Oui</label>
      </div>
      <div class="radio">
        <input
          type="radio"
          :id="'no' + id"
          :name="'no' + id"
          :value="false"
          v-model="state.checked"
            :disabled="disabled || readOnly"
        />
        <label :for="'no' + id">Non</label>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "../../assets/css/_variables.scss";
.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}
.radios-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  legend {
    color: $black;
    font-size: 12px;
  }
  .radios {
    display: flex;
    gap: 20px;
    .radio {
      display: flex;
      align-items: center;
      label {
        color: $black;
        text-transform: uppercase;
        margin-left: 3px;
        margin-top: 5px;
        cursor: pointer;
      }
      input {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
