import axios from 'axios';

class UserService {
  getUsers(page = 1, controller, sort = 'firstname', order = 'asc') {
    return axios
      .get(`${process.env.VUE_APP_API_URL}users?page=${page}&order[${sort}]=${order}`, { signal: controller.signal })
      .then((response) => {
        /*if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }*/
        return response.data;
      });
  }

  getUserById(id, controller) {
    return axios.get(`${process.env.VUE_APP_API_URL}users/${id}`, { signal: controller.signal }).then((response) => {
      return response.data;
    });
  }

  addUser(body, controller) {
    return axios.post(`${process.env.VUE_APP_API_URL}users`, body, { signal: controller.signal }).then((response) => {
      return response.data;
    });
  }

  updateUser(body, id, controller) {
    return axios
      .put(`${process.env.VUE_APP_API_URL}users/${id}`, body, { signal: controller.signal })
      .then((response) => {
        return response.data;
      });
  }

  delete(id, controller) {
    return axios.delete(`${process.env.VUE_APP_API_URL}users/${id}`, { signal: controller.signal }).then((response) => {
      return response.data;
    });
  }
}
export default new UserService();
