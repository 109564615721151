import axios from "axios";

class PeopleService {
  get(page = 1, params, controller) {
    return axios
      .get(
        `${process.env.VUE_APP_API_URL}people?page=${page}${
          params ? params : ""
        }`,
        {
          signal: controller?.signal ?? null,
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getFilteredByExploitationId(exploitationId, page = 1, params, controller) {
    return axios
      .get(
        `${
          process.env.VUE_APP_API_URL
        }exploitations/${exploitationId}/people?page=${page}${
          params ? params : ""
        }`,
        {
          signal: controller?.signal ?? null,
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  create(body, controller) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}people`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }

  update(id, body, controller) {
    return axios
      .put(`${process.env.VUE_APP_API_URL}people/${id}`, body, {
        signal: controller?.signal ?? null,
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new PeopleService();
