import { onBeforeUnmount, onMounted } from "vue";

export function useClickOutside(targetRef, callbackFn) {
  setTimeout(() => {
    if (!targetRef.value) return;
  });

  let listener = (e) => {
    if (
      e.target === targetRef.value ||
      e.composedPath().includes(targetRef.value)
    ) {
      return;
    }

    if (typeof callbackFn == "function") {
      callbackFn();
    }
  };

  onMounted(() => {
    setTimeout(() => {
      window.addEventListener("click", listener);
    });
  });
  onBeforeUnmount(() => {
    window.removeEventListener("click", listener);
  });

  return {
    listener,
  };
}
