<template>
  <transition name="bounce">
    <div
        v-if="props.isVisible"
        class="dropdown"
    >
      <div class="note-container">
        <div
            class="close-modal"
            @click="$emit('onClose');"
        >
          X
        </div>

        <div class="notes">
          <div class="notes-title">
            Notes
          </div>
          <textarea
              :value="inputValues.note"
              @input="inputValues.note = $event.target.value"
          />
        </div>
      </div>
      <Button
          class="search-btn"
          @on-click="submitForm(); !props.isVisible"
      >
        ENREGISTRER LE CHIEN
      </Button>
    </div>
  </transition>
</template>

<script setup>
import {useStore} from '../../stores';
import {reactive, defineEmits, ref, onMounted} from "vue";
import dogsService from "@/services/dogs.service";
import {useRouter} from "vue-router";

const props = defineProps({
  isVisible: Boolean,
  dog: Object,
})

const store = useStore();
const router = useRouter();
const controller = ref(new AbortController());

const emit = defineEmits(['queryParams', 'onClose', 'onSubmitted'])
const inputValues = reactive({
  note: '',
})
const form = reactive({...inputValues});

function resetForm() {
  Object.assign(form, inputValues)
}

const handleChange = (value, name) => {
  form[name] = value;
};

async function submitForm() {
  const body = {
    notes: inputValues.note
  };
  try {
    if (props.dog) await dogsService.updateDog(props.dog.id, body, controller);
    emit('onSubmitted');
    await router.push({path: `/dogs/${props.dog.id}`});
    resetForm();

  } catch (e) {
    console.log(e);
  }

}

onMounted(() => {
  if (props.dog) {
    inputValues.note = props.dog.notes;
  }
})
</script>


<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.dropdown {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  height: 168%;
  background-color: white;
  z-index: 1;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-head {
    text-align: center;
    font-size: 24px;
    margin-bottom: 0.8em;
    background-color: $green;
    color: white;
    padding: 1em;
    border-radius: 25px;

    &-title {
      font-size: 15px;
      font-width: 100;
    }

    &-id {
      font-weight: bold;
    }
  }

  .note-container {
    padding: 1em 1em 0 1em;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    position: relative;
    height: 42%;

    .close-modal {
      position: absolute;
      left: 91%;
      top: 4%;
      font-weight: 600;
    }

    .notes {
      display: flex;
      flex-direction: column;
      height: 95%;

      &-title {
        color: $green;
        font-weight: bold;
      }


      textarea {
        background: rgba(101, 159, 18, 0.3);
        border: 1px solid #C6D6C4;
        border-radius: 30px;
        padding-right: 1em;
        padding-left: 1.5em;
        padding-top: 1.5em;
        margin-top: 1em;
        height: 70%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }

  }


  .search-btn {
    width: 100%;
    text-decoration: none;
    padding: 0.5em 1em;
    text-align: center;
    background-color: $green;
    color: white;
    border-radius: 14px;
  }
}

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-height: 741px) {

  form{
    margin-top: 5em;
  }

  .modal-title{
    margin-bottom: 0 !important;
  }


  .close-modal{
    position: absolute;
    left: 91%;
    top: 4%;
    font-weight: 600;
  }
}


</style>