<script setup>
import { onMounted, onUnmounted, reactive, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import holdingService from '../../../services/holding.service';
import HoldingEditFormTemplate from '../../../components/form/HoldingEditFormTemplate.vue';
import IconArrowLeft from '../../../components/icons/IconArrowLeft.vue';
import HoldingDeleteModal from "../../../views/components/modal/delete/HoldingDeleteModal.vue";
import {useStore} from "../../../stores/index";

const route = useRoute();
const store = useStore();

const loading = ref(true);
const state = reactive({ holding: null });
const controller = ref(new AbortController());

const isUserAdmin = computed(() => store.userIsAdmin);

onMounted(async () => {
  if (route.params.holdingId) {
    const res = await holdingService.getHoldingById(route.params.holdingId, controller.value);
    state.holding = res;
  }
  loading.value = false;
});

onUnmounted(() => {
  controller.value.abort();
});
</script>
<template>
  <div class="content">
    <RouterLink
        v-if="!loading"
        class="nav-button tw-no-underline"
      :to="{path: '/holdings/' + state.holding.id, query: { history: 'true' } }"
    >
      <IconArrowLeft /> <span>Retour</span>
    </RouterLink>
    <h1>Exploitations</h1>
    <LoaderList v-if="loading" />
    <div class="tw-flex tw-items-center tw-justify-between">
      <h2 v-if="!loading">{{ state.holding.name }}</h2>

      <HoldingDeleteModal
          v-if="state.holding?.id && isUserAdmin"
          :holdingId="state.holding.id"
      />
    </div>

    <HoldingEditFormTemplate
      v-if="!loading"
      :key="state.user"
      :holding="state.holding"
    />
  </div>
</template>
<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}
</style>
