import CacheHelper from "./cacheHelper";

export default class SynchronizerCensusHelper {
  constructor(censusId) {
    const existingCensus = CacheHelper.getCensusToSync(censusId);
    this.census = existingCensus
      ? existingCensus.census
      : CacheHelper.getRecensementById(censusId);
    this.actions = existingCensus ? existingCensus.actions : [];
  }

  updateOwnerHolding(ownerId, body) {
    this.findAndDeleteOldAction("update_owner_holding");
    this.actions = [
      ...this.actions,
      {
        type: "update_owner_holding",
        id: ownerId,
        body: body,
        censusId: this.census.id,
      },
    ];
  }

  addOwnerHolding(body) {
    this.findAndDeleteOldAction("add_owner_holding");
    this.actions = [
      ...this.actions,
      { type: "add_owner_holding", body: body, censusId: this.census.id },
    ];
  }

  updateHolding(id, body, owner) {
    this.findAndDeleteOldAction("update_holding");
    if (owner) {
      body.owner = owner;
    }
    this.actions = [
      ...this.actions,
      { type: "update_holding", id: id, body: body, censusId: this.census.id },
    ];
    this.census.exploitation = { ...this.census.exploitation, ...body };
  }

  updateDog(id, datas) {
    const { body } = datas
    // const { ownerBody, body, form } = datas;
    // const {
    //   correctDog: { master: masterBase, owner: ownerBase },
    // } = datas;
    const previousActions = this.actions.filter(
      (a) => (a.type === "update_dog" && a.id !== id) || a.type !== "update_dog"
    );

    this.actions = [
      ...previousActions,
      {
        type: "update_dog",
        id: id,
        body: datas,
        censusId: this.census.id,
      },
    ];

    let previousCensusDogsToSync = this.census.dogs
      ? this.census.dogs.filter((d) => d.id !== id)
      : [];
    const currentDog = {
      id,
      ...body,
      // owner: !form.masterIsOwnerDog
      //   ? { ...ownerBase, ...ownerBody }
      //   : masterBase,
      // master: !form.masterIsOwnerDog
      //   ? masterBase
      //   : { ...ownerBase, ...ownerBody },
    };

    this.census.dogs = [...previousCensusDogsToSync, currentDog];
  }
  
  updateDogOwnerMaster(id, datas) {
    const { 
      correctDog,
      masterBody,
      ownerBody,
      exploitation,
      ownerId,
      masterId  ,
      masterIsOwnerExploitation,
      ownerIsOwnerExploitation,
      masterIsOwnerDog
    } = datas
    let dogMaster = CacheHelper.getDogMasterList();
    // const { ownerBody, body, form } = datas;
    // const {
    //   correctDog: { master: masterBase, owner: ownerBase },
    // } = datas;
    const previousActions = this.actions.filter(
      (a) => (a.type === "update_dog_master_owner" && a.id !== id) || a.type !== "update_dog_master_owner"
    );

    this.actions = [
      ...previousActions,
      {
        type: "update_dog_master_owner",
        id: id,
        correctDog,
        masterBody,
        ownerBody,
        exploitation,
        ownerId,
        masterId  ,
        masterIsOwnerExploitation,
        ownerIsOwnerExploitation,
        masterIsOwnerDog,
        censusId: this.census.id,
      },
    ];

    let previousOwnerToSync = dogMaster
      ? {entries: dogMaster.entries.filter((d) => (d.value !== ownerId) && (d.value !== masterId)), list: dogMaster.list.filter((d) => (d.id !== ownerId) && (d.id !== masterId))}
      : [];
    if (!masterIsOwnerDog) {
      if (ownerId > -1) {
        previousOwnerToSync.entries = 
          [
            ...previousOwnerToSync.entries,
            {
              value: ownerId,
              label: ownerBody.firstname
                ? `${ownerBody.firstname} ${ownerBody.lastname}`
                : `${ownerBody.companyname}`
            }
          ]
          previousOwnerToSync.list = 
          [
            ...previousOwnerToSync.list,
            {
              ...dogMaster.list.find((d) => (d.id === ownerId)),
              ...ownerBody
            },
          ]
      }
      if (masterId > -1) {
        previousOwnerToSync.entries = 
          [
            ...previousOwnerToSync.entries,
            {
              value: masterId,
              label: masterBody.firstname
                ? `${masterBody.firstname} ${masterBody.lastname}`
                : `${masterBody.companyname}`
            },
          ]
        previousOwnerToSync.list = 
          [
            ...previousOwnerToSync.list,
            {
              ...dogMaster.list.find((d) => (d.id === masterId)),
              ...masterBody
            },
          ]
      }
    } else {
      if (ownerId > 0) {
        previousOwnerToSync.entries = 
          [
            ...previousOwnerToSync.entries,
            {
              value: masterId,
              label: masterBody.firstname
                ? `${masterBody.firstname} ${masterBody.lastname}`
                : `${masterBody.companyname}`
            },
          ]
        previousOwnerToSync.list = 
          [
            ...previousOwnerToSync.list,
            {
              ...dogMaster.list.find((d) => (d.id === masterId)),
              ...{...masterBody, ...ownerBody}
            },
          ]
      }
    }

    localStorage.setItem("dogMaster", JSON.stringify(previousOwnerToSync))
  }

  updateMaster(id, body, form) {
    const previousActions = this.actions.filter(
      (a) =>
        (a.type === "update_master" && a.id !== id) ||
        a.type !== "update_master"
    );
    this.actions = [
      ...previousActions,
      { type: "update_master", id, body, form, censusId: this.census.id },
    ];

    const dogsNoMatchMaster = this.census.dogs.filter(
      (d) => d.master?.id !== id
    );

    let dogs = this.census.dogs.filter((d) => d.master?.id === id);
    dogs = dogs.map((dog) => {
      return {
        ...dog,
        master: { ...dog.master, ...body, id },
        owner: dog.masterIsOwnerDog
          ? { ...dog.master, ...body, id }
          : dog.owner,
      };
    });
    this.census.dogs = [...dogs, ...dogsNoMatchMaster];
    if (form.masterIsOwnerExploitation)
      this.census.exploitation.owner = { ...body, id };
  }

  findAndDeleteOldAction(type) {
    const index = this.actions.findIndex((item) => item.type == type);

    if (index != -1) {
      this.actions.splice(index, 1);
    }
  }

  persistInCache() {
    CacheHelper.addCensusToSync({ census: this.census, actions: this.actions });
  }
}
