<script setup>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import penSvg from "../../../assets/images/icon-pen.svg";
import { fr } from "date-fns/locale";
import {onMounted, reactive, ref, watch} from "vue";

const props = defineProps({
  model: Date,
  label: String,
  name: String,
  errors: Object,
  formatOptions: Object,
  onChange: Function,
  timepicker: Boolean,
  datepicker: Boolean,
  customClass: String,
  disabled: Boolean,
  readOnly: Boolean,
  required: Boolean,
});

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
};

const noHourOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const state = reactive({
  date: props.model ? props.model : null,
  formatedDate: props.model
    ? props.timepicker
      ? new Intl.DateTimeFormat("fr-FR", options).format(props.model)
      : new Intl.DateTimeFormat("fr-FR", noHourOptions).format(props.model)
    : null,
});

watch(
  () => props.model,
  (newDate, oldDate) => {
    if (new Date(newDate).getTime() !== new Date(oldDate).getTime()) {
      state.formatedDate = newDate
        ? props.timepicker
          ? new Intl.DateTimeFormat("fr-FR", options).format(props.model)
          : new Intl.DateTimeFormat("fr-FR", noHourOptions).format(props.model)
        : "";
      props.onChange(newDate, props.name);
    }
  }
);

const scrollWindow = () => {};

watch(
  () => state.date,
  (date) => {
    props.onChange(date, props.name);
  }
);

 const backgroundColor = ref('white');
 const borderColorColor = ref('#659F12FF');
 const greenBorderColor = ref('#659F12FF')
 const dogBorderColor = ref('#0F8B8DFF')
 const masterBorderColor = ref('#0086C9FF')
 const holdingBorderColor = ref('#EC9A29FF')

 onMounted(() => {
   if (props.disabled || props.readOnly) {
     backgroundColor.value = '#e5e7eb';
     borderColorColor.value = '#d1d5db';
     greenBorderColor.value = '#d1d5db';
     dogBorderColor.value = '#d1d5db';
     masterBorderColor.value = '#d1d5db';
     holdingBorderColor.value = '#d1d5db';
   }
 });
</script>

<template>
  <div class="form-item" data-title-style="middle" data-is-title-smaller="true">
    <div class="form-item-input">
      <div class="form-item-title">
        <div class="form-item-title-text">{{ label }}{{ required ? ' *' : '' }}</div>
        <span class="form-item-title-mandatory">*</span>
      </div>
      <div class="form-item-title-ghost-clone">
        <div class="form-item-title-ghost-clone-text">{{ label }}</div>
        <span class="form-item-title-ghost-clone-mandatory">*</span>
      </div>
      <Datepicker
        v-model="state.date"
        :enable-time-picker="timepicker ? timepicker : false"
        locale="fr"
        :format-locale="fr"
        cancel-text="Annuler"
        select-text="Sélectionner"
        @open="window?.scrollBy(100, 100)"
        @close="window?.scrollBy(-100, -100)"
        :disabled="disabled || readOnly"
        input-class-name="tw-gray-500"
      >
        <template #trigger>
          <div :class="props.customClass" class="date-input-container">
            <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-4 tw-h-6 tw-text-primary" width="24"
                 height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                 stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"/>
              <path d="M16 3v4"/>
              <path d="M8 3v4"/>
              <path d="M4 11h16"/>
              <path d="M7 14h.013"/>
              <path d="M10.01 14h.005"/>
              <path d="M13.01 14h.005"/>
              <path d="M16.015 14h.005"/>
              <path d="M13.015 17h.005"/>
              <path d="M7.01 17h.005"/>
              <path d="M10.01 17h.005"/>
            </svg>
            <div v-if="state.formatedDate">
              {{ state.formatedDate }}
            </div>
            <img :src="penSvg" />
          </div>
        </template>
        <template #action-preview="{ value }">
          {{ value ? value.toDateString() : "" }}
        </template>
      </Datepicker>

      <div v-if="errors?.length > 0">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../BaseFormComponent/BaseFormComponent.scss";

.date-picker {
  width: 100%;
}
.date-input-container {
  display: flex;
  gap: 20px;
  padding: 16px;
  border-radius: $inputRadius;

  img {
    width: 16px;
    height: 16px;
  }

  img:last-of-type {
    margin-left: auto;
  }
}

.green-input {
  border: 1px solid v-bind(greenBorderColor);
  background-color: v-bind(backgroundColor);
}

.dog-input {
  border: 1px solid v-bind(dogBorderColor);
  background-color: v-bind(backgroundColor);
}

.master-input {
  border: 1px solid v-bind(masterBorderColor);
  background-color: v-bind(backgroundColor);
}

.holding-input {
  border: 1px solid v-bind(holdingBorderColor);
  background-color: v-bind(backgroundColor);
}
</style>
