<script setup>
import axios from 'axios'
import { ref, onMounted, computed } from 'vue'

import DeleteElementModalComponent from "../../../../views/components/modal/delete/ElementDeleteModal.vue";
import useCensusStore from "../../../../stores/useCensusStore";
import useDogStore from "../../../../stores/useDogStore";
import {createToaster} from "@meforma/vue-toaster";

const censusStore = useCensusStore()
const dogStore = useDogStore()

const census = ref(null)
const show = ref(false)
const loading = ref(true)
const modal = ref(null)

const props = defineProps({ censusId: Number })
const emit = defineEmits(['deleted', 'error'])
const toaster = createToaster({ position: "bottom" });

const fetchCensus = async () => {
  loading.value = true
  census.value = (await axios.get(`${process.env.VUE_APP_API_URL}censuses/${props.censusId}`)).data
  loading.value = false
}
const handleDelete = async () => {
  loading.value = true
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API_URL}censuses/${props.censusId}`)
    if (response.status === 204) {
      toaster.success("Le recensement a bien été supprimé")
      if (census.value) {
        censusStore.deleteItem(census.value.id)

        if (census.value.dogs?.length > 0) {
          dogStore.deleteItems(census.value.dogs.map(dog => dog.id))
        }
      }
      emit('deleted')
      modal.value.show = false;
    } else {
      emit('error', response)
      toaster.error("Une erreur est survenue lors de la suppression du recensement")
    }
  } catch (e) {
    emit('error', e)
    toaster.error("Une erreur est survenue lors de la suppression du recensement")
  }
  loading.value = false
}

onMounted(() => {
  fetchCensus();
})
const name = computed(() => "n°" + census.value?.id + " - " + new Date(census.value?.date).toLocaleDateString() +  " " + census.value?.exploitation.name + " créé par " + census.value?.person.firstname + ' ' + census.value?.person.lastname)
</script>

<template>
  <DeleteElementModalComponent
      :loading="loading"
      @delete="handleDelete"
      :title="`Suppression du recensement ${name}`"
      :description="(census?.dogs ?? []).length ? 'Les éléments suivants seront également supprimés :' : ''"
      ref="modal"
      >
    <p v-for="dog in census?.dogs ?? []" :key="dog.id" class="tw-text-base tw-leading-7 tw-text-gray-600">Chien n°{{ dog.id }} - {{ dog.name }}</p>
  </DeleteElementModalComponent>
</template>