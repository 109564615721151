export default function useDog() {

    const getDogForm = (dog) => ({
        name: dog.name,
        behaviorWithHuman: dog.behaviorWithHuman,
        birthName: dog?.birthName,
        gender: dog?.gender,
        birthdate: dog?.birthdate,
        identificationNum: dog?.identificationNum,
        identificationType: dog?.identificationType,
        verificationPuce: dog?.verificationPuce,
        characteristics: dog?.characteristics,
        numLof: dog?.numLof,
        confirmLof: dog?.confirmLof,
        isLof: dog?.isLof,
        breed: dog?.breed,
        appearance: dog?.appearance,
        isIdentified: dog?.isIdentified,
        isBornHerd: dog?.isBornHerd,
        beforeTwoMonth: dog?.beforeTwoMonth,
        month: dog?.month,
        isFirstForBreeder: dog?.isFirstForBreeder,
        isHealthy: dog?.isHealthy,
        isAdaptedMorpho: dog?.isAdaptedMorpho,
        isAdaptedSize: dog?.isAdaptedSize,
        isAdaptedDemarche: dog?.isAdaptedDemarche,
        isAdaptedAplombs: dog?.isAdaptedAplombs,
        isAdaptedBackLine: dog?.isAdaptedBackLine,
        isAdaptedJaw: dog?.isAdaptedJaw,
        isAdaptedPigmentation: dog?.isAdaptedPigmentation,
        hasHealthyTrouble: dog?.hasHealthyTrouble,
        hipDysplasia: dog?.hipDysplasia,
        parcel: dog?.parcel,
        parcelHeifers: dog?.parcelHeifers,
        parcelOthers: dog?.parcelOthers,
        elbowDysplasia: dog?.elbowDysplasia,
        otherProblem: dog?.otherProblem,
        exploitationAge: dog?.exploitationAge,
        introductionCondition: dog?.introductionCondition,
        introductionConditionPuppies: dog?.introductionConditionPuppies,
        introductionConditionAdults: dog?.introductionConditionAdults,
        temper: dog?.temper,
        aboutDogTemper: dog.aboutDogTemper,
        respectingOthers: dog?.respectingOthers,
        // ageRespectingOthers: dog?.ageRespectingOthers,
        startRespectingOthers: dog?.startRespectingOthers,
        endRespectingOthers: dog?.endRespectingOthers,
        hasTesticleConcerned: dog?.hasTesticleConcerned,
        isGoodProtector: dog?.isGoodProtector,
        tookTime: dog?.tookTime,
        isEasyDog: dog?.isEasyDog,
        isManipulable: dog?.isManipulable,
        isAgressif: dog?.isAgressif,
        isMunipulableCensusTaker: dog?.isMunipulableCensusTaker,
        trustWithOthers: dog?.trustWithOthers,
        trustOthersWithoutYou: dog?.trustOthersWithoutYou,
        positionInGroup: dog?.positionInGroup,
        canWorkInGroup: dog?.canWorkInGroup,
        canWorkAlone: dog?.canWorkAlone,
        presentHerd: dog?.presentHerd,
        watchfulAwakening: dog?.watchfulAwakening,
        hasExcessiveBarking: dog?.hasExcessiveBarking,
        distanceHerd: dog?.distanceHerd,
        fenceRespect: dog?.fenceRespect,
        runAfterVehicles: dog?.runAfterVehicles,
        isAlwaysUsable: dog?.isAlwaysUsable,
        isPotentialBreeder: dog?.isPotentialBreeder,
        hasGoodImpregnateHerdBfrTwoMounth: dog?.hasGoodImpregnateHerdBfrTwoMounth,
        hasGoodFamiliarizationHumansBfrTwoMounths: dog?.hasGoodFamiliarizationHumansBfrTwoMounths,
        hasGoodFamiliarizationHumansAfterTwoMounths: dog?.hasGoodFamiliarizationHumansAfterTwoMounths,
        wasEasySetupPuppy: dog?.wasEasySetupPuppy,
        isClingyWithHerd: dog?.isClingyWithHerd,
        hasGoodGlobalAppreciation: dog?.hasGoodGlobalAppreciation,
        unknownDogTemper: dog.unknownDogTemper
            ? dog.unknownDogTemper?.toString()
            : "",
        unknownIsManipulable: dog?.unknownIsManipulable,
        unknownIsAgressif: dog?.unknownIsAgressif,
        unknownIsMunipulableCensusTaker: dog?.unknownIsMunipulableCensusTaker,
        unknownTrustWithOthers: dog.unknownTrustWithOthers,
        unknownTrustOthersWithoutYou: dog.unknownTrustOthersWithoutYou,
        adaptedMorphoCommentary: dog?.adaptedMorphoCommentary,
        nbPersons: dog?.nbPersons,
        humainPresence: dog?.humainPresence ? dog.humainPresence.split(",") : null,
        otherDogsPresence: dog?.otherDogsPresence ? dog.otherDogsPresence.split(",") : null,
        oustiderHumanPresence: dog?.oustiderHumanPresence ? dog?.oustiderHumanPresence.split(",") : null,
        otherTypeOfPredation: dog?.otherTypeOfPredation,
        isOtherSpecie: dog?.isOtherSpecie,
        otherSpecie: dog?.otherSpecie ? dog.otherSpecie.split(",") : null,
        otherTypeOfSystem: dog?.otherTypeOfSystem,
        recovery: dog?.recovery,
        wantPuppy: dog?.wantPuppy,
        father: dog?.father,
        mother: dog?.mother,
        owner: dog?.owner,
        master: dog?.master,
        masterIsOwnerDog: dog?.masterIsOwnerDog,
        notes: dog?.notes,
        isAgressifToOtherDogs: dog?.isAgressifToOtherDogs,
        typeOfPredation: dog?.typeOfPredation,
        typeOfDriving: dog?.typeOfDriving,
        typeOfSystem: dog?.typeOfSystem,
        otherTypeOfDriving: dog?.otherTypeOfDriving ? dog.otherTypeOfDriving.split(",") : null,
        otherStartAtPeriodHousing: dog?.otherStartAtPeriodHousing,
        otherEndAtPeriodHousing: dog?.otherEndAtPeriodHousing,
        ownerIsOwnerExploitation: dog?.ownerIsOwnerExploitation,
        specie: dog?.specie?.length > 0 ? dog.specie.split("##")[0].split(',') : [],
    });

    return {
        getDogForm
    };
}