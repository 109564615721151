<script setup>
    import iconAdd from "../../assets/images/icon-add.svg";

    const props = defineProps({
        icon: String,
        line1: String,
        line2: String,
        total: Number,
        type: String //primary | secondary
    })

    const emit = defineEmits(['onAdd', 'onSelect']);
</script>
<template>
  <div
    class="kpi"
    :class="{primary: props.type == 'primary', secondary: props.type == 'secondary' }"
    @click="$emit('onSelect')"
  >
    <div class="content-kpi">
      <div class="header">
        <img :src="props.icon"> 
        <img
          class="is-clickable"
          :src="iconAdd"
          @click="$emit('onAdd')"
        > 
      </div>
            
      <div class="texts">
        <label>{{ props.line1 }}</label>
        <label>{{ props.line2 }}</label>
      </div>
      <span>{{ props.total }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";
.kpi, .content-kpi, .header, img, label{
    cursor: pointer;
}
.kpi{
    width: 48%;
    height: 155px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;

     &.secondary{
        background-color: $secondaryLight;

        span{
            color: $secondary;
        }
    }

    &.primary{
      background: rgba(101, 159, 18, 0.3);

        span{
            color: $primary;
        }
    }

    .content-kpi{
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .is-clickable{
                cursor: pointer;
                transition: 0.3s;
            }

            .is-clickable:hover{
                opacity: 0.7;
            }
        }

        .texts{
            display: flex;
            flex-direction: column;
        }

        label{
            text-transform: uppercase;
            color: $black;
            font-family: 'NunitoSansBold';
            font-weight: 12px;
        }

        span{
            font-family: 'NunitoSansBold';
            font-size: 28px;
        }
    }
}
.kpi:hover{
    opacity: 0.7;
}

@media screen and (max-width: 1281px) {
  .texts {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 500px) {
  .content-kpi {
    margin: 10px!important;
  }
}


</style>