<template>
  <AuthTemplate
    :icon="iconAuth"
    title="Nouveau mot de passe"
    secondary-text="Veuillez indiquer votre nouveau mot de passe"
  >
    <form @submit.prevent="submitForm()">
      <TextInputController
        :on-change="firstInputChange"
        :name="'newPw'"
        :model="state.newPw"
        :errors="v$.newPw.$errors"
        :on-blur="v$.newPw.$touch"
        :label="'Nouveau mot de passe'"
        type="password"
      >
        <template #icon>
          <IconPassword />
        </template>
      </TextInputController>
      <TextInputController
        :on-change="handleChange"
        :name="'confirmPw'"
        :model="state.confirmPw"
        :errors="v$.confirmPw.$errors"
        :on-blur="v$.confirmPw.$touch"
        :label="'Confirmation mot de passe'"
        type="password"
      >
        <template #icon>
          <IconPassword />
        </template>
      </TextInputController>

      <span
        v-if="error"
        class="error"
      >{{ error }}</span>

      <Button
        type="primary"
        :disabled="v$.$invalid"
        :submit="true"
        margin-top="20px"
      >
        VALIDER
      </Button>
      <Button
        class="btn"
        type="transparent"
        margin-top="10px"
        @on-click="router.push({path: '/login'})"
      >
        ANNULER
      </Button>
    </form>
  </AuthTemplate>
</template>

<script setup>
import iconAuth from '../assets/images/icon-auth.svg';
import AuthTemplate from "@/components/auth-template/AuthTemplate";
import {useRoute, useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import {alphaNum, helpers} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/auth.service";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "bottom" });
const router = useRouter();
const route = useRoute();
const error = ref();
const state = reactive({
  newPw: '',
  confirmPw: '',
});

const isPwIdentical = () => state.newPw === state.confirmPw;

const rules = computed(() => {
  return {
    newPw: {
      newPw: helpers.withMessage("Le champ n'est pas au bon format !", alphaNum),
    },
    confirmPw: {
      confirmPw: helpers.withMessage("Le mot de passe doit correspondre au premier!", isPwIdentical),
    }
  };
});
const v$ = useVuelidate(rules, state);

const firstInputChange = (value, name) => {
  state[name] = value;
}

const handleChange = (value, name) => {
  state[name] = value;
  v$.value[name].$validate();
};

async function submitForm() {
  error.value = null;
  const body = {
    confirmPassword: state.confirmPw,
    password: state.newPw,
  }

  try {
    const isFormCorrect = await v$.value.$validate();
    if (isFormCorrect) {
      await AuthService.changePassword(route.query.token, body);
      toaster.success("Votre mot de passe a été modifié !");
      router.push({path: '/login'})
    }
  } catch (e) {
    error.value = "Les mots de passe ne correspondent pas !";
    console.log(e);
  }
}

</script>
<style lang="scss" scoped>

form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  input {
    margin-top: 40px;
  }
}

.error {
  margin-top: 1em;
  text-align: center;
}


</style>