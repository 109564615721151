<script setup>
import BaseFormComponent from "../BaseFormComponent/BaseFormComponent.vue";
import { onMounted, reactive, ref, watch } from "vue";

const props = defineProps({
  v$: Object,
  name: String,
  model: [String, Object, Boolean, Number, Array],
  isMultiple: Boolean,
  options: Object,
  isSearchable: Boolean,
  isDropdownOpened: Boolean,
  inputText: Boolean,
  onBlur: Function,
  onChange: Function,
  onIconClick: Function,
  errors: Object,
  label: [String, Boolean],
  firstTooltip: String,
  secondTooltip: String,
  thirdTooltip: String,
  fourthTooltip: String,
  customClass: String,
  disabled: Boolean,
  config: Array,
  isDepartmentInput: Boolean,
  readOnly: Boolean,
  maxMultiple: Number,
  required: Boolean,
});

const isFulfilled = ref(true);
const isOpen = ref(false);
let optionsArray = ref(props.options && props.options.length ? props.options : []);
let searchedOption = ref(null);

const state = reactive({
  selectedOptions: props.isMultiple
    ? props.config?.filter((configValue) =>
        props.model?.includes(configValue.value)
      )
    : []
    ? props.options?.find((o) => o.value === props.model)
    : null,
});

const clickHandler = (e) => {
  if (!props.disabled) {
    if (props.isMultiple && isOpen) return;
    else if (
      !e.target.classList.contains("select") &&
      !e.target.classList.contains("select-value")
    ) {
      isOpen.value = !isOpen.value;
    }
  }
};

const focusHandler = (e) => {
  if (!props.disabled) {
    isOpen.value = true;
  }
};
const blurHandler = (e) => {
  if (!props.disabled) {
    isOpen.value = false;
  }
  if (props.onBlur) {
    props.onBlur();
  }
};

const valueChangeHandler = (option, isOptionAlreadySelected) => {
  if (props.isMultiple) {
    if (!isOptionAlreadySelected) {
      if (!props.maxMultiple || state.selectedOptions.length < props.maxMultiple){
        state.selectedOptions = [...state.selectedOptions, option];
      }
    } else {
      const copy = [...state.selectedOptions];
      copy.splice(
        state.selectedOptions.findIndex((val) => val.value === option.value),
        1
      );

      state.selectedOptions = copy;
    }
  } else {
    if (!isOptionAlreadySelected) {
      state.selectedOptions = option;
    } else {
      state.selectedOptions = {value: undefined, label: undefined};
    }
  }

  if (state.selectedOptions) {
    isFulfilled.value = props.isMultiple
      ? state.selectedOptions.length > 0
      : !!state.selectedOptions;

    props.onChange(
      props.isMultiple
        ? state.selectedOptions.map((opt) => opt.value)
        : state.selectedOptions.value,
      props.name
    );
  }
};

watch(
  () => props.model,
  function () {
    if (props.isMultiple) {
      state.selectedOptions = props.config?.filter((configValue) =>
        props.model?.includes(configValue.value)
      );
    } else {
      state.selectedOptions = props.options?.find(
        (o) => o.value === props.model
      );
    }
  }
);

const isOptionSelected = (option) => {
  return !!props.isMultiple
    ? state?.selectedOptions?.find((opt) => opt.value === option.value)
    : state.selectedOptions?.value === option.value;
};

const handleSearch = (value) => {
  state.selectedOptions = null;
  optionsArray.value = props.options.filter((option) =>
    option.value.includes(value)
  );
  searchedOption.value = value;
};

onMounted(() => {
  if (state.selectedOptions) {
    isFulfilled.value = true;
  }
});

</script>

<template>
  <BaseFormComponent
    title-style="middle"
    :custom-class="'Select ' + props.customClass"
    :conditional-class="{ 'is-list-opened': isOpen }"
    :is-fulfilled="isFulfilled"
    :errors="props.errors"
    :title="label"
    :class="props.tooltip ? 'has-tooltip' : ''"
    :required="required"
  >
    <template #icon>
      <slot name="icon" />
    </template>
    <template #form-component>
      <div
        tabindex="0"
        :class="props.readOnly ? 'select select-disabled' : 'select'"
        @focusin="focusHandler"
        @focusout="blurHandler"
        @click="clickHandler"
        :style="[props.disabled || props.readOnly ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
      >
        <input
          v-if="props.isDepartmentInput"
          class="select-input"
          type="text"
          :value="
            state.selectedOptions ? state.selectedOptions.label : searchedOption
          "
          @focusin="focusHandler"
          @focusout="blurHandler"
          @click="clickHandler"
          @keyup="(e) => handleSearch(e.target.value)"
        />
        <div v-if="props.isMultiple" class="select-value">
          <div
            v-for="option of state.selectedOptions"
            :key="option.label"
            class="select-value-item"
          >
            {{ option.label }}
          </div>
        </div>
        <div
          v-else-if="state.selectedOptions && !props.isDepartmentInput"
          class="select-value"
        >
          <div class="select-value-item">
            {{ state.selectedOptions.label }}
          </div>
        </div>
        <div class="select-list-icon">
          <i class="fal fa-chevron-down" />
        </div>
        <div class="select-list">
          <div class="select-list-end">
            <div
              v-for="(option, index) of optionsArray"
              :key="option.value"
              :data-id="index"
              :value="option.value"
              class="select-list-item"
              :title="option.label"
              :class="{
                'is-selected': isOptionSelected(option),
              }"
              @click="
                () => {
                  valueChangeHandler(option, isOptionSelected(option));
                }
              "
            >
              <span class="select-list-item-value">{{ option.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <slot name="select-icon" />
      <Tooltip
        v-if="props.firstTooltip"
        :text="props.firstTooltip"
        :second-text="props.secondTooltip"
        :third-text="props.thirdTooltip"
        :fourth-text="props.fourthTooltip"
        :custom-class="props.customClass"
      />
    </template>
  </BaseFormComponent>
</template>

<style lang="scss" scoped>
@import "../../../assets/css/_all.scss";
@import "./SelectInputController.scss";

.select-disabled {
  pointer-events: none;
}
.select-list-item-value,
.select-value-item {
  text-transform: uppercase;
}
</style>
