<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { createToaster } from "@meforma/vue-toaster";

import { useStore } from "../../stores";
import iconPlus from "../../assets/images/icon-plus.svg";
import CacheHelper from "../../helpers/cacheHelper";
import iconDogs from "../../assets/images/icon-dogs.svg";
import censusService from "../../services/census.service";
import DogCard from "../dog-card/DogCard.vue";
import ConfirmDelete from "@/components/dog-modal/confirm-delete";
import TheButton from "@/components/button/Button";
import dogsService from "@/services/dogs.service";
import RecensementService from "@/services/recensement.service";
import ConfirmValidation from "@/components/recensement-modals/confirm-validation.vue";
import CensusDeleteModal from "../../views/components/modal/delete/CensusDeleteModal.vue";
import CensusResetModal from "../../views/components/modal/CensusResetModal.vue";
import whiteLeftArrow from "../../assets/images/white-left-arrow.svg";
import useDogStore from "../../stores/useDogStore";
import useCensusStore from "../../stores/useCensusStore";

const toaster = createToaster({ position: "bottom" });

const props = defineProps({ census: Object });
const censusStore = useCensusStore();
const store = useStore();
const dogStore = useDogStore()
const route = useRoute();
const router = useRouter();
const controller = new AbortController();
const showAddPopUp = ref(false);
const loading = ref(true);
const isOffline = computed(() => !store.getIsOnline);
let showConfirmModal = ref(false);
let dogToDelete = ref(null);

const dogsData = reactive({
  lists: props.census ? props.census.dogs : [],
  relatedCensus: props.census ? props.census : null,
});

const deleteDogHandler = async (dogToD) => {
  if(!isSameUser.value && !isUserAdmin.value) return

  try {
    loading.value = true;
    dogsData.lists.splice(
        dogsData.lists.findIndex((dog) => dog.id === dogToD.id),
        1
    );
    await dogsService.deleteDog(dogToD.id, controller);
    // const census = await censusService.updateCensus(
    //   dogsData.relatedCensus.id,
    //   { dogs: dogsData.lists },
    //   controller
    // );

    dogStore.deleteItem(dogToD.id)
    store.deleteDog(dogToD.id)
    const storedCensuses = localStorage.getItem("recensements") ? JSON.parse(localStorage.getItem("recensements")) : [];
    const filteredCensuses = storedCensuses.map(el => ({
      ...el,
      dogs: el.dogs ? el.dogs.filter(d => d.id !== dogToD.id): []
    }))

    localStorage.setItem("recensements",  JSON.stringify(filteredCensuses));

    dogToDelete.value = null;
    await RecensementService.upgradeIfComplete(dogsData.relatedCensus.id);
    loading.value = false;
    // location.reload();
  } catch (error) {
    dogToDelete.value = null;
    console.log(error);
  }
};

const launchCensus = async () => {
  try {
    loading.value = true;
    await censusService.updateCensus(
        dogsData.relatedCensus.id,
        { status: "running" },
        controller
    );
    //To update cache with this new census
    const census = await censusService.getCensus(
        dogsData.relatedCensus.id,
        controller,
        true
    );
    census && CacheHelper.addRecensements({ "hydra:member": [census] });
    await router.push({ path: `/census/${dogsData.relatedCensus.id}` });

    await router.push({
      name: 'census_details_page',
      params: {
        censusId: dogsData.relatedCensus.id,
        dogId: dogsData.lists[0].id
      }
    })

    censusStore.lastItemId = dogsData.relatedCensus.id;
    store.setCensusFilterType({
      name: "Recensements en cours",
      value: "en cours",
      status: "running",
    });
  } catch (error) {}
};
const validateCensus = async () => {
  for (let dog of dogsData.lists) {
    const monthsRef = new Date(1000 * 60 * 60 * 24 * 30 * 18);
    const today = new Date();
    const birthDate = new Date(dog.birthdate);

    if (today.getTime() - birthDate.getTime() > monthsRef.getTime()) {
      if(RecensementService.getDogRateCompletion(dog) < 100){
        toaster.error("Merci de valider tous les chiens avant de terminer le recensement");

        await router.push({name: 'census_details_page', params: {censusId: dogsData.relatedCensus.id, dogId: dog.id}})
        return
      }
    }
  }

  showConfirmModal.value = true;
}
watch(
    () => dogsData.lists,
    () => {
      if (dogsData.lists === 0) {
        showAddPopUp.value = true;
      }
    }
);
const handleDeleted = () => {
  store.currentCensus.step = 0;
  router.push({ path: "/census" });
};

const handleReset = () => {
  store.currentCensus.step = 0;
  censusStore.lastItemId = dogsData.relatedCensus.id;
  store.setCensusFilterType({
    name: "Recensements à valider",
    value: "à valider",
    status: "to_validate",
  });

  router.push({ path: "/census/" + dogsData.relatedCensus.id });
};

// const storedDog = computed(() => store.getSelectedDog);

onMounted(async () => {
  store.currentCensus.step = 3
  if (!props.census) {
    dogsData.relatedCensus = await censusService.getCensus(
        route.params.censusId,
        controller,
        !isOffline.value
    );
    //To update cache with this new census
    const census = await censusService.getCensus(
        dogsData.relatedCensus.id,
        controller,
        true
    );

    census && CacheHelper.addRecensements({ "hydra:member": [census] });
    dogsData.lists = dogsData.relatedCensus.dogs;
    // if (storedDog.value) {
    //   dogsData.lists.push(storedDog.value);
    // }
  }
  if (dogsData.relatedCensus.dogs.length === 0) {
    showAddPopUp.value = true;
  }
  store.setCensusData(dogsData.relatedCensus);
  loading.value = false;
});

const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return dogsData.relatedCensus.person.id === currentUser.value.id;
});

const confirm = async () => {
  try {
    const body = {
      status: "completed",
    };
    await censusService.updateCensus(dogsData.relatedCensus.id, body, controller);
    censusStore.lastItemId = dogsData.relatedCensus.id;
    store.setCensusFilterType({
      name: "Recensements terminés",
      value: "terminés",
      status: "completed",
    });
    store.currentCensus.step = 0;
    await router.push({path: "/census"});
  } catch (e) {
    toaster.error('Une erreur est survenue')
  }
}
const handleBack = () => {
  if(dogsData.relatedCensus?.status){
    store.censusFilterType.status = dogsData.relatedCensus.status;
  }
  router.push({ path: "/census" });
};
</script>

<template>
  <LoaderList v-if="loading" />

  <ConfirmValidation
      :is-visible="showConfirmModal"
      @onClick="confirm"
      @onClose="showConfirmModal = false"
  />

  <div
      v-if="!loading"
      class="main-content"
      :class="dogToDelete ? 'blured' : ''"
  >
    <div class="list-dog-title">
      <div class="tw-flex tw-space-x-2 tw-items-center tw-flex-1">
        <div
            class="tw-bg-primary tw-px-2 tw-text-white tw-rounded-full tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-relative tw-py-1 tw-no-underline"
             @click="handleBack"
        >
          <img :src="whiteLeftArrow" alt="left-arrow"/>
          Retour
        </div>

        <h1 class="tw-text-center tw-flex-1">
          {{
            dogsData.relatedCensus.dogs.length > 0
                ? "Liste des chiens"
                : "Ajouter un chien"
          }}
        </h1>
      </div>

      <div class="tw-flex tw-space-x-3 tw-items-center">
        <CensusDeleteModal
            v-if="isUserAdmin && dogsData.relatedCensus?.id"
            :census-id="dogsData.relatedCensus?.id"
            @deleted="handleDeleted"
        />

        <RouterLink
            v-if="dogsData.relatedCensus.dogs.length > 0"
            :to="{
            name: 'census_details_page',
            params: {
              censusId: dogsData.relatedCensus.id,
              dogId: dogsData.relatedCensus.dogs[0].id
            },
            query: {
              tab: 'holding'
            }
          }"
            class="tw-bg-holding tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-py-1 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-2 tw-no-underline"
        >
          <svg class="tw-h-5 tw-w-5" width="14" height="14" viewBox="0 0 14 14" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 7C14 7.4813 13.6533 7.875 13.2222 7.875H12.4443V13.125C12.4443 13.6082 12.096 14 11.6665 14H9.33343C8.90385 14 8.55565 13.6082 8.55565 13.125V10.0623C8.55565 9.82073 8.38158 9.62484 8.16681 9.62484H5.83362C5.61886 9.62484 5.44474 9.82073 5.44474 10.0623V13.125C5.44474 13.6082 5.09653 14 4.66696 14H2.33389C1.90434 14 1.55611 13.6082 1.55611 13.125V7.875H0.777778C0.345674 7.875 2.43056e-05 7.48007 0 7C0 6.75248 0.0933819 6.51066 0.265465 6.34118L6.48769 0.216617C6.65693 0.049875 6.85069 0 7 0C7.18285 0 7.36573 0.0722148 7.51231 0.216617L13.7345 6.34118C13.9066 6.51066 14 6.75248 14 7Z"></path>
          </svg>
          <span class="tw-hidden md:tw-inline">Exploitation</span>
        </RouterLink>

        <div
            v-if="(isUserAdmin || isSameUser) && dogsData.relatedCensus.status !== 'completed'"
            @click="validateCensus"
            class="tw-bg-primary tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-py-1 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-2 tw-no-underline"
        >
          <svg
              class="tw-h-5 tw-w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 12l5 5l10 -10" />
          </svg>

          <span class="max-md:tw-hidden tw-whitespace-nowrap">Terminer le recensement</span>
        </div>

        <CensusResetModal
            v-if="isUserAdmin && dogsData.relatedCensus.status === 'completed'"
            :census-id="dogsData.relatedCensus?.id"
            @success="handleReset"
        />
      </div>
    </div>

    <p
        v-if="dogsData.relatedCensus && dogsData.relatedCensus.status === 'scheduled'"
        class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
    >Merci de lancer le recensement pour remplir le formulaire.</p>

    <p
        v-if="dogsData.relatedCensus && (!isUserAdmin && !isSameUser)"
        class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ dogsData.relatedCensus.person.firstname }} {{ dogsData.relatedCensus.person.lastname }}, modification non autorisée</p>

    <p
        v-else-if="dogsData.relatedCensus && isUserAdmin"
        class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ dogsData.relatedCensus.person.firstname }} {{ dogsData.relatedCensus.person.lastname }}</p>

    <p class="tw-text-red-500 tw-font-bold tw-text-center" v-if="!showAddPopUp && store.uploadSizeError">{{ store.uploadSizeError }}</p>
    <div v-if="!showAddPopUp" class="page-content">
      <div class="dog-list">
        <DogCard
            v-for="dog in dogsData.lists.sort((a, b) => a.name.localeCompare(b.name))"
            :key="dog.name"
            :dog="dog"
            :census="dogsData.relatedCensus"
            @onDelete="dogToDelete = dog"
            :read-only="!isSameUser && !isUserAdmin"
        />
        <div
            v-if="dogsData.relatedCensus.status !== 'completed' && (isUserAdmin || isSameUser)"
            class="add-card"
            @click="
            () => {
              router.push({
                path: `/census/${dogsData.relatedCensus.id}/dogs/add`,
              });
            }
          "
        >
          <img alt="" :src="iconPlus" />
        </div>
      </div>
      <div
          v-if="isUserAdmin || isSameUser"
          class="actions">
        <TheButton
            v-if="dogsData.relatedCensus.status === 'scheduled'"
            type="green"
            @on-click="launchCensus"
        >
          Lancer le recensement
        </TheButton>
        <TheButton
            v-if="dogsData.relatedCensus.status === 'scheduled'"
            type="green"
            @on-click="
            () => {
              router.push(`/`);
            }
          "
        >
          Valider la préparation
        </TheButton>
      </div>
    </div>

    <div v-if="showAddPopUp" class="tw-flex tw-flex-1 tw-justify-center tw-items-center lg:tw-mt-20">
      <div
          class="tw-flex tw-min-w-96 tw-flex-col tw-items-center tw-justify-center tw-text-center tw-p-8 tw-rounded-xl tw-gap-2 tw-shadow-lg tw-shadow-primary-500/40"
      >
        <img
            class="tw-h-32 tw-w-32 tw-object-cover"
            :src="iconDogs"
            alt=""
        />

        <TheButton class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-py-2 tw-px-10 lg:tw-px-20"
                type="green"
                @on-click="
            () => {
              router.push(`/census/${dogsData.relatedCensus.id}/dogs/add`);
            }"
        >
          Ajouter un chien
        </TheButton>

        <TheButton
            v-if="dogsData.lists.length"
            class="tw-mt-2 tw-text-base"
            type="green"
            @click="launchCensus"
        >
          Finaliser la préparation
        </TheButton>
      </div>
    </div>
  </div>

  <ConfirmDelete
      :is-visible="!!dogToDelete"
      @on-close="dogToDelete = null"
      @delete-dog="
      () => {
        deleteDogHandler(dogToDelete);
      }
    "
  />
</template>

<style scoped lang="scss">
@import "../../assets/css/_variables.scss";

.main-content {
  position: relative;
  width: 100%;
  height: 70%;
  .actions {
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    button {
      margin: auto;
    }
  }

  .page-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    height: 100%;

    .dog-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .add-card {
        display: flex;
        background-color: $altGreen;
        align-items: center;
        justify-content: center;
        min-height: 210px;
        max-width: 250px;
      }

      > * {
        width: calc(50% - 7.5px);
        border-radius: 30px;
      }
    }
  }
}

.content-block {
  position: absolute;
  top: 50%;
  width: 80%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  border-radius: 30px;
  gap: 10px;
  -webkit-box-shadow: 0 0 29px -9px $green;
  box-shadow: 0 0 29px -9px $green;

  h1 {
    font-size: 26px;
  }

  button.green {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px;
  }
  .icon-button-bloc {
    img {
      width: 98px;
      height: 100px;
    }
  }
}

.blured {
  filter: blur(5px);
}

.validate {
  margin-top: 10px !important;
  font-size: 16px;
}

.list-dog-title {
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
  padding-bottom: 2em;

  h1 {
    padding: 0;
    margin: 0;
  }
}

@media screen and (min-width: 1281px) {
  .content-block {
    max-width: 500px;
    left: calc(50% + 165px);
  }
}

@media screen and (max-width: 800px) {
  .list-dog-title {
    display: flex;
    flex-direction: column;
    gap: 1em;

    h1 {
      padding: 0;
      margin: 0;
    }
  }
}
</style>
