import axios from "axios";
import CacheHelper from "../helpers/cacheHelper";
import censusService from "./census.service";
import useCensusStore from "../stores/useCensusStore";
import {useStore} from "../stores";

class RecensementService {
    getRecensements(
        page = 1,
        status,
        params,
        controller,
        isOnline = true,
        filterType,
        personId = null
    ) {
        //Mode local
        //return CacheHelper.getRecensements(status);
        if (isOnline && status != "to_sync") {
            let paramStatus = status
                ? `&status=${status}`
                : "";
            if (filterType.value.value === "à valider") {
                paramStatus = `&status=${filterType.value.status}`;
            }
            if (
                ["à valider", "en cours", "terminés"].includes(filterType.value.value)
            ) {
                paramStatus = `${paramStatus}&order[date]=desc`;
            }

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}censuses?page=${page}${paramStatus}${
            params ? params : ""
          }` + ( personId ? `&person.id=${personId}` : ''),
          { signal: controller.signal }
        )
        .then((response) => {
          CacheHelper.addRecensements(response.data);
          return response.data;
        });
    } else {
      return CacheHelper.getRecensements(status);
    }
  }

  getAllRecensements(controller) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}censuses?pagination=false`, {
        signal: controller.signal,
      })
      .then((response) => {
        return response.data;
      });
  }

  exportRecensements() {
    return axios
      .get(`${process.env.VUE_APP_API_URL}export/censuses`, {
        responseType: "blob",
      })
      .then((response) => {
        return response.data;
      });
  }

    getRateCompletion(census) {
        const booleanDogFields = [
            'withSiblings',
            'isFirstForBreeder',
            'isHealthy',
            'isAdaptedPigmentation',
            'hasHealthyTrouble',
            'isCalm',
            'tookTime',
            'isManipulable',
            'hasBehaviorTrouble',
            'isAgressif',
            'hasExcessiveBarking',
            'hasFastRecuperation',
            'wantPuppy',
            'isReproductive',
            'hasGoodImpregnationHerd',
            'isSterile',
            'isLof',
            'hasGoodFixationHerd',
            'hasGoodGlobalAppreciation',
            'isWorking',
            'isFiliationCertified',
            'ownerIsOwnerExploitation',
            'unknownIsManipulable',
            'unknownIsAgressif',
            'unknownIsMunipulableCensusTaker',
            'isOtherSpecie',
            'isEighteenMailSent',
        ];

        const requiredFieldDog = [
            "name",
            // 'birthName',
            "gender",
            "birthdate",
            "identificationNum",
            "identificationType",
            // "verificationPuce",
            "characteristics",
            // 'numLof',
            //'confirmLof',
            //"isLof",
            // "breed",
            "appearance",
            "isIdentified",
            // "number",
            "isBornHerd",
            "beforeTwoMonth",
            // "month",
            "isFirstForBreeder",
            "isHealthy",
            "isAdaptedMorpho",
            //"isAdaptedSize",
            //"isAdaptedDemarche",
            //"isAdaptedAplombs",
            //"isAdaptedBackLine",
            //"isAdaptedJaw",
            //"isAdaptedPigmentation",
            "hasHealthyTrouble",
            "hipDysplasia",
            "parcel",
            "parcelHeifers",
            "elbowDysplasia",
            "exploitationAge",
            "introductionCondition",
            //"introductionConditionPuppies",
            //"introductionConditionAdults",
            "behaviorWithHuman",
            "temper",
            //"aboutDogTemper",
            "respectingOthers",
            //"ageRespectingOthers",
            "isGoodProtector",
            "tookTime",
            "isEasyDog",
            "isManipulable",
            //"isAgressif",
            "isMunipulableCensusTaker",
            //"trustWithOthers",
            //"trustOthersWithoutYou",
            "positionInGroup",
            "canWorkInGroup",
            "canWorkAlone",
            "presentHerd",
            "watchfulAwakening",
            "hasExcessiveBarking",
            "distanceHerd",
            "fenceRespect",
            "runAfterVehicles",
            "isAlwaysUsable",
            "isPotentialBreeder",
            // "hasGoodImpregnateHerdBfrTwoMounth",
            // "hasGoodFamiliarizationHumansBfrTwoMounths",
            // "hasGoodFamiliarizationHumansAfterTwoMounths",
            // "wasEasySetupPuppy",
            //"isClingyWithHerd",
            //"hasGoodGlobalAppreciation",
            "unknownDogTemper",
            //"unknownIsManipulable",
            //"unknownIsAgressif",
            //"unknownIsMunipulableCensusTaker",
            "unknownTrustWithOthers",
            "unknownTrustOthersWithoutYou",
            //"adaptedMorphoCommentary",
            "recovery",
            "wantPuppy",
            "isAgressifToOtherDogs",
            // "owner",
            "typeOfSystem",
            "typeOfPredation",
            // "typeOfDriving",
            // "otherTypeOfConduct",
            //"otherStartAtPeriodHousing",
            //"otherEndAtPeriodHousing",
            // "ownerOwnsExploitation",
            "specie",
            "nbPersons",
            //"cpNumber",
            "humainPresence",
            "oustiderHumanPresence",
            //"otherTypeOfPredation",
            // "notes"
            "isOtherSpecie",
            "otherDogsPresence",
        ];

        const requiredFieldMaster = [
            /*'address',
                  'city',
                  'email',
                  'firstname',
                  'lastname',
                  'phoneNumber',
                  'zipCode', */
        ];

        const requiredFieldOwnerDog = ["city", "companyname", "zipCode", "address"];

        const requiredFieldHolding = [
            // 'address',
            // 'city',
            // 'comment',
            // 'cpNumber',
            //'edeNumber',
            //'mail',
            //'mobile',
            // 'name',
            //'siretNumber',
            //'zipCode',
        ];

        const requiredFieldOwner = [
            // "city",
            //'email',
            // "firstname",
            // "lastname",
            //'phoneNumber',
            // "zipCode",
            // "address",
        ];

        let nbField = 0;
        let nbFieldFilled = 0;
        const monthsRef = new Date(1000 * 60 * 60 * 24 * 30 * 18);
        const today = new Date();

        census.dogs.map((dog) => {
            let currentRequiredFieldDog = [...requiredFieldDog]
            if (dog.appearance) {
                if (dog.appearance !== 'undetermined' || dog.appearance === 'standard' || dog.appearance === 'cross' || dog.appearance === 'pure_lof') {
                    currentRequiredFieldDog = [...currentRequiredFieldDog, 'breed']
                }

                if (dog.appearance === 'pure_lof') {
                    currentRequiredFieldDog = [...currentRequiredFieldDog, 'numLof']
                }
            }

            if (dog.typeOfSystem === 'indoor') {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'typeOfDriving']
            }

            if (dog.typeOfPredation === 'indoor' && dog.typeOfPredation.includes('other')) {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'otherTypeOfPredation']
            }

            if (dog.isOtherSpecie) {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'otherSpecie']
            }

            if (dog.isOtherSpecie) {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'otherTypeOfSystem']
            }

            if (dog.isOtherSpecie && dog.otherTypeOfSystem !== 'indoor') {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'otherTypeOfDriving']
            }

            if (dog.isAdaptedMorpho === 'false') {
                currentRequiredFieldDog = [
                    ...currentRequiredFieldDog,
                    'isAdaptedSize',
                    'isAdaptedAplombs',
                    'isAdaptedBackLine',
                    'isAdaptedJaw',
                    'isAdaptedPigmentation',
                    'hasTesticleConcerned',
                    'adaptedMorphoCommentary'
                ]
            }

            if (dog.hasHealthyTrouble) {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'otherProblem']
            }

            if (dog.introductionCondition === 'other_puppies') {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'introductionConditionPuppies']
            }

            if (dog.introductionCondition === 'with_adults') {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'introductionConditionAdults']
            }

            if (dog.parcelHeifers?.includes('others')) {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'parcelOthers']
            }

            if (dog.respectingOthers === 'little' || dog.respectingOthers === 'a_lot') {
                currentRequiredFieldDog = [...currentRequiredFieldDog, 'startRespectingOthers', 'endRespectingOthers']
            }

            if (
                dog?.hasGoodImpregnateHerdBfrTwoMounth
                || dog?.hasGoodFamiliarizationHumansBfrTwoMounths
                || dog?.hasGoodFamiliarizationHumansAfterTwoMounths
                || dog?.wasEasySetupPuppy
                || dog?.isClingyWithHerd
            ) {
                currentRequiredFieldDog = [
                    ...currentRequiredFieldDog,
                    'hasGoodImpregnateHerdBfrTwoMounth',
                    'hasGoodFamiliarizationHumansBfrTwoMounths',
                    'hasGoodFamiliarizationHumansAfterTwoMounths',
                    'wasEasySetupPuppy',
                    'isClingyWithHerd',
                ]
            }

            const birthDate = new Date(dog.birthdate);
            const dogCanBeRecensed =
                today.getTime() - birthDate.getTime() > monthsRef.getTime();
            if (dogCanBeRecensed) {
                currentRequiredFieldDog.map((field) => {
                    nbField += 1;
                    if (dog[field] || dog[field] === 0 || (booleanDogFields.includes(field) && dog[field] === false)) {
                        nbFieldFilled += 1;
                    } else {
                        // console.log(field, dog[field]);
                    }
                });
            }
            requiredFieldMaster.map((field) => {
                nbField += 1;
                if (dog?.master[field] && dog?.master[field] != "") {
                    nbFieldFilled += 1;
                }
            });

            if (dog.father?.ownerName){
                nbField++;

                if(dog.father?.email || dog.father?.phone){
                    nbFieldFilled++;
                }
            }

            if (dog.mother?.ownerName){
                nbField++;

                if(dog.mother?.email || dog.mother?.phone){
                    nbFieldFilled++;
                }
            }

            requiredFieldOwnerDog.map((field) => {
                nbField += 1;
                if (field !== "companynamedog")
                    if (dog?.owner && dog?.owner[field]) {
                        nbFieldFilled += 1;
                    } else if (
                        (dog?.owner?.firstname && dog?.owner?.lastname) ||
                        dog?.owner?.companyname
                    ) {
                        nbFieldFilled += 1;
                    }
            });
        });
        requiredFieldHolding.map((field) => {
            nbField += 1;
            if (census.exploitation[field] && census.exploitation[field] != "") {
                nbFieldFilled += 1;
            } else {
                //console.log(field);
            }
        });

        if (census.exploitation.owner) {
            requiredFieldOwner.map((field) => {
                nbField += 1;
                if (
                    census.exploitation.owner[field] &&
                    census.exploitation.owner[field] != ""
                ) {
                    nbFieldFilled += 1;
                }
            });
        } else {
            nbField += requiredFieldOwner.length;
        }
        if (nbField == 0) {
            return 100;
        }
        return ((nbFieldFilled * 100) / nbField).toFixed(0);
    }

    getDogRateCompletion(dog) {
        const monthsRef = new Date(1000 * 60 * 60 * 24 * 30 * 18);
        const today = new Date();

        const birthDate = new Date(dog.birthdate);
        const dogCanBeRecensed =
            today.getTime() - birthDate.getTime() > monthsRef.getTime();
        if (!dogCanBeRecensed) {
            return 100;
        }

        const booleanDogFields = [
            'withSiblings',
            'isFirstForBreeder',
            'isHealthy',
            'isAdaptedPigmentation',
            'hasHealthyTrouble',
            'isCalm',
            'tookTime',
            'isManipulable',
            'hasBehaviorTrouble',
            'isAgressif',
            'hasExcessiveBarking',
            'hasFastRecuperation',
            'wantPuppy',
            'isReproductive',
            'hasGoodImpregnationHerd',
            'isSterile',
            'isLof',
            'hasGoodFixationHerd',
            'hasGoodGlobalAppreciation',
            'isWorking',
            'isFiliationCertified',
            'ownerIsOwnerExploitation',
            'unknownIsManipulable',
            'unknownIsAgressif',
            'unknownIsMunipulableCensusTaker',
            'isOtherSpecie',
            'isEighteenMailSent',
        ];

        let requiredFieldDog = [
            "name",
            // 'birthName',
            "gender",
            "birthdate",
            "identificationNum",
            "identificationType",
            // "verificationPuce",
            "characteristics",
            //'numLof',
            //'confirmLof',
            //"isLof",
            // "breed",
            "appearance",
            "isIdentified",
            // "number",
            "isBornHerd",
            "beforeTwoMonth",
            // "month",
            "isFirstForBreeder",
            "isHealthy",
            "isAdaptedMorpho",
            //"isAdaptedSize",
            //"isAdaptedDemarche",
            //"isAdaptedAplombs",
            //"isAdaptedBackLine",
            //"isAdaptedJaw",
            //"isAdaptedPigmentation",
            "hasHealthyTrouble",
            "hipDysplasia",
            "parcel",
            "parcelHeifers",
            "elbowDysplasia",
            "exploitationAge",
            "introductionCondition",
            //"introductionConditionPuppies",
            //"introductionConditionAdults",
            "behaviorWithHuman",
            "temper",
            //"aboutDogTemper",
            "respectingOthers",
            //"ageRespectingOthers",
            "isGoodProtector",
            "tookTime",
            "isEasyDog",
            "isManipulable",
            //"isAgressif",
            "isMunipulableCensusTaker",
            //"trustWithOthers",
            //"trustOthersWithoutYou",
            "positionInGroup",
            "canWorkInGroup",
            "canWorkAlone",
            "presentHerd",
            "watchfulAwakening",
            "hasExcessiveBarking",
            "distanceHerd",
            "fenceRespect",
            "runAfterVehicles",
            "isAlwaysUsable",
            "isPotentialBreeder",
            // "hasGoodImpregnateHerdBfrTwoMounth",
            // "hasGoodFamiliarizationHumansBfrTwoMounths",
            // "hasGoodFamiliarizationHumansAfterTwoMounths",
            // "wasEasySetupPuppy",
            //"isClingyWithHerd",
            //"hasGoodGlobalAppreciation",
            "unknownDogTemper",
            //"unknownIsManipulable",
            //"unknownIsAgressif",
            //"unknownIsMunipulableCensusTaker",
            "unknownTrustWithOthers",
            "unknownTrustOthersWithoutYou",
            //"adaptedMorphoCommentary",
            "recovery",
            "wantPuppy",
            "isAgressifToOtherDogs",
            // "owner",
            "typeOfSystem",
            "typeOfPredation",
            // "typeOfDriving",
            // "otherTypeOfConduct",
            //"otherStartAtPeriodHousing",
            //"otherEndAtPeriodHousing",
            // "ownerOwnsExploitation",
            "specie",
            "nbPersons",
            //"cpNumber",
            "humainPresence",
            "oustiderHumanPresence",
            //"otherTypeOfPredation",
            // "notes"
            "isOtherSpecie",
            "otherDogsPresence",
        ];

        if (dog.appearance) {
            if (dog.appearance !== 'undetermined' || dog.appearance === 'standard' || dog.appearance === 'cross' || dog.appearance === 'pure_lof') {
                requiredFieldDog = [...requiredFieldDog, 'breed']
            }

            if (dog.appearance === 'pure_lof') {
                requiredFieldDog = [...requiredFieldDog, 'numLof']
            }
        }

        if (dog.typeOfSystem === 'indoor') {
            requiredFieldDog = [...requiredFieldDog, 'typeOfDriving']
        }

        if (dog.typeOfPredation === 'indoor' && dog.typeOfPredation.includes('other')) {
            requiredFieldDog = [...requiredFieldDog, 'otherTypeOfPredation']
        }

        if (dog.isOtherSpecie) {
            requiredFieldDog = [...requiredFieldDog, 'otherSpecie']
        }

        if (dog.isOtherSpecie) {
            requiredFieldDog = [...requiredFieldDog, 'otherTypeOfSystem']
        }

        if (dog.isOtherSpecie && dog.otherTypeOfSystem !== 'indoor') {
            requiredFieldDog = [...requiredFieldDog, 'otherTypeOfDriving']
        }

        if (dog.isAdaptedMorpho === 'false') {
            requiredFieldDog = [
                ...requiredFieldDog,
                'isAdaptedSize',
                'isAdaptedAplombs',
                'isAdaptedBackLine',
                'isAdaptedJaw',
                'isAdaptedPigmentation',
                'hasTesticleConcerned',
                'adaptedMorphoCommentary'
            ]
        }

        if (dog.hasHealthyTrouble) {
            requiredFieldDog = [...requiredFieldDog, 'otherProblem']
        }

        if (dog.introductionCondition === 'other_puppies') {
            requiredFieldDog = [...requiredFieldDog, 'introductionConditionPuppies']
        }

        if (dog.introductionCondition === 'with_adults') {
            requiredFieldDog = [...requiredFieldDog, 'introductionConditionAdults']
        }

        if (dog.parcelHeifers?.includes('others')) {
            requiredFieldDog = [...requiredFieldDog, 'parcelOthers']
        }

        if (dog.respectingOthers === 'little' || dog.respectingOthers === 'a_lot') {
            requiredFieldDog = [...requiredFieldDog, 'startRespectingOthers', 'endRespectingOthers']
        }

        if (dog.technicalFollowUp) {
            requiredFieldDog = [
                ...requiredFieldDog,
                'hasGoodImpregnateHerdBfrTwoMounth',
                'hasGoodFamiliarizationHumansBfrTwoMounths',
                'hasGoodFamiliarizationHumansAfterTwoMounths',
                'wasEasySetupPuppy',
                'isClingyWithHerd',
            ]
        }

        const requiredFieldOwnerDog = ["city", "companyname", "zipCode", "address"];

        let nbField = 0;
        let nbFieldFilled = 0;
        requiredFieldDog.map((field) => {
            nbField += 1;
            if (dog[field] || dog[field] === 0 || (booleanDogFields.includes(field) && dog[field] === false)) {
                nbFieldFilled += 1;
            }
        });

        if (dog.father?.ownerName){
            nbField++;

            if(dog.father?.email || dog.father?.phone){
                nbFieldFilled++;
            }
        }

        if (dog.mother?.ownerName){
            nbField++;

            if(dog.mother?.email || dog.mother?.phone){
                nbFieldFilled++;
            }
        }

        requiredFieldOwnerDog.map((field) => {
            nbField += 1;
            if (field !== "companynamedog")
                if (dog?.owner && dog?.owner[field]) {
                    nbFieldFilled += 1;
                } else if (
                    (dog?.owner?.firstname && dog?.owner?.lastname) ||
                    dog?.owner?.companyname
                ) {
                    nbFieldFilled += 1;
                }
        });
        if (nbField == 0) {
            return 100;
        }
        return ((nbFieldFilled * 100) / nbField).toFixed(0);
    }

    async upgradeIfComplete(id, controller) {
        const censusStore = useCensusStore();
        const store = useStore()

        const census = await censusService.getCensusById(id, controller);
        const rate = this.getRateCompletion(census);

        if(census.status === 'to_validate' && rate < 100){
            await censusService.updateCensus(id, {status: "running"}, controller);
            censusStore.items = censusStore.items.map(el => el.id === census.id ? census : el)
            censusStore.lastItemId = census.id;
            store.setCensusFilterType({
                name: "Recensements en cours",
                value: "en cours",
                status: "running",
            });

            return;
        }

        if (rate == 100 && census.status != "completed") {
            await censusService.updateCensus(
                id,
                {status: "to_validate"},
                controller
            );
            censusStore.items = censusStore.items.map(el => el.id === census.id ? census : el)
            censusStore.lastItemId = census.id;
            store.setCensusFilterType({
                name: "Recensements à valider",
                value: "à valider",
                status: "to_validate",
            });
        }
    }

    async validateCensuses(ids, controller) {
        await Promise.all(
            ids.map(async (id) => {
                await censusService.updateCensus(
                    id,
                    {status: "completed"},
                    controller
                );
            })
        );
    }
}

export default new RecensementService();
