<template>
  <ContentLoader viewBox="0 0 300 25">
    <rect
      x="0"
      y="0"
      rx="3"
      ry="3"
      width="300"
      height="20"
    />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader,
  },
}
</script>