<script setup>
import iconMenuSvg from "../../assets/images/menu.svg";
import logoSvg from "../../assets/images/logo-icad.svg";
import profilSvg from "../../assets/images/profil.svg";
import iconDogs from "../../assets/images/icon-dogs.svg";
import iconRecensements from "../../assets/images/icon-recensements.svg";
import iconMore from "../../assets/images/icon-more.svg";
import iconExploitations from "../../assets/images/icon-exploitations.svg";
import iconClose from "../../assets/images/icon-close.svg";
import iconCloseYellow from "../../assets/images/icon-close-yellow.svg";
import iconHome from "../../assets/images/icon-home.svg";
import iconToValidate from "../../assets/images/icon-to-validate.svg";
import iconLogout from "../../assets/images/icon-logout.svg";
import "../../services/interceptors.service";
import authService from "../../services/auth.service";
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUnmounted, computed, ref, nextTick } from "vue";
import debounce from "lodash/debounce";
import {useStore} from "../../stores";
import cacheHelper from "../../helpers/cacheHelper";
import version from "../../../version.json"

const store = useStore();
const isOffline = computed(() => !store.getIsOnline);

const router = useRouter();
const route = useRoute();
const user = computed(() => store.user);
const overflow = ref(false)
const showDropdownUser = ref(false);
const showDropdownNav = ref(false);
const showTooltip = ref(false);
const showModalSynchronization = ref(
    localStorage.getItem("censuses_to_sync")
        ? JSON.parse(localStorage.getItem("censuses_to_sync")).length
        : false
);

const itemsUsersDropdown = [
  { name: "Comptes utilisateurs", link: "/users", isAdmin: true },
  { name: "Données personnelles", link: "/me" },
  //{name: "FAQ", link: '/'},
  { name: "Déconnexion", link: null },
];

const itemsNavDropdown = [
  { name: "Liste des exploitations", link: "/holdings" },
  { name: "Liste des recensements", link: "/census" },
  { name: "Liste des chiens de protection", link: "/dogs" },
];

let windowWidth = ref(window.innerWidth);
const onWidthChange = () => (windowWidth.value = window.innerWidth);
const onChangeStatusOnline = () => {
  if (navigator.onLine) {
    showModalSynchronization.value = localStorage.getItem("censuses_to_sync")
        ? JSON.parse(localStorage.getItem("censuses_to_sync")).length
        : false;
  }
  store.setIsOnline(navigator.onLine);
};

onMounted(() => {
  cacheHelper.removeOldRecensements();
  window.addEventListener("resize", onWidthChange);
  window.addEventListener("online", onChangeStatusOnline);
  window.addEventListener("offline", onChangeStatusOnline);
  onChangeStatusOnline();

  setTimeout(() => nextTick(() => overflow.value = true), 500)
});

onUnmounted(() => window.removeEventListener("resize", onWidthChange));
const typeScreen = computed(() => {
  if (windowWidth.value <= 1280) return "mobile";

  return "web"; // This is an unreachable line, simply to keep eslint happy.
});

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

async function getUser() {
  const res = await authService.me();
  if (!res) {
    router.push({ path: `/login` });
  }
  store.setUser(res);
}
getUser();

function logout() {
  authService.logout();
  router.push({ path: `/login` });
}

const onScroll = debounce(
    ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        store.setScroll(true);
      }
    },
    200
);

function reload() {
  location.reload();
}
</script>
<template>
  <TooltipOffline />
  <div v-if="user" class="page">
    <ModalSynchronization
        v-if="showModalSynchronization"
        @on-close="
        () => {
          showModalSynchronization = false;
          reload();
        }
      "
    />
    <header v-if="typeScreen === 'mobile'">
      <IconButton
          :icon="showDropdownNav === true ? iconCloseYellow : iconMenuSvg"
          class="secondary"
          @on-click="
          showDropdownUser = false;
          showDropdownNav = !showDropdownNav;
        "
      />
      <img :src="logoSvg" @click="router.push({ path: '/' })" />
      <IconButton
          :icon="showDropdownUser === true ? iconClose : profilSvg"
          class="primary"
          @on-click="
          showDropdownNav = false;
          showDropdownUser = !showDropdownUser;
        "
      />
    </header>
    <Dropdown
        v-if="showDropdownUser"
        :is-visible="showDropdownUser"
        type="primary"
        :items="itemsUsersDropdown"
        @on-click="logout()"
        @on-close="showDropdownUser = false"
        @click-outside="showDropdownUser = false"
    />
    <Dropdown
        v-if="showDropdownNav"
        :is-visible="showDropdownNav"
        type="secondary"
        :items="itemsNavDropdown"
        @on-click="logout()"
        @on-close="showDropdownNav = false"
        @click-outside="showDropdownNav = false"
    />

    <nav v-if="typeScreen === 'web'">
      <img class="logo" :src="logoSvg" />

      <ProfilInfo />

      <ItemMenu :icon="iconHome" name="Accueil" url="/" />
      <ItemMenu
          :icon="iconRecensements"
          name="Préparer un recensement"
          url="/census/add"
      />
      <ItemMenu
          :icon="iconDogs"
          name="Liste des chiens de protection"
          url="/dogs"
      />

      <ItemMenu
          :icon="iconRecensements"
          name="Liste des recensements"
          url="/census"
      />
      <ItemMenu
          :icon="iconExploitations"
          name="Liste des exploitations"
          url="/holdings"
      />
      <AdminPermission>
        <ItemMenu
            :icon="iconToValidate"
            name="Comptes utilisateurs"
            url="/users"
        />
      </AdminPermission>
      <ItemMenu :icon="profilSvg" name="Mes données personnelles" url="/me" />

      <label class="tw-mt-auto tw-text-xs tw-text-center">version: {{ version?.version ?? '1.0' }}</label>

      <div class="block-end">
        <div class="logout" @click="logout()">
          <img :src="iconLogout" />
          <label>Déconnexion</label>
        </div>
      </div>
    </nav>

    <main
        :class="{'tw-overflow-y-auto': overflow}"
        :style="{ paddingBottom: isOffline ? '110px' : '0px' }"
        @scroll="!isOffline && onScroll"
    >
      <router-view />
    </main>
    <nav v-if="typeScreen === 'mobile' && route.name === 'Home'">
      <div class="nav-row">
        <div class="nav-row-left">
          <div class="little-image" @click="router.push({ path: '/dogs' })">
            <img :src="iconDogs" alt="dog-icon" />
          </div>

          <div class="little-image" @click="router.push({ path: '/census' })">
            <img :src="iconRecensements" />
          </div>
        </div>

        <div class="large-image" @click="router.push({ path: '/holdings' })">
          <img :src="iconExploitations" />
        </div>
      </div>

      <div
          class="button-more"
          @click="
          () => {
            store.setCensusStep(0);
            router.push({ path: '/census/add' });
          }
        "
      >
        <div class="more-icon">
          <img :src="iconMore" />
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

@media screen and (max-width: 1280px) {
  .page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    label {
      color: $primary;
      font-family: "NunitoSans";
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      padding: 0px 30px;
      background-color: white;
      /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/

      img {
        transition: 0.3s;
      }

      img:hover {
        opacity: 0.6;
      }
    }

    main {
      flex: 1;
      padding: 0px 30px;
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
      padding-top: 10px;
      position: relative;
      margin-top: 1.55em;

      div {
        cursor: pointer;
        transition: 0.3s;
      }
      div:hover {
        opacity: 0.7;
      }

      .button-more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        border-radius: 100%;
        background-color: $primary;
        position: absolute;
        left: 43%;
        top: -25%;
        box-shadow: 0 4px 4px rgba(0, 82, 189, 0.3);
      }
    }
  }

  @supports (-webkit-touch-callout: none) {
    .page {
      height: -webkit-fill-available;
    }
  }

  .nav-row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 20px;
    background-color: #d1e2b8;

    .large-image {
      padding-right: 2em;
    }

    &-left {
      width: 100%;
      display: flex;
      align-items: center;

      .little-image:first-child {
        padding-right: 2.25em;
      }
    }
  }
}

@media screen and (min-width: 1281px) {
  .page {
    .logo {
      max-width: 200px;
    }

    nav {
      top: 0;
      position: fixed;
      width: 260px;
      min-height: calc(100vh - 40px);
      background-color: #f6f6f6;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 10px;

      .block-end {
        display: flex;

        .logout {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;

          img,
          label {
            cursor: pointer;
          }

          label {
            color: $primary;
            margin-left: 20px;
          }
        }

        .logout:hover {
          opacity: 0.7;
        }
      }
    }

    main {
      margin-left: 330px;
      margin-right: 15px;
      padding-right: 15px;
      height: 100vh;
    }
  }
}

@media screen and (max-width: 500px) {
  .page {
    header {
      padding: 0px 10px;
    }

    main {
      padding: 0px 10px;
    }

    .nav-row {
      padding: 10px 0px;
    }
  }
}
</style>
