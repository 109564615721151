import { computed } from 'vue';

export default function useForm() {

    const isRequired = computed(() => (validator) => {
        const type = validator?.required?.$params?.type ?? '';

        if (type === 'required') {
            return true;
        }

        if (type === 'requiredIf') {
            return !validator.required.$invalid;
        }

        return false;
    });

  return {
      isRequired
  };
}