import axios from 'axios'
import router from "../router";
import authService from "../services/auth.service";

const baseURL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
    baseURL,
});

axiosInstance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        router.push({path: '/login'})
    }
    return Promise.reject(error)
})

axiosInstance.interceptors.request.use(
    config => {
        const token = authService.getToken();
        if(token){
            config.headers = {
                'Authorization': `Bearer ${authService.getToken()}`,
                'Accept': 'application/ld+json',
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

export default axiosInstance;