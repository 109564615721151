import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/css/main.css";
import './assets/css/tailwind.scss';
import IconButton from "./components/icon-button/IconButton.vue";
import IconMail from "./components/icons/IconMail.vue";
import IconPhone from "./components/icons/IconPhone.vue";
import IconPassword from "./components/icons/IconPassword.vue";
import IconTooltip from "./components/icons/IconTooltip.vue";
import Button from "./components/button/Button.vue";
import AuthTemplate from "./components/auth-template/AuthTemplate.vue";
import Dropdown from "./components/dropdown/Dropdown.vue";
import KpiDashboard from "./components/kpi-dashboard/KpiDashboard.vue";
import ProfilInfo from "./components/profil-info/ProfilInfo.vue";
import ItemMenu from "./components/item-menu/ItemMenu.vue";
import LoaderList from "./components/loaders/LoaderList.vue";
import LoaderItem from "./components/loaders/LoaderItem.vue";
import TooltipOffline from "./components/tooltip-offline/TooltipOffline.vue";
import RequiredOnlineMode from "./components/required-online-mode/RequiredOnlineMode.vue";
import AdminPermission from "./components/permissions/AdminPermission";
import RecenseurPermission from "./components/permissions/RecenseurPermission";
import SwitchButton from "./components/form/SwitchButton.vue";
import RadioYesNo from "./components/form/RadioYesNo.vue";
import TextInputController from "./components/form/TextInputController/TextInputController";
import TitleLine from "./components/title-line/TitleLine.vue";
import FormLine from "./components/form/FormLine/FormLine.vue";
import SelectInputController from "./components/form/SelectInputController/SelectInputController";
import DatePickerController from "./components/form/DatePickerController/DatePickerController";
import Tooltip from "./components/form/Tooltip";
import SelectorList from "./components/form/SelectorList";
import ModalSynchronization from "./components/modal-synchronization/ModalSynchronization";
import { Toaster } from "@meforma/vue-toaster";

loadFonts();

const pinia = createPinia()
const app = createApp(App);

app.use(router);
app.use(pinia);

app.component("AuthTemplate", AuthTemplate);
app.component("IconButton", IconButton);
app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("KpiDashboard", KpiDashboard);
app.component("ProfilInfo", ProfilInfo);
app.component("ItemMenu", ItemMenu);
app.component("LoaderList", LoaderList);
app.component("LoaderItem", LoaderItem);
app.component("AdminPermission", AdminPermission);
app.component("RecenseurPermission", RecenseurPermission);
app.component("TooltipOffline", TooltipOffline);
app.component("RequiredOnlineMode", RequiredOnlineMode);
app.component("IconMail", IconMail);
app.component("IconPhone", IconPhone);
app.component("IconPassword", IconPassword);
app.component("IconTooltip", IconTooltip);
app.component("TitleLine", TitleLine);
app.component("Toaster", Toaster);
//Forms
app.component("TextInputController", TextInputController);
app.component("SelectInputController", SelectInputController);
app.component("DatePickerController", DatePickerController);
app.component("SwitchButton", SwitchButton);
app.component("RadioYesNo", RadioYesNo);
app.component("FormLine", FormLine);
app.component("Tooltip", Tooltip);
app.component("SelectorList", SelectorList);
app.component("ModalSynchronization", ModalSynchronization);

app.mount("#app");
