<script setup>
import axios from 'axios'
import {ref, onMounted, computed} from 'vue'

const census = ref(null)
const open = ref(true)
const loading = ref(true)

const props = defineProps({
  censusId: String|Number,
})

const fetchCensus = async () => {
  loading.value = true
  census.value = (await axios.get(`${process.env.VUE_APP_API_URL}censuses/${props.censusId}`)).data
  loading.value = false
}

onMounted(() => {
  fetchCensus();
})

const name = computed(() => "Recensement n°" + census.value?.id  +  " - " + census.value?.exploitation.name + " du " + new Date(census.value?.date).toLocaleDateString() + " créé par " + census.value?.person.firstname + ' ' + census.value?.person.lastname)
</script>

<template>
  <div class="tw-pt-6">
    <div
        class="tw-cursor-pointer tw-flex tw-w-full tw-items-start tw-justify-between tw-text-left tw-text-gray-900"
        @click="open = !open"
    >
      <span class="tw-text-base tw-font-semibold tw-leading-7">{{ name  }}</span>

      <span class="tw-ml-6 tw-flex tw-h-7 tw-items-center">
          <svg
              class="tw-h-6 tw-w-6" :class="{ 'tw-hidden': open }"
              fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"></path>
          </svg>
          <svg
              class="tw-h-6 tw-w-6"
              :class="{ 'tw-hidden': !(open) }" fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6"></path>
          </svg>
        </span>
    </div>

    <div class="tw-mt-2 tw-pl-5" v-if="open">
      <p v-for="dog in census?.dogs ?? []" :key="dog.id" class="tw-text-base tw-leading-7 tw-text-gray-600">Chien n°{{ dog.id }} - {{ dog.name }}</p>
    </div>
  </div>

</template>