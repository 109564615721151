<script setup>
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed, onMounted, reactive, ref, watch } from "vue";
import SelectInputController from "./SelectInputController/SelectInputController.vue";
import DatePickerController from "./DatePickerController/DatePickerController.vue";
import { useStore } from "../../stores";
import dogsService from "../../services/dogs.service";
import censusService from "../../services/census.service";
import { useRouter } from "vue-router";
import peopleService from "../../services/people.service";
import { convertIcadServiceDateToDate } from "../../helpers/dateHelpers";
import DogmastersModal from "../dogmaster-modal/DogmastersModal.vue";
import holdingService from "../../services/holding.service";
import penSvg from "../../assets/images/icon-pen.svg";
import * as dogConst from "@/config/DogConfig";
import {
  formatBody,
} from "../census/DetailCensus/Dog/handle-datas-dog/formatDatasBodies.js";
import RecensementService from "../../services/recensement.service";

const store = useStore();
const router = useRouter();
const props = defineProps({
  dog: Object,
  census: Object,
  identificationNum: String,
  mode: String,
  dataToApplyOnOthers: Object,
});
const dogGender = computed(() => {
  let gender = null;
  if (props.mode === "add") {
    if (props.dog?.codsexe === "M") {
      gender = props.dog?.sterilise === 0 ? "full_male" : "castrated_male";
    }
    if (props.dog?.codsexe === "F") {
      gender = props.dog?.sterilise === 0 ? "full_female" : "sterilized_female";
    }
  } else if (props.mode === "edit") {
    gender = props.dog?.gender;
  }
  return gender;
});
const loading = ref(true);
const formLoading = ref(false);
const fieldKeys = reactive({
  master: 0,
  owner: 0,
});
const fieldLoading = reactive({
  master: false,
  owner: false,
});
const personFormConfig = reactive({
  showModal: false,
  target: "",
  personToEdit: null,
});
const error = ref(null);
const controller = new AbortController();
const getCurrentOwner = () => {
  const dogOwner = props.census.dogs.find(
      (dog) => dog.ownerIsOwnerExploitation && dog.owner.id
  );
  return dogOwner?.owner?.id;
};
const isMasterIsOwnerDog = () => {
  if (props?.dog?.owner && props?.dog?.master && (props?.dog?.owner?.id === props?.dog?.master?.id)) {
    return true
  }
  return false;
};
const isOwnerExploitationOwner = () => {
  if (props?.dog?.owner && props.exploitation?.owner && (props?.dog?.owner?.id === props.exploitation?.owner?.id)) {
    return true
  }
  return false;
};

const isMasterExploitationOwner = () => {
  if (props?.dog?.master && props.exploitation?.owner && (props?.dog?.master?.id === props.exploitation?.owner?.id)) {
    return true
  }
  return false;
};
const state = reactive({
  identificationNum: props.identificationNum ? props.identificationNum : null,
  name: props.dog
      ? props.mode === "add"
          ? props.dog.noma
          : props.dog.name
      : null,
  birthName: props.dog
      ? props.mode === "add"
          ? props.dog.nomNai
          : props.dog.birthName
      : null,
  gender: dogGender.value,
  birthdate: props.dog
      ? props.mode === "add"
          ? convertIcadServiceDateToDate(props.dog.dtnai)
          : new Date(props.dog.birthdate)
      : null,
  category: props.dog ? props.dog.category : null,
  country: props.dog ? props.dog.country : null,
  coatType: props.dog
      ? props.dog.robe
          ? props.dog.robe
          : props.dog.coatType
              ? props.dog.coatType
              : null
      : null,
  hair: props.dog
      ? props.dog.poil
          ? props.dog.poil
          : props.dog.hair
              ? props.dog.hair
              : null
      : null,
  isOriginRegistered: props.dog
      ? props.mode === "add"
          ? props.dog.codEsp === 0
              ? "true"
              : "false"
          : Boolean(props.dog.codEsp)
              ? "true"
              : "false"
      : null,
  isCrossBreed: props.dog
      ? props.mode === "add"
          ? props.dog.croise === 0
              ? "true"
              : "false"
          : Boolean(props.dog.croise)
              ? "true"
              : "false"
      : null,
  appearance: props.dog ? props.dog.appearance : null,
  masterIsOwnerDog: isMasterIsOwnerDog(),
  ownerIsOwnerExploitation: isOwnerExploitationOwner(),
  masterIsOwnerExploitation: isMasterExploitationOwner(),
  master: props.dog && props.mode === "edit" ? props.dog.master?.id : null,
  owner: props.dog && props.mode === "edit" ? props.dog.owner?.id : null,
  dogExploit: {
    companyname: props.census.exploitation?.name,
    address: props.census.exploitation?.address,
    city: props.census.exploitation?.city,
    zipCode: props.census.exploitation?.zipCode,
    email: props.census.exploitation?.mail,
    phoneNumber: props.census.exploitation?.mobile,
  },
  ownerExploitationAlreadyExist: getCurrentOwner(),
});

watch(
    () => state?.masterIsOwnerDog,
    (isExp) => {
      state["master"] = state?.masterIsOwnerDog
          ? state["owner"]
          : props.dog && props.mode === "edit"
              ? props.dog.master?.id
              : null;
    }
);
const dogMaster = reactive({
  entries: [],
  list: [],
});

const handleChange = (value, name) => {
  state[name] = value;
  if (v$.value[name]) {
    v$.value[name].$validate();
  }
};

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    gender: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    birthdate: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
  };
});

const datePickerFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  time: "numeric",
};

const submitForm = async () => {
  try {
    let res;
    formLoading.value = true;
    const buildPeopleApiId = (id) => {
      if (id) {
        return `/api/people/${id}`;
      }
    };

    // if (state["owner"] === -2 || state["master"] === -2) {
    //   const resp = await peopleService.create(
    //     {
    //       ...state.dogExploit,
    //       exploitation: props.census.exploitation["@id"],
    //     },
    //     controller
    //   );

    //   if (state["owner"] === -2) state["owner"] = resp.id;
    //   if (state["master"] === -2) state["master"] = resp.id;
    // }

    const body = {
      ...state,
      isSterile: state.isSterile === "true",
      isCrossBreed: state.isCrossBreed,
      isOriginRegistered: state.isOriginRegistered,
    };

    if (props.dog?.id) {
      res = await dogsService.updateDog(props.dog.id, body, controller);
      store.setCorrectDog(res);
    } else {
      res = await dogsService.createDog(body, controller);
      await censusService.updateCensus(
          props.census.id,
          { dogs: [...props.census.dogs.map((dog) => dog["@id"]), res["@id"]] },
          controller
      );
      store.setCorrectDog(res);
    }

    const entities = await dogsService.updateDogEntities(
        res.id,
        {
          exploitation: props.census.exploitation["@id"],
          owner: buildPeopleApiId(state.owner),
          master: state.masterIsOwnerDog
              ? buildPeopleApiId(state.owner)
              : buildPeopleApiId(state.master),
        },
        controller
    );
    if (props.census.exploitation) {
      if (state.ownerIsOwnerExploitation) {
        holdingService.updateHolding(
            props.census.exploitation.id,
            { owner: buildPeopleApiId(state.owner) },
            controller
        );
      } else if (state.masterIsOwnerExploitation && !state.masterIsOwnerDog) {
        holdingService.updateHolding(
            props.census.exploitation.id,
            { owner: buildPeopleApiId(state.master) },
            controller
        );
      }
    }

    if (props.dataToApplyOnOthers && res) {
      const newDatas = formatBody(
          {
            ...res,
            ...props.dataToApplyOnOthers,
          },
          // props.dataToApplyOnOthers.specNbPersons
      );
      await dogsService.update(res.id, newDatas);
    }

    formLoading.value = false;
    await RecensementService.upgradeIfComplete(props.census.id);
    router.push({ path: `/census/${props.census.id}/dogs` });
  } catch (error) {
    loading.value = false;
    console.log(error);
  }
};

const refreshDogMasterEntries = async () => {
  const res = await peopleService.getFilteredByExploitationId(
      props.census.exploitation.id,
      1,
      `&pagination=false`,
      controller
  );
  dogMaster.list = res["hydra:member"];
  dogMaster.list = res["hydra:member"];
  if (props?.census?.exploitation?.owner && !dogMaster.list?.some((master) => master.id === props?.census?.exploitation?.owner?.id)) {
    dogMaster.list.push(props?.census?.exploitation?.owner)
  }
  dogMaster.entries = [
    { value: -1, label: "Nouvelle entrée" },
    ...dogMaster.list.map((dogMaster) => {
      return {
        value: dogMaster.id,
        label: dogMaster.firstname
            ? `${dogMaster.firstname} ${dogMaster.lastname}`
            : `${dogMaster.companyname}`,
      };
    }),
  ];
  // if (!state.ownerExploitationAlreadyExist)
  //   dogMaster.entries = [
  //     {
  //       value: -2,
  //       label: state.dogExploit.companyname,
  //     },
  //     ...dogMaster.entries,
  //   ];
};

const peopleFormSubmitHandler = async (value) => {
  personFormConfig.showModal = false;
  if (value.personId !== -2) {
    if ([-1, null].includes(state.master)) {
      state.master = value.emitTarget === "master" ? value.personId : null;
      fieldLoading.master = true;
    }
    if ([-1, null].includes(state.owner)) {
      state.owner = value.emitTarget === "owner" ? value.personId : null;
      fieldLoading.owner = true;
    }
    await refreshDogMasterEntries();
  } else state.dogExploit = value.newValue;
  personFormConfig.personToEdit = null;
  fieldLoading.master = false;
  fieldLoading.owner = false;
};

const peopleFormCloseHandler = async (value) => {
  fieldLoading[value.target] = true;
  personFormConfig.showModal = false;
  state[value.target] = null;
  await refreshDogMasterEntries();
  fieldLoading[value.target] = false;
};

const editIconClickHandler = async (target) => {
  personFormConfig.personToEdit =
      state[target] !== -2
          ? dogMaster.list.find((dogMaster) => dogMaster.id === state[target])
          : { id: -2, ...state.dogExploit };
  personFormConfig.target = target;
  personFormConfig.showModal = true;
};

function handleOwnerExploit(newVal) {
  const exploit = props.census.exploitation;
  if (newVal) {
    if (exploit?.owner) {
      state.owner = exploit.owner.id;
      if (state.masterIsOwnerDog) {
        state.master = exploit.owner.id
      }
    }
    state.ownerIsOwnerExploitation = true
  } else {
    if (exploit.owner && state.owner === exploit.owner.id) {
      state.owner = null;
      if (state.masterIsOwnerDog) {
        state.master = null;
      }
    }
    state.ownerIsOwnerExploitation = false
  }
}

function handleMasterExploit(newVal) {
  const exploit = props.census.exploitation;
  if (newVal) {
    if (exploit.owner) {
      state.master = exploit.owner.id;
      if (state.masterIsOwnerDog) {
        state.owner = exploit.owner.id
      }
    }
  } else {
    if (exploit.owner && state.master === exploit.owner.id) {
      state.master = null;
      if (state.masterIsOwnerDog) {
        state.owner = null;
      }
    }
  }
}

const v$ = useVuelidate(rules, state);

watch(
    () => state.owner,
    () => {
      if (state.owner === -1) {
        personFormConfig.target = "owner";
        personFormConfig.showModal = true;
      }
      if (state.owner) {
        if (state.owner === props.census.exploitation?.owner?.id) {
          state.ownerIsOwnerExploitation = true;
        } else {
          state.ownerIsOwnerExploitation = false;
        }
      } else {
        state.ownerIsOwnerExploitation = false;
      }
    }
);

watch(
    () => state.master,
    () => {
      if (state.master === -1) {
        personFormConfig.target = "master";
        personFormConfig.showModal = true;
      }
      if (state.master) {
        if (state.master === props.census.exploitation?.owner?.id) {
          state.masterIsOwnerExploitation = true;
        } else {
          state.masterIsOwnerExploitation = false;
        }
      } else {
        state.masterIsOwnerExploitation = false;
      }
    }
);

watch(
    () => dogMaster.list,
    () => {
      fieldKeys.master += 1;
      fieldKeys.owner += 1;
    }
);

onMounted(async () => {
  await refreshDogMasterEntries();
  loading.value = false;
});

const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return props.census
      && props.census.person
      && props.census.person.id === currentUser.value.id;
});
const readonly = computed(() => !isUserAdmin.value && !isSameUser.value);
</script>

<template>
  <LoaderList v-if="loading" />
  <DogmastersModal
      v-if="personFormConfig.showModal"
      :census="props.census"
      :people="personFormConfig.personToEdit"
      :emit-target="personFormConfig.target"
      @onClose="
      (value) => {
        peopleFormCloseHandler(value);
      }
    "
      @submit="
      (value) => {
        peopleFormSubmitHandler(value);
      }
    "
  />
  <form
      v-if="!loading"
      class="form-column"
      :class="personFormConfig.showModal ? 'blured' : ''"
  >
    <TextInputController
        :on-change="handleChange"
        name="name"
        :model="state.name"
        :errors="v$.name.$errors"
        :on-blur="v$.name.$touch"
        label="Nom d'usage *"
        placeholder="Filou"
        :read-only="readonly"
    />

    <TextInputController
        :on-change="handleChange"
        name="birthName"
        :model="state.birthName"
        label="Nom de naissance"
        placeholder="Rex"
        :read-only="readonly"
    />

    <SelectInputController
        name="gender"
        :model="state.gender"
        :on-change="handleChange"
        :errors="v$.gender.$errors"
        :on-blur="v$.gender.$touch"
        :options="dogConst.sexeConfig"
        label="Sexe*"
        :read-only="readonly"
    />

    <DatePickerController
        label="Date de naissance*"
        name="birthdate"
        :on-change="handleChange"
        :errors="v$.birthdate.$errors"
        :on-blur="v$.birthdate.$touch"
        :format-options="datePickerFormatOptions"
        :timepicker="false"
        custom-class="green-input"
        :model="state.birthdate"
        :read-only="readonly"
    />

    <SelectInputController
        :on-change="handleChange"
        :name="'appearance'"
        :model="state.appearance"
        label="Apparence Raciale"
        :options="dogConst.appearanceConfig"
        :read-only="readonly"
    />

    <div class="sub-title">PROPRIETAIRE DU CHIEN</div>

    <SelectInputController
        v-if="!fieldLoading.owner"
        :key="fieldKeys.owner"
        :name="'owner'"
        :model="state.owner"
        :on-change="handleChange"
        :options="dogMaster.entries"
        :label="'Propriétaire du chien'"
        :read-only="readonly"
    >
      <template #select-icon>
        <img
            v-show="Boolean(state.owner) && state.owner !== -1"
            class="edit-people-icon"
            :src="penSvg"
            @click="
            () => {
              editIconClickHandler('owner');
            }
          "
        />
      </template>
    </SelectInputController>
    <RadioYesNo
        label="Le propriétaire du chien est celui de l'exploitation"
        :is-italic="true"
        :default-value="state.ownerIsOwnerExploitation"
        :disabled="props.disabled"
        @on-change="(newValue) => (handleOwnerExploit(newValue))"
        id="ownerIsOwnerExploitation"
        :read-only="readonly"
    />
    <RadioYesNo
        label="Le propriétaire est le maître du chien"
        :is-italic="true"
        :default-value="state.masterIsOwnerDog"
        :disabled="props.disabled"
        @on-change="(newValue) => (state.masterIsOwnerDog = newValue)"
        id="masterIsOwnerDog"
        :read-only="readonly"
    />
    <div class="sub-title" v-if="!state.masterIsOwnerDog">MAITRE DU CHIEN</div>

    <!-- v-if="!fieldLoading.owner && !state.masterIsOwnerDog" -->
    <SelectInputController
        v-if="!fieldLoading.master && !state.masterIsOwnerDog"
        :key="fieldKeys.master"
        :name="'master'"
        :model="state.master"
        :on-change="handleChange"
        :options="dogMaster.entries"
        :label="'Maître du chien'"
        :read-only="readonly"
    >
      <template #select-icon>
        <img
            v-show="Boolean(state.master) && state.master !== -1"
            class="edit-people-icon"
            :src="penSvg"
            @click="
            () => {
              editIconClickHandler('master');
            }
          "
        />
      </template>
    </SelectInputController>

    <RadioYesNo
        v-if="!fieldLoading.master && !state.masterIsOwnerDog"
        label="Le maître est le propiétaire de l'exploitation ?"
        :is-italic="true"
        :default-value="state.masterIsOwnerExploitation"
        :disabled="props.disabled"
        @on-change="(newValue) => (handleMasterExploit(newValue))"
        id="masterIsOwnerExploitation"
        :read-only="readonly"
    />

    <p
        v-if="isUserAdmin && !isSameUser"
        class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}</p>

    <p
        v-if="readonly"
        class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
    >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}, modification non autorisée</p>
    <Button
        v-else
        type="primary"
        :disabled="v$.$invalid || formLoading"
        @on-click="submitForm()"
    >
      VALIDER
    </Button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../assets/css/all";

.edit-people-icon {
  position: relative;
  z-index: 2;
  margin: 0 8px;
  max-width: 21px;
}

.sub-title {
  font-weight: bold;
}

.blured {
  filter: blur(4px);
  pointer-events: none;
}
</style>
