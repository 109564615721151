<script setup>
import censusService from "../../../services/census.service";
import {reactive} from "vue";
import { useRouter } from "vue-router";

defineEmits(["onClose"]);
const props = defineProps({
  dogFound: Object,
  census: Object,
});
const state = reactive({
  isLoading: false,
});
const controller = new AbortController();
const router = useRouter();

const handleAddExistingDog = async () => {
  try {
    state.isLoading = true;
    await censusService.updateCensus(
      props.census.id,
      {
        dogs: [
          ...props.census.dogs.map((dog) => dog["@id"]),
          props.dogFound["@id"],
        ],
      },
      controller
    );
    state.isLoading = false;
    router.push({ path: `/census/${props.census.id}/dogs` });
  } catch (error) {
    state.isLoading = false;
    console.log(error);
  }
};

</script>
<template>
  <teleport to="body">
    <div class="tw-inset-0 tw-w-full tw-h-full tw-fixed tw-z-10 tw-flex tw-items-center tw-justify-center">
      <div style="background-color: rgb(0 0 0 / 0.2)" class="tw-absolute tw-h-full tw-w-full"></div>

      <div class="tw-z-10 tw-p-10 tw-bg-white tw-rounded-lg tw-shadow-xl tw-flex tw-flex-col tw-space-y-5">
        <h2 class="tw-text-sm md:tw-text-lg lg:tw-text-2xl tw-font-semibold lg:tw-font-bold">
          Le chien <span class="tw-text-primary">{{ props.dogFound.identificationNum }}</span> existe déjà
          en base de données.
        </h2>

        <span class="tw-text-xs md:tw-text-lg lg:tw-text-xl">Il est rattaché au recensement {{ census.exploitation.name }} créé par {{ census.person.firstname }} {{ census.person.lastname }}</span>

        <span class="tw-text-xs md:tw-text-lg lg:tw-text-xl">Voulez vous l'ajouter au recensement ?</span>

        <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-5">
          <Button
              type="green"
              :disabled="state.isLoading"
              @on-click="
          () => {
            handleAddExistingDog();
          }
        "
          >
            Transférer
          </Button>
          <Button type="secondary" @on-click="$emit('onClose')"> Annuler </Button>
        </div>
      </div>
    </div>
  </teleport>
</template>
