<template>
  <form :class="props.showModal ? 'blured' : ''">
    <div
        class="tw-flex tw-items-center"
    >
      <div
          style="background-color: #0086C9"
          class="tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-mr-2 tw-cursor-pointer"
          @click="saveAndLeave()"
      >
        <img :src="arrowBack" />
      </div>
      <SelectorList
          class="tw-flex-1"
        v-if="dogList"
        type="master"
        :items="dogList"
        :default="props.dogs[0]"
        @on-click="(item) => props.currentPage && props.currentPage === 'owner' && changeDog(item)"
        @on-action="() => router.push({ path: `/census/${props.censusId}/dogs` })"
    />
    </div>

    <div v-if="!loading">

      <div class="holding">
        <div class="holding-head">
          <p>INFORMATIONS SUR LE PROPRIETAIRE</p>
        </div>

        <div class="holding-body">
          <div class="holding-body-cat">
            <TitleLine
                title="Propriétaire du chien"
                type="master"
            />

            <RadioYesNo
                label="Le propriétaire du chien est celui de l'exploitation"
                :is-italic="true"
                :default-value="state.ownerIsOwnerExploitation"
                :disabled="props.disabled"
                @on-change="(newValue) => handleOwnerExploit(newValue)"
                id="ownerIsOwnerExploitation"
                :read-only="!isSameUser && !isUserAdmin"
            />
            <RadioYesNo
                label="Le propriétaire est le maître du chien"
                :is-italic="true"
                :default-value="state.masterIsOwnerDog"
                :disabled="props.disabled"
                @on-change="
                (newValue) => {
                  state.masterIsOwnerDog = newValue;
                }
              "
                id="masterIsOwnerDog"
                :read-only="!isSameUser && !isUserAdmin"
            />

            <SelectInputController
                custom-class="master-input"
                :name="'owner'"
                :model="state.owner?.id ?? -1"
                :on-change="changeOwner"
                :options="dogMaster.entries"
                :label="'Propriétaire du chien'"
                :read-only="!isSameUser && !isUserAdmin"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="socialHandleChange"
                :name="'reasonOwner'"
                :label="'Raison sociale'"
                :model="social?.reasonOwner"
                :placeholder="'Particulier'"
                :options="[
                { value: 'particular', label: 'Particulier' },
                { value: 'social', label: 'Raison Sociale' },
              ]"
            />
            <TextInputController
                v-if="social?.reasonOwner === 'social'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChangeOwner"
                :name="'companyname'"
                :model="ownerForm.companyname"
                label="Nom de la société *"
                placeholder=""
                :disabled="props.disabled"
            />
            <TextInputController
                v-if="social?.reasonOwner === 'particular'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChangeOwner"
                :name="'lastname'"
                :model="ownerForm.lastname"
                label="Nom du propriétaire *"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                v-if="social?.reasonOwner === 'particular'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChangeOwner"
                :name="'firstname'"
                :model="ownerForm.firstname"
                label="Prénom du propriétaire *"
                placeholder=""
                :disabled="props.disabled"
            />
            <TextInputController
                v-if="social?.reasonOwner === 'particular'"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChangeOwner"
                :name="'address'"
                :model="ownerForm.address"
                label="Adresse *"
                placeholder=""
                :disabled="props.disabled"
            />

            <FormLine>
              <TextInputController
                  custom-class="master-input"
                  :read-only="!isSameUser && !isUserAdmin"
                  :on-change="handleChangeOwner"
                  :name="'zipCode'"
                  :type="'zipCode'"
                  :model="ownerForm.zipCode"
                  label="Code postal *"
                  placeholder=""
                  :disabled="props.disabled"
                  :maxlength="5"
                  :errors="vOwner$.zipCode.$errors"
                  :on-blur="vOwner$.zipCode.$touch"
              />

              <TextInputController
                  custom-class="master-input"
                  :read-only="!isSameUser && !isUserAdmin"
                  :on-change="handleChangeOwner"
                  :name="'city'"
                  :model="ownerForm.city"
                  label="Ville *"
                  placeholder=""
                  :disabled="props.disabled"
              />
            </FormLine>

            <TextInputController
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChangeOwner"
                :name="'email'"
                :model="ownerForm.email"
                :errors="vOwner$.email.$errors"
                :on-blur="vOwner$.email.$touch"
                label="Mail du propriétaire *"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChangeOwner"
                :name="'phoneNumber'"
                :type="'phone'"
                :model="ownerForm.phoneNumber"
                label="Téléphone du propriétaire"
                placeholder=""
                :maxlength="10"
                :disabled="props.disabled"
            />
          </div>
        </div>
      </div>

      <div class="holding" v-if="!state.masterIsOwnerDog">
        <div class="holding-head">
          <p>INFORMATIONS SUR LE MAÎTRE</p>
        </div>
        <div class="holding-body">
          <div class="holding-body-cat">
            <RadioYesNo
                label="Le maître est le propiétaire de l'exploitation"
                :is-italic="true"
                :model="state.masterIsOwnerExploitation"
                :default-value="state.masterIsOwnerExploitation"
                :name="'masterIsOwnerExploitation'"
                :disabled="props.disabled"
                @on-change="(newValue) => handleMasterExploit(newValue)"
                id="masterIsOwnerExploitation"
                :read-only="!isSameUser && !isUserAdmin"
            />

            <TitleLine title="Identité du maître" type="master" />

            <SelectInputController
                custom-class="master-input"
                :name="'master'"
                :model="state.master?.id ?? -1"
                :on-change="changeMaster"
                :options="dogMaster.entries"
                :label="'Maître du chien'"
                :read-only="!isSameUser && !isUserAdmin"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="socialHandleChange"
                :name="'reasonMaster'"
                :label="'Raison sociale'"
                :model="social?.reasonMaster"
                :placeholder="'Particulier'"
                :options="[
                { value: 'particular', label: 'Particulier' },
                { value: 'social', label: 'Raison Sociale' },
              ]"
            />
            <TextInputController
                v-if="social?.reasonMaster === 'particular'"
                style="margin-top: 20px"
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'lastname'"
                :model="form.lastname"
                label="Nom *"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                v-if="social?.reasonMaster === 'particular'"
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'firstname'"
                :model="form.firstname"
                label="Prénom *"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                v-if="social?.reasonMaster === 'social'"
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'companyname'"
                :model="form.companyname"
                label="Nom de la société *"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                v-if="social?.reasonMaster === 'particular'"
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'address'"
                :model="form.address"
                label="Adresse *"
                placeholder=""
                :disabled="props.disabled"
            />

            <FormLine>
              <TextInputController
                  custom-class="master-input"
                  :read-only="!isSameUser && !isUserAdmin"
                  :on-change="handleChange"
                  :name="'zipCode'"
                  :type="'zipCode'"
                  :model="form.zipCode"
                  label="Code postal *"
                  placeholder=""
                  :disabled="props.disabled"
                  :maxlength="5"
              />

              <TextInputController
                  custom-class="master-input"
                  :read-only="!isSameUser && !isUserAdmin"
                  :on-change="handleChange"
                  :name="'city'"
                  :model="form.city"
                  label="Ville *"
                  placeholder=""
                  :disabled="props.disabled"
              />
            </FormLine>

            <TextInputController
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'email'"
                :model="form.email"
                label="Mail *"
                :errors="v$.email.$errors"
                :on-blur="v$.email.$touch"
                placeholder=""
                :disabled="props.disabled"
            />

            <TextInputController
                custom-class="master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                :name="'phoneNumber'"
                :type="'phone'"
                :model="form.phoneNumber"
                label="Téléphone"
                placeholder=""
                :maxlength="10"
                :disabled="props.disabled"
            />
          </div>
        </div>
      </div>

      <div class="holding">
        <div class="holding-head">
          <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
        </div>
        <div class="holding-body">
          <div class="holding-body-cat">
            <TextInputController
                style="margin-top: 10px"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChange"
                :name="'usedCp'"
                :model="form.usedCp"
                label="Nombre de chiens de protection utilisés"
                placeholder=""
                :disabled="props.disabled"
                :type="'num'"
            />

            <TextInputController
                style="margin-top: 10px"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="master-input"
                :on-change="handleChange"
                :name="'yearRegister'"
                :model="form.yearRegister"
                label="Utilisateur depuis... (Notez l'année)"
                placeholder=""
                :disabled="props.disabled"
                :type="'num'"
                :maxlength="4"
            />

            <SelectInputController
                custom-class="Select master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                name="hasGrownPuppy"
                label="A-t-il déjà produit des chiots ?"
                :model="form.hasGrownPuppy"
                :options="trueOrFalseConfig"
                :disabled="props.disabled"
            />

            <SelectInputController
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="hasCpFormation"
                label="A-t-il suivi au moins une formation sur les CP ? (plusieurs choix possibles)"
                :model="form.hasCpFormation"
                :options="hasTechnicalFormations"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="hasTechnicalFormations"
            />

            <SelectInputController
                custom-class="Select master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                name="hasTechnicalFormation"
                label="A-t-il bénéficié d'un accompagnement technique ? (plusieurs choix possibles)"
                :model="form.hasTechnicalFormation"
                :options="hasTechnicalFormations"
                :disabled="props.disabled"
                :is-multiple="true"
                :config="hasTechnicalFormations"
            />
          </div>
        </div>
      </div>
      <div class="holding">
        <div class="holding-head">
          <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
        </div>
        <div class="holding-body">
          <div class="holding-body-cat">
            <div class="tooltip-row">
              <TitleLine
                  title="Appréciation de l’envie du propriétaire/maître à intégrer un réseau de « producteurs » de chiens"
                  type="master"
              />
              <Tooltip
                  custom-class="master-input"
                  text="Questionner l’éleveur que s’il correspond au profil et peut offrir aux chiots des conditions d’élevage satisfaisantes ; sinon, ne pas aborder le sujet. "
              />
            </div>
            <SelectInputController
                custom-class="Select master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                name="profileMatch"
                label="Le maître correspond-il au profil pour intégrer le réseau de producteur selon vous ?"
                :model="form.profileMatch"
                :options="trueOrFalseConfig"
                :disabled="props.disabled"
            />
            <div v-if="form.profileMatch === true" class="sub-title">
              Est-il disposé à
            </div>
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="hasRegularVisit"
                label="Recevoir la visite régulière d'un conseiller pour avoir un suivi"
                :model="form.hasRegularVisit"
                :options="yesNoMaybeConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="breedChart"
                label="Suivre une Charte de Bonnes Pratiques d’Elevage ?"
                :model="form.breedChart"
                :options="trueOrFalseConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="couplings"
                label="Mettre en place des accouplements raisonnés (se déplacer pour faire saillir sa femelle ou son mâle,
              faire des tests génétiques aux parents et aux chiots…)?"
                :model="form.couplings"
                :options="trueOrFalseConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="hasSelfFormation"
                label="Se former / s’informer régulièrement ?"
                :model="form.hasSelfFormation"
                :options="trueOrFalseConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="batchCaring"
                label="A-t-il le temps, la place et l’envie de s’occuper d’une portée ?"
                :model="form.batchCaring"
                :options="multipleYesConfig"
                :disabled="props.disabled"
            />
            <SelectInputController
                v-if="form.profileMatch === true"
                :read-only="!isSameUser && !isUserAdmin"
                custom-class="Select master-input"
                :on-change="handleChange"
                name="brokenDogs"
                label="A-t-il le temps, la place et l’envie de produire un ou des chiens débourrés (7-8 mois) ?"
                :model="form.brokenDogs"
                :options="multipleYesConfig"
                :disabled="props.disabled"
            />
          </div>
        </div>
      </div>
      <div class="holding">
        <div class="holding-head">
          <p>EXPÉRIENCE DU PROPRIÉTAIRE ET/OU MAÎTRE EN MATIÈRE DE CHIENS DE PROTECTION</p>
        </div>
        <div class="holding-body">
          <div class="holding-body-cat">
            <div class="tooltip-row">
              <TitleLine
                  title="Conclusion technique par le recenseur"
                  type="master"
              />
              <Tooltip
                  custom-class="master-input"
                  text="A remplir hors présence de l’éleveur. Objectif : repérer les conditions vraiment défavorables."
              />
            </div>

            <SelectInputController
                custom-class="Select master-input"
                :read-only="!isSameUser && !isUserAdmin"
                :on-change="handleChange"
                name="puppyGrowth"
                label="Environnement d'élevage (pour des chiots) ?"
                :model="form.puppyGrowth"
                :options="favorableOrNotConfig"
                :disabled="props.disabled"
            />

            <div class="notes">
              <div class="sub-title">Commentaire</div>
              <textarea
                  :value="form.notes"
                  :disabled="props.disabled || (!isSameUser && !isUserAdmin)"
                  @input="form.notes = $event.target.value"
                  :style="[disabled || (!isSameUser && !isUserAdmin) ? 'background: #e5e7eb; border-color: #d1d5db !important;' : '']"
              />
            </div>
          </div>
        </div>
      </div>

      <p
          v-if="isUserAdmin && !isSameUser"
          class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
      >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}</p>

      <div
          v-if="isSameUser || isUserAdmin"
          class="buttons"
      >
        <Button
            v-if="!props.disabled"
            class="search-btn"
            :disabled="loading"
            @on-click="submitForm()"
        >
          ENREGISTRER LES INFORMATIONS
        </Button>

        <Button
            v-if="!props.disabled && props.census.status !== 'completed'"
            class="search-btn"
            :disabled="loading || censusProgression !== '100'"
            @click="emit('showModal')"
        >
          VALIDER LE RECENSEMENT
        </Button>
      </div>

      <p
          v-if="!isUserAdmin && !isSameUser"
          class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
      >Fiche créée par {{ props.census.person.firstname }} {{ props.census.person.lastname }}, modification non autorisée</p>

      <span v-if="error" class="error">{{ error }}</span>
    </div>

    <div v-if="props.dogs.length === 0">Aucun chien</div>

    <div v-if="loading">
      <loader-list />
    </div>
  </form>
</template>

<script setup>
import {
  computed,
  reactive,
  ref,
  watch,
  onUnmounted,
  onMounted,

} from "vue";
import SelectInputController from "@/components/form/SelectInputController/SelectInputController.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from '../../../../../stores';
import dogsService from "@/services/dogs.service";
import FormLine from "@/components/form/FormLine/FormLine";
import TextInputController from "@/components/form/TextInputController/TextInputController";
import Tooltip from "@/components/form/Tooltip";
import PeopleService from "@/services/people.service";
import { createToaster } from "@meforma/vue-toaster";
import LoaderList from "@/components/loaders/LoaderList";
import {
  getOwnerForm,
  getFullMasterForm,

} from "../handle-datas-dog/formatDatasForm";
import {
  getOwnerBody,
  getFullMasterBody,


} from "../handle-datas-dog/formatDatasBodies";
import { handleSubmitOwnerMaster } from "../handle-datas-dog/dogFormSubmit";
import SynchronizerCensusHelper from "../../../../../helpers/synchronizerCensusHelper";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required, minLength } from "@vuelidate/validators";
import {
  trueOrFalseConfig,
  hasTechnicalFormations,
  multipleYesConfig,
  favorableOrNotConfig,
  yesNoMaybeConfig,
} from "../../../../../config/MasterConfig";
import cacheHelper from "../../../../../helpers/cacheHelper";
import arrowBack from "@/assets/images/arrow-back.svg";

const toaster = createToaster({ position: "bottom" });

const props = defineProps({
  dogs: Array,
  exploitation: Object,
  disabled: Boolean,
  censusId: Number,
  isOffline: Boolean,
  censusProgression: String,
  showModal: Boolean,
  censusUserId: Number,
  census: Object,
  refreshDatas: Function,
  masters: Array,
  currentPage: String,
});
let correctDog = computed(() => store.getCorrectDog);
const emit = defineEmits(["showModal"]);
const error = ref();
const controller = ref(new AbortController());
const route = useRoute();
const router = useRouter();
const store = useStore();
const loading = ref(true);
const lofRegex = /[a-zA-Z]{3}-\d{10}-?\d{4}-?\d{1,2}/;
// const getCurrentOwner = () => {
//   const dogOwner = props.census.dogs.find(
//     (dog) => dog.ownerIsOwnerExploitation && dog.owner.id
//   );
//   return dogOwner?.owner;
// };

const isOwnerExploitationOwner = () => {
  if (correctDog?.value?.owner && props.census.exploitation?.owner && (correctDog?.value?.owner?.id === props.census.exploitation?.owner?.id)) {
    return true
  }
  return false;
};

const isMasterExploitationOwner = () => {
  if (correctDog?.value?.master && props.census.exploitation?.owner && (correctDog?.value?.master?.id === props.census.exploitation?.owner?.id)) {
    return true
  }
  return false;
};

const isMasterIsOwnerDog = () => {
  if (correctDog?.value?.owner && correctDog?.value?.master && (correctDog?.value?.owner?.id === correctDog?.value?.master?.id)) {
    return true
  }
  return false;
};
const state = reactive({
  error: null,
  leave: false,
  // ownerExploitationAlreadyExist: getCurrentOwner(),
  masterIsOwnerDog: isMasterIsOwnerDog(),
  ownerIsOwnerExploitation: isOwnerExploitationOwner(),
  masterIsOwnerExploitation: isMasterExploitationOwner(),
  master: correctDog?.value?.master ? correctDog.value.master : null,
  owner: correctDog?.value?.owner ? correctDog.value.owner : null,
});

const dogMaster = reactive({
  entries: [],
  list: [],
});

const refreshDogMasterEntries = async () => {
  if (!props.isOffline) {
    const res = await PeopleService.getFilteredByExploitationId(
        props.census.exploitation.id,
        1,
        `&pagination=false`,
        controller
    );
    dogMaster.list = res["hydra:member"];
    if (props?.exploitation?.owner && !dogMaster.list?.some((master) => master.id === props?.exploitation?.owner?.id)) {
      dogMaster.list.push(props?.exploitation?.owner)
    }
    dogMaster.entries = [
      { value: -1, label: "Nouvelle entrée" },
      ...dogMaster.list.map((dogMaster) => {
        return {
          value: dogMaster.id,
          label: dogMaster.firstname
              ? `${dogMaster.firstname} ${dogMaster.lastname}`
              : `${dogMaster.companyname}`,
        };
      }),
    ];
    localStorage.setItem("dogMaster", JSON.stringify({entries: dogMaster.entries.filter((ent) => ent.value !== -1), list: dogMaster.list}))
  } else {
    let cacheDogMaster = cacheHelper.getDogMasterList()
    dogMaster.entries = cacheDogMaster.entries
    dogMaster.list = cacheDogMaster.list
  }
};

const dogList = computed(() => {
  return props.dogs.sort((a, b) => a.name.localeCompare(b.name)).map((dog) => {
    return { label: `${dog.name}`, value: dog.id, test: props.census.id };
  });
});

const selectedMaster = computed(() =>
    props.masters?.find((master) => master.id === state.master.id)
);

const threeDigtsRegex = /[a-zA-Z]{3}\d{3}/;
const fifteenDigitsRegex = /\d{15}/;
const social = reactive({
  reasonOwner: correctDog.value?.owner?.companyname ? "social" : "particular",
  reasonMaster: correctDog.value?.master?.companyname ? "social" : "particular",
});

const socialHandleChange = (value, name) => {
  social[name] = value;
};

const handleChange = (value, name) => {
  form[name] = value;
};

const handleChangeOwner = (value, name) => {
  ownerForm[name] = value;
  if (state.masterIsOwnerDog) {
    form[name] = value
  }
};

async function changeMaster(item) {
  state.master = dogMaster.list?.find((master) => master.id === item);
  if (state.master) {
    if (state.master.id === props?.exploitation?.owner?.id) {
      state.masterIsOwnerExploitation = true;
    } else {
      state.masterIsOwnerExploitation = false;
    }
    if (state.master.companyname) {
      social.reasonMaster = "social"
    } else {
      social.reasonMaster = "particular"
    }
    form.firstname = state.master.firstname;
    form.lastname = state.master.lastname;
    form.companyname = state.master.companyname;
    form.address = state.master.address;
    form.zipCode = state.master.zipCode;
    form.city = state.master.city;
    form.email = state.master.email;
    form.phoneNumber = state.master.phoneNumber;
    form.yearRegister = state.master.yearRegister;
    form.hasCpFormation = state.master.hasCpFormation;
    form.hasTechnicalFormation = state.master.hasTechnicalFormation;
    form.isJoiningProductionNetwork = state.master.isJoiningProductionNetwork;
    form.profileMatch = state.master.profileMatch;
    form.hasRegularVisit = state.master.hasRegularVisit;
    form.breedChart = state.master.breedChart;
    form.hasSelfFormation = state.master.hasSelfFormation;
    form.batchCaring = state.master.batchCaring;
    form.brokenDogs = state.master.brokenDogs;
    form.puppyGrowth = state.master.puppyGrowth;
    form.usedCp = state.master.usedCp;
    form.notes = state.master.notes;
    form.couplings = state.master.couplings;
    form.hasGrownPuppy = state.master.hasGrownPuppy;
  } else {
    state.masterIsOwnerExploitation = false;
    social.reasonMaster = "particular";
    form.firstname = "";
    form.lastname = "";
    form.companyname = null;
    form.address = "";
    form.zipCode = "";
    form.city = "";
    form.email = "";
    form.phoneNumber = "";
    form.yearRegister = "";
    form.hasCpFormation = "";
    form.hasTechnicalFormation = "";
    form.isJoiningProductionNetwork = null;
    form.profileMatch = null;
    form.hasRegularVisit = "";
    form.breedChart = null;
    form.hasSelfFormation = null;
    form.batchCaring = "";
    form.brokenDogs = "";
    form.puppyGrowth = "";
    form.usedCp = "";
    form.notes = "";
    form.couplings = null;
    form.hasGrownPuppy = null;
  }
}

async function changeOwner(item) {
  state.owner = dogMaster.list?.find((master) => master.id === item);
  if (state.owner) {
    if (state.owner.id === props?.exploitation?.owner?.id) {
      state.ownerIsOwnerExploitation = true;
    } else {
      state.ownerIsOwnerExploitation = false;
    }
    if (state.owner.companyname) {
      social.reasonOwner = "social"
    } else {
      social.reasonOwner = "particular"
    }
    ownerForm.companyname = state.owner.companyname;
    ownerForm.firstname = state.owner.firstname;
    ownerForm.lastname = state.owner.lastname;
    ownerForm.address = state.owner.address;
    ownerForm.city = state.owner.city;
    ownerForm.zipCode = state.owner.zipCode;
    ownerForm.email = state.owner.email;
    ownerForm.phoneNumber = state.owner.phoneNumber;
  } else {
    state.ownerIsOwnerExploitation = false;
    social.reasonOwner = "social";
    ownerForm.companyname = "";
    ownerForm.firstname = "";
    ownerForm.lastname = "";
    ownerForm.address = "";
    ownerForm.city = "";
    ownerForm.zipCode = "";
    ownerForm.email = "";
    ownerForm.phoneNumber = "";
  }
}

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
  };
});

const ownerForm = reactive(getOwnerForm(correctDog, props.census.exploitation.id));
const form = reactive(getFullMasterForm(correctDog, props.census.exploitation.id));

const v$ = useVuelidate(rules, form);
const vOwner$ = useVuelidate(rules, ownerForm);

watch(
    () => social?.reasonOwner,
    (reasonOwner) => {
      if (reasonOwner === "social") {
        ownerForm.firstname = null;
        ownerForm.lastname = null;
      } else {
        ownerForm.companyname = "";
      }
    }
);

watch(
    () => social?.reasonMaster,
    (reasonMaster) => {
      if (reasonMaster === "social") {
        form.firstname = null;
        form.lastname = null;
      } else {
        form.companyname = "";
      }
    }
);

function handleOwnerExploit(newVal) {
  const exploit = props.census.exploitation;
  if (newVal) {
    if (!exploit.owner) {
      changeOwner(-1)
    } else {
      changeOwner(exploit.owner.id)
      if (state.masterIsOwnerDog) {
        changeMaster(exploit.owner.id)
      }
    }
    state.ownerIsOwnerExploitation = true;
  } else {
    if (state.owner?.id === exploit?.owner?.id) {
      changeOwner(-1)
      if (state.masterIsOwnerDog) {
        changeMaster(-1)
      }
    }
  }
}

function handleMasterExploit(newVal) {
  const exploit = props.census.exploitation;
  if (newVal) {
    if (!exploit.owner) {
      changeMaster(-1)
    } else {
      changeMaster(exploit.owner.id)
      if (state.masterIsOwnerDog) {
        changeOwner(exploit.owner.id)
      }
    }
  } else {
    if (state.master.id === exploit?.owner.id) {
      changeMaster(-1)
      if (state.masterIsOwnerDog) {
        changeOwner(-1)
      }
    }
  }
}

async function changeDog(item) {
  loading.value = true;

  const updatedDog = !props.isOffline
      ? await dogsService.getById(item.value, controller)
      : (await dogsService.getById(item.value, controller, props.census.id)) ??
      props.census.dogs.find((d) => d.id == item.value);
  loading.value = false;
  store.setCorrectDog(updatedDog);
  social["reasonOwner"] = updatedDog.owner?.companyname ? "social" : "particular";

  if (updatedDog) {
    if (updatedDog?.master && updatedDog?.owner && (updatedDog?.master?.id === updatedDog?.owner?.id)) {
      state.masterIsOwnerDog = true
    } else {
      state.masterIsOwnerDog = false
    }
    changeMaster(updatedDog?.master?.id ?? -1)
    changeOwner(updatedDog?.owner?.id ?? -1)
    // state.master = updatedDog?.master;
    // state.owner = updatedDog?.owner;
    // ownerForm.firstname = updatedDog?.owner?.firstname;
    // ownerForm.lastname = updatedDog?.owner?.lastname;
    // ownerForm.companyname = updatedDog?.owner?.companyname;
    // ownerForm.address = updatedDog?.owner?.address;
    // ownerForm.zipCode = updatedDog?.owner?.zipCode;
    // ownerForm.city = updatedDog?.owner?.city;
    // ownerForm.email = updatedDog?.owner?.email;
    // ownerForm.phoneNumber = updatedDog?.owner?.phoneNumber;
    // ownerForm.exploitation = `/api/exploitations/${props.census.exploitation.id}`;
    // form.firstname = updatedDog?.master?.firstname;
    // form.lastname = updatedDog?.master?.lastname;
    // form.companyname = updatedDog?.master?.companyname;
    // form.address = updatedDog?.master?.address;
    // form.zipCode = updatedDog?.master?.zipCode;
    // form.city = updatedDog?.master?.city;
    // form.email = updatedDog?.master?.email;
    // form.phoneNumber = updatedDog?.master?.phoneNumber;
    // form.exploitation = updatedDog?.master?.exploitation;
  }
}

const submitForm = async (delegate = false) => {
  if(!isSameUser.value && !isUserAdmin.value) return

  const ownerBody = getOwnerBody(ownerForm, props.census.exploitation.id);
  const masterBody = getFullMasterBody(form, props.census.exploitation.id);

  if (!props.isOffline) {
    try {
      const masterId = state.master?.id ?? -1
      const ownerId = state.owner?.id ?? -1
      let updateReturn = await handleSubmitOwnerMaster(
          correctDog,
          masterBody,
          ownerBody,
          props.census.exploitation,
          ownerId,
          masterId,
          state?.masterIsOwnerExploitation,
          state?.ownerIsOwnerExploitation,
          state?.masterIsOwnerDog
      );
      if (updateReturn === 'error') {
        state.leave !== true && toaster.error("Le formulaire n'est pas valide");
      } else {
        state.leave !== true &&
        toaster.success("Formulaire correctement enregistré");
        if (!delegate){
          router.go(0);
        }
      }
    } catch (e) {
      console.log(e);
      state.leave !== true && toaster.error("Le formulaire n'est pas valide");
    }
  } else {
    const syncService = new SynchronizerCensusHelper(props.censusId);
    syncService.updateDogOwnerMaster(correctDog.value.id, {
      correctDog: JSON.parse(JSON.stringify(correctDog.value)),
      masterBody: JSON.parse(JSON.stringify(masterBody)),
      ownerBody: JSON.parse(JSON.stringify(ownerBody)),
      exploitation: JSON.parse(JSON.stringify(props.census.exploitation)),
      ownerId: state.owner?.id ?? -1,
      masterId: state.master?.id ?? -1,
      masterIsOwnerExploitation: state?.masterIsOwnerExploitation ,
      ownerIsOwnerExploitation: state?.ownerIsOwnerExploitation ,
      masterIsOwnerDog: state?.masterIsOwnerDog,
    });
    syncService.persistInCache();
    props.refreshDatas();
    toaster.info("Données mises en cache, prêtes à être synchronisées");
    await refreshDogMasterEntries();
  }
};

onMounted(async () => {
  store.correctDog = props.dogs[0];
  await refreshDogMasterEntries();
  changeDog({value: correctDog.value.id})
  loading.value = false;
});

onUnmounted(() => {
  state.leave = true;
  // submitForm();
});

const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return props.censusUserId === currentUser.value.id;
});

const saveAndLeave = async () => {
  await submitForm(true);
  await router.replace({ path: `/census/${props.censusId}/dogs` });
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/css/_variables.scss";

.buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

h1 {
  font-size: 16px;
}

.container-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 1em;

  .title {
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .border {
    margin-left: 10px;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid $censusDog;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .lof {
    display: flex;
    align-items: center;
  }

  .title-line {
    margin-top: 1em;
    margin-bottom: 1em;
    color: $censusDog;
  }
}

.lof {
  display: flex;
  align-items: center;
}

.lof-switch {
  margin-left: 1em !important;
  margin-top: 0 !important;
  margin-bottom: 7% !important;
  width: 50% !important;
}

.light-blue {
  color: $censusDog;
}

.medium-blue {
  color: $censusMaster;
}

.orange {
  color: $censusHolding;
}

.holding {
  &-head {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1em 2em;
    color: white;
    background-color: $censusMaster;
    &.has-tooltip {
      position: relative;
    }
    p {
      margin: 0 !important;
      font-size: 15px;
    }
  }

  &-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 1em 1em;

    &-cat {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-top: 1em;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-icon-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }

      &-input {
        min-height: 70px !important;
        width: 100%;
        padding-top: 0.5em;
      }
    }
  }
}

.sub-title {
  font-size: 13px;
}

.sub-title-tooltip {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
  margin-bottom: 1em;
}

.carac {
  margin-top: 2em;

  &-selects {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    border-radius: 15px;
    background-color: rgba(216, 216, 216, 0.2);
    padding: 15px 2em;
  }
}

.date-picker {
  position: relative;
}

textarea {
  background: white;
  border: 1px solid $censusMaster;
  border-radius: 30px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  margin-top: 1em;
  height: 250px;
  width: 92%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.nav-button {
  display: flex;
  background-color: $green;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 20px;
  color: #fff;
  gap: 8px;
}

.blured {
  filter: blur(5px);
  pointer-events: none;
}

form {
  transition: filter 200ms;
}

.has-switch {
  &-switch {
    width: 50% !important;
    margin-left: 1em;
  }

  display: flex;
  align-items: center;
}

.other-specie {
  margin-top: 1em !important;
}

.period-title {
  margin-top: 0.5em;
}

.error {
  color: $danger;
}

p {
  font-size: 14px;
}

.identity-body {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(216, 216, 216, 0.2);
  padding: 2em 1em;
  margin-bottom: 1em;
}

.form-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #3f3f3f;
}

.house-icon {
  padding-right: 1em;
}

.little-title {
  margin: 0;
}
.notes {
  textarea {
    text-transform: uppercase;
  }
}
.notes-title {
  position: relative;
  display: flex;
  align-items: center;
}

.no-dogs {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 2em;
}

.label-is-lof label {
  width: 12%;
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
  position: relative;
}
</style>
