<script>
export default {
    props: {
        icon: String,
        title: String,
        secondaryText: String
    }
}
</script>

<template>
  <div class="page">
    <div class="content">
      <IconButton
        :icon="icon"
        class="secondary"
        :can-click="false"
      />

      <h4
        v-if="title"
        :style="{marginBottom:secondaryText ? '0px' : '20px' }"
      >
        {{ title }}
      </h4>
      <p v-if="secondaryText">
        {{ secondaryText }}
      </p>

      <slot />
    </div>
  </div>
</template>
<style lang="scss" scoped>
    .page{
        display: flex;
        min-height: 100vh;
        
    }
    h4{
        margin-top: 60px;
      text-align: center;
    }
    p{
        text-align: center;
        padding: 0px 20px;
    }
    .content{
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-end;
        flex-grow: 1;
    }

    @media screen and (min-width: 1281px) {
  .content{
    padding-top: 15px;
  }
}

</style>
