<template>
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.054047 17.9253L1.07578 22.3535C1.29919 23.3232 2.151 24 3.14634 24C14.6454 24 24 14.6455 24 3.14639C24 2.15175 23.3218 1.30214 22.3513 1.08094L17.9267 0.0540949C16.9328 -0.175171 15.9067 0.341204 15.498 1.28311L13.4538 6.0541C13.0795 6.9301 13.3212 7.92549 14.0558 8.53116L15.955 10.0868C14.6096 12.5588 12.5536 14.6147 10.081 15.9609L8.5275 14.0639C7.93936 13.3396 6.92198 13.0906 6.0548 13.4509L1.28527 15.4966C1.28452 15.498 1.28377 15.498 1.28306 15.498C0.341906 15.9053 -0.175219 16.9262 0.054047 17.9253ZM1.51598 17.5883C1.44858 17.2954 1.60092 16.9951 1.87777 16.875L6.63928 14.833C6.88978 14.729 7.19227 14.7993 7.36584 15.0131L9.30605 17.3804C9.5265 17.6499 9.90591 17.7334 10.2172 17.5781C13.3966 16.0136 16.0092 13.4018 17.5737 10.2231C17.7275 9.91036 17.6455 9.53316 17.376 9.31196L15.0087 7.3725C14.7941 7.19522 14.7224 6.90225 14.8329 6.64374L16.8757 1.87636C16.976 1.64494 17.2045 1.49991 17.4499 1.49991C17.496 1.49991 17.5429 1.50502 17.5898 1.51603L22.0151 2.54288C22.3015 2.60808 22.5 2.85633 22.5 3.14639C22.5 13.8178 13.8178 22.5 3.14634 22.5C2.85848 22.5 2.60217 22.2964 2.53772 22.0166L1.51598 17.5883Z"
      fill="#659F12"
    />
  </svg>
</template>
