<script setup>
import {computed, onUnmounted, reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import dogPlaceholder from '../../assets/images/dog-placeholder.svg';
import iconUpload from '../../assets/images/icon-file-upload.svg';
import dogsService from '../../services/dogs.service';
import Button from '../button/Button.vue';
import {useStore} from "../../stores";
import RecensementService from "@/services/recensement.service";
import PuppyIconComponent from "../../views/components/PuppyIconComponent.vue";

const emit = defineEmits(['onDelete']);
const props = defineProps({dog: Object, census: Object, readOnly: Boolean});
const router = useRouter();
const loading = ref(false);
const state = reactive({
  dogImg: props.dog ? props.dog.imagePath : null,
  imgReload: false,
  imgResult: null,
});
const store = useStore();
const isUserAdmin = computed(() => store.userIsAdmin)

let imagePath = computed(() => state.dogImg + '?' + new Date().getTime());

const controller = new AbortController();
const imgKey = ref(0);
const onFileInputChange = async (e) => {
  //file size validation
  if (e.target.files[0].size >= 2000000) {
    const sizeInMo = Math.round(e.target.files[0].size / 1000000 * 100) / 100;
    store.uploadSizeError = 'Fichier trop volumineux ' + sizeInMo + 'MO / 2 MO';
    return;
  }


  const file = e.target.files[0];

  if (!file) return;

  let formData = new FormData();
  if (file) {
    try {
      loading.value = true;
      formData.append('file', file);
      const res = await dogsService.setDogImage(props.dog.id, formData, controller);
      state.dogImg = res.imagePath + '?' + new Date().getTime();
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
};


onUnmounted(() => {
  store.uploadSizeError = null;
  controller.abort;
});
</script>

<template>
  <div
      :class="{
            loading,
            'minor': dog.isMinor,
              'major': !dog.isMinor
      }"
      class="dog-card tw-relative"
  >
    <div
        v-if="!readOnly"
        class="dog-upload">
      <input @change="onFileInputChange" :id="`dog-upload${dog.id}`" type="file"/>
      <label :for="`dog-upload${dog.id}`">
        <img :src="iconUpload"/>
      </label>
    </div>
    <div class="dog-detail"
         @click="router.push({ name: 'census_details_page', params: { censusId: census.id, dogId: dog.id }})">
      <div class="dog-illustration tw-relative">
        <img :src="state.dogImg ? imagePath : dogPlaceholder" :key="imgKey" alt="photo-chien"/>

        <PuppyIconComponent
            class="tw-absolute"
            style="bottom: -0.25rem; right: -0.25rem;"
            v-if="dog?.isMinor" />
      </div>
      <div class="dog-info">
        <h3>{{ dog.name }}</h3>
        <p v-if="dog.isSterile">Stérile</p>
      </div>
      <progress
          v-if="dog"
          id="recensement-progress"
          max="100"
          :value="RecensementService.getDogRateCompletion(dog)"
      >
        {{ RecensementService.getDogRateCompletion(dog) }}%
      </progress>
      <label for="recensement-progress" v-if="dog"
      >{{ RecensementService.getDogRateCompletion(dog) }}%</label
      >
    </div>
    <Button v-if="!readOnly && (props.census.status != 'completed' || isUserAdmin)" @on-click="emit('onDelete')"
            :type="dog.isMinor ? 'secondary-green' :'secondary'">Supprimer
    </Button>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/_variables.scss';

.dog-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $green;
  color: $white;
  padding: 8px 0;
  max-width: 250px;
  position: relative;

  &.loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .dog-upload {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $green;
    border-radius: 20px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='file'] {
    display: none;
  }

  .dog-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .dog-info {
    margin: auto;
    text-align: center;
  }

  h3 {
    font-size: 18px;
    color: #fff;
  }

  p {
    font-size: 16px;
  }

  p,
  h3 {
    margin: 0;
  }

  button {
    max-width: 80%;
    margin: 12px 0 6px 0 !important;
  }

  .dog-illustration {
    img {
      width: 132px;
      height: 125px;
      border-radius: 20px;
      background-color: white
    }
  }

  progress {
    margin-top: 5px;
    border: 0;
    height: 8px;
    border-radius: 12px;
    border: 1px solid $altGreen;
  }

  progress::-moz-progress-bar {
    background: $green;
    border-radius: 20px;
  }

  progress::-webkit-progress-value {
    background: $green;
    border-radius: 20px;
  }

  progress::-webkit-progress-bar {
    background: $altGreen;
    border-radius: 20px;
  }
}

.minor {
  background-color: #9DC168;
}
</style>
