import axios from 'axios';
import router from '../router/index'
import authService from './auth.service';

axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        router.push({path: '/login'})
    }
    return Promise.reject(error)
})

axios.interceptors.request.use(
    config => {
        const token = authService.getToken();
        if(token){
            config.headers = { 
                'Authorization': `Bearer ${authService.getToken()}`,
                'Accept': 'application/ld+json',
              }
        }
        return config;
    },  
    error => {
      Promise.reject(error)
  });