<template>
  <div class="container-error">
    <h1>404</h1>
    <h2>Page introuvable :(</h2>
    <p>Il semble que la page que vous avez demandé n'a pas pu être trouvée.</p>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables.scss";

.container-error{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 400px;
    margin: auto;
    background-color: $primary;
    color: white;
    border-radius: 800px;
    height: 400px;
    margin-top: 50px;

    h1{
        font-size: 70px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
    h2{
        margin-top: 25px;
        margin-bottom: 0px;
        font-size: 30px;
    }
    
    p{
        font-size: 17px;
        max-width: 280px;
    }
}
</style>