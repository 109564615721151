<script setup>
import { useStore } from '../../stores';
import { computed } from 'vue';

const props = defineProps({
  showMessage: Boolean
})

const store = useStore();
const isAdmin = computed(() => store.userIsAdmin);
</script>

<template>
  <template v-if="isAdmin">
    <slot />
  </template>

  <div
      v-if="props.showMessage && !isAdmin"
      class="container-error"
  >
    <h1>403</h1>
    <h2>Permissions requises</h2>
    <p>Vous n'avez pas les permissions requises pour accéder à cette page <!DOCTYPE html></p>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.container-error{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 400px;
  margin: auto;
  background-color: $primary;
  color: white;
  border-radius: 800px;
  height: 400px;
  margin-top: 50px;

  h1{
    font-size: 70px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  h2{
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 30px;
  }

  p{
    font-size: 17px;
    max-width: 280px;
  }
}
</style>