<script setup>
import { reactive, watch } from "vue";

const props = defineProps({
  label: String,
  isItalic: Boolean,
  isUppercase: Boolean,
  customClass: String,
  defaultValue: Boolean,
  disabled: Boolean,
});

const emit = defineEmits(["onChange"]);

const state = reactive({
  checked: props.defaultValue ? props.defaultValue : false,
});

watch(
  () => props.defaultValue,
  function () {
    state.checked = props.defaultValue;
  }
);

watch(
  () => state.checked,
  function (checked) {
    emit("onChange", checked);
  }
);
</script>
<template>
  <label class="toggle_button" :class="{ active: state.checked }">
    <span
      class="toggle_label"
      :class="{ uppercase: props.isUppercase, italic: props.isItalic }"
      >{{ props.label }}</span
    >

    <input v-model="state.checked" type="checkbox" :disabled="props.disabled" />
    <span
      class="toggle_switch"
      :class="{
        toggle_switch_enabled: state.checked,
        holding_switch_enabled:
          customClass == 'holding-switch' && state.checked,
        master_switch_enabled: customClass == 'master-switch' && state.checked,
        dog_switch_enabled: customClass == 'dog-switch' && state.checked,
      }"
    />
  </label>
</template>
<style scoped lang="scss">
@import "../../assets/css/_variables.scss";

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.toggle_button {
  margin-top: 15px;
  margin-bottom: 15px;
  vertical-align: middle;
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  .toggle_label {
    color: $black;
    font-size: 12px;
  }

  input[type="checkbox"] {
    /*opacity: 0;
        position: absolute;
        width: 1px;
        height: 1px;*/
    display: none;
  }

  .toggle_switch {
    display: inline-block;
    height: 12px;
    border-radius: 6px;
    width: 33px;
    background: #bfcbd9;
    box-shadow: inset 0 0 1px #bfcbd9;
    position: relative;
    margin-left: 10px;
    transition: all 0.25s;
  }

  .toggle_switch::after,
  .toggle_button .toggle_switch::before {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    left: 0;
    top: -3px;
    transform: translateX(0);
    transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
  }

  .toggle_switch::after {
    background: #666;
    box-shadow: 0 0 1px #666;
  }

  .toggle_switch_enabled::after {
    background: $primary;
  }

  .holding_switch_enabled::after {
    background: $censusHolding !important;
  }

  .master_switch_enabled::after {
    background: $censusMaster !important;
  }

  .dog_switch_enabled::after {
    background: $censusDog !important;
  }

  .toggle_switch::before {
    background: #4d4d4d;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    opacity: 0;
  }
}

.active {
  .toggle_switch::after,
  .toggle_switch::before {
    margin-left: 14px;
  }
}
</style>
