<template>
  <LoaderList v-if="loading"/>
  <div v-if="!loading">
    <NoteDogModal
        :is-visible="showModifyNoteModal"
        @onClose="showModifyNoteModal = false"
        :dog="state.dog"
        @onSubmitted="handleSubmitted"
    />
    <div class="header-buttons">
      <div class="go-back"
           @click="router.push(censusId ? { path: `/census/${censusId}/dogs`} : { path: `/dogs`, query: { history: 'true' } })">
        <img :src="whiteLeftArrow" alt="left-arrow"/>
        Retour
      </div>
      <!-- <div class="go-next" @click="router.push({ path: `/census/${state.dog.census.id}`})">
        Accéder au recensement
        <img :src="whiteLeftArrow" class="right-arrow" alt="right-arrow" />
      </div> -->

      <div class="tw-flex tw-gap-1">
        <DogDeleteModal
            v-if="store.userIsAdmin && state.dog?.id"
            :dog-id="state.dog.id"
            @deleted="router.push(censusId ? { path: `/census/${censusId}/dogs`} : { path: `/dogs`, query: { history: 'true' } })"
        />

        <RouterLink
            v-if="state.dog"
            :to="{
            name: 'census_details_page',
            params: {
              censusId: state.dog.census?.id,
              dogId: state.dog.id
            },
            query: {
              tab: 'holding'
            }
          }"
            class="tw-bg-holding tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-py-1 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-2 tw-no-underline"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 7C14 7.4813 13.6533 7.875 13.2222 7.875H12.4443V13.125C12.4443 13.6082 12.096 14 11.6665 14H9.33343C8.90385 14 8.55565 13.6082 8.55565 13.125V10.0623C8.55565 9.82073 8.38158 9.62484 8.16681 9.62484H5.83362C5.61886 9.62484 5.44474 9.82073 5.44474 10.0623V13.125C5.44474 13.6082 5.09653 14 4.66696 14H2.33389C1.90434 14 1.55611 13.6082 1.55611 13.125V7.875H0.777778C0.345674 7.875 2.43056e-05 7.48007 0 7C0 6.75248 0.0933819 6.51066 0.265465 6.34118L6.48769 0.216617C6.65693 0.049875 6.85069 0 7 0C7.18285 0 7.36573 0.0722148 7.51231 0.216617L13.7345 6.34118C13.9066 6.51066 14 6.75248 14 7Z"></path>
          </svg>
          <span class="tw-hidden md:tw-inline">Exploitation</span>
        </RouterLink>
      </div>
    </div>
    <p class="tw-text-red-500 tw-font-bold tw-text-center" v-if="store.uploadSizeError">{{ store.uploadSizeError }}</p>

    <div class="dog">
      <div class="dog-card">
        <div class="dog-card-img tw-relative">

          <div class="dog-upload">
            <input
                :id="`dog-upload${state?.dog?.id}`"
                type="file"
                @change="onFileInputChange"
            />
            <label :for="`dog-upload${state?.dog?.id}`">
              <img :src="iconUpload"/>
            </label>
          </div>
          <img
              v-if="!loading"
              :key="imgKey"
              class="dog-card-pic"
              :src="state.dog?.imagePath ? imagePath : dogPlaceholder"
              alt="photo-chien"
          />

          <PuppyIconComponent
              class="tw-absolute"
              style="bottom: -0.25rem; right: -0.25rem;"
              v-if="state?.dog?.isMinor" />
        </div>
        <progress
            v-if="!loading && state.dog"
            id="recensement-progress"
            max="100"
            :value="RecensementService.getDogRateCompletion(state.dog)"
        >
          {{ RecensementService.getDogRateCompletion(state.dog) }}%
        </progress>
        <label for="recensement-progress" v-if="!loading && state.dog"
        >{{ RecensementService.getDogRateCompletion(state.dog) }}%</label
        >

        <div class="dog-card-right">
          <div class="dog-card-right-name">
            <div>{{ state.dog?.name }}</div>
          </div>
          <div class="dog-card-right-birthdate">
            <label> Né le </label>
            <div>{{ new Date(state.dog?.birthdate).toLocaleDateString() }}</div>
          </div>
          <div class="dog-card-right-identification">
            <label>N° Identification </label>
            <div>{{ state.dog?.identificationNum }}</div>
          </div>
          <div class="dog-card-right-breed">
            <label> Espèce </label>
            <div>{{ dogBreed?.label }}</div>
          </div>
          <div class="dog-card-right-sexe">
            <label>Sexe</label>
            <div>{{ dogGender?.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="censuses-infos">
      <div class="primary censuses-infos-title">
        Informations sur le recensement
      </div>

      <div class="sand-cards">
        <div class="sand-cards-card">
          <div>Date de création</div>
          <div class="sand-cards-card-content">
            {{ new Date(state.dog?.census.createdAt).toLocaleDateString() }}
          </div>
        </div>
        <div class="sand-cards-card">
          <div>Recenseur</div>
          <div class="sand-cards-card-content">
            {{ referrerName }}
          </div>
        </div>
      </div>
    </div>

    <div class="primary censuses-infos-title">
      Informations sur le propriètaire du chien
    </div>
    <div class="details-card">
      <div class="details-card-row">
        <label> Nom : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.lastname }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Prénom : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.firstname }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Adresse : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.address }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Mail : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.email }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Téléphone : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.phoneNumber }}
        </div>
      </div>
    </div>

    <div class="primary censuses-infos-title">
      Informations sur le maître chien
    </div>
    <div class="details-card">
      <div class="details-card-row">
        <label> Nom : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.lastname }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Prénom : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.firstname }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Adresse : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.address }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Mail : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.email }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Téléphone : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.master?.phoneNumber }}
        </div>
      </div>
    </div>

    <div class="primary censuses-infos-title">Informations sur le chien</div>
    <div class="details-card">
      <div class="details-card-row">
        <label> Espèce : &nbsp;</label>
        <div class="details-card-content">
          {{ dogBreed?.label }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Nom d'usage (I-CAD) : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.name }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Sexe : &nbsp;</label>
        <div class="details-card-content">
          {{ dogGender?.label }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Né le (I-CAD) : &nbsp;</label>
        <div class="details-card-content">
          {{ new Date(state.dog?.birthdate).toLocaleDateString() }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Catégorie de Dangerosité (I-CAD) : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.category ? state.dog?.category : "-" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Pays de provenance (I-CAD) : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.country ? state.dog?.country : "-" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Type de robe : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.coatType ? state.dog?.coatType : "-" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Inscrit à un livre des origines : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.isLof ? "Oui" : "Non" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Croisé : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.isCrossBreed == "true" ? "Oui" : "Non" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Stérilisé : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dog?.isSterile ? "Oui" : "Non" }}
        </div>
      </div>
      <div class="details-card-row">
        <label>Apparence raciale : &nbsp;</label>
        <div class="details-card-content">
          {{ state.dogAppearance?.label }}
        </div>
      </div>
    </div>

    <div class="primary censuses-infos-title">Notes</div>
    <div class="note-card">
      {{ state.dog?.notes ? state.dog?.notes : "Non renseigné" }}
      <Button class="search-btn" @click="showModifyNoteModal = true">
        Modifier la note
      </Button>
    </div>

    <div class="primary censuses-infos-title">Prise de rendez-vous</div>
    <div class="census-rdv">
      <div
          v-for="(census, index) in state.census"
          :key="index"
          class="census-rdv-row"
      >
        Recensement
        <div class="census-rdv-row-date">
          {{ new Date(census.date).toLocaleDateString() }}
        </div>
      </div>
    </div>
    <Button class="search-btn" @click="nextMetting">
      Nouveau rendez-vous
    </Button>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {useStore} from "../../../stores";
import whiteLeftArrow from "../../../assets/images/white-left-arrow.svg";
import {useRoute, useRouter} from "vue-router";
import DogsService from "@/services/dogs.service";
import Button from "@/components/button/Button";
import NoteDogModal from "@/components/dog-modal/note-dog-modal";
import {appearanceConfig, raceConfig} from "@/config/DogConfig";
import CensusService from "@/services/census.service";
import dogsService from "@/services/dogs.service";
import {sexeConfig} from "@/config/DogConfig";
import iconUpload from "../../../assets/images/icon-file-upload.svg";
import dogPlaceholder from '../../../assets/images/dog-placeholder.svg';
import RecensementService from "@/services/recensement.service";
import DogDeleteModal from "../../components/modal/delete/DogDeleteModal.vue";
import PuppyIconComponent from "../../../views/components/PuppyIconComponent.vue";

const loading = ref(false);
let showModifyNoteModal = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();
const controller = ref(new AbortController());
const state = reactive({
  dog: null,
  census: null,
  dogIcad: null,
  dogAppearance: Object,
  imgResult: null,
});
const showDeleteModal = ref(false);

const censusId = route.params.censusId;

const species = ref();

let imagePath = computed(
    () => state.dog?.imagePath + "?" + new Date().getTime()
);

let referrerName = computed(() =>
    state.census?.length
        ? `${state.census[0].person.firstname} ${state.census[0].person.lastname}`
        : ""
);

async function getDog() {
  loading.value = true;
  const id = route.params.dogId;
  state.dog = await DogsService.getById(id, controller);
  const census = await CensusService.getCensusFiltered(
      1,
      `&dogs.id=${state.dog.id}`,
      controller
  );
  state.census = census["hydra:member"];
  state.dogAppearance = appearanceConfig.find(
      (appearance) => appearance.value === state.dog?.appearance
  );
  loading.value = false;
}

const dogBreed = computed(() =>
    raceConfig.find((race) => race?.value === state.dog?.breed)
);
const dogGender = computed(() =>
    sexeConfig.find((sex) => sex?.value === state.dog?.gender)
);

const nextMetting = () => {
  store.setDogIdNumber(state.dog.identificationNum);
  store.setDogData(state.dog);
  router.push({path: `/census/add`});
};

const imgKey = ref(0);
const onFileInputChange = async (e) => {
  if (e.target.files[0].size >= 2000000) {
    const sizeInMo = Math.round(e.target.files[0].size / 1000000 * 100) / 100;
    store.uploadSizeError = 'Fichier trop volumineux ' + sizeInMo + ' MO / 2 MO';
    return;
  }

  const file = e.target.files[0];
  let formData = new FormData();
  if (file) {
    try {
      loading.value = true;
      formData.append("file", file);
      const res = await dogsService.setDogImage(
          state.dog.id,
          formData,
          controller
      );
      state.dog.imagePath = res.imagePath + "?" + new Date().getTime();
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
};

onMounted(() => {
  store.uploadSizeError = null;
  getDog();
});

const handleSubmitted = () => {
  getDog();
  showModifyNoteModal.value = false;
}

const handleDeleteDog = () =>{
  confirm('Voulez-vous vraiment supprimer ce chien ?');
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.go-back {
  display: flex;
  justify-content: space-around;
  padding: 0.25em;
  margin-top: 1em;
  margin-bottom: 1em;
  background: $primary;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  width: 70px;
  cursor: pointer;
}

.go-next {
  display: flex;
  justify-content: space-around;
  padding: 0.25em;
  margin-top: 1em;
  margin-bottom: 1em;
  background: #6f916b;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  width: 200px;
  cursor: pointer;
}

.right-arrow {
  transform: rotate(180deg);
}

.dog {
  display: flex;
  width: 100%;
  justify-content: center;
}

.dog-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 0px 2px 12px rgba(107, 134, 179, 0.25);
  padding: 0.5em;
  min-width: 80%;

  &-img {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 20px;
    border: 1px solid #c6d6c4;
  }

  &-left {
    padding: 0.35em 0.5em;
  }

  &-pic {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    object-fit: cover;
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.5em;

    &-name {
      text-transform: uppercase;
      font-weight: bold;
    }

    &-birthdate {
      display: flex;
      align-items: center;
      font-size: 16px;
      flex-direction: column;

      div {
        font-weight: bold;
      }
    }

    &-identification {
      div {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &-breed {
      display: flex;
      align-items: center;
      flex-direction: column;

      div {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &-sexe {
      display: flex;
      align-items: center;
      flex-direction: column;

      div:first-letter {
        text-transform: uppercase;
      }

      div {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.censuses-infos {
  margin-top: 2em;
  margin-bottom: 2em;

  &-title {
    font-weight: bold;
    margin-bottom: 1em;
  }
}

.sand-cards {
  display: flex;

  &-card {
    border-radius: 14px;
    background-color: $sand;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 0.5em;
    height: 60px;
    width: 120px;

    &-content {
      font-weight: bold;
    }

    &:first-child {
      margin-right: 0.5em;
    }
  }
}

.primary-census-infos-title {
  color: $primary;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.note-card {
  padding: 1em;
  border: 1px solid #c6d6c4;
  border-radius: 25px;
  margin-bottom: 2em;
  background: rgba(101, 159, 18, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-card {
  padding: 1em;
  border: 1px solid #c6d6c4;
  border-radius: 25px;
  margin-bottom: 2em;

  label {
    white-space: nowrap;
  }

  &-row {
    font-size: 14px;
    display: flex;
  }

  &-content {
    font-weight: bold;
  }

  div:not(div:last-child) {
    margin-bottom: 0.5em;
  }
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659f12;
  color: white;
  border-radius: 20px;
  margin-bottom: 1em !important;
}

.census-rdv {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;

  &-row {
    display: flex;
    align-items: center;
    border: 1px solid #c6d6c4;
    border-radius: 18px;
    padding: 0.5em 0.5em;
    justify-content: space-between;
    font-weight: bold;

    &-date {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $altGreen;
      padding: 0.5em 0.8em;
      border-radius: 16px;
      color: $green;
      font-size: 16px;
      width: 40%;

      font-weight: 400;
    }
  }
}

.dog-upload {
  position: absolute;
  right: -8px;
  top: -6px;
  background-color: $green;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="file"] {
  display: none;
}

progress {
  margin-top: 5px;
  border: 0;
  height: 8px;
  border-radius: 12px;
}

progress::-moz-progress-bar {
  background: $green;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  background: $green;
  border-radius: 20px;
}

progress::-webkit-progress-bar {
  background: $altGreen;
  border-radius: 20px;
}

label {
  font-size: 12px;
  text-align: end;
}

@media screen and (min-width: 550px) {
  .dog-card {
    flex-direction: column;
    width: 40%;
    align-items: center;

    &-right {
      text-align: center;
    }
  }
}
</style>
