<script setup>
import { ref } from 'vue';

const props = defineProps({
  customClass: String,
  conditionalClass: Object,
  key: String,
  groupKey: String,
  title: String,
  titleStyle: String,
  state: String,
  icon: String,
  iconStyle: String,
  required: Boolean,
  isDisabled: Boolean,
  isInvisible: Boolean,
  errors: Object,
  onChange: Function,
  onBlur: Function,
  onClick: Function,
  isFulfilled: Boolean,
  multipleLines: Boolean
});
const parentClass = `form-item ${props.customClass}`;
const hasBeenTouched = ref(false);

const clickHandler = (e) => {
  hasBeenTouched.value = true;
};

const setFieldState = (errors, isFulfilled) => {
  if (errors?.length > 0) {
    return 'invalid';
  } else if (isFulfilled) {
    return 'valid';
  } else {
    return 'untouched';
  }
};
</script>

<template>
  <div
    :onClick="clickHandler"
    :data-is-fulfilled="props.isFulfilled"
    :data-groupKey="props.groupKey"
    :data-title-style="props.titleStyle"
    :data-state="setFieldState(props.errors, props.isFulfilled)"
    :class="[
      { 'is-disabled': props.isDisabled, hasIcon: $slots.icon()[0].children.length > 0 },
      parentClass,
      props.conditionalClass,
      { 'multiple-lines': props.multipleLines }
    ]"
  >
    <div class="form-item-input">
      <div class="form-item-icon">
        <slot name="icon" />
      </div>
      <div class="form-item-title">
        <div class="form-item-title-text">{{ props?.title }}{{ required ? ' *' : '' }}</div>
        <span class="form-item-title-mandatory">*</span>
      </div>
      <div class="form-item-title-ghost-clone">
        <div class="form-item-title-ghost-clone-text">{{ props?.title }}{{ required ? ' *' : '' }}</div>
        <span class="form-item-title-ghost-clone-mandatory">*</span>
      </div>
      <div class="form-item-component">
        <slot name="form-component" />
      </div>
    </div>
    <div v-if="errors?.length > 0" class="form-item-error">
      <i class="fal fa-exclamation"></i>
      <span class="form-item-error-message">{{ errors?.length ? errors[0].$message : '' }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>  
@import '../../../assets/css/_all.scss';
@import './BaseFormComponent.scss';
</style>
