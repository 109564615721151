<script setup>

import { useStore } from "../../stores";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const census = () => store.getCurrentCensusData;
const isSameUser = () => census()?.person?.id === store.getUser.id;
const readOnly = () => !store.userIsAdmin && !isSameUser;

const quit = () => {
  const path = store.quittingCensusToPath;

  store.quittingCensus = false;
  store.quittingCensusToPath = null;
  store.resetCurrentCensus();

  router.replace({ path });
}
</script>
<template>
  <transition name="bounce">
    <div
        v-if="store.quittingCensus"
        class="tw-fixed tw-inset-0 tw-bg-white tw-bg-opacity-50 tw-z-50 tw-flex tw-items-center tw-justify-center"
    >

      <div class="tw-rounded-lg tw-p-10 tw-bg-white tw-shadow-lg">
        <h1 class="tw-text-xl">
          Un recensement est en {{ census()?.status === 'completed' || readOnly() ? 'lecture' : 'cours' }}, êtes-vous sûr de vouloir quitter cette page?
        </h1>
        <div class="tw-flex tw-justify-center tw-w-full tw-space-x-5">
          <button
              type="button"
              @click="quit"
              class="tw-bg-error tw-text-white tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
          >
            Sortir
          </button>

          <button
              type="button"
              @click="store.quittingCensus = false"
              class="tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
          >
            Annuler<span class="tw-hidden lg:tw-inline"> et rester</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .dropdown {
    min-width: 90%;
    left: 2.5%;
    padding: 0.5em;
  }
}

</style>