<template>
  <ModalSynchronization
      v-if="showModalSynchronization"
      @on-close="() => (showModalSynchronization = false)"
  />
  <h1
      :class="{
      blured: showDropdownRecensement || showSearchDropdownRecensement,
    }"
  >
    Liste des recensements
  </h1>
  <div class="filter">
    <div class="filter-title tw-flex md:tw-hidden">
      {{ filterType.value }}
    </div>
    <div class="filter-title tw-hidden md:tw-flex">
      {{ filterTitle(filterType.value) }}
    </div>
    <IconButton
        class="filter-icon"
        :icon="showSearchDropdownRecensement === true ? iconClose : iconSearch"
        alt="centered-menu-icon"
        @click="
        showSearchDropdownRecensement = !showSearchDropdownRecensement;
        showDropdownRecensement = false;
      "
    />
    <IconButton
        class="filter-icon"
        :icon="showDropdownRecensement === true ? iconClose : centeredMenuIcon"
        alt="centered-menu-icon"
        @click="
        showDropdownRecensement = !showDropdownRecensement;
        showSearchDropdownRecensement = false;
      "
    />

    <div class="filter-title tw-flex tw-px-3 tw-cursor-pointer tw-space-x-2" @click="switchShowUserOnly"
         style="width: auto">
      <svg
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="tw-w-6 tw-h-6">
        <path
            v-if="store.showMyCensusesOnly"
            stroke-linecap="round" stroke-linejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
        <path
            v-else
            stroke-linecap="round" stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"/>
      </svg>

      <span class="tw-hidden md:tw-flex">{{
          store.showMyCensusesOnly ? 'Mes Recensements' : 'Tous les recensements'
        }}</span>
    </div>

    <AdminPermission>
      <IconButton
          class="filter-icon download-icon"
          :icon="iconDownload"
          alt="centered-menu-icon"
          @click="exportRecensements()"
      />
    </AdminPermission>

    <RecensementModal
        class="search-dropdown"
        :is-visible="showDropdownRecensement"
        type="primary"
        :items="itemsRecensementsDropdown"
        @onClick="
        (item) => {
          store.setCensusFilterType(item);
          showDropdownRecensement = false;
        }
      "
    />
  </div>

  <Button
      v-if="filterType.value === 'à synchroniser' && !isOffline"
      type="primary"
      @on-click="showModalSynchronization = true"
  >
    Etat des synchronisation
  </Button>
  <LoaderList v-if="loading && state.recensements.length === 0"/>

  <div class="recensement" :class="{ blured: showDropdownRecensement }">
    <div
        v-if="state.recensements.length === 0 && !loading"
        class="empty-recensement"
    >
      {{
        filterType.value !== "mes recensements"
            ? `Aucun recensement ${filterType.value}`
            : "Aucun recensement concernant cet utilisateur"
      }}
    </div>

    <div
        v-for="recensement in state.recensements"
        :key="recensement.id"
        class="recensement-row"
        @click="handleSelect(recensement.id)"
        :id="'census-' + recensement.id"
    >
      <div class="recensement-row-name">
        {{ recensement.exploitation.name }}
      </div>
      <div
          v-if="
          filterType.value !== 'en cours' && filterType.value !== 'à valider'
        "
          class="recensement-row-date"
      >
        {{
          new Intl.DateTimeFormat("fr-FR").format(new Date(recensement.date))
        }}
      </div>
      <div
          v-if="
          filterType.value === 'en cours' || filterType.value === 'à valider'
        "
          class="recensement-row-progress"
      >
        <progress
            id="recensement-progress"
            max="100"
            :value="RecensementService.getRateCompletion(recensement)"
        >
          {{ RecensementService.getRateCompletion(recensement) }}%
        </progress>
        <label for="recensement-progress"
        >{{ RecensementService.getRateCompletion(recensement) }}%</label
        >
      </div>

      <img class="recensement-row-icon" :src="rightArrow" alt="right-arrow"/>
    </div>
  </div>

  <SearchRecensementModal
      :is-visible="showSearchDropdownRecensement"
      type="primary"
      @onClose="showSearchDropdownRecensement = false"
      @queryParams="(value) => (state.params = value)"
  />

  <Button
      v-if="filterType === 'à valider' && state.recensements.length"
      type="primary"
      style="margin-top: 20px"
      @click="validateCensus()"
  >
    Finaliser les recensements
  </Button>

  <Button
      v-if="maxSize !== state.recensements.length"
      class="btn-more"
      @click="showMore()"
  >
    Voir plus
  </Button>
  <Button
      v-if="state.params.length > 0"
      class="btn-more"
      @click="state.params = ''"
  >
    Annuler le filtre
  </Button>

  <LoaderItem v-if="loading && state.recensements.length !== 0"/>
</template>

<script setup>
import centeredMenuIcon from "../../../assets/images/centered-menu-icon.svg";
import rightArrow from "../../../assets/images/arrow-right.svg";
import RecensementService from "@/services/recensement.service";
import {useRouter} from "vue-router";
import {onMounted, computed, reactive, ref, watch, nextTick} from "vue";
import {useStore} from "../../../stores";
import RecensementModal from "@/components/recensement-modals/RecensementModal";
import SearchRecensementModal from "@/components/recensement-modals/SearchRecensementModal";
import iconClose from "../../../assets/images/icon-close.svg";
import iconSearch from "../../../assets/images/icon-search.svg";
import iconDownload from "../../../assets/images/download.svg";
import Button from "@/components/button/Button.vue";
import useCensusStore from "../../../stores/useCensusStore";

const censusStore = useCensusStore()

const router = useRouter();
const store = useStore();

const loading = ref(false);
const showModalSynchronization = ref(false);
let showDropdownRecensement = ref(false);
let showSearchDropdownRecensement = ref(false);
const page = ref(0);
const maxSize = ref(0);
const controller = ref(new AbortController());
const isOffline = computed(() => !store.getIsOnline);
const filterType = computed(() => store.getCensusFilterType);

const itemsRecensementsDropdown = [
  {name: "Recensements à venir", value: "à venir", status: "scheduled"},
  {name: "Recensements en cours", value: "en cours", status: "running"},
  {
    name: "Recensements à synchroniser",
    value: "à synchroniser",
    status: "to_sync",
    user: store.getUser.id,
  },
  {
    name: "Recensements à valider",
    value: "à valider",
    status: "to_validate",
    user: store.getUser.id,
  },
  {name: "Recensements terminés", value: "terminés", status: "completed"},
  // {
  //   name: "Tous mes recensements",
  //   value: "mes recensements",
  //   status: store.getUser.id,
  // },
];

const state = reactive({
  recensements: [],
  params: "",
  status: filterType.value.status,
});

onMounted(() => {
  if (censusStore.items.length) {
    state.recensements = censusStore.items;
    page.value = censusStore.page;

    if (censusStore.lastItemId) {
      nextTick(() => {
        const element = document.querySelector('#census-' + censusStore.lastItemId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
            element.focus();
          }, 150);
        }
      });
    }

    return
  }


  getRecensements(1);
});

watch(
    () => store.loadNextData,
    async function () {
      if (
          store.loadNextData === true &&
          maxSize.value !== state.recensements.length
      ) {
        await getRecensements(page.value + 1);
        store.setScroll(false);
      }
    }
);

watch(
    () => filterType.value.status,
    function () {
      state.recensements = [];
      getRecensements(1);
      showSearchDropdownRecensement.value = false;
    }
);

watch(
    () => state.params,
    function (status) {
      state.recensements = [];
      getRecensements(1);
      showSearchDropdownRecensement.value = false;
    }
);

async function showMore() {
  if (maxSize.value !== state.recensements.length) {
    await getRecensements(page.value + 1);
  }
}

async function getRecensements(newPage) {
  loading.value = true;
  page.value = newPage;
  const res = await RecensementService.getRecensements(
      newPage,
      filterType.value.status,
      state.params,
      controller.value,
      !isOffline.value,
      filterType,
      store.showMyCensusesOnly ? currentUser.value.id : null,
  );
  state.recensements = [...state.recensements, ...res["hydra:member"]];
  if (newPage === 1) {
    maxSize.value = res["hydra:totalItems"];
  }

  censusStore.items = state.recensements;
  censusStore.page = page.value;

  loading.value = false;
}

async function validateCensus() {
  await RecensementService.validateCensuses(
      state.recensements.map((recensement) => recensement.id),
      controller
  );
  state.recensements = [];
  await getRecensements(1, controller.value);
}

async function exportRecensements() {
  const res = await RecensementService.exportRecensements();

  let blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      url = window.URL.createObjectURL(blob);
  window.open(url);
}

const currentUser = computed(() => store.getUser);

const switchShowUserOnly = async () => {
  store.showMyCensusesOnly = !store.showMyCensusesOnly;
  state.recensements = [];
  page.value = 1;
  await getRecensements(page.value);
};

const filterTitle = computed(() => (filterType) => {
  const title = {
    "mes à venir": "mes recensements à venir",
    "mes en cours": "mes recensements en cours",
    "mes à synchroniser": "mes recensements à synchroniser",
    "mes à valider": "mes recensements à valider",
    "mes terminés": "mes recensements terminés",
    "mes mes recensements": "mes recensements",
    "tous à venir": "tous les recensements à venir",
    "tous en cours": "tous les recensements en cours",
    "tous à synchroniser": "tous les recensements à synchroniser",
    "tous à valider": "tous les recensements à valider",
    "tous terminés": "tous les terminés",
    "tous mes recensements": "tous les recensements"
  }

  return title[(store.showMyCensusesOnly ? 'mes ' : 'tous ') + filterType];
});

const handleSelect = (id) => {
  censusStore.lastItemId = id;
  router.push({path: `/census/${id}/dogs`})
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/_variables.scss";

h1 {
  font-size: 25px;
  text-align: center;
  margin-bottom: 1em;
  transition: filter 200ms;
}

.filter {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: $green;
  margin-bottom: 1em;
  position: relative;

  div:first-child {
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
  }

  &-title {
    background-color: $altGreen;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-transform: uppercase;
    transition: 200ms;
    text-align: center;
  }

  &-icon {
    background-color: $altGreen;
  }
}

.recensement {
  display: flex;
  flex-direction: column;
  gap: 1em;
  transition: filter 200ms;

  &-row {
    border: 1px solid rgba(101, 159, 18, 0.3);
    border-radius: 20px;
    padding: 0.5em 0.7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 200ms;

    &:hover {
      border: 1px solid rgba(101, 159, 18, 0.3);
      box-shadow: 0 2px 15px 0 rgba(101, 159, 18, 0.3);
    }

    &-name {
      font-weight: bold;
      font-size: 16px;
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-progress {
      width: 30%;
      display: flex;
      flex-direction: column;

      label {
        margin-top: 0.5em;
        font-size: 12px;
        text-align: end;
      }
    }

    progress {
      width: 100%;
      border: 0;
      height: 8px;
      border-radius: 12px;
    }

    progress::-moz-progress-bar {
      background: $green;
      border-radius: 20px;
    }

    progress::-webkit-progress-value {
      background: $green;
      border-radius: 20px;
    }

    progress::-webkit-progress-bar {
      background: $altGreen;
      border-radius: 20px;
    }

    &-date {
      display: flex;
      justify-content: center;
      background-color: $altGreen;
      padding: 0.7em 0.8em;
      border-radius: 16px;
      color: $green;
      font-size: 16px;
      width: 25%;
    }

    &-icon {
      background-color: $sand;
      padding: 0.7em 1em;
      border-radius: 16px;
    }
  }
}

.empty-recensement {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blured {
  filter: blur(4px);
}

.btn-more {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: #659f12;
  color: white;
  border-radius: 20px;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.search-dropdown {
  top: 91% !important;
  margin-left: 0.8em;
  margin-right: 0.8em;
}
</style>
